package ru.playa.sce.views.externalSystems

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Common
import ru.playa.sce.api.External
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.ApplicableType
import ru.playa.sce.dto.ApplicableTypeGroups
import ru.playa.sce.dto.ExternalSystem
import ru.playa.sce.dto.Option
import kotlin.dom.appendText
import kotlin.js.Promise


class ExternalSystemsView : View() {

    companion object {
        const val PATH = "external-systems"
    }

    private lateinit var applicableTypes: Array<ApplicableType>

    private lateinit var datagrid: DataGrid<ExternalSystem>
    private var contentIsChanged = false

    override fun render() {
        async {
            if (Application.userIsOwner) {
                applicableTypes = Common.getApplicableTypes().await().data
                contentIsChanged = false

                dom.apply {
                    clrPageHeader("Внешние справочники") {
                        clrLink("Объекты") {
                            if (contentIsChanged) {
                                clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                    modalDialogConfiguration = {
                                        this.size = ModalDialog.Size.Small
                                    }
                                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                        contentIsChanged = true
                                        Navigation.start()
                                    }
                                    clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                }
                            } else Navigation.start()
                        }
                        clrButtonGroup {
                            clrButton("Добавить внешний справочник") {
                                style = ButtonStyle.Secondary
                                iconShape = IconShape.Plus
                                onClickFunction = {
                                    if (contentIsChanged) {
                                        clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                            modalDialogConfiguration = {
                                                this.size = ModalDialog.Size.Small
                                            }
                                            clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                contentIsChanged = true
                                                Navigation.ExternalSystems.add()
                                            }
                                            clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                        }
                                    } else Navigation.ExternalSystems.add()
                                }
                            }
                        }
                    }
                    datagrid = clrDatagrid {
                        clrColumn("Код", 1) {
                            innerText = it.code
                        }
                        clrColumn("Наименование", 3) {
                            innerText = it.name
                        }
                        getDataFunction = {
                            External.getAll()
                        }
                        clrOverallDetails { overalDetailItem ->
                            async {
                                createEditContent(overalDetailItem) { item ->
                                    External.editExternalSystem(item).then {
                                        datagrid.render()
                                    }
                                }
                            }
                        }
                        onExpandableRowCloseFunction = {
                            Promise { d, _ ->
                                if (contentIsChanged) {
                                    clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                        modalDialogConfiguration = {
                                            this.size = ModalDialog.Size.Small
                                        }
                                        clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                            d(true)
                                        }
                                        clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                                            d(false)
                                        }
                                    }
                                } else {
                                    d(true)
                                }
                            }
                        }
                        onExpandableRowOpenFunction = {
                            contentIsChanged = false
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }

    private fun HTMLDivElement.createEditContent(ext: ExternalSystem?, onSaveClick: (ExternalSystem) -> Unit) {
        var code: Input? = null
        var name: Input? = null
        var saveButton: ButtonGroupButton? = null
        var saveButtonGroup: ButtonGroup? = null

        fun validate() {
            if (name?.value?.isBlank() != false || code?.value?.isBlank() != false) {
                saveButton?.isDisabled = true
                saveButtonGroup?.render()
            } else if (name?.value?.isNotBlank() == true && code?.value?.isNotBlank() == true) {
                saveButton?.isDisabled = false
                saveButtonGroup?.render()
                contentIsChanged = true
            }
        }

        val selectedApplicableTypes = arrayListOf<ApplicableType>()
        div().apply {
            style.marginLeft = "24px"
            clrForm {
                clrBlock {
                    clrGroup("Код справочника:") {
                        code = clrInput {
                            type = InputType.Text
                            this.value = ext?.code ?: ""
                            onInputFunction = {
                                validate()
                            }
                        }
                    }
                    clrGroup("Наименование справочника:") {
                        name = clrInput {
                            type = InputType.Text
                            this.value = ext?.name ?: ""
                            onInputFunction = {
                                validate()
                            }
                        }
                    }
                }
                clrBlock("Для каких объектов применим справочник:") {
                }
            }
            clrGridRow {
                ApplicableTypeGroups.values().forEach { applicableTypeGroup ->
                    clrGridColumn(6, 6, 3, 3, 3) {
                        style.width = "${100 / ApplicableTypeGroups.values().size}%"
                        classList.remove("col-sm-6")
                        span {
                            style.apply { marginBottom = "8px"; fontWeight = "bold" }
                            appendText(applicableTypeGroup.groupName)
                        }
                        applicableTypeGroup.groupTypes.forEach { typeGroup ->
                            val applicableType = applicableTypes.find { it.code == typeGroup }
                            if (applicableType != null) {
                                clrCheckbox(applicableType.name) {
                                    this.isChecked = ext?.applicableTypes?.any {
                                        if (it.id == applicableType.id) {
                                            selectedApplicableTypes.add(applicableType)
                                            true
                                        } else
                                            false
                                    } ?: false

                                    this.onChangeFunction = {
                                        validate()
                                        if (isChecked) {
                                            selectedApplicableTypes.add(applicableType)
                                        } else
                                            selectedApplicableTypes.remove(applicableType)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div {
                style.width = "100%"
                saveButtonGroup = clrButtonGroup {
                    this.onPostRender = {
                        this.getHTMLElement().style.setProperty("float", "left")
                    }
                    saveButton = clrButton("Сохранить") {
                        iconShape = IconShape.Check
                        style = ButtonStyle.Primary
                        isDisabled = true
                        onClickFunction = { _ ->
                            val result = ExternalSystem(ext?.id ?: 0, code?.value ?: throw IllegalStateException(),
                                    name?.value ?: throw IllegalStateException(),
                                    selectedApplicableTypes.map { Option(it.id, it.name, false) }.toTypedArray())
                            onSaveClick(result)
                        }
                    }
                }
                clrButtonGroup {
                    this.onPostRender = {
                        this.getHTMLElement().style.setProperty("float", "right")
                    }
                    this.menuPosition = ButtonGroupMenuPosition.BottomRight
                    ext?.let { _ ->
                        clrButton("Удалить") {
                            style = ButtonStyle.WarningOutline
                            onClickFunction = { _ ->
                                clrChoiceDialog("Вы уверены что хотите удалить элемент справочника?") {
                                    modalDialogConfiguration = {
                                        size = ModalDialog.Size.Small
                                    }
                                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                        async {
                                            External.delete(ext.id).then {
                                                datagrid.render()
                                            }
                                        }
                                    }
                                    clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
