@file:Suppress("unused")

package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.ObjectDescription
import ru.playa.sce.dto.ObjectDescriptionType
import ru.playa.sce.dto.Option


object Descriptions {

    fun get(objectType: String, id: Int) = Network.request<ListResult<ObjectDescription>>(Network.Method.GET,
            "/sce-web/api/user/objects/$objectType/description/$id")

    fun create(objectType: String, description: ObjectDescription) = Network.send(Network.Method.POST, description,
            "/sce-web/api/user/objects/$objectType/description")

    fun edit(objectType: String, description: ObjectDescription) = Network.send(Network.Method.PUT, description,
            "/sce-web/api/user/objects/$objectType/description")

    fun delete(objectType: String, objectId: Int, descriptionId: Int) = Network.requestNoResult(Network.Method.DELETE,
            "/sce-web/api/user/objects/$objectType/description/$objectId/$descriptionId")

    fun getTypes(pageSize: Int = 50, start: Int = 0, orderField: String = "name", reverseOrder: Boolean = false) =
            Network.request<ListResult<ObjectDescriptionType>>(Network.Method.GET,
                    "/sce-web/api/user/object/descriptiontype/search?pageSize=$pageSize&page=$start&orderField=$orderField&reverseOrder=$reverseOrder")

    fun createType(type: ObjectDescriptionType) = Network.send(Network.Method.POST, type, "/sce-web/api/user/object/descriptiontype")

    fun editType(type: ObjectDescriptionType) = Network.send(Network.Method.PUT, type, "/sce-web/api/user/object/descriptiontype")

    fun deleteType(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/object/descriptiontype/$id")

    fun getByAssetId(objId: Int, assetId: Int) = Network.request<ListResult<Option>>(Network.Method.GET, "/sce-web/api/user/objects/asset/object/$objId/asset/$assetId/description")

    fun linkDescriptionsToAsset(objId: Int, assetId: Int, descriptions: Array<Int>) = Network.sendNoResult(Network.Method.POST, descriptions, "/sce-web/api/user/objects/asset/object/link/$objId/$assetId/description/package")
}
