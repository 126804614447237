package ru.playa.sce.views.app

import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.route.js.View
import kotlin.dom.appendText


class PageNotFoundView : View() {
    override fun render() {
        dom.apply {
            h1 {
                appendText("Запрашиваемая страница не найдена")
            }
        }
    }
}