package ru.playa.sce.views

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.Blob
import org.w3c.files.get
import redux.ReduxExt
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.icons.clrIcon
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Assets
import ru.playa.sce.api.Descriptions
import ru.playa.sce.api.TextBlocks
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.common.buildAssetsGridLayout
import ru.playa.sce.views.common.buildImageAssetCard
import kotlin.browser.window
import kotlin.dom.appendText
import kotlin.dom.clear
import kotlin.js.Promise

/**
 * Базовый абстрактный класс отображения версионируемого объекта.
 * @param T Тип экземпляра объекта унаследованного от WebObject.
 * @param id Идентификатор версионируемого объекта.
 * @param coreId Идентификатор ядра версионируемого объекта.
 * @param initialTab Вкладка, которая должна быть показана когда страница загрузится.
 * @constructor Используется в качестве конструктора при наследовании.
 */
abstract class BaseVOView<T : WebObject>(
        val id: Int,
        val coreId: Int,
        var initialTab: String,
        private val loadDescription: Boolean = true
) : View() {

    enum class StoreFields {
        PHOTO_TAB_SCROLL_TOP
    }

    /**
     * Название типа версионируемого объекта.
     * Требуется для большинства запросов к серверу в качестве параметра.
     */
    protected abstract var objectType: String
    /**
     * Экземпляр версионируемого объекта.
     * Инициализируется с помощью метода [getVO]
     */
    protected lateinit var vo: T
    /**
     * Черновик версионируемого объекта - [objectStatus] == DRAFT.
     * Инициализируется изначально только в объектах [vo] с версией черновик.
     * Инициализируется с помощью метода [getDraft]
     */
    protected lateinit var draft: T
    /**
     * Массив строк привилегий пользователя. Определяет допустимость действий текущего пользователя.
     * Инициализируется с помощью метода [getPrivileges]
     */
    protected lateinit var privileges: Array<String>
    /**
     * Массив объектов, отображаемых во вкладках (изображения, файлы и пр.)
     * Инициализируется с помощью метода [reloadAssets]
     */
    protected lateinit var assets: Array<Asset>
    /**
     * Массив описаний объекта. Используется на вкладке описаний и фотографий.
     * Инициализируется с помощью метода [reloadDescriptions]
     */
    protected lateinit var descriptions: Array<ObjectDescription>
    /**
     * Статус объекта
     */
    protected lateinit var objectStatus: ObjectStatus

    /**
     * Параметры пользователя для работы с фотографиями и иллюстрациями.
     * Инициализируется с помощью метода [getPhotoRatio]
     */
    private lateinit var photoRatio: Ratio
    /**
     * Элементы справочника "Типы иллюстраций"
     * Инициализируется с помощью метода [getFlags]
     */
    private lateinit var imageFlags: Array<Flag>
    /**
     * Массив текстовых блоков.
     * Инициализируется поиском [TextBlocks.get] с фильтрацией по текущему объекту.
     */
    private lateinit var textBlocks: Array<Text>
    /**
     *  Компонент - Заголовок страницы.
     */
    private lateinit var pageHeader: PageHeader
    /**
     * Вкладка фотографий (инициализируется в потомке)
     */
    private lateinit var photoTab: Tab
    private lateinit var photoContainer: Container
    /**
     * Вкладка иллюстраций (инициализируется в потомке)
     */
    private lateinit var illustrationsTab: Tab
    /**
     * Вкладка файлов.
     */
    private lateinit var filesTab: Tab
    /**
     * Вкладка новостей.
     */
    private lateinit var newsTab: Tab
    private lateinit var newsContainer: Container
    /**
     * Вкладка баннеров.
     */
    private lateinit var bannersTab: Tab
    private lateinit var bannersContainer: Container

    private lateinit var descContainer: Container
    /**
     * Таблица списка файлов.
     */
    private lateinit var fileListTable: Table

    /**
     * Получение экземпляра версионируемого объекта.
     * Инициализирует [vo]
     */
    protected abstract fun getVO(): Promise<T>

    /**
     * Получение черновика версионируемого объекта.
     * Инициализирует [draft].
     * Вызов метода провоцирует изменения версий объекта на сервере.
     * Для предотвращения неосознанных действий с версиями используется метод [checkVersionObjectStatus]
     */
    protected abstract fun getDraft(): Promise<T>

    /**
     * Переход в черновик версионируемого объекта.
     * @param tab Инициализируемая вкладка
     */
    protected abstract fun toDraftTab(tab: String)

    /**
     * Изменение статуса объекта [objectStatus] на "Утвержден"
     */
    protected abstract fun approve(): Promise<Unit>

    /**
     * Изменение статуса объекта [objectStatus] на "Опубликован"
     */
    protected abstract fun publish(): Promise<Unit>

    /**
     * Архивирование объекта
     * @param id Идентификатор архивируемого объекта
     */
    protected abstract fun delete(id: Int): Promise<Unit>

    /**
     * Восстановление архивированного объекта
     */
    protected abstract fun restoreArchived(): Promise<T>

    /**
     * Построение интерактивного пути из ссылок. Например: Объекты/Страны/Австралия
     */
    protected abstract fun PageHeader.buildPageHeaderPathPart()

    /**
     * Переход к родительской странице.
     */
    protected abstract fun navigateToParent()

    /**
     * Переход к другому объекту
     * @param id Идентификатор версии объекта
     * @param coreId Идентификатор ядра объекта
     */
    protected abstract fun navigateTo(id: Int, coreId: Int)

    /**
     * Получение списка привилегий.
     * Инициализирует [privileges]
     */
    protected open fun getPrivileges() = Accounts.getObjectTypePrivileges(objectType)

    /**
     * Получить параметры для работы с фотографиями и иллюстрациями.
     * Инициализирует [photoRatio]
     */
    protected open fun getPhotoRatio() = Assets.getRatio(objectType)

    /**
     * Получить типы иллюстраций. Можно переопределить в потомке т.к тип не всегда соответствует типу объекта.
     * Инициализирует [imageFlags]
     */
    protected open fun getFlags(): Promise<ListResult<Flag>> {
        return Assets.getFlags(objectType)
    }

    /**
     * @return Скрывать кнопку просмотра или нет. Можно переопределить для расширения логики
     */
    protected open fun isViewButtonDisabled(urlProperty: Option?): Boolean {
        return urlProperty == null
    }

    /**
     * Переход на страницу редактирования описания
     * @params desc объект описания
     */
    private fun editDescription(desc: ObjectDescription, objType: String) {
        Navigation.DescriptionEditor.editDescription(objType, draft.id, coreId, desc.id)
    }

    /**
     * Переход на страницу создания описания
     */
    private fun addDescription(objType: String) = Navigation.DescriptionEditor.addDescription(objType, draft.id, draft.coreId)

    /**
     * Получить ассеты. Инициализирует [assets]
     */
    private fun reloadAssets(id: Int = vo.id) = async {
        assets = Assets.get(id).await().data
    }

    /**
     * Получить описания. Инициализирует [descriptions]
     */
    private fun reloadDescriptions(id: Int = vo.id) = async {
        descriptions = Descriptions.get(objectType, id).await().data.apply { sortBy { it.name } }
    }

    /**
     * Базовая инициализация
     */
    protected fun initEditorData() = async {
        vo = getVO().await()
        privileges = getPrivileges().await()
        objectStatus = ObjectStatus.valueOf(vo.status)
        if (objectStatus == ObjectStatus.DRAFT)
            draft = getDraft().await()
        reloadAssets().await()
        descriptions = if (loadDescription) Descriptions.get(objectType, vo.id).await().data.sortedBy { it.name }.toTypedArray() else emptyArray()
        textBlocks = TextBlocks.get(pageSize = 100, coreId = coreId).await().data
        imageFlags = getFlags().await().data
        photoRatio = getPhotoRatio().await()
    }

    /**
     * Контроль изменения версии объекта.
     * По факту внесения изменений в текущую версию объекта [vo], проверяет ее статус [objectStatus]
     * и совершает необходимые в данной ситуации действия.
     * @param tab используется в [toDraftTab]
     * @param defaultPublishedAction позволяет переписать дефолтный переход в Черновик выполнением [callback]
     * @param callback функция, выполняемая в случае если пользователю разрешено изменять текущую версию.
     */
    protected fun checkVersionObjectStatus(
            tab: String,
            defaultPublishedAction: Boolean = true,
            callback: () -> Unit
    ): Promise<Any> {
        return async {
            if (objectStatus != ObjectStatus.ARCHIVED) {
                if (objectStatus != ObjectStatus.DRAFT) draft = getDraft().await()
                when (objectStatus) {
                    ObjectStatus.APPROVED -> {
                        objectStatus = ObjectStatus.DRAFT
                        val draft = getDraft().await()

                        if (defaultPublishedAction) {
                            this@BaseVOView.clear()
                            this@BaseVOView.render()
                            toDraftTab(tab)
                        } else callback()
                    }
                    ObjectStatus.PUBLISHED -> {
                        Application.applicationLayout.topLevelAlert.show {
                            val alertScope = this
                            clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                button("btn alert-action") {
                                    innerText = "OK"
                                    onclick = {
                                        alertScope.items.clear()
                                        alertScope.hide()
                                    }
                                }
                            }
                            style = Alert.Style.WARNING
                        }
                        if (defaultPublishedAction) {
                            toDraftTab(tab)
                        } else callback()
                    }
                    else -> callback()
                }
            } else {
                dom.clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                        async {
                            draft = restoreArchived().await()
                            vo = draft
                            objectStatus = ObjectStatus.DRAFT
                            pageHeader.label = objectStatus.displayName
                            pageHeader.render()
                            this@BaseVOView.clear()
                            this@BaseVOView.render()
                            callback()
                        }
                    }
                    clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                }
            }
        }
    }

    /**
     * Создание вкладки описаний
     * @param objType Название типа объекта. Может отличаться от [objectType]
     */
    protected fun Tabs.createDescriptionsTab(objType: String = objectType) {
        clrTab("Описания", initialTab == "desc") {
            /*clrButton("Добавить") {
                style = ButtonStyle.Secondary
                iconShape = IconShape.Plus
                isDisabled = !privileges.contains("EDIT")
                onClickFunction = {
                    checkVersionObjectStatus("desc") {
                        addDescription(objType)
                    }
                }
            }
            clrDatagrid<ObjectDescription> {
                clrColumn("Название", 2) {
                    appendText(it.name)
                }
                clrColumn("Тип описания", 1) {
                    appendText(it.descriptionType?.name ?: "—")
                }
                clrColumn("Язык", 1) {
                    appendText(it.language?.name ?: "—")
                }
                clrColumn("Краткое описание", 3) {
                    appendText(it.briefDescription)
                }
                if (privileges.contains("EDIT")) {
                    clrColumn("", 1) { desc ->
                        clrIcon(IconShape.Pencil) {
                            onclick = { _ ->
                                checkVersionObjectStatus("desc", false) {
                                    async {
                                        val draftDescriptions = Descriptions.get(objectType, draft.id).await().data
                                        if (draftDescriptions.any { it.id == desc.id }) {
                                            editDescription(desc, objType)
                                        } else toDraftTab("desc")
                                    }
                                }
                            }
                            this.style.color = "#0079b8"
                            this.style.cssFloat = "right"
                            this.style.cursor = "pointer"
                            this.style.marginRight = "24px"
                            this.setAttribute("title", "Редактировать")
                        }
                        this.style.alignSelf = "right"
                    }
                }
                clrOverallDetails { objDesc ->
                    async {
                        buildDetails(objDesc, this@clrDatagrid, objType)
                    }
                }
                pageSize = 10
                dataList = descriptions.toList()
            }*/
            createDescriptionsTab(objType)
        }
    }

    protected fun HTMLElement.createDescriptionsTab(objType: String = objectType) {
        descContainer = container {
            clrButton("Добавить") {
                style = ButtonStyle.Secondary
                iconShape = IconShape.Plus
                isDisabled = !privileges.contains("EDIT")
                onClickFunction = {
                    checkVersionObjectStatus("desc") {
                        addDescription(objType)
                    }
                }
            }
            clrDatagrid<ObjectDescription> {
                clrColumn("Название", 2) {
                    appendText(it.name)
                }
                clrColumn("Тип описания", 1) {
                    appendText(it.descriptionType?.name ?: "—")
                }
                clrColumn("Язык", 1) {
                    appendText(it.language?.name ?: "—")
                }
                clrColumn("Краткое описание", 3) {
                    appendText(it.briefDescription)
                }
                if (privileges.contains("EDIT")) {
                    clrColumn("", 1) { desc ->
                        clrIcon(IconShape.Pencil) {
                            onclick = { _ ->
                                checkVersionObjectStatus("desc") {
                                    async {
                                        editDescription(desc, objType)
                                    }
                                }
                            }
                            this.style.color = "#0079b8"
                            this.style.cssFloat = "right"
                            this.style.cursor = "pointer"
                            this.style.marginRight = "24px"
                            this.setAttribute("title", "Редактировать")
                        }
                        this.style.alignSelf = "right"
                    }
                }
                clrOverallDetails { objDesc ->
                    async {
                        buildDetails(objDesc, this@clrDatagrid, objType)
                    }
                }
                pageSize = 10
                dataList = descriptions.toList()
            }
        }

    }


    /**
     * Создание вкладки иллюстраций
     */
    protected fun Tabs.createIllustrationTab() {
        illustrationsTab = clrTab("Иллюстрации", initialTab == "illustrations", "Титульная картинка, логотипы, флаги, ...") {
            //            clrTable {
//                clrBody {
//                    imageFlags.forEach { imageFlag ->
//                        val illustration = assets.filter { it.assetType == AssetType.IMAGE.name }.find { it.imageType != null && it.imageType?.code == imageFlag.code }
//                        val imageFlagRatio = Application.account.properties.find { it.code == "ru.playa.sce.image.ratio.${imageFlag.code}" }
//                        clrRow {
//                            clrCell {
//                                div {
//                                    style.apply { display = "flex"; flexDirection = "column"; alignItems = "flex-start" }
//                                    div {
//                                        style.marginLeft = "12px"
//                                        appendText(imageFlag.name)
//                                    }
//                                    div {
//                                        style.apply { display = "flex"; flexDirection = "column"; alignItems = "flex-start" }
//                                        if (illustration != null) {
//                                            val replaceFileInput = input {
//                                                type = "file"
//                                                accept = "image/*"
//                                                hidden = true
//                                                onchange = {
//                                                    val file = (it.target as HTMLInputElement).files?.item(0)
//                                                    if (file != null) {
//                                                        async {
//                                                            Assets.sendObjectImage(
//                                                                    form = newForm(),
//                                                                    blob = file,
//                                                                    objId = draft.id,
//                                                                    coreId = draft.coreId,
//                                                                    fileName = file.name,
//                                                                    name = "",
//                                                                    descriptions = arrayListOf(),
//                                                                    selectedFlags = arrayListOf(imageFlag)
//                                                            ).await()
//                                                            Assets.removeLinkWithObject(draft.id, illustration.id).await()
//                                                            assets = Assets.get(draft.id).await().data
//                                                            illustrationsTab.rebuild()
//                                                        }
//                                                    }
//                                                }
//                                            }
//                                            clrButton("Изменить") {
//                                                style = ButtonStyle.Flat
//                                                iconShape = IconShape.Pencil
//                                                isDisabled = !privileges.contains("EDIT")
//                                                onClickFunction = {
//                                                    checkVersionObjectStatus("illustrations") {
//                                                        if (imageFlagRatio == null) {
//                                                            replaceFileInput.click()
//                                                        } else {
//                                                            dom.imageUploadDialog {
//                                                                headerName = imageFlag.name
//                                                                ratio = imageFlagRatio.name
//                                                                descriptions = emptyArray()
//                                                                onSaveData = { blobObject, newFileName, _, _ ->
//                                                                    async {
//                                                                        Assets.sendObjectImage(
//                                                                                form = newForm(),
//                                                                                blob = blobObject,
//                                                                                objId = draft.id,
//                                                                                coreId = draft.coreId,
//                                                                                fileName = newFileName,
//                                                                                name = "",
//                                                                                descriptions = arrayListOf(),
//                                                                                selectedFlags = arrayListOf(imageFlag)
//                                                                        ).await()
//                                                                        Assets.removeLinkWithObject(draft.id, illustration.id).await()
//                                                                        assets = Assets.get(draft.id).await().data
//                                                                        illustrationsTab.rebuild()
//                                                                    }
//                                                                }
//                                                            }
//                                                        }
//                                                    }
//                                                }
//                                            }
//                                            clrButton("Удалить") {
//                                                style = ButtonStyle.Flat
//                                                iconShape = IconShape.Trash
//                                                isDisabled = !privileges.contains("EDIT")
//                                                onClickFunction = {
//                                                    checkVersionObjectStatus("illustrations") {
//                                                        clrChoiceDialog("Иллюстрация будет удалена. Продолжить?") {
//                                                            modalDialogConfiguration = { size = ModalDialog.Size.Small }
//                                                            clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
//                                                                async {
//                                                                    Assets.removeLinkWithObject(draft.id, illustration.id).await()
//                                                                    assets = Assets.get(draft.id).await().data
//                                                                    illustrationsTab.rebuild()
//                                                                }
//                                                            }
//                                                            clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
//                                                        }
//                                                    }
//                                                }
//                                            }
//                                        } else {
//                                            val addFileInput = input {
//                                                type = "file"
//                                                accept = "image/*"
//                                                hidden = true
//                                                onchange = {
//                                                    val file = (it.target as HTMLInputElement).files?.item(0)
//                                                    if (file != null) {
//                                                        async {
//                                                            Assets.sendObjectImage(
//                                                                    form = newForm(),
//                                                                    blob = file,
//                                                                    objId = draft.id,
//                                                                    coreId = draft.coreId,
//                                                                    fileName = file.name,
//                                                                    name = "",
//                                                                    descriptions = arrayListOf(),
//                                                                    selectedFlags = arrayListOf(imageFlag)
//                                                            ).await()
//                                                            assets = Assets.get(draft.id).await().data
//                                                            illustrationsTab.rebuild()
//                                                        }
//                                                    }
//                                                }
//                                            }
//                                            clrButton("Добавить") {
//                                                style = ButtonStyle.Flat
//                                                iconShape = IconShape.Plus
//                                                isDisabled = !privileges.contains("EDIT")
//                                                onClickFunction = {
//                                                    checkVersionObjectStatus("illustrations") {
//                                                        if (imageFlagRatio == null) {
//                                                            addFileInput.click()
//                                                        } else {
//                                                            dom.imageUploadDialog {
//                                                                headerName = imageFlag.name
//                                                                ratio = imageFlagRatio.name
//                                                                descriptions = emptyArray()
//                                                                onSaveData = { blobObject, newFileName, _, _ ->
//                                                                    async {
//                                                                        Assets.sendObjectImage(
//                                                                                form = newForm(),
//                                                                                blob = blobObject,
//                                                                                objId = draft.id,
//                                                                                coreId = draft.coreId,
//                                                                                fileName = newFileName,
//                                                                                name = "",
//                                                                                descriptions = arrayListOf(),
//                                                                                selectedFlags = arrayListOf(imageFlag)
//                                                                        ).await()
//                                                                        assets = Assets.get(draft.id).await().data
//                                                                        illustrationsTab.rebuild()
//                                                                    }
//                                                                }
//                                                            }
//                                                        }
//                                                    }
//                                                }
//                                            }
//                                        }
//                                    }
//                                }
//                            }
//                            clrCell {
//                                style.display = "flex"
//                                if (illustration != null) {
//                                    var illustrationFilename = illustration.variants.firstOrNull {
//                                        it.code == "original"
//                                    }?.filename ?: illustration.filename
//                                    var thumbnailWidth = -1
//
//                                    Application.account.properties.find {
//                                        it.code == "ru.playa.sce.image.thumbnail.width.${imageFlag.code}"
//                                    }?.let { property ->
//                                        thumbnailWidth = property.name.toIntOrNull() ?: 200
//                                        illustration.variants.firstOrNull {
//                                            it.code == "thumbnail"
//                                        }?.let {
//                                            illustrationFilename = it.filename
//                                        }
//                                    }
//                                    div {
//                                        if (thumbnailWidth != -1) style.width = "${thumbnailWidth}px"
//                                        img {
//                                            if (thumbnailWidth != -1) style.width = "100%"
//                                            src = "/sce/assets/${Application.account.uuid}/${illustration.coreId}/$illustrationFilename"
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
            createIllustrationTab()
        }
    }

    protected fun HTMLElement.createIllustrationTab() {
        clrTable {
            clrBody {
                imageFlags.forEach { imageFlag ->
                    val illustration = assets.filter { it.assetType == AssetType.IMAGE.name }.find { it.imageType != null && it.imageType?.code == imageFlag.code }
                    val imageFlagRatio = Application.account.properties.find { it.code == "ru.playa.sce.image.ratio.${imageFlag.code}" }
                    clrRow {
                        clrCell {
                            div {
                                style.apply { display = "flex"; flexDirection = "column"; alignItems = "flex-start" }
                                div {
                                    style.marginLeft = "12px"
                                    appendText(imageFlag.name)
                                }
                                div {
                                    style.apply { display = "flex"; flexDirection = "column"; alignItems = "flex-start" }
                                    if (illustration != null) {
                                        val replaceFileInput = input {
                                            type = "file"
                                            accept = "image/*"
                                            hidden = true
                                            onchange = {
                                                val file = (it.target as HTMLInputElement).files?.item(0)
                                                if (file != null) {
                                                    async {
                                                        Assets.sendObjectImage(
                                                                form = newForm(),
                                                                blob = file,
                                                                objId = draft.id,
                                                                coreId = draft.coreId,
                                                                fileName = file.name,
                                                                name = "",
                                                                descriptions = arrayListOf(),
                                                                selectedFlags = arrayListOf(imageFlag)
                                                        ).await()
                                                        Assets.removeLinkWithObject(draft.id, illustration.id).await()
                                                        //assets = Assets.get(draft.id).await().data
                                                        reloadAssets().then {
                                                            this@createIllustrationTab.clear()
                                                            this@createIllustrationTab.createIllustrationTab()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        clrButton("Изменить") {
                                            style = ButtonStyle.Flat
                                            iconShape = IconShape.Pencil
                                            isDisabled = !privileges.contains("EDIT")
                                            onClickFunction = {
                                                checkVersionObjectStatus("illustrations") {
                                                    if (imageFlagRatio == null) {
                                                        replaceFileInput.click()
                                                    } else {
                                                        dom.imageUploadDialog {
                                                            headerName = imageFlag.name
                                                            ratio = imageFlagRatio.name
                                                            descriptions = emptyArray()
                                                            onSaveData = { blobObject, newFileName, _, _ ->
                                                                async {
                                                                    Assets.sendObjectImage(
                                                                            form = newForm(),
                                                                            blob = blobObject,
                                                                            objId = draft.id,
                                                                            coreId = draft.coreId,
                                                                            fileName = newFileName,
                                                                            name = "",
                                                                            descriptions = arrayListOf(),
                                                                            selectedFlags = arrayListOf(imageFlag)
                                                                    ).await()
                                                                    Assets.removeLinkWithObject(draft.id, illustration.id).await()
                                                                    //assets = Assets.get(draft.id).await().data
                                                                    reloadAssets().then {
                                                                        this@createIllustrationTab.clear()
                                                                        this@createIllustrationTab.createIllustrationTab()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        clrButton("Удалить") {
                                            style = ButtonStyle.Flat
                                            iconShape = IconShape.Trash
                                            isDisabled = !privileges.contains("EDIT")
                                            onClickFunction = {
                                                checkVersionObjectStatus("illustrations") {
                                                    clrChoiceDialog("Иллюстрация будет удалена. Продолжить?") {
                                                        modalDialogConfiguration = { size = ModalDialog.Size.Small }
                                                        clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                            async {
                                                                Assets.removeLinkWithObject(draft.id, illustration.id).await()
                                                                //assets = Assets.get(draft.id).await().data
                                                                reloadAssets().then {
                                                                    this@createIllustrationTab.clear()
                                                                    this@createIllustrationTab.createIllustrationTab()
                                                                }
                                                            }
                                                        }
                                                        clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        val addFileInput = input {
                                            type = "file"
                                            accept = "image/*"
                                            hidden = true
                                            onchange = {
                                                val file = (it.target as HTMLInputElement).files?.item(0)
                                                if (file != null) {
                                                    async {
                                                        Assets.sendObjectImage(
                                                                form = newForm(),
                                                                blob = file,
                                                                objId = draft.id,
                                                                coreId = draft.coreId,
                                                                fileName = file.name,
                                                                name = "",
                                                                descriptions = arrayListOf(),
                                                                selectedFlags = arrayListOf(imageFlag)
                                                        ).await()
                                                        //assets = Assets.get(draft.id).await().data
                                                        reloadAssets().then {
                                                            this@createIllustrationTab.clear()
                                                            this@createIllustrationTab.createIllustrationTab()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        clrButton("Добавить") {
                                            style = ButtonStyle.Flat
                                            iconShape = IconShape.Plus
                                            isDisabled = !privileges.contains("EDIT")
                                            onClickFunction = {
                                                checkVersionObjectStatus("illustrations") {
                                                    if (imageFlagRatio == null) {
                                                        addFileInput.click()
                                                    } else {
                                                        dom.imageUploadDialog {
                                                            headerName = imageFlag.name
                                                            ratio = imageFlagRatio.name
                                                            descriptions = emptyArray()
                                                            onSaveData = { blobObject, newFileName, _, _ ->
                                                                async {
                                                                    Assets.sendObjectImage(
                                                                            form = newForm(),
                                                                            blob = blobObject,
                                                                            objId = draft.id,
                                                                            coreId = draft.coreId,
                                                                            fileName = newFileName,
                                                                            name = "",
                                                                            descriptions = arrayListOf(),
                                                                            selectedFlags = arrayListOf(imageFlag)
                                                                    ).await()
                                                                    //assets = Assets.get(draft.id).await().data
                                                                    reloadAssets().then {
                                                                        this@createIllustrationTab.clear()
                                                                        this@createIllustrationTab.createIllustrationTab()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        clrCell {
                            style.display = "flex"
                            if (illustration != null) {
                                var illustrationFilename = illustration.variants.firstOrNull {
                                    it.code == "original"
                                }?.filename ?: illustration.filename
                                var thumbnailWidth = -1

                                Application.account.properties.find {
                                    it.code == "ru.playa.sce.image.thumbnail.width.${imageFlag.code}"
                                }?.let { property ->
                                    thumbnailWidth = property.name.toIntOrNull() ?: 200
                                    illustration.variants.firstOrNull {
                                        it.code == "thumbnail"
                                    }?.let {
                                        illustrationFilename = it.filename
                                    }
                                }
                                div {
                                    if (thumbnailWidth != -1) style.width = "${thumbnailWidth}px"
                                    img {
                                        if (thumbnailWidth != -1) style.width = "100%"
                                        src = "/sce/assets/${Application.account.uuid}/${illustration.coreId}/$illustrationFilename"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    /**
     * Создание вкладки фотографий
     */
    protected fun Tabs.createPhotoTab() {
        photoTab = clrTab("Фотографии", initialTab == "photo", "Фотографии для описаний") {
            //            val photo = assets.filter { it.assetType == AssetType.IMAGE.name && it.imageType == null }
//
//            clrButton("Добавить") {
//                style = ButtonStyle.Secondary
//                iconShape = IconShape.Plus
//                isDisabled = !privileges.contains("EDIT")
//                onClickFunction = {
//                    checkVersionObjectStatus("photo") {
//                        dom.imageUploadDialog {
//                            ratio = photoRatio.original
//                            descriptions = this@BaseVOView.descriptions
//                            onSaveData = { iblob, ifileName, iname, idescriptions ->
//                                async {
//                                    Assets.sendObjectImage(
//                                            form = newForm(),
//                                            blob = iblob,
//                                            objId = draft.id,
//                                            coreId = draft.coreId,
//                                            fileName = ifileName,
//                                            name = iname,
//                                            descriptions = idescriptions,
//                                            selectedFlags = arrayListOf()
//                                    ).await()
//                                    assets = Assets.get(draft.id).await().data
//                                    photoTab.rebuild()
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//            buildAssetsGridLayout(
//                    descriptions,
//                    photo,
//                    privileges.contains("EDIT"),
//                    photoRatio.thumbnailWidth,
//                    onReorder = { reorderedIds ->
//                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
//                        checkVersionObjectStatus("photo") {
//                            Assets.reorder(draft.id, reorderedIds).then {
//                                async {
//                                    assets = Assets.get(draft.id).await().data
//                                    photoTab.rebuild()
//                                }
//                            }
//                        }
//
//                    },
//                    onEdit = { assetId, photoName, selectedDescriptions ->
//                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
//                        checkVersionObjectStatus("photo") {
//                            Assets.rename(AssetName(assetId, photoName)).then { _ ->
//                                Descriptions.linkDescriptionsToAsset(draft.id, assetId, selectedDescriptions).then {
//                                    async {
//                                        assets = Assets.get(draft.id).await().data
//                                        photoTab.rebuild()
//                                    }
//                                }
//                            }
//                        }
//                    },
//                    onDelete = { assetId ->
//                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
//                        checkVersionObjectStatus("photo") {
//                            clrChoiceDialog("Фотография будет удалена. Продолжить?") {
//                                modalDialogConfiguration = {
//                                    this.size = ModalDialog.Size.Small
//                                }
//                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
//                                    async {
//                                        Assets.removeLinkWithObject(draft.id, assetId).await()
//                                        assets = Assets.get(draft.id).await().data
//                                        photoTab.rebuild()
//                                    }
//                                }
//                                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
//                            }
//                        }
//                    }
//            )
//            window.setTimeout({
//                Application.reduxStore.getState().data[StoreFields.PHOTO_TAB_SCROLL_TOP.name]?.let {
//                    Application.applicationLayout.contentArea.scrollTop = it as Double
//                }
//            }, 500)
            createPhotoTab()

        }
    }

    protected fun HTMLElement.createPhotoTab() {
        photoContainer = container {
            val photo = assets.filter { it.assetType == AssetType.IMAGE.name && it.imageType == null }

            clrButton("Добавить") {
                style = ButtonStyle.Secondary
                iconShape = IconShape.Plus
                isDisabled = !privileges.contains("EDIT")
                onClickFunction = {
                    checkVersionObjectStatus("photo") {
                        dom.imageUploadDialog {
                            ratio = photoRatio.original
                            descriptions = this@BaseVOView.descriptions
                            onSaveData = { iblob, ifileName, iname, idescriptions ->
                                async {
                                    Assets.sendObjectImage(
                                            form = newForm(),
                                            blob = iblob,
                                            objId = vo.id,
                                            coreId = vo.coreId,
                                            fileName = ifileName,
                                            name = iname,
                                            descriptions = idescriptions,
                                            selectedFlags = arrayListOf()
                                    ).await()
                                    //assets = Assets.get(vo.id).await().data
                                    reloadAssets().then {
                                        photoContainer.render()
                                    }

                                }
                            }
                        }
                    }
                }
            }
            buildAssetsGridLayout(
                    descriptions,
                    photo,
                    privileges.contains("EDIT"),
                    photoRatio.thumbnailWidth,
                    onReorder = { reorderedIds ->
                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
                        checkVersionObjectStatus("photo") {
                            Assets.reorder(vo.id, reorderedIds).then {
                                async {
                                    //assets = Assets.get(draft.id).await().data
                                    reloadAssets().then {
                                        photoContainer.render()
                                    }
                                }
                            }
                        }

                    },
                    onEdit = { assetId, photoName, selectedDescriptions, isDescChange ->
                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
                        checkVersionObjectStatus("photo") {
                            val a = AssetRename(assetId, photoName)
                            Assets.rename(a).then { _ ->
                                async {
                                    if (isDescChange) {
                                        Descriptions.linkDescriptionsToAsset(vo.id, assetId, selectedDescriptions).await()
                                    }
                                        async {
                                            //assets = Assets.get(vo.id).await().data
                                            reloadAssets().then {
                                                reloadDescriptions(vo.id).then {
                                                    photoContainer.render()
                                                }
                                            }
                                        }
                                }
                            }
                        }
                    },
                    onDelete = { assetId ->
                        Application.reduxStore.dispatch(ReduxExt.createMessage(StoreFields.PHOTO_TAB_SCROLL_TOP.name, Application.applicationLayout.contentArea.scrollTop))
                        checkVersionObjectStatus("photo") {
                            clrChoiceDialog("Фотография будет удалена. Продолжить?") {
                                modalDialogConfiguration = {
                                    this.size = ModalDialog.Size.Small
                                }
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    async {
                                        Assets.removeLinkWithObject(draft.id, assetId).await()
                                        //assets = Assets.get(draft.id).await().data
                                        reloadAssets().then {
                                            photoContainer.render()
                                        }
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                            }
                        }
                    },
                    onPreEdit = {
                        checkVersionObjectStatus("photo") {
                            Unit
                        }
                    }
            )
            window.setTimeout({
                Application.reduxStore.getState().data[StoreFields.PHOTO_TAB_SCROLL_TOP.name]?.let {
                    Application.applicationLayout.contentArea.scrollTop = it as Double
                }
            }, 500)
        }

    }

    /**
     * Создание вкладки файлов
     */
    protected fun Tabs.createFilesTab() {
        filesTab = clrTab("Файлы", initialTab == "files") {
            //            lateinit var fileInput: Input
//            clrButton("Добавить") {
//                style = ButtonStyle.Secondary
//                iconShape = IconShape.Plus
//                isDisabled = !privileges.contains("EDIT")
//                onClickFunction = {
//                    checkVersionObjectStatus("files") {
//                        fileInput.getHTMLElement().click()
//                    }
//                }
//            }
//            var form: Form? = null
//            form = clrForm {
//                this.clrBlock {
//                    fileInput = clrInput {
//                        type = InputType.File
//                        accept = ".txt,.pdf,.zip,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//                        onChangeFunction = { event ->
//                            Application.applicationLayout.topLevelAlert.showLimitedTime {
//                                style = Alert.Style.INFO
//                                clrAlertItem("Файл загружается", style)
//                            }
//                            Application.loadingIndicator.show()
//                            val file = (event.target as HTMLInputElement).files?.get(0)
//                            if (file != null) {
//                                if (file.size > 10485760) {
//                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
//                                        style = Alert.Style.WARNING
//                                        clrAlertItem("Файл превышает допустимый размер", style)
//                                    }
//                                } else {
//                                    Assets.sendFile(form?.getHTMLElement() as HTMLFormElement, this@BaseVOView.id, this@BaseVOView.coreId, file.name
//                                            , file as Blob, file.name).then { _ ->
//                                        reloadAssets().then { _ ->
//                                            Application.loadingIndicator.hide()
//                                            reloadAssets().then {
//                                                fileListTable.render()
//                                                Application.applicationLayout.topLevelAlert.showLimitedTime {
//                                                    style = Alert.Style.SUCCESS
//                                                    clrAlertItem("Файл загружен", style)
//                                                }
//                                            }
//                                        }.catch {
//                                            Application.loadingIndicator.hide()
//                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
//                                                style = Alert.Style.ERROR
//                                                clrAlertItem("Ошибка загрузки файла", style)
//                                            }
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                        onPostRender = {
//                            hide()
//                        }
//                    }
//                }
//            }
//            fileListTable = clrTable {
//                clrBody {
//                    assets.filter { it.assetType == AssetType.FILE.name }.apply {
//                        if (isEmpty())
//                            this@clrTable.apply {
//                                this.onPostRender = {
//                                    this.hide()
//                                }
//                            }
//                        else {
//                            this@clrTable.apply {
//                                this.onPostRender = {
//                                    this.show()
//                                }
//                            }
//                        }
//                    }.forEach { assetItem ->
//                        clrRow {
//                            clrCell {
//                                a {
//                                    innerText = assetItem.name
//                                    href = "javascript:void(0);"
//                                    onclick = {
//                                        window.open("/sce/assets/${Application.account.uuid}/${this@BaseVOView.coreId}/${assetItem.filename}")
//                                    }
//                                }
//                                this.style.verticalAlign = "middle"
//                                this.align = "left"
//                            }
//                            clrCell {
//                                this.align = "right"
//                                clrButton {
//                                    style = ButtonStyle.Flat
//                                    iconShape = IconShape.Trash
//                                    text = "Удалить"
//                                    isDisabled = !privileges.contains("EDIT")
//                                    onClickFunction = { _ ->
//                                        checkVersionObjectStatus("files") {
//                                            Assets.removeLinkWithObject(this@BaseVOView.id, assetItem.id).then { _ ->
//                                                reloadAssets().then {
//                                                    this@clrTable.render()
//                                                }
//                                            }
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
            createFilesTab()
        }
    }

    protected fun HTMLElement.createFilesTab() {
        lateinit var fileInput: Input
        clrButton("Добавить") {
            style = ButtonStyle.Secondary
            iconShape = IconShape.Plus
            isDisabled = !privileges.contains("EDIT")
            onClickFunction = {
                checkVersionObjectStatus("files") {
                    fileInput.getHTMLElement().click()
                }
            }
        }
        var form: Form? = null
        form = clrForm {
            this.clrBlock {
                fileInput = clrInput {
                    type = InputType.File
                    accept = ".txt,.pdf,.zip,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChangeFunction = { event ->
                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                            style = Alert.Style.INFO
                            clrAlertItem("Файл загружается", style)
                        }
                        Application.loadingIndicator.show()
                        val file = (event.target as HTMLInputElement).files?.get(0)
                        if (file != null) {
                            if (file.size > 10485760) {
                                Application.applicationLayout.topLevelAlert.showLimitedTime {
                                    style = Alert.Style.WARNING
                                    clrAlertItem("Файл превышает допустимый размер", style)
                                }
                            } else {
                                Assets.sendFile(form?.getHTMLElement() as HTMLFormElement, this@BaseVOView.id, this@BaseVOView.coreId, file.name
                                        , file as Blob, file.name).then { _ ->
                                    reloadAssets().then { _ ->
                                        Application.loadingIndicator.hide()
                                        reloadAssets().then {
                                            fileListTable.render()
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.SUCCESS
                                                clrAlertItem("Файл загружен", style)
                                            }
                                        }
                                    }.catch {
                                        Application.loadingIndicator.hide()
                                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                                            style = Alert.Style.ERROR
                                            clrAlertItem("Ошибка загрузки файла", style)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    onPostRender = {
                        hide()
                    }
                }
            }
        }
        fileListTable = clrTable {
            clrBody {
                assets.filter { it.assetType == AssetType.FILE.name }.apply {
                    if (isEmpty())
                        this@clrTable.apply {
                            this.onPostRender = {
                                this.hide()
                            }
                        }
                    else {
                        this@clrTable.apply {
                            this.onPostRender = {
                                this.show()
                            }
                        }
                    }
                }.forEach { assetItem ->
                    clrRow {
                        clrCell {
                            a {
                                innerText = assetItem.name
                                href = "javascript:void(0);"
                                onclick = {
                                    window.open("/sce/assets/${Application.account.uuid}/${this@BaseVOView.coreId}/${assetItem.filename}")
                                }
                            }
                            this.style.verticalAlign = "middle"
                            this.align = "left"
                        }
                        clrCell {
                            this.align = "right"
                            clrButton {
                                style = ButtonStyle.Flat
                                iconShape = IconShape.Trash
                                text = "Удалить"
                                isDisabled = !privileges.contains("EDIT")
                                onClickFunction = { _ ->
                                    checkVersionObjectStatus("files") {
                                        Assets.removeLinkWithObject(this@BaseVOView.id, assetItem.id).then { _ ->
                                            reloadAssets().then {
                                                this@clrTable.render()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Создание вкладки новостей.
     * Фильтрует [textBlocks] и вызывает [buildTextBlocksTab]
     */
    protected fun Tabs.createNewsTab(objType: String = objectType) {
        newsTab = clrTab("Новости", initialTab == "news") {
            createNewsTab(objType)
        }
    }

    protected fun HTMLElement.createNewsTab(objType: String = objectType) {
        newsContainer = container {
            val newsTextBlocks = textBlocks.filter { it.textType == "NEWS_ITEM" }
            buildTextBlocksTab("news", newsTextBlocks, objType.toUpperCase()) {
                //newsTab.rebuild()
                newsContainer.render()
            }
        }
    }

    /**
     * Создание вкладки баннеров.
     * Фильтрует [textBlocks] и вызывает [buildTextBlocksTab]
     */
    protected fun Tabs.createBannersTab(objType: String = objectType) {
        bannersTab = clrTab("Баннеры", initialTab == "banners") {
            createBannersTab(objType)
        }
    }

    protected fun HTMLElement.createBannersTab(objType: String = objectType) {
        bannersContainer = container {
            val bannersTextBlocks = textBlocks.filter { it.textType == "TEXT_BANNER" || it.textType == "GRAPHICAL_BANNER" }
            buildTextBlocksTab("banners", bannersTextBlocks, objType.toUpperCase()) {
                //bannersTab.rebuild()
                bannersContainer.render()
            }
        }
    }

    lateinit var serviceContainer: Container
    fun HTMLElement.createServicesTab(objType: String = objectType, includeParents: Boolean, includeCountry: Boolean) {
        serviceContainer = container {
            async {
                val bannersTextBlocks = TextBlocks.get(textType = TextType.SERVICES, coreId = coreId, includeParents = includeParents, includeCountry = includeCountry).await().data.toList()
                buildTextBlocksTab("services", bannersTextBlocks, objType.toUpperCase()) {
                    serviceContainer.render()
                }
            }
        }
    }


    /**
     * Создание заголовка страницы
     * @param objType Название типа объекта. Может отличаться от [objectType]
     */
    protected fun HTMLElement.createPageHeader(objType: String) {
        pageHeader = clrPageHeader(vo.name) {
            label = objectStatus.displayName
            buildPageHeaderPathPart()
            clrSideImageButton {
                val urlProperty = Application.account.properties.find { it.code == "ru.playa.sce.page.${objType.toLowerCase()}.url" }
                src = "lib/extra/icon_view.png"
                alt = "Просмотр"
                isDisabled = isViewButtonDisabled(urlProperty)
                onClickFunction = {
                    if (urlProperty != null) {
                        val prefix = urlProperty.name.substringBefore('=', "")
                        val url = "$prefix=$coreId&v=${this@BaseVOView.id}"
                        window.open(url)
                    }
                }
            }
            clrSideImageButton {
                src = "lib/extra/icon_approve.png"
                alt = "Утвердить"
                isDisabled = !privileges.contains("APPROVE")
                skipPredicate = { objectStatus != ObjectStatus.DRAFT }
                onClickFunction = {
                    async {
                        approve().await()
                        /*objectStatus = ObjectStatus.APPROVED
                        pageHeader.label = objectStatus.displayName
                        pageHeader.render()
                        */
                        this@BaseVOView.clear()
                        this@BaseVOView.render()
                    }
                }
            }
            clrSideImageButton {
                src = "lib/extra/icon_publish.png"
                alt = "Опубликовать"
                isDisabled = !privileges.contains("PUBLISH")
                skipPredicate = { !(objectStatus == ObjectStatus.DRAFT || objectStatus == ObjectStatus.APPROVED) }
                onClickFunction = {
                    async {
                        publish().await()
                        /*objectStatus = ObjectStatus.PUBLISHED
                        pageHeader.label = objectStatus.displayName
                        pageHeader.render()*/
                        this@BaseVOView.clear()
                        this@BaseVOView.render()
                    }
                }
            }
            clrSideImageButton {
                src = "lib/extra/icon_withdraw.png"
                alt = "Отозвать"
                isDisabled = !privileges.contains("EDIT")
                skipPredicate = { objectStatus != ObjectStatus.PUBLISHED }
                onClickFunction = {
                    async {
                        val draft = getDraft().await()
                        delete(draft.id).await()
                        val c2 = getDraft().await()
                        delete(vo.id).await()
                        navigateTo(c2.id, c2.coreId)
                    }
                }
            }
            clrSideImageButton {
                src = "lib/extra/icon_delete.png"
                alt = "Удалить"
                isDisabled = !privileges.contains("DELETE")
                skipPredicate = { objectStatus == ObjectStatus.ARCHIVED }
                onClickFunction = {
                    clrChoiceDialog("Вы уверены что хотите удалить объект?") {
                        clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                            async {
                                delete(vo.id).await()
                                navigateToParent()
                            }
                        }
                        clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                    }
                }
            }
        }
    }

    /**
     * Построение тела описания при раскрытии элемента Datagrid
     * @param desc Текущий элемент [descriptions]
     * @param descDatagrid Компонент Datagrid
     * @param objType Название типа объекта. Может отличаться от [objectType]
     */
    private fun HTMLDivElement.buildDetails(desc: ObjectDescription, descDatagrid: DataGrid<ObjectDescription>, objType: String = objectType) {
        // Получение ассетов, привязанных к данному описанию
        //Assets.getByDescriptionId(desc.id).then { result ->
        val descAssets: Array<Asset> = desc.images.toList().sortedBy { it.id }.toTypedArray() //result.data
        div {
            style.width = "100%"

            div {
                style.apply { display = "flex"; justifyContent = "space-between" }

                div {
                    innerHTML = desc.fullDescription
                }
                div {
                    style.apply { display = "flex"; flexDirection = "column"; alignItems = "center"; marginLeft = "5px"; }

                    descAssets.forEach { asset ->
                        buildImageAssetCard(
                                descriptions, asset, privileges.contains("EDIT"),
                                onEdit = { assetId, photoName, selectedDescs, isDescChanged ->
                                    checkVersionObjectStatus("desc") {
                                        val a = AssetRename(assetId, photoName)
                                        Assets.rename(a).then { _ ->
                                            async {
                                                if (isDescChanged) {
                                                    Descriptions.linkDescriptionsToAsset(vo.id, assetId, selectedDescs).await()
                                                }
                                                reloadDescriptions(vo.id).then {
                                                    descContainer.render()
                                                }
                                                /*reloadAssets(vo.id).await()

                                                //photoTab.rebuild()
                                                this@buildDetails.removeChild(container)
                                                this@buildDetails.buildDetails(desc, descDatagrid, objType)*/
                                                /*initialTab = "desc"
                                                this@BaseVOView.clear()
                                                this@BaseVOView.render()*/
                                            }
                                        }
                                    }
                                }
                                , thumbnailWidth = photoRatio.thumbnailWidth)
                    }
                }
            }
            clrForm {
                clrButton("Редактировать") {
                    style = ButtonStyle.Secondary
                    iconShape = IconShape.Pencil
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = { _ ->
                        checkVersionObjectStatus("desc", false) {
                            async {
                                //val draftDescriptions = Descriptions.get(objectType, draft.id).await().data
                                //if (draftDescriptions.any { it.id == desc.id }) {
                                //if (descriptions.any { it.id == desc.id }) {
                                editDescription(desc, objType)
                                //} else toDraftTab("desc")
                            }
                        }
                    }
                }
                clrButton("Удалить") {
                    style = ButtonStyle.WarningOutline
                    iconShape = IconShape.Trash
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = { _ ->
                        checkVersionObjectStatus("desc") {
                            async {
                                Descriptions.delete(objectType, draft.id, desc.id).await()
                                reloadDescriptions(draft.id).await()
                                descDatagrid.dataList = descriptions.toList()
                                descDatagrid.render()
                            }
                        }
                    }
                }
            }
        }
        //}
    }

    /**
     * Универсальное построение контента вкладки текстовых блоков.
     * @param type Название типа текстового блока [TextType]. Используются только "news" и "banner"
     * @param items Отфильтрованные [textBlocks] для данного [type]
     * @param objType Название типа объекта. Может отличаться от [objectType]
     */
    private fun HTMLElement.buildTextBlocksTab(type: String, items: List<Text>, objType: String, rebuild: () -> Unit) {
        clrButton("Добавить") {
            style = ButtonStyle.Secondary
            iconShape = IconShape.Plus
            isDisabled = !privileges.contains("EDIT")
            onClickFunction = {
                Navigation.TextBlocks.addByObject(objType, this@BaseVOView.id, this@BaseVOView.coreId, type, this@BaseVOView.vo.name)
            }
        }
        clrDatagrid<Text> {
            clrColumn("Заголовок", 2) { text ->
                a {
                    appendText(text.name)
                    href = "javascript://"
                    onclick = {
                        Navigation.TextBlocks.textBlock(text.id, text.coreId)
                    }
                }
            }
            clrColumn("Тип", 1) {
                appendText(TextType.valueOf(it.textType).displayName)
            }
            clrColumn("Статус", 1) {
                appendText(ObjectStatus.valueOf(it.status).displayName)
            }
            clrOverallDetails {
                async {
                    buildTextBlockDetails(it, objType, type, rebuild)
                }
            }
            pageSize = 50
            dataList = items
        }
    }

    /**
     * Построение тела текстового блока при раскрытии элемента Datagrid
     * @param textBlock Текущий элемент [descriptions]
     * @param objType Название типа объекта. Может отличаться от [objectType]
     * @param type Название типа текстового блока [TextType]. Используется только "news" и "banner"
     */
    private fun HTMLDivElement.buildTextBlockDetails(textBlock: Text, objType: String, type: String, rebuild: () -> Unit) {
        div {
            clrForm {
                isCompact = true
                clrBlock {
                    clrGroup("Период отображения") {
                        span {
                            if (textBlock.start == null && textBlock.end == null) {
                                appendText("—")
                            } else {
                                var datesText = ""
                                datesText += if (textBlock.start != null) {
                                    "${textBlock.start.slice(8..9)}.${textBlock.start.slice(5..6)}.${textBlock.start.slice(0..3)} — "
                                } else "... - "
                                datesText += if (textBlock.end != null) {
                                    "${textBlock.end.slice(8..9)}.${textBlock.end.slice(5..6)}.${textBlock.end.slice(0..3)}"
                                } else "..."
                                appendText(datesText)
                            }
                        }
                    }
                    clrGroup("Тип ссылки") {
                        val linkType = LinkType.valueOf(textBlock.linkType)
                        span { appendText(linkType.displayName) }
                        when (linkType) {
                            LinkType.URL -> a {
                                appendText("(${textBlock.linkURL})")
                                href = "javascript://"
                                onclick = {
                                    window.open(textBlock.linkURL, "_blank")
                                }
                            }
                            LinkType.OBJECT -> a {
                                textBlock.target?.let { linkObject ->
                                    appendText("(${linkObject.publishedName ?: linkObject.draftName ?: "Удаленный"})")
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.navigateToObject(
                                                linkObject.objectType.toUpperCase(),
                                                linkObject.publishedVersionId
                                                        ?: linkObject.draftVersionId ?: 0,
                                                linkObject.id
                                        )
                                    }
                                }
                            }
                            else -> {
                            }
                        }
                    }
                    clrGroup("Блок контента") {
                        span {
                            var blocksText = ""
                            val blocksLastIndex = textBlock.blocks.lastIndex
                            textBlock.blocks.forEachIndexed { index, block ->
                                blocksText += block.name
                                if (index != blocksLastIndex) blocksText += ", "
                            }
                            if (blocksLastIndex == -1) blocksText += "—"
                            appendText(blocksText)
                        }
                    }
                    clrGroup("Анонс") {
                        span {
                            val textBlockAnons = if (textBlock.anons != null && textBlock.anons.isNotBlank()) textBlock.anons else "—"
                            appendText(textBlockAnons)
                        }
                    }
                }
                clrBlock("Описание") {}
            }
            div {
                innerHTML = textBlock.description ?: ""
            }
            clrForm {
                clrButton("Редактировать") {
                    style = ButtonStyle.Secondary
                    iconShape = IconShape.Pencil
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = {
                        Navigation.TextBlocks.editByObject(textBlock.coreId, objType, this@BaseVOView.id, this@BaseVOView.coreId, type)
                    }
                }
                clrButton("Удалить") {
                    style = ButtonStyle.WarningOutline
                    iconShape = IconShape.Trash
                    onClickFunction = { _ ->
                        if (textBlock.blocks.isEmpty() && textBlock.objects.size == 1) {
                            clrChoiceDialog("Текстовый блок будет удален. Продолжить?") {
                                modalDialogConfiguration = {
                                    size = ModalDialog.Size.Small
                                }
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    async {
                                        TextBlocks.delete(textBlock.id).await()
                                        textBlocks = TextBlocks.get(pageSize = 100, coreId = coreId).await().data
                                        /*when (type) {
                                            "news" -> newsTab.rebuild()
                                            "banners" -> bannersTab.rebuild()
                                        }*/
                                        rebuild()
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                            }
                        } else {
                            clrChoiceDialog("Текстовый блок будет исключен из объекта. Продолжить?") {
                                modalDialogConfiguration = {
                                    size = ModalDialog.Size.Small
                                }
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    async {
                                        val updated = textBlock.let { tb ->
                                            Text(
                                                    id = tb.id, code = tb.code, name = tb.name, coreId = tb.coreId,
                                                    textType = tb.textType, anons = tb.anons, description = tb.description,
                                                    start = tb.start, end = tb.end, linkType = tb.linkType,
                                                    linkURL = tb.linkURL, target = tb.target, blocks = tb.blocks,
                                                    objects = textBlock.objects.filter { it.id != coreId }.toTypedArray()
                                            )
                                        }
                                        TextBlocks.update(updated).await()
                                        textBlocks = TextBlocks.get(pageSize = 100, coreId = coreId).await().data
                                        /*when (type) {
                                            "news" -> newsTab.rebuild()
                                            "banners" -> bannersTab.rebuild()
                                        }*/
                                        rebuild()
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                            }
                        }
                    }
                }
            }
        }
    }
}
