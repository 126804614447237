package ru.playa.sce.dto

class User(
        val email: String,
        val name: String,
        val roles: Array<Role>
)

class UserRequest(
        val email: String,
        val status: Int
)