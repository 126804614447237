package ru.playa.sce.components

import google.maps.*
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.components.Input
import ru.playa.kotlinx.clarity.js.components.clrButton
import ru.playa.kotlinx.clarity.js.components.clrInput
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.util.async

class GoogleMap(val width: String, val height: String, parent: HTMLElement) : Component(parent) {

    private var map: Map? = null
    private var mapStatus = GoogleMapStatus.NotInitialized

    var bounds: GoogleMapBounds? = null
    var marker: GoogleMapPoint? = null
    var onChangeFunction: () -> Unit = {}

    // Public Methods Section
    fun mapFitBounds(south: Double, west: Double, north: Double, east: Double) {
        map?.fitBounds(LatLngBounds(LatLng(south, west), LatLng(north, east)), 0)
    }

    fun mapSetZoom(zoom: Int) {
        map?.setZoom(zoom)
    }

    fun mapSetCenter(lat: Double, lng: Double) {
        map?.setCenter(LatLng(lat, lng))
    }

    fun mapFitControllerBounds() {
        bounds?.let {
            mapFitBounds(
                    it.southWest.latitude,
                    it.southWest.longitude,
                    it.northEast.latitude,
                    it.northEast.longitude
            )
        }
    }
    // End of Public Methods Section

    // Marker Section
    var markerMode = GoogleMapMarkerMode.Disabled
    var markerCanMove = false

    private var markerObject: Marker? = null

    private fun createMarker(latLng: LatLng, map: Map) {
        markerObject = Marker().apply {
            setDraggable(markerCanMove)
            setPosition(latLng)
            setMap(map)
            event.addListener(this, "position_changed") {
                getPosition().let {
                    marker = GoogleMapPoint(it.lat().toDouble(), it.lng().toDouble())
                }
                onChangeFunction()
            }
        }
    }

    private fun markerSetPosition(lat: Double, lng: Double) {
        markerObject?.setPosition(LatLng(lat, lng)) ?: run {
            map?.let { createMarker(LatLng(lat, lng), it) }
        }
        marker = GoogleMapPoint(lat, lng)
    }

    var coordinatesEnabled = false
    private lateinit var latInput: Input
    private lateinit var lngInput: Input
    // End of Marker Section

    // Route Section
    var routePoints: ArrayList<GoogleMapPoint> = arrayListOf()
    private var routeMarkers: LinkedHashMap<GoogleMapPoint, Marker> = linkedMapOf()
    private var routeLine: Polyline? = null

    private fun initRouteMarkers() {
        if (routeMarkers.size > 0) {
            val pathMarkers = arrayListOf<LatLng>()
            routeMarkers.values.forEach { pathMarkers.add(it.getPosition()) }
            routeLine = Polyline().apply {
                setMap(map)
                setPath(pathMarkers.toTypedArray())
                setOptions(PolylineOptionsClass().apply {
                    strokeWeight = 1
                    strokeColor = "#808080"
                })
            }

            routeMarkers.keys.forEach { item ->
                event.addListener(routeMarkers[item] ?: throw IllegalStateException(), "dragend") {
                    routeMarkers[item]?.getPosition()?.let {
                        item.latitude = it.lat().toDouble()
                        item.longitude = it.lng().toDouble()
                    }
                    val path = arrayListOf<LatLng>()
                    routePoints.clear()
                    routeMarkers.values.forEach {
                        path.add(it.getPosition())
                        routePoints.add(
                                GoogleMapPoint(
                                        it.getPosition().lat().toDouble(),
                                        it.getPosition().lng().toDouble()
                                )
                        )
                    }
                    routeLine?.setPath(path.toTypedArray())
                    onChangeFunction()
                }
            }
        }
    }

    private fun extendMapOnRoute() {
        when {
            routePoints.size == 0 -> map?.fitBounds(LatLngBounds(LatLng(-10.0, -90.0), LatLng(10.0, 90.0)), 0)
            routePoints.size >= 1 -> {
                val extendableBounds = LatLngBounds()
                routePoints.forEach {
                    extendableBounds.extend(LatLng(it.latitude, it.longitude))
                }
                map?.fitBounds(extendableBounds, 20)
                if (routePoints.size == 1) mapSetZoom(12)
            }
        }
    }
    // End of Route Section

    // Geocoding Section
    var geocodeEnabled = false
    private lateinit var geocoderApi: Geocoder
    private lateinit var geocodeInput: Input

    fun fillGeocodeInput(
            objectName: String = "",
            countryName: String? = null,
            parentName: String? = null,
            hotelCategory: String? = null
    ) {
        var result = objectName
        arrayOf(hotelCategory, parentName, countryName).forEach {
            if (it != null) {
                if (result.isNotBlank()) result += ", "
                result += it
            }
        }

        geocodeInput.value = result
        geocodeInput.render()
    }

    private fun geocodeAddress(query: String) {
        val request = GeocoderRequestClass().apply {
            address = query
        }

        geocoderApi.geocode(request) { results, status ->
            if (status == GeocoderStatus.OK) {
                results[0].geometry.let {
                    map?.fitBounds(it.viewport)
                    if (markerMode != GoogleMapMarkerMode.Disabled) {
                        markerSetPosition(
                                it.location.lat().toDouble(),
                                it.location.lng().toDouble()
                        )
                    }

                    if (coordinatesEnabled) {
                        latInput.value = it.location.lat().toString()
                        latInput.render()
                        lngInput.value = it.location.lng().toString()
                        lngInput.render()
                    }
                    onChangeFunction()
                }
            }
        }
    }
    // End of Geocoding Section

    override fun build() = async {
        val controller = this@GoogleMap

        return@async newDiv {
            if (geocodeEnabled) {
                geocoderApi = Geocoder()
                div {
                    style.apply { display = "flex"; width = "100%"; marginBottom = "5px" }

                    div {
                        style.flexGrow = "1"
                        geocodeInput = clrInput {
                            width = "100%"
                            placeholder = "Заполните обязательные поля формы"
                            value = ""
                            onPressEnterFunction = {
                                geocodeAddress(value)
                            }
                        }
                    }
                    clrButton("Найти на карте") {
                        isSmall = true
                        margin = "0px"
                        onClickFunction = {
                            geocodeAddress(geocodeInput.value)
                        }
                    }
                }
            }
            div {
                style.width = controller.width
                style.height = controller.height

                try {
                    map = Map(
                            this,
                            MapOptionsClass().apply {
                                mapTypeControl = false
                            }
                    )
                } catch (e: Exception) {
                }

                map?.apply {
                    val mapThis = this

                    event.addListener(mapThis, "idle") {
                        when (controller.mapStatus) {
                            GoogleMapStatus.NotInitialized -> {
                                controller.mapStatus = GoogleMapStatus.Initializing
                                controller.mapFitControllerBounds()
                                when (controller.markerMode) {
                                    GoogleMapMarkerMode.Marker -> {
                                        controller.marker?.let {
                                            createMarker(LatLng(it.latitude, it.longitude), mapThis)
                                        }
                                    }
                                    GoogleMapMarkerMode.Route -> {
                                        routePoints.forEachIndexed { index, item ->
                                            routeMarkers[item] = Marker().apply {
                                                val mrk = this
                                                setDraggable(markerCanMove)
                                                setPosition(LatLng(item.latitude, item.longitude))
                                                setLabel(MarkerLabelClass().apply {
                                                    text = (index + 1).toString()
                                                    color = "white"
                                                })
                                                setMap(mapThis)
                                                val markerInfoWindow = InfoWindow().apply {
                                                    val root = newDiv { }
                                                    item.content.invoke(root)
                                                    setContent(root.innerHTML)
                                                }
                                                event.addListener(mrk, "click") {
                                                    markerInfoWindow.open(mapThis, mrk)
                                                }
                                            }
                                        }
                                        initRouteMarkers()
                                        extendMapOnRoute()
                                    }
                                    else -> {
                                    }
                                }
                            }

                            GoogleMapStatus.Initializing -> {
                                controller.mapStatus = GoogleMapStatus.Initialized
                                val onMapChange = {
                                    getBounds()?.let {
                                        controller.bounds = GoogleMapBounds(
                                                GoogleMapPoint(
                                                        it.getSouthWest().lat().toDouble(),
                                                        it.getSouthWest().lng().toDouble()
                                                ),
                                                GoogleMapPoint(
                                                        it.getNorthEast().lat().toDouble(),
                                                        it.getNorthEast().lng().toDouble()
                                                )
                                        )
                                    }
                                    controller.onChangeFunction()
                                }
                                event.addListener(mapThis, "center_changed") {
                                    onMapChange()
                                }
                                event.addListener(mapThis, "zoom_changed") {
                                    onMapChange()
                                }
                                if (markerCanMove) {
                                    event.addListener(mapThis, "click") { event: MouseEvent ->
                                        when (controller.markerMode) {
                                            GoogleMapMarkerMode.Marker -> {
                                                val latLng = event.latLng
                                                markerSetPosition(
                                                        latLng.lat().toDouble(),
                                                        latLng.lng().toDouble()
                                                )

                                                if (coordinatesEnabled) {
                                                    latInput.value = latLng.lat().toString()
                                                    latInput.render()
                                                    lngInput.value = latLng.lng().toString()
                                                    lngInput.render()
                                                }
                                                controller.onChangeFunction()
                                            }

                                            GoogleMapMarkerMode.Route -> {
                                                val pointIndex = routePoints.size
                                                if (pointIndex <= 1) {
                                                    val latLng = event.latLng
                                                    val mapPoint = GoogleMapPoint(
                                                            latLng.lat().toDouble(),
                                                            latLng.lng().toDouble()
                                                    )
                                                    routePoints.add(mapPoint)
                                                    routeMarkers[mapPoint] = Marker().apply {
                                                        setDraggable(markerCanMove)
                                                        setPosition(LatLng(mapPoint.latitude, mapPoint.longitude))
                                                        setLabel(MarkerLabelClass().apply {
                                                            text = (pointIndex + 1).toString()
                                                            color = "white"
                                                        })
                                                        setMap(mapThis)
                                                    }
                                                    if (pointIndex == 1) initRouteMarkers()
                                                    controller.onChangeFunction()
                                                }
                                            }

                                            else -> {
                                            }
                                        }
                                    }
                                } else {
                                }
                            }

                            else -> {
                            }
                        }
                    }
                }
            }
            if (coordinatesEnabled) {
                div {
                    latInput = clrInput {
                        value = marker?.latitude?.toString() ?: "0"
                    }
                    lngInput = clrInput {
                        value = marker?.longitude?.toString() ?: "0"
                    }
                    clrButton("Найти") {
                        isSmall = true
                        onClickFunction = {
                            val latDouble = latInput.value.toDouble()
                            val lngDouble = lngInput.value.toDouble()
                            if (markerMode != GoogleMapMarkerMode.Disabled) markerSetPosition(latDouble, lngDouble)
                            mapSetCenter(latDouble, lngDouble)
                            controller.onChangeFunction()
                        }
                    }
                }
            }
        }
    }
}

/**
 * GoogleMaps API Interface Initialization
 */
class MarkerLabelClass : MarkerLabel

class PolylineOptionsClass : PolylineOptions
class GeocoderRequestClass : GeocoderRequest
class MapOptionsClass : MapOptions

enum class GoogleMapStatus {
    NotInitialized,
    Initializing,
    Initialized
}

enum class GoogleMapMarkerMode {
    Disabled,
    Marker,
    Route
}

class GoogleMapPoint(var latitude: Double = 0.0, var longitude: Double = 0.0, val content: HTMLElement.() -> Unit = {})

class GoogleMapBounds(val southWest: GoogleMapPoint, val northEast: GoogleMapPoint)


fun GoogleMap.mapMarker(latitude: Double, longitude: Double) =
        GoogleMapPoint(latitude, longitude).also { marker = it }

fun GoogleMap.mapBounds(south: Double, west: Double, north: Double, east: Double) =
        GoogleMapBounds(GoogleMapPoint(south, west), GoogleMapPoint(north, east)).also { bounds = it }

fun GoogleMap.mapRoutePoint(latitude: Double, longitude: Double, content: HTMLElement.() -> Unit = {}) {
    this.routePoints.add(GoogleMapPoint(latitude, longitude, content))
}

fun HTMLElement.googleMap(width: String, height: String, block: GoogleMap.() -> Unit) =
        GoogleMap(width, height, this).apply(block).apply { render() }
