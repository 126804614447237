@file:Suppress("unused")

package ru.playa.sce.dto

import kotlin.js.Date

class Core(
        val id: Int,
        val code: String,
        val objectType: String,
        val objectSubtype: String? = null,
        val publishedVersionId: Int? = null,
        val draftVersionId: Int? = null,
        val publishedName: String? = null,
        val draftName: String? = null,
        val deletedDate: Date? = null
)
