@file:Suppress("unused")

package ru.playa.sce.dto


open class ObjectDescription(
        val id: Int = 0,
        val parentCoreId: Int,
        val parentVersionId: Int,
        val descriptionType: ObjectDescriptionType? = null,
        val name: String = "",
        val briefDescription: String = "",
        val fullDescription: String = "",
        val language: DictionaryEntry? = null,
        val images: Array<Asset>
)


