package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.p
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.dom.appendText


class ChoiceDialog(parent: HTMLElement, private val messageText: String = "") : Component(parent) {
    class Choice(val text: String, val style: ButtonStyle, val iconShape: IconShape?, val onEvent: () -> Unit)

    var choices: ArrayList<Choice> = arrayListOf()
    var modalDialogConfiguration: ModalDialog.() -> Unit = {}
    override fun build() = async {
        return@async newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogBody {
                        p {
                            appendText(messageText)
                        }
                    }
                    clrModalDialogFooter {
                        choices.forEach { chi ->
                            clrButton {
                                style = chi.style
                                iconShape = chi.iconShape
                                this.text = chi.text
                                onClickFunction = {
                                    parent.remove()
                                    chi.onEvent()
                                }
                            }
                        }
                    }
                }
            }.apply(modalDialogConfiguration)
        }
    }

}

fun ChoiceDialog.clrChoice(text: String, style: ButtonStyle, iconShape: IconShape? = null, onEvent: () -> Unit = {}) {
    this.choices.add(ChoiceDialog.Choice(iconShape = iconShape, style = style, text = text, onEvent = onEvent))
}

fun HTMLElement.clrChoiceDialog(messageText: String, block: ChoiceDialog.() -> Unit = {}) = ChoiceDialog(this, messageText).apply(block).apply { render() }