package ru.playa.sce.dto

open class WebObject(
        id: Int,
        code: String,
        name: String,
        objectType: String,
        coreId: Int,
        status: String,
        val publishedVersionId: Int?,
        val draftVersionId: Int?,
        val externalMappings: Array<ExternalMapping>,
        val visibility: Array<Option>,
        val tags: Array<Option>,
        val synonyms: String? = ""
) : VersionedObject(id, code, name, objectType, coreId, status)
