package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.Role
import ru.playa.sce.dto.User
import ru.playa.sce.dto.UserRequest

object UsersRoles {
    fun getUsers() = Network.request<ListResult<User>>(Network.Method.GET, "/sce-web/api/user/account/users")
    fun checkUserByEmail(email: String) = Network.request<UserRequest>(Network.Method.GET, "/sce-web/api/user/account/users/search?email=$email")
    fun getUserByEmail(email: String) = Network.request<User>(Network.Method.GET, "/sce-web/api/user/account/users/search?email=$email")
    fun updateUser(user: User) = Network.send(Network.Method.POST,
            user, "/sce-web/api/user/account/users")

    fun getRoles(accountId: Int) = Network.request<ListResult<Role>>(Network.Method.GET, "/sce-web/api/user/accountrole/account/$accountId")
    fun getRoleById(roleId: Int) = Network.request<Role>(Network.Method.GET, "/sce-web/api/user/accountrole/$roleId")
    fun createRole(accountId: Int, role: Role) = Network.send(Network.Method.POST,
            role, "/sce-web/api/user/accountrole/account/$accountId")

    fun updateRole(accountId: Int, role: Role) = Network.send(Network.Method.PUT,
            role, "/sce-web/api/user/accountrole/account/$accountId")

    fun deleteRole(roleId: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/accountrole/$roleId")
}