package ru.playa.sce.dto

enum class LocationType(val displayName: String, val pluralName: String, val accusativeName: String) {
    REGION("Регион", "Регионы", "Регион"),
    TOWN("Город", "Города", "Город"),
    DISTRICT("Район", "Районы", "Район"),
    HOTEL("Отель", "Отели", "Отель"),
    LANDMARK("Достопримечательность", "Достопримечательности", "Достопримечательность"),
    AIRPORT("Аэропорт", "Аэропорты", "Аэропорт"),
    PORT("Порт", "Порты", "Порт"),
    MARINA("Яхтенный порт (Марина)", "Яхтенные порты (Марины)", "Яхтенный порт (Марину)"),
    EXCURSION("Экскурсия", "Экскурсии", "Экскурсию")
}
