package ru.playa.sce.dto

class DictionaryEntry(
        id: Int = 0,
        code: String = "",
        name: String = "",
        objectType: String = "dictionaryentry",
        val parent: DictionaryEntry? = null,
        val rating: Int?,
        val externalMappings: Array<ExternalMapping>? = emptyArray(),
        val visibility: Array<Option>? = emptyArray(),
        val synonyms: String? = ""
) : BaseObject(id, code, name, objectType)
