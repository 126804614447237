package ru.playa.sce.dto


class ApplicableType(val id: Int,
                     val name: String,
                     val code: String,
                     val objectType: String,
                     val dictionary: Boolean)

// temporary hardcoded
enum class ApplicableTypeGroups(val groupName: String, val groupTypes: List<String>) {
    GEOGRAPHY("География", listOf("country", "region", "town", "district", "landmark")),
    HOTELS_AND_TOURS("Отели и туры", listOf("hotel", "room", "tour", "mealgroup", "meal", "menu", "excursion", "tourpacket")),
    DATA("Данные", listOf("externalsystem", "categorygroup", "category", "tag", "imageflag", "hoteltype", "language", "currency", "contentblock")),
    PAGES("Страницы", listOf("news", "text"))
}

// temporary hardcoded
enum class ApplicableTypeRolesGroups(val groupName: String, val groupTypes: List<String>) {
    GEOGRAPHY("География", listOf("country", "region", "town", "district")),
    TOURISM("Туризм", listOf("tour", "landmark", "airport", "port", "hotel", "excursion", "room", "menu", "news", "text", "banner", "externalsystem", "tourpacket")),
    DICTIONARIES("Справочники", listOf("contentblock", "currency", "categorygroup", "mealgroup", "category", "hoteltype", "tourtype", "imageflag", "meal", "tag", "language", "tourpackettype"))
}

