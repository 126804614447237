package ru.playa.sce.dto


class ObjectDescriptionType(
        val id: Int = 0,
        val code: String = "",
        val name: String = "",
        val objectType: String = "ObjectDescriptionType",
        val applicableTypes: Array<Option> = emptyArray()
)
