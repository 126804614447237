package ru.playa.sce.dto

class Text(
        id: Int = 0,
        code: String = "",
        name: String = "",
        objectType: String = "Text",
        coreId: Int = 0,
        status: String = ObjectStatus.DRAFT.name,
        publishedVersionId: Int? = null,
        draftVersionId: Int? = null,
        externalMappings: Array<ExternalMapping> = emptyArray(),
        visibility: Array<Option> = emptyArray(),
        tags: Array<Option> = emptyArray(),
        synonyms: String? = "",
        val textType: String,
        val anons: String?,
        val description: String?,
        val start: String?,
        val end: String?,
        val linkType: String,
        val linkURL: String = "",
        val blocks: Array<Option> = emptyArray(),
        val objects: Array<Core> = emptyArray(),
        val target: Core?
) : WebObject(id, code, name, objectType, coreId, status, publishedVersionId, draftVersionId, externalMappings, visibility, tags, synonyms)