package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.ExternalSystem


object External {
    fun getAll() = Network.request<ListResult<ExternalSystem>>(Network.Method.GET,
            "/sce-web/api/user/object/externalsystem/search")

    fun getByObjectType(objectType: String) =
            Network.request<ListResult<ExternalSystem>>(Network.Method.GET,
                    "/sce-web/api/user/object/externalsystem/search?objecttype=$objectType")

    fun createExternalSystem(systems: ExternalSystem) =
            Network.send(Network.Method.POST, systems, "/sce-web/api/user/object/externalsystem")

    fun editExternalSystem(systems: ExternalSystem) =
            Network.send(Network.Method.PUT, systems, "/sce-web/api/user/object/externalsystem")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/object/externalsystem/$id")
}