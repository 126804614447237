package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.Country
import ru.playa.sce.dto.ObjectStatus


object Countries {

    fun get(
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            external: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            visibility: List<String> = emptyList(),
            tags: List<String> = emptyList()
    ) = Network.request<ListResult<Country>>(Network.Method.GET,
            "/sce-web/api/user/objects/country/search?pageSize=$pageSize&start=$start&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict&external=$external${objectStatus.joinToString("") { "&objectStatus=$it" }}${visibility.joinToString("") { "&visibility=$it" }}${tags.joinToString("") { "&tags=$it" }}")

    fun getById(id: Int) = Network.request<Country>(Network.Method.GET,
            "/sce-web/api/user/objects/country/$id")

    fun getDraft(coreId: Int) = Network.request<Country>(Network.Method.GET,
            "/sce-web/api/user/objects/country/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<Country>(Network.Method.GET,
            "/sce-web/api/user/objects/country/draft/$coreId?version=$version&archive=$archive")

    fun update(country: Country) = Network.send(Network.Method.PUT,
            country, "/sce-web/api/user/objects/country")

    fun create(country: Country) = Network.send(Network.Method.POST,
            country, "/sce-web/api/user/objects/country")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/country/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/country/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/country/publish/$id")

}