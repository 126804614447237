@file:Suppress("unused")

package ru.playa.sce.api

import org.w3c.dom.HTMLFormElement
import org.w3c.files.Blob
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.*


object Assets {

    fun get(objectId: Int) = Network.request<ListResult<Asset>>(
            Network.Method.GET,
            "/sce-web/api/user/objects/asset/object/$objectId"
    )

    fun getByDescriptionId(descriptionId: Int) = Network.request<ListResult<Asset>>(
            Network.Method.GET,
            "/sce-web/api/user/objects/asset/description/$descriptionId"
    )

    fun removeLinkWithDescription(descriptionId: Int, assetId: Int) = Network.requestNoResult(
            Network.Method.DELETE,
            "/sce-web/api/user/objects/asset/description/link/$descriptionId/$assetId"
    )

    fun removeLinkWithObject(objectId: Int, assetId: Int) = Network.requestNoResult(
            Network.Method.DELETE,
            "/sce-web/api/user/objects/asset/object/link/$objectId/$assetId"
    )

    fun sendTempImage(form: HTMLFormElement) = Network.sendForm<FileUploaded>(
            Network.Method.POST,
            form,
            "/sce-web/servlet/user/upload"
    )

    fun sendObjectImage(
            form: HTMLFormElement,
            blob: Blob,
            objId: Int,
            coreId: Int,
            fileName: String,
            name: String,
            descriptions: ArrayList<ObjectDescription>,
            selectedFlags: ArrayList<Flag>
    ) = Network.sendObjectImage<FileUploaded>(
            Network.Method.POST,
            form,
            blob,
            objId,
            coreId,
            fileName,
            name,
            descriptions,
            selectedFlags,
            "/sce-web/servlet/user/asset"
    )

    fun getFlags(objectType: String) = Network.request<ListResult<Flag>>(
            Network.Method.GET,
            "/sce-web/api/user/objects/asset/flags/$objectType "
    )

    fun getRatio(objectType: String) = Network.request<Ratio>(
            Network.Method.GET,
            "/sce-web/api/user/objects/asset/ratio/$objectType"
    )

    fun reorder(objectId: Int, assets: Array<Int>) = Network.sendNoResult(Network.Method.PUT, assets, "/sce-web/api/user/objects/asset/object/$objectId/order")

    fun sendFile(
            form: HTMLFormElement,
            objId: Int,
            coreId: Int,
            name: String,
            file: Blob,
            fileName: String, hidden: Boolean = false
    ) = Network.sendForm<Asset>(
            Network.Method.POST,
            form,
            "/sce-web/servlet/user/asset",
            if (hidden) hashMapOf(
                    Pair("objectId", objId.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("name", name),
                    Pair("hidden", "true"))
            else {
                hashMapOf(
                        Pair("objectId", objId.toString()),
                        Pair("coreId", coreId.toString()),
                        Pair("name", name))
            },
            file,
            fileName
    )

    fun rename(newName: AssetRename) = Network.sendNoResult(
            Network.Method.PUT,
            newName
            , "/sce-web/api/user/objects/asset/rename"
    )
}
