package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.js.Promise

/**
 * @author Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru).
 */
class Container(parent: HTMLElement, val body: HTMLElement.() -> Unit) : Component(parent) {
    override fun build(): Promise<HTMLElement> = async {
        newDiv {
        }.apply(body)
    }

}

fun HTMLElement.container(body: HTMLElement.() -> Unit) = Container(this, body).apply { render() }