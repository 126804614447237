package ru.playa.sce.views.countries

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Countries
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.External
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.dictionaries.dictionaryEntryAddDialog
import kotlin.dom.appendText

class CountryEditorView(private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "countries/editor"
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/

    private lateinit var draft: Country

    private var countryCode = ""
    private var countryName = ""
    private var countryCurrency: DictionaryEntry? = null
    private var countryArea: Area? = null
    private val countryTags = mutableListOf<Option>()
    private var countrySynonyms = ""
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()

    private lateinit var googleMap: GoogleMap

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = Regex("[A-Za-zА-Яа-я\\- .]{2,200}").matches(value)
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex("[A-Za-zА-Яа-я0-9]{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(countryName)) validationErrors.add("Указано некорректное наименование страны")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (!validateSynonyms(countrySynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
    }

    override fun saveEditorData() = async {
        val country = Country(
                id = if (coreId != null) draft.id else 0,
                code = countryCode,
                name = countryName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = countryTags.toTypedArray(),
                synonyms = countrySynonyms,
                currency = countryCurrency,
                area = countryArea
        )
        val result = if (coreId == null) Countries.create(country).await() else Countries.update(country).await()
//        confirmOnLeave = false
        Navigation.Countries.country(result.id, result.coreId)
    }

    private fun initEditorData() = async {
        countryTags.clear()
        if (coreId != null) {
            draft = Countries.getDraft(coreId).await()
            countryCode = draft.code
            countryName = draft.name
            countryCurrency = draft.currency
            countryArea = draft.area
            countryTags.addAll(draft.tags)
            countrySynonyms = draft.synonyms ?: ""
        } else {
            countryCode = ""
            countryName = ""
            countryCurrency = null
            countryArea = null
            countrySynonyms = ""
        }
        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType("country").await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        validationAlert = null
//        confirmOnLeave = false
    }

    override fun render() {
        async {
            initEditorData().await()
            dom.apply {
                clrPageHeader(if (coreId == null) "Новая страна" else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Страны") {
                        Navigation.Countries.countries()
                    }
                }
                div {
                    style.apply { display = "flex"; flexDirection = "row" }
                    div {
                        style.width = "600px"
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                clrGroup("Код", true) {
                                    clrInput {
                                        value = countryCode
                                        tooltipContent = "Введён недопустимый символ"
                                        validationPredicate = { true }
                                        onChangeFunction = {
                                            value = checkCodeInput(value.toLowerCase()).first
                                            countryCode = value
                                            render()
                                        }
                                        onInputFunction = {
                                            val (result, codeIsValid) = checkCodeInput(value.toLowerCase())
                                            value = result
                                            countryCode = value
                                            if (codeIsValid) {
                                                validationPredicate = { true }
                                                isInvalid = false
                                            } else {
                                                validationPredicate = { false }
                                                isInvalid = true
                                            }
                                            onPostRender = {
                                                getHTMLElement().focus()
                                            }
                                            render()
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        value = countryName
                                        tooltipContent = "От 2 до 200 букв латинского и русского алфавитов. Может содержать знаки пробела, точку и тире"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            value = value.capitalize()
                                            countryName = value
                                            googleMap.fillGeocodeInput(countryName)
                                            this@clrInput.render()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Валюта") {
                                    val currency = countryCurrency
                                    span { appendText(if (currency != null) "${currency.name} (${currency.code})" else "Не выбрана") }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Pencil
                                        tooltipTitle = "Выбрать"
                                        onClickFunction = { _ ->
                                            dom.selectionDialog<DictionaryEntry> {
                                                title = "Выберите валюту"
                                                searchQuery = currency?.name ?: ""
                                                selectedValue = currency
                                                onSelectFunction = {
                                                    countryCurrency = selectedValue
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                                datagridColumn("Код", 1, "code") {
                                                    appendText(it.code)
                                                }
                                                datagridColumn("Наименование", 3, "name") {
                                                    appendText(it.name)
                                                }
                                                orderField = "name"
                                                getDataFunction = { start, pageSize, orderField, reverseOrder, query ->
                                                    Dictionaries.getDictionaryEntries("currency", start, pageSize, orderField, reverseOrder, query)
                                                }
                                                equalityPredicate = { x, y -> x.id == y.id }
                                                addDialogFunction = {
                                                    dom.dictionaryEntryAddDialog("currency") {
                                                        headerTitle = "Новая валюта"
                                                        onSaveFunction = {
                                                            countryCurrency = it
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (countryTags.size > 0) {
                                        countryTags.sortBy { it.name.toLowerCase() }
                                        countryTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    countryTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (countryTags.none { it.id == entry.id }) {
                                                countryTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                countryTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = countrySynonyms
                                        onChangeFunction = {
                                            countrySynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.Countries.country(draft.id, draft.coreId)
                                else Navigation.Countries.countries()
                            }
                        }
                    }
                    div {
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                        googleMap = googleMap("100%", "500px") {
                            geocodeEnabled = true

                            countryArea?.let {
                                mapBounds(it.southWest.latitude, it.southWest.longitude, it.northEast.latitude, it.northEast.longitude)
                            } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)

                            onChangeFunction = {
                                countryArea = bounds?.let {
                                    Area(Point(it.southWest.latitude, it.southWest.longitude), Point(it.northEast.latitude, it.northEast.longitude))
                                }
                                enableSaveButton()
                            }

                            onPostRender = {
                                mapFitControllerBounds()
                                fillGeocodeInput(countryName)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun checkCodeInput(value: String): Pair<String, Boolean> {
        var result = ""
        var isValid = true
        value.forEach {
            when {
                (it in 'а'..'я') -> result += charToLat(it)
                (it in 'a'..'z') || (it in '1'..'9') -> result += it.toString()
                (it == ' ' || it == '-') -> result += "-"
                else -> isValid = false
            }
        }
        return Pair(result, isValid)
    }

    private fun charToLat(ch: Char) = when (ch) {
        'а' -> "a"
        'б' -> "b"
        'в' -> "v"
        'г' -> "g"
        'д' -> "d"
        'е' -> "e"
        'ё' -> "yo"
        'ж' -> "zh"
        'з' -> "z"
        'и' -> "i"
        'й' -> "y"
        'к' -> "k"
        'л' -> "l"
        'м' -> "m"
        'н' -> "n"
        'о' -> "o"
        'п' -> "p"
        'р' -> "r"
        'с' -> "s"
        'т' -> "t"
        'у' -> "u"
        'ф' -> "f"
        'х' -> "kh"
        'ц' -> "ts"
        'ч' -> "ch"
        'ш' -> "sh"
        'щ' -> "sch"
        'ъ' -> ""
        'ы' -> "ih"
        'ь' -> ""
        'э' -> "e"
        'ю' -> "ui"
        'я' -> "ia"
        else -> ""
    }
}
