package ru.playa.sce.components

import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.util.async

class RenderBlocks(parent: HTMLElement) : Component(parent) {

    val blocks = mutableListOf<RenderBlock>()
    var blockIndex = 0

    fun nextBlock() {
        blockIndex = if (blockIndex == blocks.lastIndex) 0 else blockIndex + 1
        render()
    }

    fun previousBlock() {
        blockIndex = if (blockIndex == 0) blocks.lastIndex else blockIndex - 1
        render()
    }

    override fun build() = async {
        newDiv {
            blocks[blockIndex].block(this)
        }
    }
}

class RenderBlock(val block: HTMLDivElement.() -> Unit)

fun RenderBlocks.renderBlock(block: HTMLDivElement.() -> Unit = {}) = RenderBlock(block).also { blocks.add(it) }

fun HTMLElement.renderBlocks(block: RenderBlocks.() -> Unit = {}) = RenderBlocks(this).apply(block).apply { render() }