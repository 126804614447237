package ru.playa.sce.api

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.core.Network
import ru.playa.sce.dto.Account
import ru.playa.sce.dto.AccountInfo
import ru.playa.sce.dto.SCEUser


object Accounts {

    fun getSCEUser() = Network.request<SCEUser>(Network.Method.GET, "/sce-web/api/user/auth-test/welcome")

    fun getCurrentAccount() = Network.request<Account>(Network.Method.GET, "/sce-web/api/user/info/accounts/current")

    fun updateAccount(account: Account) = Network.send(Network.Method.PUT, account, "/sce-web/api/user/account")

    fun getAll() = Network.request<Array<Account>>(Network.Method.GET, "/sce-web/api/user/info/accounts")

    fun changeActiveAccount(id: Int) = Network.request<Account>(Network.Method.POST, "/sce-web/api/user/info/accounts/$id")

    private fun getPrivileges() = Network.request<AccountInfo>(Network.Method.GET, "/sce-web/api/user/info/privileges")

    fun getObjectTypePrivileges(objectTypeCode: String) = async {
        val accountInfo: AccountInfo = getPrivileges().await()
        accountInfo.privileges.find { it.objectType.code == objectTypeCode }?.privileges ?: emptyArray()
    }

    fun getPrivilegeObjectTypes(privilege: String) = async {
        val accountInfo: AccountInfo = getPrivileges().await()
        accountInfo.privileges.filter { it.privileges.contains(privilege) }.map { it.objectType.code }
    }
}