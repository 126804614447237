@file:Suppress("unused")

package ru.playa.sce.components

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.Meta
import ru.playa.kotlinx.clarity.js.util.Paging
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Locations
import ru.playa.sce.api.Tours
import ru.playa.sce.dto.Core
import ru.playa.sce.dto.LocationType
import kotlin.dom.appendText

class CoreSelectionDialog(parent: HTMLElement) : Component(parent) {

    var selectionMode = CoreSelectionMode.SINGLE
    private var headerTitle = "Выбор объекта"
    var onSaveFunction: () -> Unit = {}
    var selectedObject: Core? = null
    val selectedObjects = mutableListOf<Core>()

    private var searchEntryType = "COUNTRY"
    private var searchLocationType = LocationType.TOWN
    private var searchCountryId = 0
    private var searchQuery = ""
    private var searchResults: Array<Core> = emptyArray()
    private var searchResultsTotal = 0

    private lateinit var allCountryCores: Array<Core>
    private lateinit var searchTable: DataGrid<Core>

    private lateinit var saveButton: Button

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    override fun build() = async {
        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
        searchCountryId = allCountryCores.firstOrNull()?.id ?: 0

        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerTitle) {
                        parent.remove()
                    }
                    clrModalDialogBody {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Тип объекта") {
                                    clrSelect<String> {
                                        clrOption("Страна", "COUNTRY", searchEntryType == "COUNTRY")
                                        LocationType.values().forEach {
                                            if (it != LocationType.MARINA) clrOption(it.displayName, it.name, searchEntryType == it.name)
                                        }
                                        clrOption("Тур", "TOUR", searchEntryType == "TOUR")

                                        onChangeFunction = {
                                            searchEntryType = selectedOptions.first().value
                                            searchTable.start = 0
                                            this@clrForm.render()
                                        }
                                    }
                                }
                                clrGroup("Страна объекта") {
                                    hidden = searchEntryType == "COUNTRY" || searchEntryType == "TOUR"
                                    clrSelect<Int> {
                                        allCountryCores.forEach {
                                            clrOption(
                                                    it.publishedName ?: it.draftName ?: "—",
                                                    it.id, searchCountryId == it.id
                                            )
                                        }

                                        onChangeFunction = {
                                            searchTable.start = 0
                                            searchCountryId = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("Поиск") {
                                    clrInput {
                                        value = searchQuery
                                        onInputFunction = {
                                            searchQuery = value
                                        }
                                        onPressEnterFunction = {
                                            searchTable.render()
                                        }
                                    }
                                    clrButton("Найти") {
                                        margin = "0.25rem 0"
                                        isSmall = true
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            searchTable.render()
                                        }
                                    }
                                }
                            }
                        }
                        searchTable = clrDatagrid {
                            getDataFunction = {
                                async {
                                    findResults(start, pageSize).await()
                                    ListResult(Meta(searchResultsTotal, Paging(this@clrDatagrid.start, searchResults.size)), searchResults)
                                }
                            }
                            this.clrColumn("Наименование") {
                                appendText(it.publishedName ?: it.draftName ?: "—")
                            }
                            this.clrColumn(if (this@CoreSelectionDialog.selectionMode == CoreSelectionMode.MULTIPLE) "Добавлен" else "Выбран") { result ->
                                when (this@CoreSelectionDialog.selectionMode) {
                                    CoreSelectionMode.SINGLE -> {
                                        clrRadio("", "searchCore", result) {
                                            isChecked = selectedObject?.id == result.id
                                            isInline = true
                                            onChangeFunction = {
                                                selectedObject = result
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                    CoreSelectionMode.MULTIPLE -> {
                                        clrCheckbox {
                                            isInline = true
                                            removeMarginBottom = true
                                            isChecked = selectedObjects.any { it.id == result.id }
                                            onChangeFunction = { _ ->
                                                if (isChecked) {
                                                    selectedObjects.add(result)
                                                } else {
                                                    selectedObjects.find { it.id == result.id }.let {
                                                        selectedObjects.remove(it)
                                                    }
                                                }
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    clrModalDialogFooter {
                        saveButton = clrButton("Выбрать") {
                            iconShape = IconShape.Check
                            isDisabled = true
                            onClickFunction = {
                                onSaveFunction()
                                parent.remove()
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                parent.remove()
                            }
                        }
                    }
                }
            }
        }
    }

    private fun findResults(start: Int, size: Int) = async {
        when (searchEntryType) {
            "COUNTRY" -> {
                Locations.getCountryCores(start, size, searchQuery).await().let { response ->
                    searchResults = response.data
                    searchResultsTotal = response.meta.total
                }
            }
            "TOUR" -> {
                Tours.getTourCores(start, size, searchQuery).await().let { response ->
                    searchResults = response.data
                    searchResultsTotal = response.meta.total
                }
            }
            else -> {
                Locations.getParentCores(
                        start, size, searchQuery, searchCountryId,
                        listOf(LocationType.valueOf(searchEntryType))
                ).await().let { response ->
                    searchResults = response.data.map {
                        Core(
                                id = it.id,
                                code = it.code,
                                objectType = it.objectType,
                                objectSubtype = it.draft?.type?.toLowerCase(),
                                publishedVersionId = it.published?.id,
                                draftVersionId = it.draft?.id,
                                publishedName = it.published?.name,
                                draftName = it.draft?.name
                        )
                    }.toTypedArray()
                    searchResultsTotal = response.meta.total
                }
            }
        }
    }
}

enum class CoreSelectionMode {
    SINGLE,
    MULTIPLE
}

fun HTMLElement.coreSelectionDialog(block: CoreSelectionDialog.() -> Unit = {}) =
        CoreSelectionDialog(this).apply(block).apply { render() }
