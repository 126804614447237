package ru.playa.sce.views.hotels

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.label
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.Meta
import ru.playa.kotlinx.clarity.js.util.Paging
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.*
import ru.playa.sce.components.clrButtonGroup
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.components.suggestionBox
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import kotlin.dom.appendText
import kotlin.js.Promise

class HotelsView : View() {

    companion object {
        const val PATH = "hotels"
    }

    private lateinit var privileges: Array<String>

    private var searchFulltext = ""
    private var searchFullStatuses = mutableListOf(ObjectStatus.PUBLISHED, ObjectStatus.DRAFT, ObjectStatus.APPROVED)
    private var searchFullVisibilities = mutableListOf<Int>()
    private var fulltextIsSearched = false

    private var searchName = ""
    private var searchStrict = false
    private var searchExternalSystem = ""
    private var searchExternalName = ""
    private var searchNoEquating = false
    private var searchCountry: Core? = null
    private var searchLocation: CoreSearch? = null
    private var searchDistrict: Location? = null
    private var searchStrictCategoryRating = false
    private var searchCategory: DictionaryEntry? = null
    private var searchHotelType: DictionaryEntry? = null
    private var searchObjectStatuses = mutableListOf<ObjectStatus>()
    private var searchVisibilities = mutableListOf<String>()
    private var searchTags = mutableListOf<String>()
    private var databaseIsSearched = false

    private lateinit var datagridFulltext: DataGrid<Hotel>
    private lateinit var datagridDatabase: DataGrid<Hotel>

    override fun render() {
        async {
            privileges = Accounts.getObjectTypePrivileges("hotel").await()
            val externals = External.getAll().await().data
            searchExternalSystem = externals.firstOrNull()?.code ?: ""
            val visibilityAreas = VisibilityAreas.get().await().data.apply { sortBy { it.id } }
            val dictionaryCategories = Dictionaries.getDictionaryEntries("categorygroup", 0, 500, "").await().data.apply { sortByDescending { it.rating } }
            val dictionaryHotelTypes = Dictionaries.getDictionaryEntries("hoteltype", 0, 500, "").await().data.apply { sortBy { it.id } }
            val allCountryCores = Locations.getCountryCores(0, 500, "").await().data
            var allCountryLocations = if (searchCountry != null) Locations.getParentCores(0, 500, "", searchCountry?.id
                    ?: 0, listOf(LocationType.TOWN, LocationType.REGION)).await().data else arrayOf()
            var allTownDistricts = if (searchLocation != null) Locations.get(pageSize = 500, locationType = listOf(LocationType.DISTRICT), parentId = searchLocation?.id).await().data else arrayOf()

            dom.apply {
                clrPageHeader("Отели") {
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrButtonGroup {
                        clrButton("Добавить отель") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Plus
                            isDisabled = !privileges.contains("CREATE")
                            onClickFunction = {
                                Navigation.Hotels.add()
                            }
                        }
                    }
                }
                clrTabs {
                    clrTab("Поиск по базе") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Название отеля или код:") {
                                    clrInput {
                                        value = searchName
                                        size = 30
                                        onChangeFunction = {
                                            searchName = value
                                        }
                                        onPressEnterFunction = {
                                            searchName = value
                                            databaseIsSearched = true
                                            datagridDatabase.getDataEnabled = databaseIsSearched
                                            datagridDatabase.start = 0
                                            datagridDatabase.render()
                                        }
                                    }
                                    clrCheckbox("точное совпадение") {
                                        isInline = true
                                        isChecked = searchStrict
                                        onChangeFunction = {
                                            searchStrict = isChecked
                                        }
                                    }
                                }
                                clrGroup("Страна:") {
                                    clrSelect<Core?> {
                                        clrOption("Не выбрана", null)
                                        allCountryCores.forEach {
                                            clrOption(it.publishedName ?: it.draftName
                                            ?: "—", it, searchCountry?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            async {
                                                searchCountry = selectedOptions.first().value
                                                searchLocation = null
                                                searchDistrict = null
                                                if (searchCountry != null) {
                                                    allCountryLocations = Locations.getParentCores(0, 500, "", searchCountry?.id
                                                            ?: 0, listOf(LocationType.TOWN, LocationType.REGION)).await().data
                                                    allTownDistricts = arrayOf()
                                                } else {
                                                    allCountryLocations = arrayOf()
                                                    allTownDistricts = arrayOf()
                                                }
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                    if (allCountryLocations.isNotEmpty()) {
                                        label { appendText("Расположение:") }
                                        clrSelect<CoreSearch?> {
                                            clrOption("Не выбран", null)
                                            allCountryLocations.forEach { countryLocation ->
                                                val version = countryLocation.published ?: countryLocation.draft
                                                version?.let {
                                                    val prefix = if (it.type === "REGION") "рег." else "г."
                                                    clrOption("$prefix ${it.name}", countryLocation, searchLocation?.id == countryLocation.id)
                                                }
                                            }
                                            onChangeFunction = {
                                                async {
                                                    searchLocation = selectedOptions.first().value
                                                    searchDistrict = null
                                                    allTownDistricts = if (searchLocation != null)
                                                        Locations.get(pageSize = 500, locationType = listOf(LocationType.DISTRICT), parentId = searchLocation?.id).await().data
                                                    else
                                                        arrayOf()
                                                    this@clrForm.render()
                                                }
                                            }
                                        }
                                    }
                                    if (allTownDistricts.isNotEmpty()) {
                                        label { appendText("Район:") }
                                        clrSelect<Location?> {
                                            clrOption("Не выбран", null)
                                            allTownDistricts.forEach {
                                                clrOption(it.name, it, searchDistrict?.id == it.id)
                                            }

                                            onChangeFunction = {
                                                searchDistrict = selectedOptions.first().value
                                            }
                                        }
                                    }
                                }
                                clrGroup("Категория отеля:") {
                                    clrSelect<Boolean> {

                                        clrOption("Не хуже", false, !searchStrictCategoryRating)
                                        clrOption("Только", true, searchStrictCategoryRating)

                                        onChangeFunction = {
                                            searchStrictCategoryRating = selectedOptions.first().value
                                        }
                                    }
                                    clrSelect<DictionaryEntry?> {
                                        clrOption("Не выбрана", null)
                                        dictionaryCategories.forEach {
                                            clrOption(it.name, it, searchCategory?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            searchCategory = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("Тип объекта:") {
                                    clrSelect<DictionaryEntry?> {
                                        clrOption("Не выбран", null)
                                        dictionaryHotelTypes.forEach {
                                            clrOption(it.name, it, searchHotelType?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            searchHotelType = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("Теги:") {
                                    if (searchTags.size > 0) {
                                        searchTags.forEach { tag ->
                                            clrLabel(tag) {
                                                style = Label.Style.Blue
                                                isDismissable = true
                                                onDismissFunction = {
                                                    searchTags.remove(tag)
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        width = 250
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (searchTags.none { it == entry.name }) {
                                                searchTags.add(entry.name)
                                                this@clrForm.render()
                                            }
                                        }
                                        inputPlaceholder = "Выберите тег"
                                    }
                                }
                                if (externals.isNotEmpty()) {
                                    clrGroup("Внешний справочник:") {
                                        clrSelect<String> {
                                            for (external in externals) {
                                                clrOption(external.name, external.code, searchExternalSystem == external.code)
                                            }

                                            onChangeFunction = {
                                                searchExternalSystem = selectedOptions.firstOrNull()?.value ?: ""
                                            }
                                        }
                                        val externalNameInput = clrInput {
                                            size = 30
                                            value = searchExternalName
                                            onChangeFunction = {
                                                searchExternalName = value
                                            }
                                        }
                                        clrCheckbox("не имеющие приравнения") {
                                            isInline = true
                                            isChecked = searchNoEquating
                                            externalNameInput.isDisabled = isChecked
                                            externalNameInput.render()
                                            onChangeFunction = {
                                                searchNoEquating = isChecked
                                                externalNameInput.isDisabled = isChecked
                                                externalNameInput.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    label { appendText("Показывать:") }
                                    div {
                                        style.marginRight = "40px"
                                        val enumStatus = ObjectStatus.values()
                                        val defaultStatusCheckboxes = mutableListOf<Checkbox>()

                                        for (status in enumStatus) {
                                            clrCheckbox(status.displayName) {
                                                isChecked = searchObjectStatuses.contains(status)
                                                onChangeFunction = {
                                                    if (isChecked) {
                                                        searchObjectStatuses.add(status)
                                                    } else {
                                                        searchObjectStatuses.remove(status)
                                                    }
                                                }
                                            }.let { defaultStatusCheckboxes.add(it) }
                                        }
                                    }
                                    label { appendText("Видимость:") }
                                    div {
                                        val defaultVisibilityCheckboxes = mutableListOf<Checkbox>()

                                        for (area in visibilityAreas) {
                                            clrCheckbox(area.name) {
                                                isChecked = searchVisibilities.contains(area.code)
                                                onChangeFunction = {
                                                    if (isChecked) {
                                                        searchVisibilities.add(area.code)
                                                    } else {
                                                        searchVisibilities.remove(area.code)
                                                    }
                                                }
                                            }.let { defaultVisibilityCheckboxes.add(it) }
                                        }
                                    }
                                }
                            }
                            clrButton("Найти") {
                                iconShape = IconShape.Search
                                onClickFunction = {
                                    databaseIsSearched = true
                                    datagridDatabase.getDataEnabled = databaseIsSearched
                                    datagridDatabase.start = 0
                                    datagridDatabase.render()
                                }
                            }
                        }
                        datagridDatabase = clrDatagrid {
                            clrColumn("Город (район)", 1) {
                                val currentHotelParent = it.parent
                                if (currentHotelParent != null)
                                    appendText(currentHotelParent.publishedName ?: currentHotelParent.draftName ?: "—")
                                else appendText("—")
                            }
                            clrColumn("Наименование", 2, "name") { hotel ->
                                a {
                                    appendText(hotel.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.Hotels.hotel(hotel.id, hotel.coreId)
                                    }
                                }
                            }
                            clrColumn("Категория отеля", 1) {
                                appendText(it.category?.name ?: "—")
                            }
                            clrColumn("Видимость", 1) {
                                var visibilityText = ""
                                val visibilitiesLastIndex = it.visibility.lastIndex
                                it.visibility.forEachIndexed { index, vis ->
                                    visibilityText += vis.name
                                    if (index != visibilitiesLastIndex) visibilityText += ", "
                                }
                                if (visibilitiesLastIndex == -1) visibilityText += "—"
                                appendText(visibilityText)
                            }
                            clrColumn("Тип объекта", 1) {
                                appendText(it.hotelType?.name ?: "—")
                            }
                            clrColumn("Статус", 1) {
                                appendText(ObjectStatus.valueOf(it.status).displayName)
                            }
                            clrColumn("Теги", 2) { hotel ->
                                val hotelTags = hotel.tags.apply { sortBy { it.name.toLowerCase() } }
                                var tagsText = ""
                                val tagsLastIndex = hotel.tags.lastIndex
                                hotelTags.forEachIndexed { index, tag ->
                                    tagsText += tag.name
                                    if (index != tagsLastIndex) tagsText += ", "
                                }
                                if (tagsLastIndex == -1) {
                                    tagsText += "—"
                                    appendText(tagsText)
                                } else {
                                    clrDropdown(tagsText) {
                                        isButton = false
                                        isDatagrid = true
                                        hotelTags.forEach { clrItem(it.name) }
                                    }
                                }
                            }
                            overflowYVisible = true
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            orderField = "name"
                            getDataEnabled = databaseIsSearched
                            getDataFunction = {
                                getDataDatabase(start, pageSize, orderField, reverseOrder)
                            }
                        }
                    }
                    clrTab("Полнотекстовый поиск") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup {
                                    clrInput {
                                        size = 50
                                        value = searchFulltext
                                        placeholder = "Название, расположение или часть описания"
                                        onChangeFunction = {
                                            searchFulltext = value
                                        }
                                        onPressEnterFunction = {
                                            searchFulltext = value
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                    clrButton("Найти") {
                                        isSmall = true
                                        margin = "0.15rem 0"
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                }
                                clrGroup("Показывать:") {
                                    val enumStatus = ObjectStatus.values()
                                    val fulltextStatusCheckboxes = mutableListOf<Checkbox>()

                                    for (status in enumStatus) {
                                        clrCheckbox(status.displayName) {
                                            isInline = true
                                            isChecked = searchFullStatuses.contains(status)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    if (status == ObjectStatus.ARCHIVED)
                                                        searchFullStatuses.clear()
                                                    else searchFullStatuses.remove(ObjectStatus.ARCHIVED)
                                                    searchFullStatuses.add(status)
                                                    this@clrForm.render()
                                                } else {
                                                    searchFullStatuses.remove(status)
                                                }
                                            }
                                        }.let { fulltextStatusCheckboxes.add(it) }
                                    }
                                }
                                clrGroup("Видимость:") {
                                    val fulltextVisibilityCheckboxes = mutableListOf<Checkbox>()

                                    for (area in visibilityAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = searchFullVisibilities.contains(area.id)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    searchFullVisibilities.add(area.id)
                                                } else {
                                                    searchFullVisibilities.remove(area.id)
                                                }
                                            }
                                        }.let { fulltextVisibilityCheckboxes.add(it) }
                                    }
                                }
                            }
                        }
                        datagridFulltext = clrDatagrid {
                            clrColumn("Наименование", 3) { entry ->
                                a {
                                    appendText(entry.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.Hotels.hotel(entry.id, entry.coreId)
                                    }
                                }
                            }
                            clrColumn("Статус", 3) { entry ->
                                appendText(ObjectStatus.valueOf(entry.status).displayName)
                            }
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            getDataEnabled = fulltextIsSearched
                            getDataFunction = {
                                getDataFulltext(start, pageSize)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getDataFulltext(start: Int, pageSize: Int) = if (searchFulltext.isNotBlank()) {
        val objectStatuses = searchFullStatuses.asSequence().map { it.name }.toList()
        Search.searchObjectType(searchFulltext, objectStatuses, start, pageSize, listOf("hotel"), searchFullVisibilities)
    } else {
        async { ListResult<Hotel>(Meta(0, Paging(0, 10)), emptyArray()) }
    }

    private fun getDataDatabase(start: Int, pageSize: Int, orderField: String, reverseOrder: Boolean): Promise<ListResult<Hotel>> {
        val external = if (searchExternalSystem.isNotBlank()) {
            when {
                searchNoEquating -> "$searchExternalSystem:null"
                searchExternalName.isNotBlank() -> "$searchExternalSystem:$searchExternalName"
                else -> ""
            }
        } else ""
        val parentId = searchDistrict?.coreId ?: searchLocation?.id

        return Hotels.get(
                start, pageSize, orderField, reverseOrder, searchName, searchStrict, external, searchObjectStatuses,
                searchVisibilities, searchTags, searchCountry?.id, parentId, searchCategory?.rating,
                searchStrictCategoryRating, searchHotelType?.id
        )
    }
}