package ru.playa.sce.dto

/**
 * @author Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru)08.09.2017.
 */
class Country(
        id: Int = 0,
        code: String = "",
        name: String = "",
        objectType: String = "country",
        coreId: Int = 0,
        status: String = ObjectStatus.DRAFT.name,
        publishedVersionId: Int? = null,
        draftVersionId: Int? = null,
        externalMappings: Array<ExternalMapping> = emptyArray(),
        visibility: Array<Option> = emptyArray(),
        tags: Array<Option> = emptyArray(),
        synonyms: String? = "",
        val currency: DictionaryEntry? = null,
        val area: Area? = null
) : WebObject(id, code, name, objectType, coreId, status, publishedVersionId, draftVersionId, externalMappings, visibility, tags, synonyms)
