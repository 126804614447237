package ru.playa.sce.components

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.files.Blob
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.dto.ObjectDescription
import kotlin.js.Promise

class ImageUploadDialog(parent: HTMLElement) : Component(parent) {

    var headerName = "Новая фотография"
    var ratio = ""
    var descriptions = emptyArray<ObjectDescription>()
    var precheckedDescriptionId = 0
    lateinit var onSaveData: (Blob, String, String, ArrayList<ObjectDescription>) -> Promise<Any>

    private var assetName = ""
    private val selectedDescriptions: ArrayList<ObjectDescription> = arrayListOf()

    private lateinit var editor: imgEditor
    private lateinit var saveButton: Button
    private var creationSuccess = false

    private val validationErrors = mutableListOf<String>()
    private var validationAlert: Alert? = null
    private lateinit var validationAlertContainer: HTMLDivElement

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun onSaveButtonClick() = async {
        editor.prepareBlob().await()
        validateEditorData()
        if (validationErrors.isEmpty()) {
            onSaveData(editor.getData(), editor.getFileName(), assetName, selectedDescriptions).then {
                creationSuccess = true
            }
        } else showValidationAlert()
    }

    private fun validateEditorData() {
        validationErrors.clear()
        if (editor.getData() == undefined) validationErrors.add(editor.getErrorStatus())
    }

    private fun showValidationAlert() {
        if (validationAlert == null) {
            validationAlert = validationAlertContainer.clrAlert {
                style = Alert.Style.ERROR
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
            }
        } else {
            validationAlert?.run {
                items.clear()
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
                render()
            }
        }
    }

    override fun build() = async {
        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerName) {
                        editor.clearOnHide()
                        parent.remove()
                    }
                    clrModalDialogBody {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                editor = imgEditor(ratio).apply {
                                    addListener("change") {
                                        enableSaveButton()
                                    }
                                }
                                clrGroup("Наименование") {
                                    clrInput {
                                        value = assetName
                                        onChangeFunction = {
                                            assetName = value
                                        }
                                    }
                                }
                                if (descriptions.isNotEmpty()) {
                                    clrGroup("Описания") {
                                        descriptions.forEach { description ->
                                            clrCheckbox(description.name) {
                                                isInline = true
                                                if (description.id == precheckedDescriptionId) {
                                                    isChecked = true
                                                    selectedDescriptions.add(description)
                                                }

                                                onChangeFunction = {
                                                    if (this.isChecked) {
                                                        selectedDescriptions.add(description)
                                                    } else {
                                                        selectedDescriptions.remove(description)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        validationAlertContainer = div {
                            style.paddingTop = "10px"
                        }
                    }
                    clrModalDialogFooter {
                        saveButton = clrButton("Сохранить") {
                            isDisabled = true
                            iconShape = IconShape.Check
                            onClickFunction = {
                                onSaveButtonClick().then {
                                    if (creationSuccess) {
                                        editor.clearOnHide()
                                        parent.remove()
                                    }
                                }
                            }
                        }
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                editor.clearOnHide()
                                parent.remove()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun HTMLElement.imageUploadDialog(block: ImageUploadDialog.() -> Unit) =
        ImageUploadDialog(this).apply(block).apply { render() }
