package ru.playa.sce.core

import org.w3c.dom.HTMLFormElement
import org.w3c.files.Blob
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import ru.playa.kotlinx.clarity.js.components.Alert
import ru.playa.kotlinx.clarity.js.components.clrAlertItem
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.keycloak.js.Auth
import ru.playa.sce.dto.Error
import ru.playa.sce.dto.Flag
import ru.playa.sce.dto.ObjectDescription
import kotlin.browser.document
import kotlin.browser.window
import kotlin.js.Promise


object Network {

    enum class Method {
        GET,
        POST,
        PUT,
        DELETE
    }

    fun <T> request(method: Method, url: String, json: String = "") = Promise<T> { d, c ->
        async {
            Application.loadingIndicator.show()
            try {
                Auth.updateToken { _ ->
                    val req = XMLHttpRequest()
                    req.open(method.name, url)
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)

                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            when (req.status) {
                                200.toShort() -> {
                                    if (req.responseText.isNotBlank()) {
                                        val resp: T = JSON.parse(req.responseText)
                                        d(resp)
                                    } else {
                                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                                            style = Alert.Style.ERROR
                                            clrAlertItem("Внутренняя ошибка сервера (200. Empty responseText)", Alert.Style.ERROR)
                                        }
                                        c(Exception("200. Empty responseText"))
                                    }
                                }
                                in 400.toShort()..499.toShort() -> {
                                    when (req.status) {
                                        401.toShort() -> {
                                            document.location?.reload()
                                            c(RuntimeException())
                                        }
                                        403.toShort() -> {
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem("Отказано в доступе", Alert.Style.ERROR)
                                            }
                                            c(Exception(req.responseText))
                                        }
                                        else -> {
                                            var errorText = "Ошибка сервера. Операция невозможна"
                                            val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                            if (responseTitle.isNotBlank()) errorText = responseTitle
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem(errorText, Alert.Style.ERROR)
                                            }
                                            c(Exception(errorText))
                                        }
                                    }
                                }
                                in 501.toShort()..599.toShort() -> {
                                    var errorText = "Ошибка выполнения. Попробуйте повторить операцию через некоторое время"
                                    val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                    if (responseTitle.isNotBlank()) errorText = responseTitle
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem(errorText, Alert.Style.ERROR)
                                    }
                                    c(Exception(errorText))
                                }
                                else -> {
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem("Внутренняя ошибка сервера", Alert.Style.ERROR)
                                    }
                                    c(Exception(req.responseText))
                                }
                            }
                            Application.loadingIndicator.hide()
                        }
                    }
                    if (json.isEmpty())
                        req.send()
                    else
                        req.send(json)
                }
            } catch (ex: Exception) {
                Application.loadingIndicator.hide()
                c(ex)
            }
        }
    }

    fun requestNoResult(method: Method, url: String, json: String = "") = Promise<Unit> { d, c ->
        async {
            Application.loadingIndicator.show()
            try {
                Auth.updateToken { _ ->
                    val req = XMLHttpRequest()

                    req.open(method.name, url)
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)
                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            d(Unit)
                            Application.loadingIndicator.hide()
                        }
                    }
                    if (json.isEmpty())
                        req.send()
                    else
                        req.send(json)
                }
            } catch (ex: Exception) {
                Application.loadingIndicator.hide()
                c(ex)
            }
        }
    }

    fun <T> send(method: Method, obj: T, url: String) = Promise<T> { d, c ->
        async {
            Application.loadingIndicator.show()
            try {
                Auth.updateToken { _ ->
                    val req = XMLHttpRequest()
                    req.open(method.name, url)
                    req.setRequestHeader("Accept", "application/json")
                    req.setRequestHeader("Content-Type", "application/json")
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)

                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            when (req.status) {
                                200.toShort(), 201.toShort() -> {
                                    if (req.responseText.isNotBlank()) {
                                        val resp: T = JSON.parse(req.responseText)
                                        d(resp)
                                    } else {
                                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                                            style = Alert.Style.ERROR
                                            clrAlertItem("Внутренняя ошибка сервера (200. Empty responseText)", Alert.Style.ERROR)
                                        }
                                        c(Exception("200. Empty responseText"))
                                    }
                                }
                                in 400.toShort()..499.toShort() -> {
                                    when (req.status) {
                                        401.toShort() -> {
                                            document.location?.reload()
                                            c(RuntimeException())
                                        }
                                        403.toShort() -> {
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem("Отказано в доступе", Alert.Style.ERROR)
                                            }
                                            c(Exception(req.responseText))
                                        }
                                        else -> {
                                            var errorText = "Ошибка сервера. Операция невозможна"
                                            val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                            if (responseTitle.isNotBlank()) errorText = responseTitle
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem(errorText, Alert.Style.ERROR)
                                            }
                                            c(Exception(errorText))
                                        }
                                    }
                                }
                                in 501.toShort()..599.toShort() -> {
                                    var errorText = "Ошибка выполнения. Попробуйте повторить операцию через некоторое время"
                                    val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                    if (responseTitle.isNotBlank()) errorText = responseTitle
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem(errorText, Alert.Style.ERROR)
                                    }
                                    c(Exception(errorText))
                                }
                                else -> {
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem("Внутренняя ошибка сервера", Alert.Style.ERROR)
                                    }
                                    c(Exception(req.responseText))
                                }
                            }
                            Application.loadingIndicator.hide()
                        }
                    }
                    req.send(JSON.stringify(obj))
                }
            } catch (ex: Exception) {
                Application.loadingIndicator.hide()
                c(ex)
            }
        }
    }

    fun <T> sendNoResult(method: Method, obj: T, url: String) = Promise<Unit> { d, c ->
        async {
            Application.loadingIndicator.show()
            try {
                Auth.updateToken { _ ->
                    val req = XMLHttpRequest()
                    req.open(method.name, url)
                    req.setRequestHeader("Accept", "application/json")
                    req.setRequestHeader("Content-Type", "application/json")
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)

                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            when (req.status) {
                                200.toShort(), 201.toShort(), 204.toShort() -> {
                                    d(Unit)
                                }
                                in 400.toShort()..499.toShort() -> {
                                    when (req.status) {
                                        401.toShort() -> {
                                            document.location?.reload()
                                            c(RuntimeException())
                                        }
                                        403.toShort() -> {
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem("Отказано в доступе", Alert.Style.ERROR)
                                            }
                                            c(Exception(req.responseText))
                                        }
                                        else -> {
                                            var errorText = "Ошибка сервера. Операция невозможна"
                                            val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                            if (responseTitle.isNotBlank()) errorText = responseTitle
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem(errorText, Alert.Style.ERROR)
                                            }
                                            c(Exception(errorText))
                                        }
                                    }
                                }
                                in 501.toShort()..599.toShort() -> {
                                    var errorText = "Ошибка выполнения. Попробуйте повторить операцию через некоторое время"
                                    val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                    if (responseTitle.isNotBlank()) errorText = responseTitle
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem(errorText, Alert.Style.ERROR)
                                    }
                                    c(Exception(errorText))
                                }
                                else -> {
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem("Внутренняя ошибка сервера", Alert.Style.ERROR)
                                    }
                                    c(Exception(req.responseText))
                                }
                            }
                            Application.loadingIndicator.hide()
                        }
                    }
                    req.send(JSON.stringify(obj))
                }
            } catch (ex: Exception) {
                Application.loadingIndicator.hide()
                c(ex)
            }
        }
    }

    fun <T> sendForm(method: Method, obj: HTMLFormElement, url: String, params: HashMap<String, String> = hashMapOf(), file: Blob? = null, fileName: String = "") = Promise<T> { d, c ->
        async {
            try {
                Auth.updateToken { _ ->
                    val formData = FormData(obj)
                    if (file != null) {
                        formData.append("file", file, fileName)
                    }
                    params.forEach {
                        formData.append(it.key, it.value)
                    }

                    val req = XMLHttpRequest()
                    req.open(method.name, url)
                    req.setRequestHeader("enctype", "multipart/form-data")
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)

                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            val resp: T = JSON.parse(req.responseText)
                            d(resp)
                        }
                    }
                    req.send(formData)
                }
            } catch (ex: Exception) {
                c(ex)
            }
        }
    }

    fun <T> sendObjectImage(method: Method, obj: HTMLFormElement, file: Blob, objId: Int, coreId: Int, fileName: String, name: String, descriptions: ArrayList<ObjectDescription>, selectedFlags: ArrayList<Flag>, url: String) = Promise<T> { d, c ->
        async {
            val loadingIndicatorTimeout = window.setTimeout({ Application.loadingIndicator.show() }, 2000)
            try {
                Auth.updateToken { _ ->
                    val formData = FormData(obj)
                    formData.append("file", file, fileName)
                    formData.append("name", name)
                    formData.append("objectId", objId.toString())
                    formData.append("coreId", coreId.toString())
                    descriptions.forEach {
                        formData.append("descriptions", it.id.toString())
                    }
                    selectedFlags.forEach {
                        formData.append(it.code, "true")
                    }

                    val req = XMLHttpRequest()
                    req.open(method.name, url)
                    req.setRequestHeader("Authorization", "Bearer " + Auth.keycloak.token)

                    req.onreadystatechange = {
                        if (req.readyState == 4.toShort()) {
                            when (req.status) {
                                200.toShort() -> {
                                    if (req.responseText.isNotBlank()) {
                                        val resp: T = JSON.parse(req.responseText)
                                        d(resp)
                                    } else {
                                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                                            style = Alert.Style.ERROR
                                            clrAlertItem("Внутренняя ошибка сервера (200. Empty responseText)", Alert.Style.ERROR)
                                        }
                                        c(Exception("200. Empty responseText"))
                                    }
                                }
                                in 400.toShort()..499.toShort() -> {
                                    when (req.status) {
                                        401.toShort() -> {
                                            document.location?.reload()
                                            c(RuntimeException())
                                        }
                                        413.toShort() -> {
                                            if (req.statusText == "Request Entity Too Large") {
                                                Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                    style = Alert.Style.ERROR
                                                    clrAlertItem("Изображение слишком большого размера", Alert.Style.ERROR)
                                                }
                                            }
                                            c(Exception(req.responseText))
                                        }
                                        else -> {
                                            var errorText = "Ошибка сервера. Операция невозможна"
                                            val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                            if (responseTitle.isNotBlank()) errorText = responseTitle
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.ERROR
                                                clrAlertItem(errorText, Alert.Style.ERROR)
                                            }
                                            c(Exception(errorText))
                                        }
                                    }
                                }
                                in 501.toShort()..599.toShort() -> {
                                    var errorText = "Ошибка выполнения. Попробуйте повторить операцию через некоторое время"
                                    val responseTitle = JSON.parse<Error>(req.responseText).title ?: ""
                                    if (responseTitle.isNotBlank()) errorText = responseTitle
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem(errorText, Alert.Style.ERROR)
                                    }
                                    c(Exception(errorText))
                                }
                                else -> {
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        style = Alert.Style.ERROR
                                        clrAlertItem("Внутренняя ошибка сервера", Alert.Style.ERROR)
                                    }
                                    c(Exception(req.responseText))
                                }
                            }
                        }
                        window.clearTimeout(loadingIndicatorTimeout)
                        Application.loadingIndicator.hide()
                    }
                    req.send(formData)
                }
            } catch (ex: Exception) {
                window.clearTimeout(loadingIndicatorTimeout)
                Application.loadingIndicator.hide()
                c(ex)
            }
        }
    }
}
