package ru.playa.sce.views.tourPackets

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.button
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.TourPackets
import ru.playa.sce.components.PageHeader
import ru.playa.sce.components.clrChoice
import ru.playa.sce.components.clrChoiceDialog
import ru.playa.sce.components.clrLink
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.ObjectStatus
import ru.playa.sce.dto.TourPacket
import ru.playa.sce.views.BaseVOView
import kotlin.dom.appendText
import kotlin.js.Promise

class TourPacketView(id: Int, coreId: Int, initialTab: String = "main") : BaseVOView<TourPacket>(id, coreId, initialTab) {

    companion object {
        const val PATH = "tourPackets/tourPacket"
    }

    override var objectType: String = "tourpacket"

    override fun getVO(): Promise<TourPacket> {
        return TourPackets.getById(id)
    }

    override fun getDraft(): Promise<TourPacket> {
        return TourPackets.getDraft(vo.coreId)
    }

    override fun approve(): Promise<Unit> {
        return TourPackets.approve(vo.id)
    }

    override fun publish(): Promise<Unit> {
        return TourPackets.publish(vo.id)
    }

    override fun delete(id: Int): Promise<Unit> {
        return TourPackets.delete(vo.id)
    }

    override fun restoreArchived(): Promise<TourPacket> {
        return TourPackets.restoreArchived(vo.coreId, vo.id)
    }

    override fun navigateTo(id: Int, coreId: Int) {
        Navigation.TourPackets.tourPacket(id, coreId)
    }

    override fun navigateToParent() {
        Navigation.TourPackets.tourPackets()
    }

    override fun toDraftTab(tab: String) {
        Navigation.TourPackets.tourPacketTab(draft.id, draft.coreId, tab)
    }

    override fun PageHeader.buildPageHeaderPathPart() {
        clrLink("Объекты") {
            Navigation.start()
        }
        clrLink("Турпакеты") {
            Navigation.TourPackets.tourPackets()
        }
    }

    private lateinit var mainTab: Tab
    private lateinit var routeTab: Tab

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                createPageHeader(objectType)
                clrTabs {
                    mainTab = clrTab("Основная информация", initialTab == "main", rebuildOnActive = true) {
                        div {
                            style.apply { display = "flex"; flexDirection = "row" }
                            div {
                                style.width = "600px"
                                clrForm {
                                    isCompact = true
                                    clrBlock {
                                        clrGroup("Название турпакета") {
                                            if (vo.name.isNotBlank()) span { appendText(vo.name) } else span { appendText("—") }
                                        }
                                        clrGroup("Основная страна") {
                                            span { appendText(vo.country?.name ?: "—") }
                                        }
                                        clrGroup("Тип турпакета") {
                                            span { appendText(vo.tourPacketType?.name ?: "—") }
                                        }
                                        clrGroup("Теги") {
                                            if (vo.tags.isNotEmpty()) {
                                                val tourTags = vo.tags.apply { sortBy { it.name.toLowerCase() } }
                                                tourTags.forEach {
                                                    clrLabel(it.name) {
                                                        style = Label.Style.Blue
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Области видимости") {
                                            if (vo.visibility.isNotEmpty()) {
                                                for (area in vo.visibility) {
                                                    span {
                                                        appendText(area.name)
                                                        vo.visibility.run { if (indexOf(area) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Варианты написания") {
                                            val synonyms = vo.synonyms ?: ""
                                            if (synonyms.isNotBlank()) span { appendText(synonyms) } else span { appendText("—") }
                                        }
                                        clrGroup("Коды во внешних справочниках") {
                                            if (vo.externalMappings.isNotEmpty()) {
                                                for (mapping in vo.externalMappings) {
                                                    span {
                                                        appendText("${mapping.external.name} (${mapping.code})")
                                                        vo.externalMappings.run { if (indexOf(mapping) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                    }
                                }
                                clrButton("Редактировать") {
                                    style = ButtonStyle.Secondary
                                    iconShape = IconShape.Pencil
                                    isDisabled = !privileges.contains("EDIT")
                                    onClickFunction = {
                                        if (objectStatus == ObjectStatus.ARCHIVED) {
                                            clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                    async {
                                                        TourPackets.restoreArchived(vo.coreId, vo.id).await()
                                                        Navigation.TourPackets.edit(vo.coreId)
                                                    }
                                                }
                                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                            }
                                        } else {
                                            if (objectStatus == ObjectStatus.PUBLISHED) {
                                                Application.applicationLayout.topLevelAlert.show {
                                                    val alertScope = this
                                                    clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                                        button("btn alert-action") {
                                                            innerText = "OK"
                                                            onclick = { _ ->
                                                                alertScope.items.clear()
                                                                alertScope.hide()
                                                            }
                                                        }
                                                    }
                                                    style = Alert.Style.WARNING
                                                }
                                            }
                                            Navigation.TourPackets.edit(vo.coreId)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    createPhotoTab()
                    createIllustrationTab()
                    createFilesTab()
                    createDescriptionsTab()
                    createNewsTab()
                    createBannersTab()
                }
            }
        }
    }
}
