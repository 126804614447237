package ru.playa.sce.views.countries

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Countries
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.dto.Country
import ru.playa.sce.dto.Option
import kotlin.dom.clear

class CountryAddDialog(parent: HTMLElement) : Component(parent) {

    private var headerTitle = "Новая страна"
    var onSaveFunction: (Country) -> Unit = {}

    private var countryCode = ""
    var countryName = ""

    private var creationSuccess = false
    private lateinit var saveButton: Button

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private val validationErrors = mutableListOf<String>()
    private lateinit var validationAlertContainer: HTMLDivElement
    private var validationAlert: Alert? = null


    private fun validateName(value: String) = Regex("[A-Za-zА-Яа-я\\- .]{2,200}").matches(value)

    private fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(countryName)) validationErrors.add("Указано некорректное наименование страны")
    }

    private fun showValidationAlert() {
        if (validationAlert == null) {
            validationAlert = validationAlertContainer.clrAlert {
                style = Alert.Style.ERROR
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
            }
        } else {
            validationAlert?.run {
                items.clear()
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
                render()
            }
        }
    }

    private fun onSaveButtonClick() = async {
        validateEditorData()
        if (validationErrors.isEmpty()) {
            validationAlertContainer.clear()
            validationAlert = null
            saveEditorData().await()
        } else showValidationAlert()
    }

    private fun saveEditorData() = async {
        val visibilityAreas = VisibilityAreas.get().await().data
                .filter { it.enableByDefault }
                .map { Option(it.id, it.name, true) }
                .toTypedArray()

        val country = Country(
                id = 0,
                code = countryCode,
                name = countryName,
                coreId = 0,
                visibility = visibilityAreas
        )
        Countries.create(country).then {
            creationSuccess = true
            onSaveFunction(it)
        }.catch {
            validationErrors.add(it.message ?: "Внутренняя ошибка сервера")
            showValidationAlert()
        }
    }

    override fun build() = async {
        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerTitle) {
                        parent.remove()
                    }
                    clrModalDialogBody {
                        style.apply { overflowX = "visible"; overflowY = "visible" }
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Код", true) {
                                    clrInput {
                                        value = countryCode
                                        tooltipContent = "Введён недопустимый символ"
                                        validationPredicate = { true }
                                        onChangeFunction = {
                                            value = checkCodeInput(value.toLowerCase()).first
                                            countryCode = value
                                            render()
                                        }
                                        onInputFunction = {
                                            val (result, codeIsValid) = checkCodeInput(value.toLowerCase())
                                            value = result
                                            countryCode = value
                                            if (codeIsValid) {
                                                validationPredicate = { true }
                                                isInvalid = false
                                            } else {
                                                validationPredicate = { false }
                                                isInvalid = true
                                            }
                                            onPostRender = {
                                                getHTMLElement().focus()
                                            }
                                            render()
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        value = countryName
                                        tooltipContent = "От 2 до 200 букв латинского и русского алфавитов. Может содержать знаки пробела, точку и тире"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            value = value.capitalize()
                                            countryName = value
                                            this@clrInput.render()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                        }
                        validationAlertContainer = div { style.marginBottom = "5px" }
                    }
                    clrModalDialogFooter {
                        saveButton = clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            isDisabled = true
                            onClickFunction = {
                                async {
                                    onSaveButtonClick().await()
                                    if (creationSuccess) parent.remove()
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                parent.remove()
                            }
                        }
                    }
                }
            }
        }
    }

    private fun checkCodeInput(value: String): Pair<String, Boolean> {
        var result = ""
        var isValid = true
        value.forEach {
            when {
                (it in 'а'..'я') -> result += charToLat(it)
                (it in 'a'..'z') || (it in '1'..'9') -> result += it.toString()
                (it == ' ' || it == '-') -> result += "-"
                else -> isValid = false
            }
        }
        return Pair(result, isValid)
    }

    private fun charToLat(ch: Char) = when (ch) {
        'а' -> "a"
        'б' -> "b"
        'в' -> "v"
        'г' -> "g"
        'д' -> "d"
        'е' -> "e"
        'ё' -> "yo"
        'ж' -> "zh"
        'з' -> "z"
        'и' -> "i"
        'й' -> "y"
        'к' -> "k"
        'л' -> "l"
        'м' -> "m"
        'н' -> "n"
        'о' -> "o"
        'п' -> "p"
        'р' -> "r"
        'с' -> "s"
        'т' -> "t"
        'у' -> "u"
        'ф' -> "f"
        'х' -> "kh"
        'ц' -> "ts"
        'ч' -> "ch"
        'ш' -> "sh"
        'щ' -> "sch"
        'ъ' -> ""
        'ы' -> "ih"
        'ь' -> ""
        'э' -> "e"
        'ю' -> "ui"
        'я' -> "ia"
        else -> ""
    }
}

fun HTMLElement.countryAddDialog(block: CountryAddDialog.() -> Unit = {}) =
        CountryAddDialog(this).apply(block).apply { render() }