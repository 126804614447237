package ru.playa.sce.views.textBlocks

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.clarity.js.html.button
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Assets
import ru.playa.sce.api.TextBlocks
import ru.playa.sce.components.PageHeader
import ru.playa.sce.components.clrChoice
import ru.playa.sce.components.clrChoiceDialog
import ru.playa.sce.components.clrLink
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.views.BaseVOView
import kotlin.browser.window
import kotlin.dom.appendText
import kotlin.js.Promise

class TextBlockView(id: Int, coreId: Int, initialTab: String = "main") : BaseVOView<Text>(id, coreId, initialTab, false) {

    companion object {
        const val PATH = "textBlocks/textBlock"
    }

    override var objectType: String = "banner"

    override fun getVO(): Promise<Text> {
        return TextBlocks.getById(id)
    }

    override fun getDraft(): Promise<Text> {
        return TextBlocks.getDraft(vo.coreId)
    }

    override fun approve(): Promise<Unit> {
        return TextBlocks.approve(vo.id)
    }

    override fun publish(): Promise<Unit> {
        return TextBlocks.publish(vo.id)
    }

    override fun delete(id: Int): Promise<Unit> {
        return TextBlocks.delete(vo.id)
    }

    override fun restoreArchived(): Promise<Text> {
        return TextBlocks.restoreArchived(vo.id, vo.coreId)
    }

    override fun navigateTo(id: Int, coreId: Int) {
        Navigation.TextBlocks.textBlock(id, coreId)
    }

    override fun navigateToParent() {
        Navigation.TextBlocks.textBlocks()
    }

    override fun toDraftTab(tab: String) {
        Navigation.TextBlocks.textBlockTab(draft.id, draft.coreId, tab)
    }

    override fun PageHeader.buildPageHeaderPathPart() {
        clrLink("Объекты") {
            Navigation.start()
        }
        clrLink("Текстовые блоки") {
            Navigation.TextBlocks.textBlocks()
        }
    }

    // Переопределяется т.к вместо 'banner' нужно отсылать 'text'
    override fun getFlags(): Promise<ListResult<Flag>> {
        return Assets.getFlags("text")
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                createPageHeader(objectType)
                clrTabs {
                    clrTab("Основная информация", initialTab == "main") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Заголовок") {
                                    if (vo.name.isNotBlank()) span { appendText(vo.name) } else span { appendText("—") }
                                }
                                clrGroup("Тип") {
                                    span { appendText(TextType.valueOf(vo.textType).displayName) }
                                }
                                clrGroup("Период отображения") {
                                    var periodText = ""
                                    vo.start?.let {
                                        periodText += "с ${it.slice(8..9)}.${it.slice(5..6)}.${it.slice(0..3)} "
                                    }
                                    vo.end?.let {
                                        periodText += "по ${it.slice(8..9)}.${it.slice(5..6)}.${it.slice(0..3)}"
                                    }
                                    if (periodText.isNotBlank()) span { appendText(periodText) } else span { appendText("—") }
                                }
                                clrGroup("Тип ссылки") {
                                    val linkType = LinkType.valueOf(vo.linkType)
                                    span { appendText(linkType.displayName) }
                                    when (linkType) {
                                        LinkType.URL -> a {
                                            appendText("(${vo.linkURL})")
                                            href = "javascript://"
                                            onclick = {
                                                window.open(vo.linkURL, "_blank")
                                            }
                                        }
                                        LinkType.OBJECT -> a {
                                            vo.target?.let { linkObject ->
                                                appendText("(${linkObject.publishedName ?: linkObject.draftName
                                                ?: "Удаленный"})")
                                                href = "javascript://"
                                                onclick = {
                                                    Navigation.navigateToObject(
                                                            linkObject.objectType.toUpperCase(),
                                                            linkObject.publishedVersionId
                                                                    ?: linkObject.draftVersionId ?: 0,
                                                            linkObject.id
                                                    )
                                                }
                                            }
                                        }
                                        else -> {
                                        }
                                    }
                                }
                                clrGroup("Блоки контента") {
                                    var blocksText = ""
                                    val blocksLastIndex = vo.blocks.lastIndex
                                    vo.blocks.forEachIndexed { index, block ->
                                        blocksText += block.name
                                        if (index != blocksLastIndex) blocksText += ", "
                                    }
                                    if (blocksLastIndex == -1) blocksText += "—"
                                    appendText(blocksText)
                                }
                                clrGroup("Объекты") {
                                    span {
                                        val linkedObjectsLastIndex = vo.objects.lastIndex
                                        vo.objects.forEachIndexed { index, linkedObject ->
                                            a {
                                                appendText(linkedObject.draftName ?: linkedObject.publishedName
                                                ?: "Безымянный")
                                                href = "javascript://"
                                                onclick = {
                                                    Navigation.navigateToObject(
                                                            linkedObject.objectType.toUpperCase(),
                                                            linkedObject.publishedVersionId
                                                                    ?: linkedObject.draftVersionId ?: 0,
                                                            linkedObject.id
                                                    )
                                                }
                                            }
                                            if (index != linkedObjectsLastIndex) appendText(", ")
                                        }
                                        if (linkedObjectsLastIndex == -1) appendText("—")
                                    }
                                }

                                if (TextType.valueOf(vo.textType) != TextType.GRAPHICAL_BANNER) {
                                    clrGroup("Анонс") {
                                        span {
                                            appendText(if (vo.anons.isNullOrBlank()) "—" else vo.anons ?: "—")
                                        }
                                    }
                                }
                                clrGroup("Описание") {
                                    span {
                                        val tbDescription = vo.description ?: ""
                                        if (tbDescription.isBlank())
                                            innerText = "—"
                                        val htmlDesc = if (tbDescription.isNotBlank()) tbDescription else "—"
                                        div {
                                            innerHTML = htmlDesc
                                        }
                                    }
                                }

                            }
                            clrBlock {
                                clrButton("Редактировать") {
                                    style = ButtonStyle.Secondary
                                    iconShape = IconShape.Pencil
                                    isDisabled = !privileges.contains("EDIT")
                                    onClickFunction = {
                                        if (objectStatus == ObjectStatus.ARCHIVED) {
                                            clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                    async {
                                                        TextBlocks.restoreArchived(vo.coreId, vo.id).await()
                                                        Navigation.TextBlocks.edit(vo.id, vo.coreId)
                                                    }
                                                }
                                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                            }
                                        } else {
                                            if (objectStatus == ObjectStatus.PUBLISHED) {
                                                Application.applicationLayout.topLevelAlert.show {
                                                    val alertScope = this
                                                    clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                                        button("btn alert-action") {
                                                            innerText = "OK"
                                                            onclick = { _ ->
                                                                alertScope.items.clear()
                                                                alertScope.hide()
                                                            }
                                                        }
                                                    }
                                                    style = Alert.Style.WARNING
                                                }
                                            }
                                            Navigation.TextBlocks.edit(vo.id, vo.coreId)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    createPhotoTab()
                }
            }
        }
    }
}
