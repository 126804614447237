package ru.playa.sce.views.common

import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.li
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.html.ul
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.sce.core.Application
import ru.playa.sce.dto.Asset
import ru.playa.sce.dto.ObjectDescription
import kotlin.browser.window
import kotlin.dom.clear
import kotlin.js.Promise


fun HTMLDivElement.buildImageAssetCard(
        descriptions: Array<ObjectDescription>, asset: Asset, canEdit: Boolean, thumbnailWidth: Int,
        onEdit: (Int, String, Array<Int>, Boolean) -> Promise<Any>
) {
    div {
        style.width = "${thumbnailWidth + 2}px"

        clrCard {
            clrCardImg {
                src = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.variants.firstOrNull { it.code == "thumbnail" }
                        ?.filename ?: asset.filename}"
            }
            clrCardBlock {
                clrCardText(asset.name)
            }
            clrCardFooter {
                var photoEditDiv: HTMLDivElement? = null
                div {
                    val photoButtonsDiv = this
                    clrButton("Редактировать") {
                        style = ButtonStyle.Flat
                        iconShape = IconShape.Pencil
                        isDisabled = !canEdit
                        isSmall = true
                        onClickFunction = {
                            photoButtonsDiv.hidden = true
                            photoEditDiv?.buildPhotoEdit(asset, descriptions, photoButtonsDiv, onEdit)
                        }
                    }
                }
                photoEditDiv = div {
                    style.apply { paddingTop = "10px"; display = "flex"; flexDirection = "column" }
                }
            }
        }
    }

}

fun HTMLElement.buildPhotoEdit(asset: Asset, descriptions: Array<ObjectDescription>, buttonsDiv: HTMLDivElement, onEdit: (Int, String, Array<Int>, Boolean) -> Promise<Any>) {
    val containerDiv = this
    val selectedDescs = arrayListOf<Int>()
    var isDescChanged = false

    div {
        val photoNameInput = clrInput {
            type = InputType.Text
            value = asset.name
            placeholder = "Название фотографии"
        }
        if (descriptions.isNotEmpty()) {
            clrTable {
                clrBody {
                    descriptions.forEach { desc ->
                        clrRow {
                            clrCell {
                                span {
                                    innerText = desc.name
                                }
                            }
                            clrCell {
                                clrCheckbox {
                                    if (asset.descriptions.any { it.id == desc.id }) {
                                        isChecked = true
                                        selectedDescs.add(desc.id)
                                    }
                                    onChangeFunction = {
                                        isDescChanged = true
                                        if (isChecked)
                                            selectedDescs.add(desc.id)
                                        else selectedDescs.remove(desc.id)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            span {
                innerText = "Нет привязаных описаний"
            }
        }
        div {
            style.apply { paddingTop = "8px"; display = "flex"; justifyContent = "space-between" }
            clrButton("Сохранить") {
                iconShape = IconShape.Check
                style = ButtonStyle.Flat
                isSmall = true
                onClickFunction = {
                    onEdit(asset.id, photoNameInput.value, selectedDescs.toTypedArray(), isDescChanged)
                }
            }
            clrButton("Отмена") {
                iconShape = IconShape.Times
                style = ButtonStyle.Flat
                isSmall = true
                onClickFunction = {
                    containerDiv.clear()
                    buttonsDiv.hidden = false
                }
            }
        }
    }
}

fun HTMLElement.buildAssetsGridLayout(
        descriptions: Array<ObjectDescription>, assetList: List<Asset>, canEdit: Boolean, thumbnailWidth: Int, onPreEdit: () -> Unit,
        onReorder: (Array<Int>) -> Promise<Any>, onEdit: (Int, String, Array<Int>, Boolean) -> Promise<Any>, onDelete: (Int) -> Promise<Any>
) {
    div {
        style.apply { display = "flex"; flexWrap = "wrap"; justifyContent = "flex-start" }

        assetList.forEachIndexed { index, asset ->

            div {
                style.apply { width = "${thumbnailWidth + 2}px"; marginRight = "10px" }

                clrCard {
                    isDraggable = true
                    onDragOverFunction = {
                        it.preventDefault()
                        it.stopPropagation()
                    }
                    onDragStartFunction = {
                        (it as DragEvent).dataTransfer?.setData("text", index.toString())
                    }
                    onDropFunction = { event ->
                        val dragIndex = (event as DragEvent).dataTransfer?.getData("text")?.toIntOrNull()
                        if ((dragIndex != null) && (dragIndex != -1)) {
                            val list = assetList.asSequence().map { it.id }.toMutableList()
                            val temp = list[dragIndex]
                            list.removeAt(dragIndex)
                            list.add(if (dragIndex > index) index else index - 1, temp)
                            onReorder(list.toTypedArray())
                        }
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    clrCardImg {
                        src = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.variants.firstOrNull { it.code == "thumbnail" }?.filename
                                ?: asset.filename}"
                        onclick = { _ ->
                            window.open("/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}", "_blank")
                        }
                    }
                    clrCardBlock {
                        clrCardText(asset.name)
                    }
                    clrCardFooter {
                        @Suppress("JoinDeclarationAndAssignment")
                        lateinit var photoEditDiv: HTMLDivElement
                        div {
                            val photoButtonsDiv = this
                            style.apply { display = "flex"; justifyContent = "space-between" }
                            ul {
                                span {
                                    innerText = "Привязан к описаниям:"
                                }
                                li {
                                    style.marginBottom = "24px"

                                    if (asset.descriptions.isNotEmpty()) {
                                        ul {
                                            style.listStyle = "none"
                                            asset.descriptions.sortedBy { it.name }.forEach {
                                                li {
                                                    innerText = it.name
                                                }
                                            }
                                        }
                                    } else {
                                        span { innerText = "нет" }
                                    }
                                }
                                style.listStyle = "none"
                                li {
                                    clrButton("Редактировать") {
                                        style = ButtonStyle.Flat
                                        iconShape = IconShape.Pencil
                                        isDisabled = !canEdit
                                        isSmall = true
                                        onClickFunction = {
                                            onPreEdit()
                                            photoButtonsDiv.hidden = true
                                            photoEditDiv.buildPhotoEdit(asset, descriptions, photoButtonsDiv, onEdit)
                                        }
                                    }
                                    clrButton("Удалить") {
                                        style = ButtonStyle.Flat
                                        iconShape = IconShape.Trash
                                        isSmall = true
                                        isDisabled = !canEdit
                                        onClickFunction = {
                                            onDelete(asset.id)
                                        }
                                    }
                                }
                            }
                        }
                        photoEditDiv = div {
                            style.apply { paddingTop = "10px"; display = "flex"; flexDirection = "column" }
                        }
                    }
                }
            }

        }
    }
}