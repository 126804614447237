package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.DeleteStatus
import ru.playa.sce.dto.Dictionary
import ru.playa.sce.dto.DictionaryEntry


object Dictionaries {

    fun getDictionaries(start: Int = 0, pageSize: Int = 50, orderField: String = "name", reverseOrder: Boolean = false) =
            Network.request<ListResult<Dictionary>>(Network.Method.GET,
                    "/sce-web/api/user/dictionary/list?start=$start&pageSize=$pageSize&orderField=$orderField&reverseOrder=$reverseOrder")

    fun getDictionaryEntries(
            dictionaryCode: String,
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            visibility: List<String> = emptyList(),
            external: String = "",
            deleteStatus: DeleteStatus = DeleteStatus.ACTIVE
    ) = Network.request<ListResult<DictionaryEntry>>(Network.Method.GET,
            "/sce-web/api/user/dictionary/entry/search/$dictionaryCode?pageSize=$pageSize&start=$start&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict${visibility.joinToString("") { "&visibility=$it" }}&external=$external&deleteStatus=${deleteStatus.name}")

    fun getDictionary(dictionaryCode: String) = Network.request<ListResult<Dictionary>>(Network.Method.GET, "/sce-web/api/user/dictionary/search?code=$dictionaryCode")

    fun getDictionaryEntry(dictionaryCode: String, entryId: Int) = Network.request<DictionaryEntry>(Network.Method.GET, "/sce-web/api/user/dictionary/entry/$dictionaryCode/$entryId")

    fun createDictionaryEntry(dictionaryCode: String, entry: DictionaryEntry) = Network.send(Network.Method.POST, entry, "/sce-web/api/user/dictionary/entry/$dictionaryCode/")

    fun updateDictionaryEntry(dictionaryCode: String, entry: DictionaryEntry) = Network.send(Network.Method.PUT, entry, "/sce-web/api/user/dictionary/entry/$dictionaryCode/")

    fun deleteDictionaryEntry(dictionaryCode: String, entryId: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/dictionary/entry/$dictionaryCode/$entryId")
}
