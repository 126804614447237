package ru.playa.sce.views

import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import kotlin.js.Promise


abstract class EditorView : View() {
    protected val validationErrors = mutableListOf<String>()
    protected var validationAlert: Alert? = null
    protected lateinit var validationAlertContainer: HTMLDivElement
    protected lateinit var saveButton: Button

    abstract fun validateEditorData()
    abstract fun saveEditorData(): Promise<Unit>

    private fun onSaveButtonClick(): Promise<Unit> {
        validateEditorData()
        return if (validationErrors.isEmpty()) saveEditorData() else async { showValidationAlert() }
    }

    private fun showValidationAlert() {
        if (validationAlert == null) {
            validationAlert = validationAlertContainer.clrAlert {
                style = Alert.Style.ERROR
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
            }
        } else {
            validationAlert?.run {
                items.clear()
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
                render()
            }
        }
    }

    fun HTMLElement.createSaveButton() {
        saveButton = clrButton("Сохранить") {
            isDisabled = true
            iconShape = IconShape.Check
            onClickFunction = {
                isDisabled = true
                this.render()
                onSaveButtonClick().then {
                    isDisabled = false
                    this.render()
                }
            }
        }
    }
}