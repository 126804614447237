package ru.playa.sce.dto

class TourPacket(
        id: Int = 0,
        code: String = "",
        name: String = "",
        objectType: String = "TourPacket",
        coreId: Int = 0,
        status: String = ObjectStatus.DRAFT.name,
        publishedVersionId: Int? = null,
        draftVersionId: Int? = null,
        externalMappings: Array<ExternalMapping> = emptyArray(),
        visibility: Array<Option> = emptyArray(),
        tags: Array<Option> = emptyArray(),
        synonyms: String? = "",
        val country: Option? = null,
        val tourPacketType: Option? = null
) : WebObject(id, code, name, objectType, coreId, status, publishedVersionId, draftVersionId, externalMappings, visibility, tags, synonyms)