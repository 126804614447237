package ru.playa.sce.dto

class TourPoint(
        id: Int = 0,
        parentCoreId: Int,
        parentVersionId: Int,
        descriptionType: ObjectDescriptionType? = null,
        name: String = "",
        briefDescription: String = "",
        fullDescription: String = "",
        language: DictionaryEntry? = null,
        val startDay: Int,
        val endDay: Int,
        var order: Int,
        val start: WayPoint,
        val finish: WayPoint? = null,
        val sights: Array<Option> = emptyArray()
) : ObjectDescription(id, parentCoreId, parentVersionId, descriptionType, name, briefDescription, fullDescription, language, emptyArray())