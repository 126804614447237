package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import org.w3c.files.Blob
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.js.Promise

@Suppress("ClassName")
external class imgEditor(ratio: String) {
    fun appendTo(element: HTMLElement)
    fun setData(data: String)
    fun prepareBlob(): Promise<Blob>
    fun getData(): Blob
    fun getFileName(): String
    fun getErrorStatus(): String
    fun addListener(eventName: String, listenerFunction: () -> Unit)
    fun clearOnHide()
}

class ImgEditorController(var ratio: String, parent: HTMLElement) : Component(parent) {
    lateinit var editor: imgEditor

    override fun build() = async {
        val div = newDiv()
        editor = imgEditor(ratio).apply { appendTo(div) }
        return@async div
    }
}

fun HTMLElement.imgEditor(ratio: String) = ImgEditorController(ratio, this).apply { render() }.editor