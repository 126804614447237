package ru.playa.sce.components

import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.sce.core.Application


@Suppress("SpellCheckingInspection")
object CKEditorExtension {

    fun init() {
        try {
            register(Application.root)
        } catch (ex: Exception) {

        }
    }

    private var objectId: Int = -1
    private var coreId: Int = -1

    fun init(objectId: Int, coreId: Int) {

        this.objectId = objectId
        this.coreId = coreId
        CKEDITOR.config.extraAllowedContent = "img[style,class,vspace,hspace,align,!src,alt,width,height](*){*};a[!href,target](*){*}"
    }

    @Suppress("UNUSED_PARAMETER")
    fun styleCommand(style: dynamic): dynamic {
        return js("new CKEDITOR.styleCommand(style)")
    }

    private fun createImageSelectDialog(root: HTMLElement) {
        val dialog: HTMLDivElement = root.div { }
        CKEDITOR.config.removePlugins = "image"
        CKEDITOR.plugins.add("sceimage", CKEditorPlugin { editor ->

            editor.addCommand("sceimage", CKEditorCommand { _ ->
                CKEditorImagePluginDialog(dialog, objectId, coreId, editor).render()
            })
            editor.ui.addButton("sceimage", CKEditorButton("Вставить картинку", "sceimage", "insert", "Image"))


        })
    }

    private fun createFileSelectDialog(root: HTMLElement) {
        val dialog: HTMLDivElement = root.div { }
        CKEDITOR.plugins.add("scefile", CKEditorPlugin { editor ->
            editor.addCommand("scefile", CKEditorCommand { _ ->
                //if ((coreId != -1 && (objectId != -1))) {
                CKEditorFilePluginDialog(dialog, objectId, coreId, editor).render()
                //} else {
                //console.warn("Cant get files for unsaved object. Save object before")
                //}
            })
            editor.ui.addButton("scefile", CKEditorButton("Вставить файл", "scefile", "insert", "addfile"))
        })
    }

    private fun createVerticalAlignTool() {
        CKEDITOR.plugins.add("scevalign", CKEditorPlugin { editor ->
            var st = js("var s = {display:'table-cell'};s['vertical-align']='top';new CKEDITOR.style({element: 'p', styles: s})")
            editor.addCommand("scevalign_top", styleCommand(st))
            /*editor.addCommand("scevalign_top", CKEditorCommand { _ ->
                val element = editor.document.findOne("#bcgimage")
                element?.getChildren()?.toArray()?.forEach {
                    it.setStyle("vertical-align", "top")
                    it.setStyle("display", "table-cell")
                }
            })*/
            editor.attachStyleStateChange(st, js("function( state ) {!editor.readOnly && editor.getCommand( 'scevalign_top' ).setState( state );}"))
            editor.ui.addButton("scevalign_top", CKEditorButton("По верху", "scevalign_top", "align", "valigntop"))

            st = js("var s = {display:'table-cell'};s['vertical-align']='middle';new CKEDITOR.style({element: 'p', styles: s})")

            /*editor.addCommand("scevalign_center", CKEditorCommand { _ ->
                val element = editor.document.findOne("#bcgimage")
                element?.getChildren()?.toArray()?.forEach {
                    it.setStyle("vertical-align", "middle")
                    it.setStyle("display", "table-cell")
                }
            })*/
            editor.addCommand("scevalign_center", styleCommand(st))
            editor.attachStyleStateChange(st, js("function( state ) {!editor.readOnly && editor.getCommand( 'scevalign_center' ).setState( state );}"))
            editor.ui.addButton("scevalign_center", CKEditorButton("По середине", "scevalign_center", "align", "valigncenter"))

            /*editor.addCommand("scevalign_bottom", CKEditorCommand { _ ->
                val element = editor.document.findOne("#bcgimage")
                element?.getChildren()?.toArray()?.forEach {
                    it.setStyle("vertical-align", "bottom")
                    it.setStyle("display", "table-cell")
                }

            })*/
            st = js("var s = {display:'table-cell'};s['vertical-align']='bottom';new CKEDITOR.style({element: 'p', styles: s})")
            editor.addCommand("scevalign_bottom", styleCommand(st))
            editor.attachStyleStateChange(st, js("function( state ) {!editor.readOnly && editor.getCommand( 'scevalign_bottom' ).setState( state );}"))
            editor.ui.addButton("scevalign_bottom", CKEditorButton("По низу", "scevalign_bottom", "align", "valignbottom"))

            st = js("var s = {display:'table-cell'};s['vertical-align']='baseline';new CKEDITOR.style({element: 'p', styles: s})")
            editor.addCommand("scevalign_default", styleCommand(st))
            editor.attachStyleStateChange(st, js("function( state ) {!editor.readOnly && editor.getCommand( 'scevalign_default' ).setState( state );}"))
            editor.ui.addButton("scevalign_default", CKEditorButton("По умолчанию", "scevalign_default", "align", "valigndefault"))

        })
    }


    private fun register(root: HTMLElement) {
        CKEditorExtension.createVerticalAlignTool()
        CKEditorExtension.createImageSelectDialog(root)
        CKEditorExtension.createFileSelectDialog(root)
        CKEDITOR.config.extraPlugins = "scefile,sceimage,scevalign"
    }
}