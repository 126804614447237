@file:Suppress("unused")

package ru.playa.sce.dto


class AssetVariant(
        var id: Int = 0,

        var code: String = "",

        var size: Long = 0,

        var height: Int = 0,

        var width: Int = 0,

        var contentType: String = "",

        var filename: String = ""
)