@file:Suppress("INTERFACE_WITH_SUPERCLASS", "OVERRIDING_FINAL_MEMBER", "RETURN_TYPE_MISMATCH_ON_OVERRIDE", "CONFLICTING_OVERLOADS", "EXTERNAL_DELEGATION", "NESTED_CLASS_IN_EXTERNAL_INTERFACE", "unused", "ClassName", "DEPRECATION", "UNUSED_PARAMETER", "SpellCheckingInspection")

package redux

external interface Action<T> {
    @JsName("type")
    var type: T
    var value: Any
}


external interface AnyAction : Action<Any> {
    @nativeGetter
    operator fun get(extraProps: String): Any?

    @nativeSetter
    operator fun set(extraProps: String, value: Any)
}

external interface Dispatch<A : Action<Any>> {
    @nativeInvoke
    operator fun <T : A> invoke(action: T): T
}

external interface Unsubscribe {
    @nativeInvoke
    operator fun invoke()
}

external interface Store<S, A : Action<Any>> {
    //var dispatch: Dispatch<A>
    fun dispatch(msg: dynamic)

    fun getState(): S
    fun subscribe(listener: () -> Unit): Unsubscribe
    fun replaceReducer(nextReducer: (state: S, action: A) -> S)
}

external interface StoreCreator {
    @nativeInvoke
    operator fun <S, A : Action<Any>, Ext, StateExt> invoke(reducer: (state: S, action: A) -> S, enhancer: ((next: (reducer: (state: S, action: A) -> S, preloadedState: Any? /*= null*/) -> Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */) -> (reducer: (state: S, action: A) -> S, preloadedState: Any? /*= null*/) -> Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */)? = definedExternally /* null */): Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */

    @nativeInvoke
    operator fun <S, A : Action<Any>, Ext, StateExt> invoke(reducer: (state: S, action: A) -> S, preloadedState: Any? = definedExternally /* null */, enhancer: ((next: (reducer: (state: S, action: A) -> S, preloadedState: Any? /*= null*/) -> Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */) -> (reducer: (state: S, action: A) -> S, preloadedState: Any? /*= null*/) -> Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */)? = definedExternally /* null */): Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */

    @nativeInvoke
    operator fun <S, A : Action<Any>, Ext, StateExt> invoke(reducer: (state: S, action: A) -> S): Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */
}

external interface MiddlewareAPI<D : Dispatch<AnyAction>, S> {
    var dispatch: D
    fun getState(): S
}

external interface Middleware<DispatchExt, S, D : Dispatch<AnyAction>> {
    @nativeInvoke
    operator fun invoke(api: MiddlewareAPI<D, S>): (next: Dispatch<AnyAction>) -> (action: Any) -> Any
}

external interface `T$0`<Ext1> {
    var dispatch: Ext1
}

external interface `T$1`<Ext1> {
    var dispatch: Ext1 /* Ext1 & Ext2 */
}

external interface `T$2`<Ext1> {
    var dispatch: Ext1 /* Ext1 & Ext2 & Ext3 */
}

external interface `T$3`<Ext1> {
    var dispatch: Ext1 /* Ext1 & Ext2 & Ext3 & Ext4 */
}

external interface `T$4`<Ext1> {
    var dispatch: Ext1 /* Ext1 & Ext2 & Ext3 & Ext4 & Ext5 */
}

external interface `T$5`<Ext> {
    var dispatch: Ext
}

external interface ActionCreator<A> {
    @nativeInvoke
    operator fun invoke(vararg args: Any): A
}

external interface ActionCreatorsMapObject<A> {
    @nativeGetter
    operator fun get(key: String): ActionCreator<A>?

    @nativeSetter
    operator fun set(key: String, value: ActionCreator<A>)
}

external object Redux {
    fun <S, A : Action<Any>> createStore(reducer: (state: S, action: A) -> S, initial: Any): Store<S /* S & StateExt */, A> /* Store<S /* S & StateExt */, A> & Ext */
}

object ReduxExt {
    fun createMessage(stype: String, value: Any): dynamic {
        return js("{type:stype,value:value}")
    }
}
