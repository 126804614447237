package ru.playa.sce.views.visibilityAreas

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.VisibilityArea
import kotlin.dom.appendText
import kotlin.js.Promise

class VisibilityAreasView : View() {

    companion object {
        const val PATH = "visibility-areas"
    }

    private var contentIsChanged = false
    private lateinit var datagrid: DataGrid<VisibilityArea>

    override fun render() {
        contentIsChanged = false
        async {
            if (Application.userIsOwner) {
                dom.apply {
                    clrPageHeader("Области видимости") {
                        clrLink("Объекты") {
                            if (contentIsChanged) {
                                clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                    modalDialogConfiguration = {
                                        this.size = ModalDialog.Size.Small
                                    }
                                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                        contentIsChanged = true
                                        Navigation.start()
                                    }
                                    clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                }
                            } else Navigation.start()
                        }
                        clrButtonGroup {
                            clrButton("Добавить область видимости") {
                                style = ButtonStyle.Secondary
                                iconShape = IconShape.Plus
                                onClickFunction = {
                                    if (contentIsChanged) {
                                        clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                            modalDialogConfiguration = {
                                                this.size = ModalDialog.Size.Small
                                            }
                                            clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                contentIsChanged = true
                                                Navigation.VisibilityAreas.add()
                                            }
                                            clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                        }
                                    } else Navigation.VisibilityAreas.add()
                                }
                            }
                        }
                    }
                    datagrid = clrDatagrid {
                        expandableRows = true
                        clrColumn("Код", 1) {
                            appendText(it.code)
                        }
                        clrColumn("Наименование", 3) {
                            appendText(it.name)
                        }
                        clrColumn("Включена по умолчанию", 2) {
                            if (it.enableByDefault)
                                appendText("Да")
                            else appendText("Нет")
                        }
                        clrOverallDetails {
                            async {
                                createEditContent(it)
                            }
                        }
                        onExpandableRowCloseFunction = {
                            Promise { d, _ ->
                                if (contentIsChanged) {
                                    dom.clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                        modalDialogConfiguration = {
                                            this.size = ModalDialog.Size.Small
                                        }
                                        clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                            dom.removeChild(this.getHTMLElement())
                                            d(true)
                                        }
                                        clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                                            dom.removeChild(this.getHTMLElement())
                                            d(false)
                                        }
                                    }
                                } else {
                                    d(true)
                                }
                            }
                        }
                        onExpandableRowOpenFunction = {
                            contentIsChanged = false
                        }
                        getDataFunction = {
                            VisibilityAreas.get()
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }

    private fun HTMLDivElement.createEditContent(area: VisibilityArea) {
        var codeInput = ""
        var nameInput = ""
        var enableByDefaultCheck = false
        var saveButton: ButtonGroup? = null

        fun validate() {
            if (nameInput.isBlank() || codeInput.isBlank()) {
                saveButton?.buttons?.forEach {
                    if (it.text == "Сохранить") {
                        it.isDisabled = true
                    }
                }
                saveButton?.render()
            } else if (nameInput.isNotBlank() && codeInput.isNotBlank()) {
                saveButton?.buttons?.forEach {
                    if (it.text == "Сохранить") {
                        it.isDisabled = false
                    }
                }
                saveButton?.render()
                contentIsChanged = true
            }
        }

        async {
            div().apply {
                style.width = "100%"
                clrForm {
                    isCompact = true
                    clrBlock("Основная информация") {
                        clrGroup("Код") {
                            clrInput {
                                size = 30
                                this.value = area.code
                                codeInput = this.value
                                onInputFunction = {
                                    codeInput = value
                                    validate()
                                }
                            }
                        }
                        clrGroup("Наименование") {
                            clrInput {
                                size = 30
                                this.value = area.name
                                nameInput = this.value
                                onInputFunction = {
                                    nameInput = value
                                    validate()
                                }
                            }
                        }
                        clrGroup("Включена по умолчанию") {
                            clrCheckbox {
                                isInline = true
                                enableByDefaultCheck = area.enableByDefault
                                isChecked = enableByDefaultCheck
                                onChangeFunction = {
                                    enableByDefaultCheck = isChecked
                                    validate()
                                }
                            }
                        }
                    }
                }
                div {
                    style.width = "100%"
                    saveButton = clrButtonGroup {
                        this.onPostRender = {
                            this.getHTMLElement().style.setProperty("float", "left")
                        }
                        clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            style = ButtonStyle.Primary
                            isDisabled = true
                            onClickFunction = { _ ->
                                val updatedVisibilityArea = VisibilityArea(
                                        area.id,
                                        codeInput,
                                        nameInput,
                                        enableByDefaultCheck
                                )
                                VisibilityAreas.update(updatedVisibilityArea).then {
                                    contentIsChanged = false
                                    this@VisibilityAreasView.datagrid.render()
                                }.catch {
                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                        clrAlertItem("Не удалось сохранить изменения")
                                    }
                                }
                            }
                        }
                    }
                    clrButtonGroup {
                        this.onPostRender = {
                            this.getHTMLElement().style.setProperty("float", "right")
                        }
                        this.menuPosition = ButtonGroupMenuPosition.BottomRight
                        clrButton("Удалить") {
                            style = ButtonStyle.WarningOutline
                            onClickFunction = {
                                dom.clrChoiceDialog("Вы уверены что хотите удалить элемент справочника?") {
                                    modalDialogConfiguration = {
                                        size = ModalDialog.Size.Small
                                    }
                                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                        async {
                                            VisibilityAreas.delete(area.id).await()
                                            dom.removeChild(this@clrChoiceDialog.getHTMLElement())
                                            datagrid.render()
                                        }
                                    }
                                    clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times) {
                                        dom.removeChild(this.getHTMLElement())
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}