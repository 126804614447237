package ru.playa.sce.views.start

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.Search
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.SearchResult
import ru.playa.sce.components.clrItemRender
import ru.playa.sce.components.clrSearchResult
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.Dictionary
import ru.playa.sce.dto.LocationType
import ru.playa.sce.dto.ObjectStatus
import ru.playa.sce.dto.SolrSearchResult
import kotlin.dom.addClass
import kotlin.dom.appendText
import kotlin.dom.clear


class StartView : View() {
    companion object {
        const val PATH = "start"
    }

    private var searchInput = ""

    private val selectedDictionaries: ArrayList<String> = arrayListOf()
    private val selectedVisibilities: ArrayList<Int> = arrayListOf()
    private val selectedStatuses: ArrayList<String> = arrayListOf()
    private var isCountriesSelected = false
    private var isTownsSelected = false
    private var isRegionsSelected = false
    private var isDistrictSelected = false
    private var isFullSearch = true
    private var isSearchStrict = false

    private val tourismList = LinkedHashMap<String, String>().apply {
        put("hotel", "Отель")
        put("excursion", "Экскурсии")
        put("tour", "Тур")
        put("landmark", "Достопримечательность")
        put("airport", "Аэропорт")
        put("port", "Порт")
//        put("marina", "Яхтенный порт")
    }

    private val selectedTourism = ArrayList<String>()

    private var resultInfo: HTMLDivElement? = null
    private var resultComponent: SearchResult<SolrSearchResult>? = null
    private var resultColumn: GridColumn? = null
    private var filterColumn: GridColumn? = null

    private var optionsTree: TreeView? = null

    private var form: Form? = null
    private var form2: Form? = null
    private var form1: Form? = null

    private var toggle: Toggle? = null

    var dictionaries: Array<Dictionary> = arrayOf()

    private fun uncheckToggle() {
        isFullSearch = false
        toggle?.isChecked = false
        toggle?.render()
    }


    override fun render() {
        async {
            val createPrivilegeObjectTypes = Accounts.getPrivilegeObjectTypes("CREATE").await()

            if (isFullSearch) {
                selectedStatuses.clear()
                selectedStatuses.add(ObjectStatus.APPROVED.name)
                selectedStatuses.add(ObjectStatus.DRAFT.name)
                selectedStatuses.add(ObjectStatus.PUBLISHED.name)
            }
            dictionaries = Dictionaries.getDictionaries(0, Int.MAX_VALUE).await().data
            val visibilities = VisibilityAreas.get().await().data
            dom.apply {
                clrGridRow {
                    clrGridColumn(6, 6, 6, 6, 6) {
                        style.minWidth = "100%"
                        form = clrForm {
                            //isCompact = true
                            clrBlock {
                                clrGroup {
                                    clrInput {
                                        size = 60
                                        value = searchInput
                                        placeholder = "Что ищем?"

                                        onChangeFunction = {
                                            searchInput = value
                                        }

                                        onPressEnterFunction = {
                                            showResult()
                                        }

                                        onInputFunction = {
                                            searchInput = value
                                        }
                                    }
                                    clrButton("НАЙТИ") {
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            showResult()
                                        }
                                    }
                                    style.apply { padding = "0px"; margin = "0px" }
                                }
                                clrGroup("Искать вхождение всех слов") {
                                    clrCheckbox {
                                        isInline = true
                                        isChecked = isSearchStrict
                                        onChangeFunction = {
                                            isSearchStrict = isChecked
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                clrGridRow {
                    resultColumn = clrGridColumn(6, 6, 6, 6, 6) {
                        this.hidden = true
                        resultInfo = div {
                        }
                        resultComponent = clrSearchResult {
                            this.pageSizeOptions.add(50)
                            clrItemRender { item ->
                                val isDict = item.objectType in dictionaries.map { it.code }
                                val dict = dictionaries.lastOrNull { it.code == item.objectType }
                                title = item.name + when (item.status) {
                                    ObjectStatus.PUBLISHED.name -> " (Опубликован)"
                                    ObjectStatus.APPROVED.name -> " (Утвержден)"
                                    ObjectStatus.DRAFT.name -> " (Черновик)"
                                    ObjectStatus.ARCHIVED.name -> " (Архивирован)"
                                    else -> ""
                                }
                                titleURL = if (isDict) Navigation.Dictionaries.getDictionaryURL(dict?.code
                                        ?: "", item.name) else
                                    when (item.objectType) {
                                        "country" -> Navigation.Countries.getCountryURL(item.id, item.coreId.toInt())
                                        "hotel" -> Navigation.Hotels.getHotelURL(item.id, item.coreId.toInt())
                                        "tour" -> Navigation.Tours.getTourURL(item.id, item.coreId.toInt())
                                        "excursion" -> Navigation.Excursions.getExcursionURL(item.id, item.coreId.toInt())
                                        else -> {
                                            if (item.objectType in tourismList) Navigation.TourismObjects.getTourismObjectURL(item.objectType.toUpperCase(), item.id, item.coreId.toInt()) else Navigation.Locations.getLocationURL(item.id, item.coreId.toInt())
                                        }
                                    }

                                addressFunction = {
                                    if (isDict) {
                                        a {
                                            textContent = "Справочники"
                                            href = Navigation.Dictionaries.getDictionariesURL()
                                        }
                                        span {
                                            textContent = "/"
                                        }
                                        a {
                                            textContent = dict?.name
                                            href = Navigation.Dictionaries.getDictionaryURL(dict?.code ?: "")
                                        }

                                    } else {
                                        a {
                                            textContent = if (tourismList.containsKey(item.objectType)) "Туризм" else "География"
                                            href = "javascript:void(0)"
                                        }
                                        span {
                                            textContent = "/"
                                        }
                                        a {
                                            textContent = when (item.objectType) {
                                                "country" -> "Страна"
                                                "town" -> "Город"
                                                "region" -> "Регион"
                                                "district" -> "Район"
                                                else -> {
                                                    tourismList[item.objectType]
                                                }
                                            }
                                            href = when (item.objectType) {
                                                "country" -> Navigation.Countries.getCountriesUrl()
                                                "hotel" -> Navigation.Hotels.getHotelsURL()
                                                "tour" -> Navigation.Tours.getToursURL()
                                                else -> {
                                                    if (item.objectType in tourismList) Navigation.TourismObjects.getTourismObjectsURL(item.objectType.toUpperCase()) else Navigation.Locations.getLocationsURL()
                                                }
                                            }
                                        }
                                    }
                                }
                                contentFunction = {
                                    item.highlights.forEachIndexed { index, highlight ->
                                        if (index < 3) {
                                            p("search_result_highlight") {
                                                span { innerText = "..." }
                                                span { innerHTML = highlight.replace("<em>", "<em style=\"background-color:yellow;\">") }
                                                span { innerText = "..." }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        filterColumn = clrGridColumn(6, 6, 6, 6, 6) {
                            this.style.minWidth = "100%"
                            form2 = clrForm {
                                isCompact = true
                                clrBlock("Где ищем?") {
                                    clrGroup {
                                        style.paddingLeft = "0px"
                                        div {
                                            style.display = "inline"
                                            toggle = clrToggle("По всей информации") {
                                                style.marginLeft = "0"
                                                isChecked = isFullSearch
                                                onChangeFunction = { _ ->
                                                    isFullSearch = isChecked
                                                    if (isChecked) {
                                                        optionsTree?.rootEntries?.forEach {
                                                            it.checked = false
                                                            it.entries.forEach { _ ->
                                                                it.checked = false
                                                            }
                                                        }
                                                        optionsTree?.render()
                                                        selectedVisibilities.clear()
                                                        selectedStatuses.clear()
                                                        selectedStatuses.add(ObjectStatus.APPROVED.name)
                                                        selectedStatuses.add(ObjectStatus.DRAFT.name)
                                                        selectedStatuses.add(ObjectStatus.PUBLISHED.name)

                                                        isCountriesSelected = false
                                                        isTownsSelected = false
                                                        isRegionsSelected = false
                                                        isDistrictSelected = false
                                                        resultComponent?.getDataFunction = null
                                                        selectedDictionaries.clear()
                                                        selectedTourism.clear()
                                                        form?.render()
                                                        form1?.render()
                                                    }
                                                    resultComponent?.getDataFunction = null
                                                    form2?.render()
                                                }
                                            }
                                        }
                                        div {
                                            style.display = "inline"
                                            optionsTree = clrTreeView {
                                                treeType = TreeViewType.Checkbox
                                                clrTreeNode("Dictionaries", "Справочники") {
                                                    someChildChecked = false
                                                    checked = dictionaries.size == selectedDictionaries.size
                                                    onCheckFunction = {
                                                        if (checked) {
                                                            uncheckToggle()
                                                        }
                                                    }
                                                    dictionaries.forEach {
                                                        this.clrTreeNode("DictionaryCode=${it.code}", it.name) {
                                                            onCheckFunction = {
                                                                if (checked) {
                                                                    uncheckToggle()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                clrTreeNode("Geography", "География") {
                                                    someChildChecked = false
                                                    //checked = isCountriesSelected && isTownsSelected && isRegionsSelected && isDistrictSelected
                                                    onCheckFunction = {
                                                        if (checked) {
                                                            uncheckToggle()
                                                        }
                                                    }
                                                    clrTreeNode("Countries", "Страны") {
                                                        someChildChecked = false
                                                        checked = isCountriesSelected
                                                        onCheckFunction = {
                                                            if (checked) {
                                                                uncheckToggle()
                                                            }
                                                        }
                                                    }
                                                    clrTreeNode("Towns", "Города") {
                                                        checked = isTownsSelected
                                                        onCheckFunction = {
                                                            if (checked) {
                                                                uncheckToggle()
                                                            }
                                                        }
                                                    }
                                                    clrTreeNode("Regions", "Регионы") {
                                                        checked = isRegionsSelected
                                                        onCheckFunction = {
                                                            if (checked) {
                                                                uncheckToggle()
                                                            }
                                                        }
                                                    }
                                                    clrTreeNode("District", "Район") {
                                                        checked = isDistrictSelected
                                                        onCheckFunction = {
                                                            if (checked) {
                                                                uncheckToggle()
                                                            }
                                                        }
                                                    }
                                                }
                                                clrTreeNode("Tourism", "Туризм") {
                                                    someChildChecked = false
                                                    onCheckFunction = {
                                                        if (checked) {
                                                            uncheckToggle()
                                                        }
                                                    }
                                                    tourismList.forEach {
                                                        clrTreeNode(it.key, it.value) {
                                                            checked = it.key in selectedTourism
                                                            onCheckFunction = {
                                                                if (checked) {
                                                                    uncheckToggle()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }.apply {
                                                selectedDictionaries.forEach {
                                                    this.toggleNodeById(it)
                                                }
                                            }
                                        }
                                    }
                                    clrGroup("Область видимости") {
                                        if (visibilities.isNotEmpty()) {
                                            visibilities.forEach { visibility ->
                                                clrCheckbox(visibility.name) {
                                                    isInline = true
                                                    isChecked = selectedVisibilities.contains(visibility.id)
                                                    onChangeFunction = {
                                                        if (isChecked) {
                                                            selectedVisibilities.add(visibility.id)
                                                            isFullSearch = false
                                                            uncheckToggle()
                                                        } else selectedVisibilities.remove(visibility.id)

                                                    }
                                                }
                                            }
                                        } else {
                                            span { textContent = "Нет" }
                                        }
                                    }
                                }
                            }
                            form1 = clrForm {
                                isCompact = true
                                clrBlock {
                                    clrGroup("Статус") {
                                        val statuses = ObjectStatus.values()
                                        statuses.forEach { status ->
                                            clrCheckbox(status.displayName) {
                                                isInline = true
                                                isChecked = selectedStatuses.contains(status.name)
                                                onChangeFunction = {
                                                    uncheckToggle()
                                                    if (isChecked) {
                                                        if (status != ObjectStatus.ARCHIVED)
                                                            selectedStatuses.remove(ObjectStatus.ARCHIVED.name)
                                                        else {
                                                            selectedStatuses.clear()
                                                        }
                                                        selectedStatuses.add(status.name)
                                                    } else selectedStatuses.remove(status.name)
                                                    resultComponent?.getDataFunction = null
                                                    this@clrForm.render()
                                                }
                                            }
                                        }
                                    }
                                }
                                onPostRender = {
                                    this.getHTMLElement().style.paddingTop = "0px"
                                }
                            }
                        }
                    }
                    //style.paddingLeft = "9.5rem"
                    val menu = div("add_menu") {
                        style.zIndex = "9999"
                        onmouseleave = {
                            style.display = "none"
                            true
                        }
                        style.display = "none"
                        ul {
                            li("title active") {
                                innerHTML = "Справочники"
                            }
                            ul {
                                dictionaries.forEach { item ->
                                    a {
                                        li("sub") {
                                            innerHTML = item.name
                                            if (createPrivilegeObjectTypes.contains(item.code)) {
                                                addClass("active")
                                                onclick = {
                                                    Navigation.Dictionaries.addEntry(item.code)
                                                }
                                            } else addClass("disabled")
                                        }
                                        href = "javascript:void(0)"
                                    }
                                }
                            }
                            li("title active") {
                                innerHTML = "География"
                            }
                            ul {
                                a {
                                    li("sub") {
                                        innerHTML = "Страна"
                                        if (createPrivilegeObjectTypes.contains("country")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Countries.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerHTML = "Регион"
                                        if (createPrivilegeObjectTypes.contains("region")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Locations.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerHTML = "Город"
                                        if (createPrivilegeObjectTypes.contains("town")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Locations.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerHTML = "Район"
                                        if (createPrivilegeObjectTypes.contains("district")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Locations.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                            }
                            li("title active") {
                                innerHTML = "Туризм"
                            }
                            ul {
                                a {
                                    li("sub") {
                                        innerHTML = "Отель"
                                        if (createPrivilegeObjectTypes.contains("hotel")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Hotels.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerHTML = "Экскурсия"
                                        if (createPrivilegeObjectTypes.contains("excursion")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Excursions.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerHTML = "Тур"
                                        if (createPrivilegeObjectTypes.contains("tour")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.Tours.add()
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerText = "Достопримечательность"
                                        if (createPrivilegeObjectTypes.contains("landmark")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.TourismObjects.add(LocationType.LANDMARK.name)
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerText = "Аэропорт"
                                        if (createPrivilegeObjectTypes.contains("airport")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.TourismObjects.add(LocationType.AIRPORT.name)
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                a {
                                    li("sub") {
                                        innerText = "Порт"
                                        if (createPrivilegeObjectTypes.contains("port")) {
                                            addClass("active")
                                            onclick = {
                                                Navigation.TourismObjects.add(LocationType.PORT.name)
                                            }
                                        } else addClass("disabled")
                                    }
                                    href = "javascript:void(0)"
                                }
                                /*a {
                                    li("sub active") {
                                        innerText = "Яхтенный порт"
                                        onclick = {
                                            Navigation.TourismObjects.add(LocationType.MARINA.name)
                                        }
                                    }
                                    href = "javascript:void(0)"
                                }*/
                            }
                        }
                    }
                    div {
                        style.position = "fixed"
                        style.bottom = "50px"
                        style.right = "50px"
                        style.zIndex = "9998"
                        img {
                            src = "plus.svg"
                            onclick = {
                                menu.style.display = "block"
                                true
                            }
                        }
                    }
                }
            }
            if (selectedTourism.isEmpty() && selectedDictionaries.isEmpty() && !isCountriesSelected && !isDistrictSelected && !isRegionsSelected && !isTownsSelected) {
                toggle?.isChecked = true
                toggle?.render()
                toggle?.onChangeFunction?.invoke(Event(""))
            }
            showResult()
        }
    }

    private fun initResult() {
        resultComponent?.getDataFunction = {
            optionsTree.let { optionsTree ->
                val types: ArrayList<String> = arrayListOf()
                optionsTree?.getCheckedElements()?.let { it ->
                    isCountriesSelected = false
                    isTownsSelected = false
                    isRegionsSelected = false
                    selectedDictionaries.clear()
                    selectedTourism.clear()
                    it.forEach { item ->
                        when {
                            item.contains("DictionaryCode") -> {
                                val code = item.split("=")[1]
                                selectedDictionaries.add(code)
                            }
                            item == "Dictionaries" -> dictionaries.forEach {
                                selectedDictionaries.add(it.code)
                            }
                            item in tourismList.keys -> selectedTourism.add(item)
                            else -> when (item) {
                                "Countries" -> {
                                    isCountriesSelected = true
                                    types.add("country")
                                }
                                "Towns" -> {
                                    isTownsSelected = true
                                    types.add("town")
                                }
                                "Regions" -> {
                                    isRegionsSelected = true
                                    types.add("region")
                                }
                                "District" -> {
                                    isDistrictSelected = true
                                    types.add("district")
                                }
                                "Geography" -> {
                                    isCountriesSelected = true
                                    isTownsSelected = true
                                    isRegionsSelected = true
                                    isDistrictSelected = true
                                    types.add("landmark")
                                    types.add("region")
                                    types.add("town")
                                    types.add("country")
                                }
                                "Tourism" -> {
                                    selectedTourism.addAll(tourismList.keys)
                                }
                            }
                        }
                    }
                }

                if (selectedDictionaries.isNotEmpty())
                    types.add("dictionaryentry")
                types.addAll(selectedDictionaries)
                types.addAll(selectedTourism)
                Search.search(
                        searchInput, selectedVisibilities.toList(), types, selectedStatuses, isSearchStrict,
                        resultComponent?.start ?: 0, resultComponent?.pageSize ?: 10
                )
            }
        }
    }

    private fun showResult() {
        if (searchInput.isNotEmpty())
            resultColumn?.html?.hidden = false
        filterColumn?.html?.style?.minWidth = ""
        initResult()
        resultComponent?.start = 0
        resultComponent?.render()
        resultInfo?.apply {
            clear()
            div("search_where_block") {
                style.cssFloat = "left"
                div("search_where_title") {
                    appendText("Искали в разделах: ")
                }
                div("search_where_block_where") {
                    ul {
                        if (isFullSearch) {
                            li {
                                span {
                                    appendText("По всей информации")
                                }
                            }
                        } else {

                            if (isCountriesSelected || isRegionsSelected || isTownsSelected) {
                                li {
                                    span {
                                        appendText("География: (${if (isCountriesSelected) "Страны;" else ""} ${if (isRegionsSelected) "Регионы;" else ""} ${if (isTownsSelected) "Города;" else ""} ${if (isDistrictSelected) "Достопримечательности;" else ""})")
                                    }
                                }
                            }
                            if (selectedTourism.isNotEmpty()) {
                                li {
                                    var cont = "("
                                    selectedTourism.forEachIndexed { index, sd ->
                                        cont += tourismList[tourismList.keys.last { it == sd }]
                                        if (index != selectedTourism.lastIndex)
                                            cont += ", "
                                    }
                                    cont += ")"
                                    span("first_level") {
                                        appendText("Туризм: $cont")
                                    }
                                }
                            }
                            if (selectedDictionaries.isNotEmpty()) {
                                li {
                                    var cont = "("
                                    selectedDictionaries.forEachIndexed { index, sd ->
                                        cont += dictionaries.last { it.code == sd }.name
                                        if (index != selectedDictionaries.lastIndex)
                                            cont += ", "
                                    }
                                    cont += ")"
                                    span("first_level") {
                                        appendText("Справочники: $cont")
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div {}
        }
    }
}


