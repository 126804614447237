package ru.playa.sce.views.dictionaries

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.Meta
import ru.playa.kotlinx.clarity.js.util.Paging
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.*
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import kotlin.dom.appendText
import kotlin.js.Promise

class DictionaryEntriesView(private val dictionaryCode: String) : View() {

    companion object {
        const val PATH = "dictionaries/entries"
    }

    private lateinit var privileges: Array<String>

    private var searchName = ""
    private var searchStrict = false
    private var searchDeleteStatus = DeleteStatus.ACTIVE
    private var searchExternalSystem = ""
    private var searchExternalName = ""
    private var searchNoEquating = false
    private val searchVisibilities = mutableListOf<String>()

    private var searchFulltext = ""
    private var searchFullStatus = "ACTIVE"

    private lateinit var parentEntries: Array<DictionaryEntry>
    private lateinit var visibilityAreas: Array<VisibilityArea>
    private lateinit var externals: Array<ExternalSystem>

    private var contentIsChangedDatabase = false
    private var contentIsChangedFulltext = false

    private lateinit var datagridDatabase: DataGrid<DictionaryEntry>
    private lateinit var datagridFulltext: DataGrid<DictionaryEntry>

    override fun render() {
        async {
            val dictionary = Dictionaries.getDictionary(dictionaryCode).await().data.firstOrNull()
                    ?: throw IllegalStateException()
            privileges = Accounts.getObjectTypePrivileges(dictionaryCode).await()
            externals = External.getByObjectType(dictionary.code).await().data
            visibilityAreas = VisibilityAreas.get().await().data
            searchExternalSystem = externals.firstOrNull()?.code ?: ""
            if (dictionary.parent != null)
                parentEntries = Dictionaries.getDictionaryEntries(dictionary.parent.code).await().data

            dom.apply {
                clrPageHeader(dictionary.name) {
                    clrLink("Справочники") {
                        Navigation.Dictionaries.dictionaries()
                    }
                    clrButtonGroup {
                        clrButton("Добавить элемент") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Plus
                            isDisabled = !privileges.contains("CREATE")
                            onClickFunction = {
                                Navigation.Dictionaries.addEntry(dictionaryCode)
                            }
                        }
                    }
                }
                clrTabs {
                    clrTab("Поиск по базе данных") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Код или наименование") {
                                    clrInput {
                                        size = 30
                                        value = searchName
                                        onChangeFunction = {
                                            searchName = value
                                        }
                                        onPressEnterFunction = {
                                            searchName = value
                                            datagridDatabase.start = 0
                                            datagridDatabase.render()
                                        }

                                    }
                                    clrCheckbox("Только точное совпадение") {
                                        isInline = true
                                        isChecked = searchStrict
                                        onChangeFunction = {
                                            searchStrict = isChecked
                                        }
                                    }
                                }
                                clrGroup("Видимость элемента") {
                                    val checks = mutableListOf<Checkbox>()
                                    for (area in visibilityAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = searchVisibilities.contains(area.code)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    searchVisibilities.add(area.code)
                                                } else {
                                                    searchVisibilities.remove(area.code)
                                                }
                                            }
                                        }.let { checks.add(it) }
                                    }
                                }
                                clrGroup("Статус элемента") {
                                    val groupName = "deleteStatus"
                                    clrRadio("Активный", groupName, DeleteStatus.ACTIVE) {
                                        isChecked = searchDeleteStatus == DeleteStatus.ACTIVE
                                        isInline = true
                                        onChangeFunction = {
                                            searchDeleteStatus = DeleteStatus.ACTIVE
                                        }
                                    }
                                    clrRadio("Архивированный", groupName, DeleteStatus.DELETED) {
                                        isChecked = searchDeleteStatus == DeleteStatus.DELETED
                                        isInline = true
                                        onChangeFunction = {
                                            searchDeleteStatus = DeleteStatus.DELETED
                                        }
                                    }
                                    clrRadio("Любой", groupName, DeleteStatus.ALL) {
                                        isChecked = searchDeleteStatus == DeleteStatus.ALL
                                        isInline = true
                                        onChangeFunction = {
                                            searchDeleteStatus = DeleteStatus.ALL
                                        }
                                    }
                                }
                                if (externals.isNotEmpty()) {
                                    clrGroup("Внешний справочник") {
                                        clrSelect<String> {
                                            for (external in externals) {
                                                val op = clrOption(external.name, external.code)
                                                if (searchExternalSystem == op.value)
                                                    selectedOptions.add(op)
                                            }
                                            onChangeFunction = {
                                                searchExternalSystem = selectedOptions.firstOrNull()?.value ?: ""
                                            }
                                        }
                                        val externalNameInput = clrInput {
                                            size = 30
                                            value = searchExternalName
                                            onChangeFunction = {
                                                searchExternalName = value
                                            }
                                        }
                                        clrCheckbox("Не имеющие приравнения") {
                                            isInline = true
                                            isChecked = searchNoEquating
                                            externalNameInput.isDisabled = isChecked
                                            externalNameInput.render()
                                            onChangeFunction = {
                                                searchNoEquating = isChecked
                                                externalNameInput.isDisabled = isChecked
                                                externalNameInput.render()
                                            }
                                        }
                                    }
                                }
                                clrButton("Найти") {
                                    iconShape = IconShape.Search
                                    onClickFunction = {
                                        datagridDatabase.start = 0
                                        datagridDatabase.render()
                                    }
                                }
                            }
                        }
                        datagridDatabase = clrDatagrid {
                            expandableRows = true
                            clrColumn("Код", 1, "code") {
                                appendText(it.code)
                            }
                            clrColumn("Наименование", 3, "name") { entry ->
                                appendText(entry.name)
                            }
                            if (dictionaryCode == "categorygroup" || dictionaryCode == "category" || dictionaryCode == "contentblock") {
                                clrColumn("Рейтинг", 1, "rating") { entry ->
                                    appendText(entry.rating.toString())
                                }
                            }
                            clrColumn("Области видимости", 2) {
                                var visibilityText = ""
                                val visibilitiesLastIndex = it.visibility?.lastIndex ?: -1
                                it.visibility?.forEachIndexed { index, vis ->
                                    visibilityText += vis.name
                                    if (index != visibilitiesLastIndex) visibilityText += ", "
                                }
                                if (visibilitiesLastIndex == -1) visibilityText += "—"
                                appendText(visibilityText)
                            }
                            if (dictionary.parent != null) {
                                clrColumn(dictionary.parent.name, 2) { entry ->
                                    if (entry.parent != null) {
                                        appendText(entry.parent.name)
                                    }
                                }
                            }
                            clrColumn("Внешние справочники", 3) { entry ->
                                if (entry.externalMappings != null) {
                                    entry.externalMappings.sortBy { it.external.name }
                                    entry.externalMappings.forEachIndexed { index, mapping ->
                                        div {
                                            if (index > 0) style.paddingTop = "5px"
                                            appendText("${mapping.external.name}:${mapping.code}")
                                        }
                                    }
                                }
                            }
                            clrOverallDetails {
                                async {
                                    createEditContent(it, dictionary.parent, "database")
                                }
                            }
                            onExpandableRowCloseFunction = {
                                Promise { d, _ ->
                                    if (contentIsChangedDatabase) {
                                        clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                            modalDialogConfiguration = {
                                                this.size = ModalDialog.Size.Small
                                            }
                                            clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                d(true)
                                            }
                                            clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                                                d(false)
                                            }
                                        }
                                    } else {
                                        d(true)
                                    }
                                }
                            }
                            onExpandableRowOpenFunction = {
                                contentIsChangedDatabase = false
                            }
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            orderField = "name"
                            getDataFunction = {
                                getDataDatabase(dictionary.code, start, pageSize, orderField, reverseOrder)
                            }
                        }
                    }
                    clrTab("Полнотекстовый поиск") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup {
                                    clrInput {
                                        size = 50
                                        value = searchFulltext
                                        onChangeFunction = {
                                            searchFulltext = value
                                        }
                                        onPressEnterFunction = {
                                            searchFulltext = value
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                    clrButton("Найти") {
                                        isSmall = true
                                        margin = "0.15rem 0"
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                }
                                clrGroup("Статус элемента") {
                                    val groupName = "fullStatus"
                                    clrRadio("Активный", groupName, "ACTIVE") {
                                        isChecked = searchFullStatus == "ACTIVE"
                                        isInline = true
                                        onChangeFunction = {
                                            searchFullStatus = "ACTIVE"
                                        }
                                    }
                                    clrRadio("Архивированный", groupName, "ARCHIVED") {
                                        isChecked = searchFullStatus == "ARCHIVED"
                                        isInline = true
                                        onChangeFunction = {
                                            searchFullStatus = "ARCHIVED"
                                        }
                                    }
                                }
                            }
                        }
                        datagridFulltext = clrDatagrid {
                            expandableRows = true
                            clrColumn("Код", 1) {
                                appendText(it.code)
                            }
                            clrColumn("Наименование", 3) { entry ->
                                appendText(entry.name)
                            }
                            clrOverallDetails {
                                async {
                                    val dictionaryEntry = Dictionaries.getDictionaryEntry(dictionaryCode, it.id).await()
                                    createEditContent(dictionaryEntry, dictionary.parent, "fulltext")
                                }
                            }
                            onExpandableRowCloseFunction = {
                                Promise { d, _ ->
                                    if (contentIsChangedFulltext) {
                                        clrChoiceDialog("Изменения будут потеряны. Продолжить?") {
                                            modalDialogConfiguration = {
                                                this.size = ModalDialog.Size.Small
                                            }
                                            clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                d(true)
                                            }
                                            clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                                                d(false)
                                            }
                                        }
                                    } else {
                                        d(true)
                                    }
                                }
                            }
                            onExpandableRowOpenFunction = {
                                contentIsChangedFulltext = false
                            }
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            orderField = "name"
                            getDataFunction = {
                                getDataFulltext(start, pageSize)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getDataDatabase(dictionaryCode: String, start: Int, pageSize: Int, orderField: String, reverseOrder: Boolean): Promise<ListResult<DictionaryEntry>> {
        val external = if (searchExternalSystem.isNotBlank()) {
            when {
                searchNoEquating -> "$searchExternalSystem:null"
                searchExternalName.isNotBlank() -> "$searchExternalSystem:$searchExternalName"
                else -> ""
            }
        } else ""

        return Dictionaries.getDictionaryEntries(
                dictionaryCode, start, pageSize, orderField, reverseOrder,
                searchName, searchStrict, searchVisibilities, external, searchDeleteStatus
        )
    }

    private fun getDataFulltext(start: Int, pageSize: Int) = if (searchFulltext.isNotBlank()) {
        val objectStatuses = if (searchFullStatus == "ACTIVE") listOf("DRAFT", "APPROVED", "PUBLISHED") else listOf("ARCHIVED")
        Search.searchObjectType(searchFulltext, objectStatuses, start, pageSize, listOf(dictionaryCode))
    } else {
        async { ListResult(Meta(0, Paging(0, 10)), emptyArray<DictionaryEntry>()) }
    }

    private fun HTMLDivElement.createEditContent(entry: DictionaryEntry, parentDictionary: Dictionary?, tab: String) {

        var codeInput = if (dictionaryCode == "tag") entry.name else entry.code
        var nameInput = ""
        var ratingInput = ""
        var synonymsInput = ""
        var currentParentEntry: DictionaryEntry?
        val externalMappingInputs: HashMap<Input, ExternalSystem> = hashMapOf()
        val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
        var saveButton: ButtonGroup? = null

        visibilityAreas.forEach { area ->
            selectedAreas[area] = entry.visibility?.any { it.id == area.id } ?: false
        }
        currentParentEntry = entry.parent

        fun validate() {
            if (privileges.contains("EDIT")) {
                if (nameInput.isBlank() || codeInput.isBlank() || ratingInput.isBlank() || synonymsInput.length > 1000) {
                    saveButton?.buttons?.forEach {
                        if (it.text == "Сохранить") {
                            it.isDisabled = true
                        }
                    }
                    saveButton?.render()
                } else if (nameInput.isNotBlank() && codeInput.isNotBlank() && ratingInput.isNotBlank() && synonymsInput.length <= 1000) {
                    saveButton?.buttons?.forEach {
                        if (it.text == "Сохранить") {
                            it.isDisabled = false
                        }
                    }
                    saveButton?.render()
                }
                if (tab == "database") contentIsChangedDatabase = true else contentIsChangedFulltext = true
            }
        }

        div().apply {
            style.width = "100%"
            clrForm {
                isCompact = true
                clrBlock("Основная информация") {
                    clrGroup("Наименование") {
                        clrInput {
                            size = 30
                            this.value = entry.name
                            nameInput = this.value
                            if (dictionaryCode == "tag") codeInput = this.value
                            onInputFunction = {
                                nameInput = value
                                if (dictionaryCode == "tag") codeInput = value
                                validate()
                            }
                        }
                    }
                    clrGroup("Рейтинг") {
                        clrInput {
                            size = 30
                            this.value = entry.rating.toString()
                            ratingInput = this.value
                            onInputFunction = {
                                ratingInput = value
                                validate()
                            }
                        }
                        clrToolTip("Только числовое значение")
                    }
                    clrGroup("Область видимости") {
                        selectedAreas.forEach { item ->
                            val area = item.key
                            clrCheckbox(area.name) {
                                isInline = true
                                isChecked = item.value
                                onChangeFunction = {
                                    selectedAreas[area] = isChecked
                                    validate()
                                }
                            }
                        }
                    }
                    if (parentDictionary != null) {
                        clrGroup(parentDictionary.name) {
                            clrSelect<DictionaryEntry?> {
                                clrOption("", null, entry.parent == null)
                                for (parentEntry in parentEntries) {
                                    clrOption(parentEntry.name, parentEntry, entry.parent?.id == parentEntry.id)
                                }
                                onChangeFunction = {
                                    currentParentEntry = selectedOptions.firstOrNull()?.value
                                    validate()
                                }
                            }
                        }
                    }
                    clrGroup("Варианты написания") {
                        clrTextArea {
                            rows = 2
                            cols = 80
                            this.value = entry.synonyms ?: ""
                            synonymsInput = this.value
                            onChangeFunction = {
                                synonymsInput = value
                            }
                            onInputFunction = {
                                synonymsInput = value
                                validate()
                            }
                        }
                    }
                }
                clrBlock("Связи с внешними справочниками") {
                    externals.forEach { external ->
                        var defVal = ""
                        entry.externalMappings?.forEach {
                            if (it.external.id == external.id) {
                                defVal = it.code
                            }
                        }
                        clrGroup(external.name) {
                            val ti = clrInput {
                                value = defVal
                                onInputFunction = {
                                    validate()
                                }
                            }
                            externalMappingInputs[ti] = external
                        }
                    }
                }
            }
            div {
                style.width = "100%"
                saveButton = clrButtonGroup {
                    this.onPostRender = {
                        this.getHTMLElement().style.setProperty("float", "left")
                    }
                    clrButton("Сохранить") {
                        iconShape = IconShape.Check
                        style = ButtonStyle.Primary
                        isDisabled = true
                        onClickFunction = { _ ->
                            val ext: ArrayList<ExternalMapping> = arrayListOf()
                            externalMappingInputs.forEach {
                                ext.add(ExternalSystem.toExternalMapping(it.value, it.key.value))
                            }
                            val options = selectedAreas
                                    .filter { it.value }
                                    .map { VisibilityArea.toOption(it.key, true) }
                                    .toTypedArray()
                            val newEntry = DictionaryEntry(
                                    id = entry.id,
                                    code = codeInput,
                                    name = nameInput,
                                    parent = currentParentEntry,
                                    externalMappings = ext.toTypedArray(),
                                    visibility = options,
                                    rating = ratingInput.toIntOrNull() ?: 0,
                                    synonyms = synonymsInput
                            )
                            Dictionaries.updateDictionaryEntry(dictionaryCode, newEntry).then {
                                if (tab == "database") {
                                    contentIsChangedDatabase = false
                                    this@DictionaryEntriesView.datagridDatabase.render()
                                } else {
                                    contentIsChangedFulltext = false
                                    this@DictionaryEntriesView.datagridFulltext.render()
                                }
                            }
                        }
                    }
                }
                clrButtonGroup {
                    this.onPostRender = {
                        this.getHTMLElement().style.setProperty("float", "right")
                    }
                    this.menuPosition = ButtonGroupMenuPosition.BottomRight
                    clrButton("Удалить") {
                        style = ButtonStyle.WarningOutline
                        isDisabled = !privileges.contains("DELETE")
                        onClickFunction = { _ ->
                            clrChoiceDialog("Вы уверены что хотите удалить элемент справочника?") {
                                modalDialogConfiguration = {
                                    size = ModalDialog.Size.Small
                                }
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    Dictionaries.deleteDictionaryEntry(dictionaryCode, entry.id).then {
                                        if (tab == "database") datagridDatabase.render() else datagridFulltext.render()
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                            }
                        }
                    }
                }
            }
        }
    }
}
