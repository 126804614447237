package ru.playa.sce.views.dictionaries

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.dto.DictionaryEntry
import ru.playa.sce.dto.Option
import kotlin.dom.clear

class DictionaryEntryAddDialog(private val dictionaryCode: String, parent: HTMLElement) : Component(parent) {

    var headerTitle = "Новый объект справочника"
    var onSaveFunction: (DictionaryEntry) -> Unit = {}

    private var entryCode = ""
    private var entryName = ""

    private var creationSuccess = false
    private lateinit var saveButton: Button

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private val validationErrors = mutableListOf<String>()
    private lateinit var validationAlertContainer: HTMLDivElement
    private var validationAlert: Alert? = null

    private fun validateEditorData() {
        validationErrors.clear()
        if (entryCode.isBlank()) validationErrors.add("Не указан код")
        if (entryName.isBlank()) validationErrors.add("Не указано наименование")
    }

    private fun showValidationAlert() {
        if (validationAlert == null) {
            validationAlert = validationAlertContainer.clrAlert {
                style = Alert.Style.ERROR
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
            }
        } else {
            validationAlert?.run {
                items.clear()
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
                render()
            }
        }
    }

    private fun onSaveButtonClick() = async {
        validateEditorData()
        if (validationErrors.isEmpty()) {
            validationAlertContainer.clear()
            validationAlert = null
            saveEditorData().await()
        } else showValidationAlert()
    }

    private fun saveEditorData() = async {
        val visibilityAreas = VisibilityAreas.get().await().data
                .filter { it.enableByDefault }
                .map { Option(it.id, it.name, true) }
                .toTypedArray()

        val newEntry = DictionaryEntry(
                id = 0,
                code = entryCode,
                name = entryName,
                visibility = visibilityAreas,
                rating = 0
        )
        Dictionaries.createDictionaryEntry(dictionaryCode, newEntry).then {
            creationSuccess = true
            onSaveFunction(it)
        }.catch {
            validationErrors.add(it.message ?: "Внутренняя ошибка сервера")
            showValidationAlert()
        }
    }

    override fun build() = async {
        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerTitle) {
                        parent.remove()
                    }
                    clrModalDialogBody {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Код", true) {
                                    clrInput {
                                        value = entryCode
                                        onChangeFunction = {
                                            entryCode = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        value = entryName
                                        onChangeFunction = {
                                            entryName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                        }
                        validationAlertContainer = div { style.marginBottom = "5px" }
                    }
                    clrModalDialogFooter {
                        saveButton = clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            isDisabled = true
                            onClickFunction = {
                                async {
                                    onSaveButtonClick().await()
                                    if (creationSuccess) parent.remove()
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                parent.remove()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun HTMLElement.dictionaryEntryAddDialog(dictionaryCode: String = "", block: DictionaryEntryAddDialog.() -> Unit = {}) =
        DictionaryEntryAddDialog(dictionaryCode, this).apply(block).apply { render() }
