package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.ButtonGroup
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.components.clrButtonGroup
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.dom.appendText

class PageHeader(var text: String, parent: HTMLElement) : Component(parent) {

    var label = ""
    val links = mutableListOf<PageHeaderLink>()
    val buttonGroups = mutableListOf<PageHeaderButtonGroup>()
    val sideImageButtons = mutableListOf<PageHeaderSideImageButton>()

    override fun build() = async {
        val controller = this@PageHeader
        return@async newDiv {
            style.marginBottom = "1rem"
            div {
                style.apply { display = "flex"; justifyContent = "space-between"; alignItems = "center" }
                div {
                    for (link in controller.links) {
                        a {
                            appendText(link.text)
                            href = "javascript://"
                            onclick = {
                                link.block()
                            }
                            style.fontSize = "0.8rem"
                        }
                        span {
                            appendText(" / ")
                            style.fontSize = "0.8rem"
                        }
                    }
                    span {
                        appendText(controller.text)
                        style.fontSize = "1.2rem"
                    }
                    if (controller.label.isNotBlank()) {
                        span {
                            appendText(" (${controller.label})".toLowerCase())
                            style.fontSize = "0.8rem"
                        }
                    }
                    if (buttonGroups.isNotEmpty()) {
                        style.marginBottom = "0.5rem"
                    }
                }
                div {
                    controller.sideImageButtons.forEach { imageButton ->
                        if (imageButton.src.isNotBlank() && !imageButton.skipPredicate()) {
                            img {
                                src = imageButton.src
                                width = imageButton.width
                                height = imageButton.height
                                alt = imageButton.alt
                                title = imageButton.alt
                                style.marginRight = "15px"
                                if (!imageButton.isDisabled) {
                                    style.apply { cursor = "pointer" }
                                    onclick = {
                                        imageButton.onClickFunction()
                                    }
                                } else style.apply { cursor = "not-allowed"; opacity = "0.2" }
                            }
                        }
                    }
                }
            }
            for (buttonGroup in controller.buttonGroups) {
                clrButtonGroup(buttonGroup.block)
            }
        }
    }
}

class PageHeaderLink(val text: String, val block: () -> Unit)

class PageHeaderButtonGroup(val block: ButtonGroup.() -> Unit)

class PageHeaderSideImageButton {
    var src = ""
    var alt = ""
    var width = 30
    var height = 30
    var isDisabled = false
    var skipPredicate: () -> Boolean = { false }
    var onClickFunction: () -> Unit = {}
}

fun PageHeader.clrLink(text: String, block: () -> Unit) = PageHeaderLink(text, block).also { links.add(it) }

fun PageHeader.clrButtonGroup(block: ButtonGroup.() -> Unit) = PageHeaderButtonGroup(block).also { buttonGroups.add(it) }

fun PageHeader.clrSideImageButton(block: PageHeaderSideImageButton.() -> Unit = {}) =
        PageHeaderSideImageButton().apply(block).also { sideImageButtons.add(it) }

fun HTMLElement.clrPageHeader(text: String = "", block: PageHeader.() -> Unit = {}) =
        PageHeader(text, this).apply(block).apply { render() }
