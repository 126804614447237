package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.*

object Locations {

    fun get(
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            external: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            visibility: List<String> = emptyList(),
            tags: List<String> = emptyList(),
            countryId: Int? = null,
            locationType: List<LocationType> = emptyList(),
            parentId: Int? = null
    ) = Network.request<ListResult<Location>>(Network.Method.GET,
            "/sce-web/api/public/objects/location/search?start=$start&pageSize=$pageSize&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict&external=$external${objectStatus.joinToString("") { "&objectStatus=$it" }}${if (countryId != null) "&countryId=$countryId" else ""}${if (parentId != null) "&parentId=$parentId" else ""}${locationType.joinToString("") { "&locationType=$it" }}${visibility.joinToString("") { "&visibility=$it" }}${tags.joinToString("") { "&tags=$it" }}")

    fun getById(id: Int) = Network.request<Location>(Network.Method.GET, "/sce-web/api/user/objects/location/$id")

    fun getDraft(coreId: Int) = Network.request<Location>(Network.Method.GET, "/sce-web/api/user/objects/location/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<Location>(Network.Method.GET,
            "/sce-web/api/user/objects/location/draft/$coreId?version=$version&archive=$archive")

    fun getCountryCores(start: Int, pageSize: Int, name: String) = Network.request<ListResult<Core>>(Network.Method.GET,
            "/sce-web/api/user/objects/location/search/country?start=$start&pageSize=$pageSize&name=$name")

    fun getParentCores(start: Int, pageSize: Int, name: String, countryId: Int, types: List<LocationType>) =
            Network.request<ListResult<CoreSearch>>(Network.Method.GET,
                    "/sce-web/api/user/objects/location/search/parent?start=$start&pageSize=$pageSize&name=${name.toLowerCase()}&country=$countryId${types.joinToString("") { "&types=$it" }}")

    fun getParentTypes(childType: LocationType) = Network.request<ListResult<String>>(Network.Method.GET,
            "/sce-web/api/user/objects/location/parents/$childType")

    fun create(location: Location) = Network.send(Network.Method.POST, location, "/sce-web/api/user/objects/location")

    fun update(location: Location) = Network.send(Network.Method.PUT, location, "/sce-web/api/user/objects/location")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/location/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/location/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/location/publish/$id")
}
