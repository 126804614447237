@file:Suppress("unused")

package ru.playa.sce.views.descriptionEditor

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newForm
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.views.EditorView
import kotlin.browser.window

class DescriptionEditorView(private val objectType: String, private val objectId: Int, private val coreId: Int, private val descriptionId: Int?) : EditorView() {

    companion object {
        const val PATH = "description/editor"
    }

    private val accountId = Application.account.uuid


    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/

    private var objectTypeName = objectType
    private lateinit var objectsName: String
    private lateinit var objectName: String
    private lateinit var navigationToObjects: () -> Unit
    private lateinit var navigationToObject: () -> Unit
    private lateinit var navigationToObjectDescList: () -> Unit

    private lateinit var gridRow: GridRow
    private lateinit var descriptions: Array<ObjectDescription>
    private lateinit var description: ObjectDescription
    private lateinit var descTypes: Array<ObjectDescriptionType>
    private lateinit var languages: Array<DictionaryEntry>
    private lateinit var photoRatio: Ratio

    private var descName = ""
    private var descType: ObjectDescriptionType? = null
    private var descLanguage: DictionaryEntry? = null
    private var descBriefDescription = ""
    private var descFullDescription = ""
    private var locationType: String = ""


    private fun enableSaveButton() {
        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = value.length in 2..200

    private fun validateBriefDescription(value: String) = value.length <= 2000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(descName)) validationErrors.add("Указано некорректное название описания")
        if (!validateBriefDescription(descBriefDescription)) validationErrors.add("Максимальная длина краткого описания - 2000 знаков")
    }

    override fun saveEditorData() = async {
        val desc = ObjectDescription(
                id = if (descriptionId != null) description.id else 0,
                parentCoreId = coreId,
                parentVersionId = objectId,
                name = descName,
                descriptionType = descType ?: throw IllegalStateException(),
                language = descLanguage ?: throw IllegalStateException(),
                briefDescription = descBriefDescription,
                fullDescription = descFullDescription,
                images = if (descriptionId != null) description.images.toList().toTypedArray() else emptyArray()
        )
        if (descriptionId == null) Descriptions.create(objectTypeName, desc).await() else Descriptions.edit(objectTypeName, desc).await()
        confirmOnLeave = false
        navigationToObjectDescList()
    }

    private fun initEditorData() = async {
        confirmOnLeave = false
        val tourismObjects = listOf("LANDMARK", "AIRPORT", "PORT")
        when (objectType) {
            "excursion" -> {
                objectsName = "Экскурсии"
                objectName = Locations.getById(objectId).await().name
                objectTypeName = "location"
                navigationToObjects = { Navigation.Excursions.excursions() }
                navigationToObject = { Navigation.Excursions.excursion(objectId, coreId) }
                navigationToObjectDescList = { Navigation.Excursions.excursionTab(objectId, coreId, "desc") }
            }
            "country" -> {
                objectsName = "Страны"
                objectName = Countries.getById(objectId).await().name
                navigationToObjects = { Navigation.Countries.countries() }
                navigationToObject = { Navigation.Countries.country(objectId, coreId) }
                navigationToObjectDescList = { Navigation.Countries.countryTab(objectId, coreId, "desc") }
            }
            "location" -> {
                objectsName = "Географические объекты"
                val l = Locations.getById(objectId).await()
                objectName = l.name
                locationType = l.type.toLowerCase()
                navigationToObjects = { Navigation.Locations.locations() }
                navigationToObject = { Navigation.Locations.location(objectId, coreId) }
                navigationToObjectDescList = { Navigation.Locations.locationTab(objectId, coreId, "desc") }
            }
            "hotel" -> {
                objectsName = "Отели"
                objectName = Hotels.getById(objectId).await().name
                navigationToObjects = { Navigation.Hotels.hotels() }
                navigationToObject = { Navigation.Hotels.hotel(objectId, coreId) }
                navigationToObjectDescList = { Navigation.Hotels.hotelTab(objectId, coreId, "desc") }
            }
            "tour" -> {
                objectsName = "Туры"
                objectName = Tours.getById(objectId).await().name
                navigationToObjects = { Navigation.Tours.tours() }
                navigationToObject = { Navigation.Tours.tour(objectId, coreId) }
                navigationToObjectDescList = { Navigation.Tours.tourTab(objectId, coreId, "desc") }
            }
            "tourpacket" -> {
                objectsName = "Турпакеты"
                objectName = TourPackets.getById(objectId).await().name
                navigationToObjects = { Navigation.TourPackets.tourPackets() }
                navigationToObject = { Navigation.TourPackets.tourPacket(objectId, coreId) }
                navigationToObjectDescList = { Navigation.TourPackets.tourPacketTab(objectId, coreId, "desc") }
            }
            in tourismObjects -> {
                objectsName = LocationType.valueOf(objectType).pluralName
                objectTypeName = "location"
                objectName = Locations.getById(objectId).await().name
                navigationToObjects = { Navigation.TourismObjects.tourismObjects(objectType) }
                navigationToObject = { Navigation.TourismObjects.tourismObject(objectType, objectId, coreId) }
                navigationToObjectDescList = { Navigation.TourismObjects.tourismObjectTab(objectType, objectId, coreId, "desc") }
            }
        }
        val appType = Common.getApplicableTypes().await().data.firstOrNull { (it.code == objectType) || (it.code == locationType) }
        descTypes = Descriptions.getTypes().await().data.filter { obj ->
            obj.applicableTypes.any { elem ->
                elem.id == appType?.id
            }
        }.toTypedArray()

        languages = Dictionaries.getDictionaryEntries("language").await().data
        descriptions = Descriptions.get(objectTypeName, objectId).await().data
        if (descriptionId != null) {
            description = descriptions.firstOrNull { it.id == descriptionId }
                    ?: throw IllegalStateException("Invalid pair of object and description IDs")
            descName = description.name
            descType = description.descriptionType
            descLanguage = description.language
            descBriefDescription = description.briefDescription
            descFullDescription = description.fullDescription
            photoRatio = Assets.getRatio(objectType.toLowerCase()).await()
        } else {
            descName = ""
            descType = descTypes.firstOrNull()
            descLanguage = languages.firstOrNull()
            descBriefDescription = ""
            descFullDescription = ""
        }
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (descriptionId == null) "Новое описание" else description.name) {
                    if (descriptionId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink(objectsName) {
                        navigationToObjects()
                    }
                    clrLink(objectName) {
                        navigationToObject()
                    }
                }
                div {
                    style.apply { display = "flex"; justifyContent = "space-between" }

                    div {
                        style.flexGrow = "1"

                        clrForm {
                            isCompact = true
                            clrBlock("Основная информация") {
                                clrGroup("Название", true) {
                                    clrInput {
                                        size = 50
                                        value = descName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            descName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Тип описания", true) {
                                    clrSelect<ObjectDescriptionType> {
                                        for (type in descTypes) {
                                            clrOption(type.name, type, descType?.id == type.id)
                                        }
                                        onChangeFunction = {
                                            descType = selectedOptions.firstOrNull()?.value
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Язык", true) {
                                    clrSelect<DictionaryEntry> {
                                        for (language in languages) {
                                            clrOption(language.name, language, descLanguage?.id == language.id)
                                        }
                                        onChangeFunction = {
                                            descLanguage = selectedOptions.firstOrNull()?.value
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Краткое описание") {
                                    clrTextArea {
                                        value = descBriefDescription
                                        onChangeFunction = {
                                            descBriefDescription = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Полное описание") {}
                        }
                        CKEDITOR.config.filebrowserBrowseUrl = ""
                        CKEDITOR.config.filebrowserUploadUrl = ""
                        CKEDITOR.config.height = 400
                        CKEDITOR.config.colorButton_colors = "757374,9B59B6,6B2365,6c2267,9b7fa0,1ABC9C,2ECC71,3498DB,9B59B6,4E5F70,F1C40F,16A085,27AE60,2980B9,8E44AD,2C3E50,F39C12,E67E22,E74C3C,ECF0F1,95A5A6,DDD,FFF,D35400,C0392B,BDC3C7,7F8C8D,999,000"
                        CKEditorExtension.init(objectId, coreId)
                        CKEDITOR.config.also { config ->
                            config.toolbarGroups = js("toolbarGroups: [" +
                                    "{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] }," +
                                    "{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] }," +
                                    "{ name: 'forms', groups: [ 'forms' ] }," +
                                    "{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] }," +
                                    "{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },\n" +
                                    "{ name: 'insert', groups: [ 'insert' ] }" +
                                    "]")
                            config.removeButtons = "scevalign_top,scevalign_center,scevalign_bottom,Image,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,About,ShowBlocks,BGColor,PasteFromWord,Checkbox"
                            config.customConfig = ""
                        }
                        ckEditor {
                            setData(descFullDescription)
                            on("change") {
                                if (checkDirty()) {
                                    descFullDescription = getData(false)
                                    enableSaveButton()
                                }
                            }
                        }
                        div {
                            style.marginTop = "24px"
                            validationAlertContainer = div()
                            createSaveButton()
                            clrButton("Отмена") {
                                style = ButtonStyle.Secondary
                                iconShape = IconShape.Times
                                onClickFunction = {
                                    navigationToObjectDescList()
                                }
                            }
                        }
                    }
                    if (descriptionId != null) {
                        renderBlocks {
                            renderBlock {
                                style.apply { display = "flex"; flexDirection = "column"; alignItems = "center"; marginLeft = "5px"; }

                                clrButton("Добавить фотографию") {
                                    style = ButtonStyle.Secondary
                                    iconShape = IconShape.Plus
                                    onClickFunction = {
                                        dom.imageUploadDialog {
                                            ratio = photoRatio.original
                                            descriptions = this@DescriptionEditorView.descriptions
                                            precheckedDescriptionId = descriptionId
                                            onSaveData = { iblob, ifileName, iname, idescriptions ->
                                                async {
                                                    Assets.sendObjectImage(
                                                            form = newForm(),
                                                            blob = iblob,
                                                            objId = this@DescriptionEditorView.objectId,
                                                            coreId = this@DescriptionEditorView.coreId,
                                                            fileName = ifileName,
                                                            name = iname,
                                                            descriptions = idescriptions,
                                                            selectedFlags = arrayListOf()
                                                    ).await()
                                                    descriptions = Descriptions.get(objectTypeName, objectId).await().data
                                                    description = this@DescriptionEditorView.descriptions.firstOrNull { it.id == descriptionId }
                                                            ?: throw IllegalStateException("Invalid pair of object and description IDs")
                                                    this@renderBlocks.render()
                                                }
                                            }
                                        }
                                    }
                                }
                                description.images.toMutableList().forEach { asset ->
                                    val thumbnail = asset.variants.firstOrNull { it.code == "thumbnail" }
                                    if (thumbnail != null) {
                                        div {
                                            style.width = "${photoRatio.thumbnailWidth + 2}px"

                                            clrCard {
                                                clrCardImg {
                                                    src = "/sce/assets/$accountId/${asset.coreId}/${thumbnail.filename}"
                                                    onclick = {
                                                        window.open("/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}", "_blank")
                                                    }
                                                }
                                                clrCardBlock {
                                                    clrCardText(asset.name)
                                                }
                                                clrCardFooter {
                                                    div {
                                                        clrButton("Удалить") {
                                                            style = ButtonStyle.Flat
                                                            iconShape = IconShape.Trash
                                                            isSmall = true
                                                            onClickFunction = {
                                                                clrChoiceDialog("Изображение будет удалено из описания. Продолжить?") {
                                                                    modalDialogConfiguration = {
                                                                        this.size = ModalDialog.Size.Small
                                                                    }
                                                                    clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                                        async {
                                                                            Assets.removeLinkWithDescription(descriptionId, asset.id).await()
                                                                            descriptions = Descriptions.get(objectTypeName, objectId).await().data
                                                                            description = this@DescriptionEditorView.descriptions.firstOrNull { it.id == descriptionId }
                                                                                    ?: throw IllegalStateException("Invalid pair of object and description IDs")
                                                                            this@renderBlocks.render()
                                                                        }
                                                                    }
                                                                    clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
