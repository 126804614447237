package ru.playa.sce.views.usersRoles

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.UsersRoles
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.ApplicableTypeRolesGroups
import ru.playa.sce.dto.Privilege
import ru.playa.sce.dto.Role
import ru.playa.sce.dto.User
import kotlin.dom.appendText

class UsersRolesView : View() {

    companion object {
        const val PATH = "users-roles"
    }

    private lateinit var users: Array<User>
    private lateinit var roles: Array<Role>

    private val accountId = Application.user.account.id

    private var emailInput = ""

    override fun render() {
        async {
            if (Application.userIsOwner) {
                users = UsersRoles.getUsers().await().data
                roles = UsersRoles.getRoles(accountId).await().data

                dom.apply {
                    clrPageHeader("Пользователи и роли")
                    clrGridRow {
                        clrGridColumn(8, 8, 8, 8, 8) {
                            clrButton("Добавить пользователя") {
                                style = ButtonStyle.Secondary
                                iconShape = IconShape.Plus
                                isDisabled = roles.isEmpty()
                                onClickFunction = {
                                    buildEmailModal()
                                }
                            }
                            table {
                                style.width = "100%"
                                style.marginTop = "24px"
                                tr {
                                    style.apply {
                                        backgroundColor = "#ededed"
                                        height = "40px"
                                        textAlign = "left"
                                    }

                                    th {
                                        style.paddingLeft = "30px"
                                        appendText("Пользователи")
                                    }
                                    th {
                                        style.paddingLeft = "30px"
                                        appendText("Роли пользователей")
                                    }
                                }
                                users.forEach { user ->
                                    tr {
                                        style.height = "60px"

                                        td {
                                            style.apply {
                                                borderBottom = "1px solid #ededed"
                                                borderCollapse = "collapse"
                                                paddingLeft = "30px"
                                            }

                                            a {
                                                appendText(user.email)
                                                href = "javascript://"
                                                onclick = {
                                                    Navigation.UsersRoles.editUserRoles(user.email)
                                                }
                                            }
                                            div {
                                                appendText(user.name)
                                            }
                                        }
                                        td {
                                            style.apply {
                                                borderBottom = "1px solid #ededed"
                                                borderCollapse = "collapse"
                                                paddingLeft = "30px"
                                            }
                                            div {
                                                style.apply {
                                                    display = "flex"
                                                    flexDirection = "row"
                                                    flexWrap = "wrap"
                                                }
                                                user.roles.forEach { userRole ->
                                                    a {
                                                        style.paddingRight = "30px"
                                                        if (userRole.name.isNotBlank())
                                                            appendText(userRole.name)
                                                        else
                                                            appendText("—")
                                                        href = "javascript://"
                                                        onclick = {
                                                            buildUserRoleModal(userRole)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        clrGridColumn(4, 4, 4, 4, 4) {
                            clrButton("Добавить роль") {
                                style = ButtonStyle.Secondary
                                iconShape = IconShape.Plus
                                onClickFunction = {
                                    Navigation.UsersRoles.addRole()
                                }
                            }
                            h3 {
                                style.marginBottom = "20px"
                                appendText("Доступные роли")
                            }
                            roles.forEach { role ->
                                div {
                                    style.marginBottom = "10px"
                                    a {
                                        style.fontSize = "14px"
                                        if (role.name.isNotBlank())
                                            appendText(role.name)
                                        else
                                            appendText("—")
                                        href = "javascript://"
                                        onclick = {
                                            Navigation.UsersRoles.editRole(role.id)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }

    private fun buildUserRoleModal(role: Role) {
        dom.clrModalDialog {
            clrModalDialogContent {
                clrModalDialogHeader("Роль ${role.name}") {
                    this@clrModalDialog.hide()
                }
                clrModalDialogBody {
                    ApplicableTypeRolesGroups.values().forEach { applicableTypeRoleGroup ->
                        div {
                            style.apply {
                                backgroundColor = "#ededed"
                                fontSize = "14px"
                                fontWeight = "bold"
                                paddingLeft = "10px"
                                paddingBottom = "5px"
                                paddingTop = "5px"
                            }
                            appendText(applicableTypeRoleGroup.groupName)
                        }
                        table {
                            style.marginTop = "10px"
                            style.marginBottom = "10px"
                            style.width = "100%"

                            applicableTypeRoleGroup.groupTypes.forEach { typeCode ->
                                val rolePrivilege = role.privileges.find { it.objectType.code == typeCode }

                                if (rolePrivilege != null) {
                                    tr {
                                        style.height = "20px"
                                        td {
                                            style.apply {
                                                width = "200px"
                                                fontSize = "14px"
                                                fontWeight = "bold"
                                                borderBottom = "1px solid #ededed"
                                                borderCollapse = "collapse"
                                                paddingLeft = "20px"
                                            }
                                            appendText(rolePrivilege.objectType.name)
                                        }
                                        td {
                                            style.apply {
                                                borderBottom = "1px solid #ededed"
                                                borderCollapse = "collapse"
                                                paddingLeft = "20px"
                                            }
                                            var privilegesText = ""
                                            rolePrivilege.privileges.forEachIndexed { index, priv ->
                                                privilegesText += Privilege.valueOf(priv).displayName
                                                if (index != rolePrivilege.privileges.lastIndex) privilegesText += ", "
                                            }
                                            appendText(privilegesText)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun buildEmailModal() {
        lateinit var alertContainerEmail: HTMLDivElement
        lateinit var emailInputElement: Input

        dom.clrModalDialog {
            clrModalDialogContent {
                clrModalDialogHeader("Новый пользователь") {
                    emailInput = ""
                    this@clrModalDialog.hide()
                }
                clrModalDialogBody {
                    clrForm {
                        isCompact = true
                        clrBlock {
                            clrGroup("Электронная почта") {
                                emailInputElement = clrInput {
                                    size = 50
                                    value = emailInput
                                    onChangeFunction = {
                                        emailInput = value
                                    }
                                    onPressEnterFunction = {
                                        emailInput = value
                                        async {
                                            if (emailInput.isNotBlank()) {
                                                val userRequest = UsersRoles.checkUserByEmail(emailInput).await()
                                                if (userRequest.status == 400) {
                                                    alertContainerEmail.style.display = "block"
                                                } else {
                                                    emailInput = ""
                                                    emailInputElement.value = ""
                                                    Navigation.UsersRoles.editUserRoles(userRequest.email)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                alertContainerEmail = div {
                    style.display = "none"

                    clrAlert {
                        style = Alert.Style.ERROR
                        clrAlertItem("Пользователь с такой электронной почтой не найден")
                    }
                }
                clrModalDialogFooter {
                    clrButton("Добавить") {
                        iconShape = IconShape.Plus
                        onClickFunction = {
                            async {
                                val userRequest = UsersRoles.checkUserByEmail(emailInput).await()
                                if (userRequest.status == 400) {
                                    alertContainerEmail.style.display = "block"
                                } else {
                                    emailInput = ""
                                    emailInputElement.value = ""
                                    Navigation.UsersRoles.editUserRoles(userRequest.email)
                                }
                            }
                        }
                    }
                    clrButton("Отмена") {
                        iconShape = IconShape.Times
                        style = ButtonStyle.Secondary
                        onClickFunction = {
                            emailInput = ""
                            this@clrModalDialog.hide()
                        }
                    }
                }
            }
        }
    }
}
