package ru.playa.sce.views.usersRoles

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Common
import ru.playa.sce.api.UsersRoles
import ru.playa.sce.components.clrChoice
import ru.playa.sce.components.clrChoiceDialog
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.views.EditorView
import kotlin.dom.appendText

class RoleEditorView(private val roleId: Int?) : EditorView() {

    companion object {
        const val PATH = "roles/editor"
    }

    private val accountId = Application.user.account.id

    private val codeToApplicableType = mutableMapOf<String, ApplicableType>()
    private val initialPrivileges = mutableMapOf<String, Array<String>>()

    private var codeInput = ""
    private var nameInput = ""
    private val selectedPrivileges = mutableMapOf<String, MutableList<String>>()

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateInput(value: String) = Regex(".{2,200}").matches(value)

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateInput(codeInput)) validationErrors.add("Указан некорректный код роли")
        if (!validateInput(nameInput)) validationErrors.add("Указано некорректное название роли")
    }

    override fun saveEditorData() = async {
        val privileges = mutableSetOf<AccountPrivilege>()
        selectedPrivileges.forEach { (key, value) ->
            val applicableType = codeToApplicableType[key]
            if (applicableType != null)
                privileges.add(AccountPrivilege(applicableType, value.toTypedArray()))
        }
        val role = Role(
                id = roleId ?: 0,
                name = nameInput,
                code = codeInput,
                privileges = privileges.toTypedArray()
        )
        if (roleId == null) UsersRoles.createRole(accountId, role).await() else UsersRoles.updateRole(accountId, role).await()
        Navigation.UsersRoles.usersRoles()
    }

    private fun initEditorData() = async {
        Common.getApplicableTypes().await().data.forEach {
            codeToApplicableType[it.code] = it
        }
        if (roleId != null) {
            val currentRole = UsersRoles.getRoleById(roleId).await()
            codeInput = currentRole.code
            nameInput = currentRole.name
            selectedPrivileges.clear()
            initialPrivileges.clear()
            currentRole.privileges.forEach {
                initialPrivileges[it.objectType.code] = it.privileges
            }
        } else {
            codeInput = ""
            nameInput = ""
            selectedPrivileges.clear()
            initialPrivileges.clear()
        }
    }

    private fun toggleGroupChildren(groupCheckboxes: MutableList<Checkbox>?, checkboxIdToTypeCode: MutableMap<String, String>, privilegeName: String, toggle: Boolean) {
        groupCheckboxes?.forEach {
            it.isChecked = toggle
            it.render()
            if (toggle) {
                selectedPrivileges[checkboxIdToTypeCode[it.id]]?.remove(privilegeName)
                selectedPrivileges[checkboxIdToTypeCode[it.id]]?.add(privilegeName)
            } else {
                selectedPrivileges[checkboxIdToTypeCode[it.id]]?.remove(privilegeName)
            }
        }
    }

    private fun toggleGroupParent(groupCheckbox: Checkbox?, groupCheckboxes: MutableList<Checkbox>?) {
        if (groupCheckbox != null && groupCheckboxes != null) {
            if (groupCheckboxes.any { it.isChecked }) {
                if (groupCheckboxes.any { !it.isChecked }) {
                    groupCheckbox.apply {
                        isChecked = false
                        isIndeterminate = true
                        render()
                    }
                } else {
                    groupCheckbox.apply {
                        isChecked = true
                        isIndeterminate = false
                        render()
                    }
                }
            } else {
                groupCheckbox.apply {
                    isChecked = false
                    isIndeterminate = false
                    render()
                }
            }
        }
    }

    override fun render() {
        async {
            if (Application.userIsOwner) {
                val privilegeValues = Privilege.values()
                initEditorData().await()

                dom.apply {
                    clrPageHeader(if (roleId != null) "Роль $nameInput" else "Новая роль")
                    clrForm {
                        isCompact = true
                        clrBlock {
                            clrGroup("Код роли", true) {
                                clrInput {
                                    value = codeInput
                                    tooltipContent = "От 2 до 200 символов"
                                    validationPredicate = {
                                        validateInput(value)
                                    }

                                    onInputFunction = {
                                        codeInput = value
                                        enableSaveButton()
                                    }
                                }
                            }
                            clrGroup("Название роли", true) {
                                clrInput {
                                    value = nameInput
                                    tooltipContent = "От 2 до 200 символов"
                                    validationPredicate = {
                                        validateInput(value)
                                    }

                                    onInputFunction = {
                                        nameInput = value
                                        enableSaveButton()
                                    }
                                }
                            }
                        }
                    }
                    clrTable {
                        isCompact = true
                        clrHeader {
                            clrRow {
                                clrHeadCell {}
                                privilegeValues.forEach {
                                    clrHeadCell {
                                        appendText(it.displayName)
                                    }
                                }
                            }
                        }
                        clrBody {
                            ApplicableTypeRolesGroups.values().forEach { it ->
                                val groupTypesLastIndex = it.groupTypes.lastIndex
                                val groupPrivilegeCheckbox = mutableMapOf<String, Checkbox>()
                                val groupPrivilegeCheckboxes = mutableMapOf<String, MutableList<Checkbox>>()
                                val checkboxIdToTypeCode = mutableMapOf<String, String>()

                                clrRow {
                                    style.backgroundColor = "#ededed"
                                    clrCell {
                                        style.apply { verticalAlign = "middle"; textAlign = "start"; fontWeight = "bold" }
                                        appendText(it.groupName)
                                    }
                                    privilegeValues.forEach { privilege ->
                                        clrCell {
                                            clrCheckbox {
                                                groupPrivilegeCheckbox[privilege.name] = this

                                                removeMarginBottom = true
                                                this.onChangeFunction = {
                                                    toggleGroupChildren(groupPrivilegeCheckboxes[privilege.name], checkboxIdToTypeCode, privilege.name, isChecked)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                it.groupTypes.forEachIndexed { groupTypeIndex, typeCode ->
                                    val applicableType = codeToApplicableType[typeCode]

                                    if (applicableType != null) {
                                        selectedPrivileges[typeCode] = mutableListOf()

                                        clrRow {
                                            clrCell {
                                                style.apply { verticalAlign = "middle"; textAlign = "start"; paddingLeft = "40px" }
                                                appendText(applicableType.name)
                                            }
                                            privilegeValues.forEach { privilege ->
                                                if (groupTypeIndex == 0) groupPrivilegeCheckboxes[privilege.name] = mutableListOf()

                                                clrCell {
                                                    clrCheckbox {
                                                        removeMarginBottom = true

                                                        if (roleId != null) {
                                                            if (initialPrivileges[typeCode]?.contains(privilege.name) == true) {
                                                                isChecked = true
                                                                selectedPrivileges[typeCode]?.add(privilege.name)
                                                            } else {
                                                                isChecked = false
                                                            }
                                                        }
                                                        groupPrivilegeCheckboxes[privilege.name]?.add(this)
                                                        checkboxIdToTypeCode[this.id] = typeCode
                                                        if (groupTypeIndex == groupTypesLastIndex) {
                                                            toggleGroupParent(groupPrivilegeCheckbox[privilege.name], groupPrivilegeCheckboxes[privilege.name])
                                                        }

                                                        onChangeFunction = {
                                                            if (isChecked) {
                                                                selectedPrivileges[typeCode]?.add(privilege.name)
                                                            } else {
                                                                selectedPrivileges[typeCode]?.remove(privilege.name)
                                                            }
                                                            toggleGroupParent(groupPrivilegeCheckbox[privilege.name], groupPrivilegeCheckboxes[privilege.name])
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div {
                        style.marginTop = "10px"
                        validationAlertContainer = div()
                        createSaveButton()
                        if (roleId != null) {
                            clrButton("Удалить") {
                                style = ButtonStyle.WarningOutline
                                iconShape = IconShape.Trash
                                onClickFunction = {
                                    clrChoiceDialog("Вы уверены что хотите удалить данную роль?") {
                                        clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                            async {
                                                UsersRoles.deleteRole(roleId).await()
                                                Navigation.UsersRoles.usersRoles()
                                            }
                                        }
                                        clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                    }
                                }
                            }
                        }
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                Navigation.UsersRoles.usersRoles()
                            }
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }
}