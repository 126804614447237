package ru.playa.sce.views.locations

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.s
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Countries
import ru.playa.sce.api.Locations
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.datagridColumn
import ru.playa.sce.components.selectionDialog
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import kotlin.dom.appendText
import kotlin.dom.clear

class LocationAddDialog(private val locationTypeName: String, parent: HTMLElement) : Component(parent) {

    var headerTitle = ""
    var onSaveFunction: (Location) -> Unit = {}

    private val locationType = LocationType.valueOf(locationTypeName)
    private var locationCode = ""
    var locationName = ""
    private var locationCountry: Core? = null
    private var locationCountryArea: Area? = null
    private var locationCountryMarker: Point? = null
    private var locationParent: Core? = null
    private var locationParentArea: Area? = null
    private var locationParentMarker: Point? = null

    private var creationSuccess = false
    private lateinit var saveButton: Button

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private val validationErrors = mutableListOf<String>()
    private lateinit var validationAlertContainer: HTMLDivElement
    private var validationAlert: Alert? = null

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)

    private fun validateEditorData() {
        validationErrors.clear()
        if (locationType == LocationType.AIRPORT || locationType == LocationType.REGION) {
            if (!validateName(locationCode)) validationErrors.add("Указан некорректный код")
        }
        if (!validateName(locationName)) validationErrors.add("Указано некорректное наименование")
        if (locationCountry == null) validationErrors.add("Не указано обязательное поле \"Страна\"")
    }

    private fun showValidationAlert() {
        if (validationAlert == null) {
            validationAlert = validationAlertContainer.clrAlert {
                style = Alert.Style.ERROR
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
            }
        } else {
            validationAlert?.run {
                items.clear()
                for (error in validationErrors) {
                    clrAlertItem(error)
                }
                render()
            }
        }
    }

    private fun onSaveButtonClick() = async {
        validateEditorData()
        if (validationErrors.isEmpty()) {
            validationAlertContainer.clear()
            validationAlert = null
            saveEditorData().await()
        } else showValidationAlert()
    }

    private fun saveEditorData() = async {
        val visibilityAreas = VisibilityAreas.get().await().data
                .filter { it.enableByDefault }
                .map { Option(it.id, it.name, true) }
                .toTypedArray()
        val locationArea = locationParentArea ?: locationCountryArea
        val locationMarker = locationParentMarker ?: locationCountryMarker

        val location = Location(
                id = 0,
                code = if (locationType == LocationType.AIRPORT || locationType == LocationType.REGION) locationCode else "",
                name = locationName,
                coreId = 0,
                country = locationCountry ?: throw IllegalStateException(),
                type = locationTypeName,
                mapData = locationMarker?.let { m -> locationArea?.let { a -> MapData(m, a) } },
                parent = locationParent,
                visibility = visibilityAreas
        )
        Locations.create(location).then {
            creationSuccess = true
            onSaveFunction(it)
        }.catch {
            validationErrors.add(it.message ?: "Внутренняя ошибка сервера")
            showValidationAlert()
        }
    }

    override fun build() = async {
        val parentTypes = Locations.getParentTypes(locationType).await().data.map { LocationType.valueOf(it) }.toList()

        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerTitle) {
                        parent.remove()
                    }
                    clrModalDialogBody {
                        style.apply { overflowX = "visible"; overflowY = "visible" }
                        clrForm {
                            isCompact = true
                            clrBlock {
                                if (locationType == LocationType.AIRPORT || locationType == LocationType.REGION) {
                                    clrGroup("Код", true) {
                                        clrInput {
                                            value = locationCode
                                            tooltipContent = "От 2 до 200 символов"
                                            validationPredicate = {
                                                validateName(value)
                                            }
                                            onChangeFunction = {
                                                locationCode = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        value = locationName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            locationName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Страна", true) {
                                    val country = locationCountry
                                    span {
                                        appendText(country?.run { publishedName ?: draftName ?: "" }
                                                ?: "Не выбрана")
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Pencil
                                        tooltipTitle = "Выбрать"
                                        onClickFunction = { _ ->
                                            parent.selectionDialog<Core> {
                                                title = "Выберите страну"
                                                searchQuery = country?.run {
                                                    publishedName ?: draftName ?: ""
                                                } ?: ""
                                                selectedValue = country
                                                onSelectFunction = {
                                                    selectedValue?.let { selectedValue ->
                                                        async {
                                                            val countryId = selectedValue.run {
                                                                publishedVersionId ?: draftVersionId
                                                            }
                                                            if (countryId != null) {
                                                                val selectedCountry = Countries.getById(countryId).await()
                                                                selectedCountry.area?.let {
                                                                    locationCountryArea = it
                                                                    locationCountryMarker = Point(
                                                                            (it.southWest.latitude + it.northEast.latitude) / 2,
                                                                            (it.southWest.longitude + it.northEast.longitude) / 2
                                                                    )
                                                                }
                                                            }
                                                            locationCountry = selectedValue
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                                datagridColumn("Наименование", 1) {
                                                    appendText(it.run { publishedName ?: draftName ?: "" })
                                                }
                                                getDataFunction = { start, pageSize, _, _, query ->
                                                    Locations.getCountryCores(start, pageSize, query)
                                                }
                                                equalityPredicate = { x, y -> x.id == y.id }
                                            }
                                        }
                                    }
                                }
                                if (parentTypes.isNotEmpty()) {
                                    clrGroup("Расположение") {
                                        span {
                                            appendText(locationParent?.run {
                                                publishedName ?: draftName ?: "Без названия"
                                            }
                                                    ?: "Не выбран")
                                        }
                                        clrButton {
                                            style = ButtonStyle.Flat
                                            isIcon = true
                                            iconShape = IconShape.Pencil
                                            isDisabled = locationCountry == null
                                            tooltipTitle = "Выбрать"
                                            onClickFunction = { _ ->
                                                async {
                                                    parent.selectionDialog<CoreSearch> {
                                                        title = "Выберите расположение"
                                                        locationParent?.let { loc ->
                                                            selectedValue = CoreSearch(loc.id, loc.code, loc.objectType, null, null)
                                                        }

                                                        searchQuery = locationParent?.run {
                                                            publishedName ?: draftName ?: ""
                                                        } ?: ""
                                                        onSelectFunction = {
                                                            selectedValue?.let { selectedValue ->
                                                                locationParent = Core(
                                                                        id = selectedValue.id,
                                                                        code = selectedValue.code,
                                                                        objectType = selectedValue.objectType,
                                                                        objectSubtype = selectedValue.draft?.type?.toLowerCase(),
                                                                        publishedVersionId = selectedValue.published?.id,
                                                                        draftVersionId = selectedValue.draft?.id,
                                                                        publishedName = selectedValue.published?.name,
                                                                        draftName = selectedValue.draft?.name
                                                                )
                                                                val parentVersion = selectedValue.published
                                                                        ?: selectedValue.draft
                                                                if (parentVersion?.mapData != null) {
                                                                    parentVersion.mapData.let {
                                                                        locationParentArea = it.area
                                                                        locationParentMarker = it.center
                                                                    }
                                                                }
                                                                this@clrForm.render()
                                                                enableSaveButton()
                                                            }
                                                        }
                                                        datagridColumn("Наименование", 1) {
                                                            val parentName = it.published?.name ?: it.draft?.name
                                                            ?: ""
                                                            if ((it.draft?.country?.deletedDate == null) && (it.published?.country?.deletedDate == null)) {
                                                                appendText(parentName)
                                                            } else {
                                                                s {
                                                                    appendText(parentName)
                                                                }
                                                            }
                                                        }
                                                        getDataFunction = { start, pageSize, _, _, query ->
                                                            Locations.getParentCores(start, pageSize, query, locationCountry?.id
                                                                    ?: 0, parentTypes)
                                                        }
                                                        equalityPredicate = { x, y -> x.id == y.id }
                                                    }
                                                }
                                            }
                                        }
                                        if (locationParent != null) {
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Очистить"
                                                onClickFunction = {
                                                    locationParent = null
                                                    locationParentArea = null
                                                    locationParentMarker = null
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        validationAlertContainer = div { style.marginBottom = "5px" }
                    }
                    clrModalDialogFooter {
                        saveButton = clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            isDisabled = locationName.isBlank()
                            onClickFunction = {
                                async {
                                    onSaveButtonClick().await()
                                    if (creationSuccess) parent.remove()
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                parent.remove()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun HTMLElement.locationAddDialog(locationTypeName: String = "", block: LocationAddDialog.() -> Unit = {}) =
        LocationAddDialog(locationTypeName, this).apply(block).apply { render() }
