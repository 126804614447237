package ru.playa.sce.views.visibilityAreas

import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.VisibilityArea
import kotlin.dom.appendText

class VisibilityAreaCreatorView : View() {

    companion object {
        const val PATH = "visibility-areas/add"
    }

    private var codeInput = ""
    private var nameInput = ""
    private var enableByDefault = false

    var saveButton: Button? = null

    private fun HTMLElement.createEditContent() {
        fun validate() {
            if (nameInput.isBlank() || codeInput.isBlank()) {
                saveButton?.isDisabled = true
                saveButton?.render()
            } else if (nameInput.isNotBlank() && codeInput.isNotBlank()) {
                saveButton?.isDisabled = false
                saveButton?.render()
            }
        }

        async {
            div().apply {
                clrPageHeader("Новая область видимости") {}
                style.width = "100%"
                clrForm {
                    isCompact = true
                    clrBlock("Основная информация") {
                        clrGroup("Код") {
                            clrInput {
                                size = 30
                                this.value = ""
                                codeInput = this.value
                                onInputFunction = {
                                    codeInput = value
                                    validate()
                                }
                            }
                        }
                        clrGroup("Наименование") {
                            clrInput {
                                size = 30
                                this.value = ""
                                nameInput = this.value
                                onInputFunction = {
                                    nameInput = value
                                    validate()
                                }
                            }
                        }
                        clrGroup("Включена по умолчанию") {
                            clrCheckbox {
                                isInline = true
                                isChecked = enableByDefault
                                onChangeFunction = {
                                    enableByDefault = isChecked
                                    validate()
                                }
                            }
                        }
                    }
                }
                div {
                    style.width = "100%"
                    saveButton = clrButton("Сохранить") {
                        iconShape = IconShape.Check
                        style = ButtonStyle.Primary
                        isDisabled = true
                        onClickFunction = { _ ->
                            val newVisibilityArea = VisibilityArea(0, codeInput, nameInput, enableByDefault)
                            VisibilityAreas.create(newVisibilityArea).then {
                                Navigation.VisibilityAreas.visibilityAreas()
                            }.catch {
                                Application.applicationLayout.topLevelAlert.showLimitedTime {
                                    clrAlertItem("Не удалось добавить область видимости")
                                }
                            }
                        }
                    }
                    clrButton("Отмена") {
                        style = ButtonStyle.Secondary
                        iconShape = IconShape.Times
                        onClickFunction = {
                            Navigation.VisibilityAreas.visibilityAreas()
                        }
                    }
                }
            }
        }
    }

    override fun render() {
        if (Application.userIsOwner) {
            dom.createEditContent()
        } else {
            dom.apply {
                h1 {
                    appendText("Отказано в доступе")
                }
            }
        }
    }
}
