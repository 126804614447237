package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.VisibilityArea


object VisibilityAreas {
    fun get() = Network.request<ListResult<VisibilityArea>>(Network.Method.GET, "/sce-web/api/user/object/visibilityarea/search")
    fun update(area: VisibilityArea) = Network.send(Network.Method.PUT, area, "/sce-web/api/user/object/visibilityarea")
    fun create(area: VisibilityArea) = Network.send(Network.Method.POST, area, "/sce-web/api/user/object/visibilityarea")
    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/object/visibilityarea/$id")
}