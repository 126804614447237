package ru.playa.sce.components

import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.label
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.dom.appendText
import kotlin.js.Promise

class SelectionDialog<T>(parent: HTMLElement) : Component(parent) {

    var title = ""
    var searchQuery = ""
    var orderField = ""
    var selectedValue: T? = null
    var onSelectFunction: () -> Unit = {}
    var addDialogFunction: (() -> Unit)? = null
    val columns = mutableListOf<SelectionDatagridColumn<T>>()
    lateinit var getDataFunction: ((Int, Int, String, Boolean, String) -> Promise<ListResult<T>>)
    lateinit var equalityPredicate: ((T, T) -> Boolean)

    override fun build() = async {
        val controller = this@SelectionDialog
        val addDialogFunctionVal = addDialogFunction
        lateinit var searchInput: Input
        lateinit var datagrid: DataGrid<T>
        lateinit var selectionButton: Button
        return@async newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(controller.title) {
                        this@clrModalDialog.hide()
                    }
                    clrModalDialogBody {
                        div {
                            style.apply { display = "flex"; flexDirection = "row"; alignItems = "center" }
                            label {
                                style.marginRight = "10px"
                                appendText("Поиск")
                            }
                            searchInput = clrInput {
                                size = 50
                                value = controller.searchQuery
                                onPressEnterFunction = {
                                    datagrid.start = 0
                                    datagrid.render()
                                }
                            }
                            div {
                                style.marginLeft = "10px"
                                clrButton("Найти") {
                                    isSmall = true
                                    iconShape = IconShape.Search
                                    onClickFunction = {
                                        datagrid.start = 0
                                        datagrid.render()
                                    }
                                }
                            }
                            if (addDialogFunctionVal != null) {
                                clrButton {
                                    style = ButtonStyle.Flat
                                    isIcon = true
                                    iconShape = IconShape.Plus
                                    tooltipTitle = "Создать"
                                    onClickFunction = {
                                        parent.remove()
                                        addDialogFunctionVal()
                                    }
                                }
                            }
                        }
                        datagrid = clrDatagrid {
                            for (column in controller.columns) {
                                clrColumn(column.title, column.width, column.orderBy) {
                                    (column.block)(it)
                                }
                            }
                            height = 280
                            pageSize = 5
                            pageSizeOptions.add(10)
                            orderField = controller.orderField
                            getDataFunction = {
                                controller.getDataFunction(start, pageSize, orderField, reverseOrder, searchInput.value)
                            }
                            selectionMode = DatagridSelectionMode.SingleSelection
                            selectedValue = controller.selectedValue
                            onSelectFunction = {
                                selectionButton.isDisabled = false
                                selectionButton.render()
                            }
                            equalityPredicate = controller.equalityPredicate
                        }
                    }
                    clrModalDialogFooter {
                        selectionButton = clrButton("Выбрать") {
                            iconShape = IconShape.Check
                            isDisabled = controller.selectedValue == null
                            onClickFunction = { _ ->
                                datagrid.selectedValue?.let {
                                    controller.selectedValue = it
                                    controller.onSelectFunction()
                                    this@clrModalDialog.hide()
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                this@clrModalDialog.hide()
                            }
                        }
                    }
                }
            }
        }
    }
}

class SelectionDatagridColumn<in T>(val title: String, val width: Int, val orderBy: String, val block: HTMLDivElement.(T) -> Unit)

fun <T> SelectionDialog<T>.datagridColumn(title: String = "", width: Int = 10, orderBy: String = "", block: HTMLDivElement.(T) -> Unit) =
        SelectionDatagridColumn(title.trim(), width, orderBy.trim(), block).also { columns.add(it) }

fun <T> HTMLElement.selectionDialog(block: SelectionDialog<T>.() -> Unit) =
        SelectionDialog<T>(this).apply(block).apply { render() }
