package ru.playa.sce.views.locations

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.countries.countryAddDialog
import kotlin.dom.appendText

class LocationEditorView(private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "locations/editor"
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/

    private lateinit var draft: Location
    private lateinit var createPrivilegeObjectTypes: List<String>
    private lateinit var locationTypeParents: List<LocationType>

    private var locationType = LocationType.REGION
    private var locationCode = ""
    private var locationName = ""
    private var locationCountry: Core? = null
    private var locationParent: Core? = null
    private var locationArea: Area? = null
    private var locationMarker: Point? = null
    private val locationTags = mutableListOf<Option>()
    private var locationSynonyms = ""
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()

    private var locationCountryArea: Area? = null
    private var locationParentArea: Area? = null

    private lateinit var countryRenderBlocks: RenderBlocks
    private lateinit var countrySuggestionBox: SuggestionBox<Core>
    private lateinit var parentRenderBlocks: RenderBlocks
    private lateinit var parentSuggestionBox: SuggestionBox<CoreSearch>
    private lateinit var googleMap: GoogleMap

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun fillMapGeocodeInput() = googleMap.fillGeocodeInput(
            locationName,
            locationCountry?.publishedName ?: locationCountry?.draftName,
            locationParent?.publishedName ?: locationParent?.draftName
    )

    private fun validateCode(value: String) = Regex(".{2,200}").matches(value)
    private fun validateName(value: String) = Regex(".{2,200}").matches(value)
    private fun validateMapData(point: Point?, area: Area?) = if (point != null && area != null) area.contains(point) else point == null && area == null
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex(".{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (locationType == LocationType.REGION) {
            if (!validateCode(locationCode)) validationErrors.add("Указан некорректный код региона")
        }
        if (!validateName(locationName)) validationErrors.add("Указано некорректное наименование географического объекта")
        if (locationCountry == null) validationErrors.add("Не указано обязательное поле \"Страна\"")
        if (!validateMapData(locationMarker, locationArea)) validationErrors.add("Указано некорректное взаимное расположение маркера и области на карте")
        if (!validateSynonyms(locationSynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (countryRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Страны (выбрать, добавить, отменить)")
            countrySuggestionBox.invalidateInput()
        }
        if (parentRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Расположения (выбрать, добавить, отменить)")
            parentSuggestionBox.invalidateInput()
        }
    }

    override fun saveEditorData() = async {
        val location = Location(
                id = if (coreId != null) draft.id else 0,
                code = if (locationType == LocationType.REGION) locationCode else if (coreId != null) draft.code else "",
                name = locationName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = locationTags.toTypedArray(),
                synonyms = locationSynonyms,
                country = locationCountry ?: throw IllegalStateException(),
                type = locationType.name,
                mapData = locationMarker?.let { m -> locationArea?.let { a -> MapData(m, a) } },
                parent = if (locationType != LocationType.REGION) locationParent else null
        )
        val result = if (coreId == null) Locations.create(location).await() else Locations.update(location).await()
//        confirmOnLeave = false
        Navigation.Locations.location(result.id, result.coreId)
    }

    private fun initEditorData() = async {
        locationTags.clear()
        if (coreId != null) {
            draft = Locations.getDraft(coreId).await()
            locationType = LocationType.valueOf(draft.type)
            locationCode = draft.code
            locationName = draft.name
            locationCountry = draft.country
            locationParent = draft.parent
            locationArea = draft.mapData?.let { Area(it.area.southWest, it.area.northEast) }
            locationMarker = draft.mapData?.center
            locationTags.addAll(draft.tags)
            locationSynonyms = draft.synonyms ?: ""
        } else {
            locationType = LocationType.REGION
            locationCode = ""
            locationName = ""
            locationCountry = null
            locationParent = null
            locationArea = null
            locationMarker = null
            locationSynonyms = ""
            locationCountryArea = null
            locationParentArea = null
        }
        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType(locationType.name.toLowerCase()).await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        validationAlert = null
        createPrivilegeObjectTypes = Accounts.getPrivilegeObjectTypes("CREATE").await()
        locationTypeParents = Locations.getParentTypes(locationType).await().data.map { LocationType.valueOf(it) }.toList()
//        confirmOnLeave = false
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (coreId == null) "Новый географический объект" else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Географические объекты") {
                        Navigation.Locations.locations()
                    }
                }
                div {
                    style.apply { display = "flex"; flexDirection = "row" }
                    div {
                        style.width = "600px"
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                if (coreId == null) {
                                    clrGroup("Тип", true) {
                                        val locationTypesAllowed = mutableListOf<LocationType>()
                                        listOf(LocationType.REGION, LocationType.TOWN, LocationType.DISTRICT).forEach {
                                            if (createPrivilegeObjectTypes.contains(it.name.toLowerCase())) locationTypesAllowed.add(it)
                                        }
                                        clrSelect<LocationType> {
                                            for (type in locationTypesAllowed) {
                                                clrOption(type.displayName, type, locationType == type)
                                            }
                                            onChangeFunction = { _ ->
                                                locationType = selectedOptions.first().value
                                                async {
                                                    val temp = externalMappings.filter { it.value.isNotBlank() }
                                                    externalMappings.clear()
                                                    External.getByObjectType(locationType.name.toLowerCase()).await().data.apply { sortBy { it.id } }.forEach { external ->
                                                        externalMappings[external] = temp[external] ?: ""
                                                    }
                                                    locationTypeParents = Locations.getParentTypes(locationType).await().data.map { LocationType.valueOf(it) }.toList()
                                                    locationParent = null
                                                    locationParentArea = null
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Код", true) {
                                    hidden = locationType != LocationType.REGION
                                    clrInput {
                                        value = locationCode
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateCode(value)
                                        }
                                        onChangeFunction = {
                                            locationCode = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        value = locationName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            locationName = value
                                            fillMapGeocodeInput()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Страна", true) {
                                    countryRenderBlocks = renderBlocks {
                                        blockIndex = if (coreId == null && locationCountry == null) 1 else 0

                                        renderBlock {
                                            span {
                                                appendText(locationCountry?.run { publishedName ?: draftName ?: "" }
                                                        ?: "Не выбрана")
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    countrySuggestionBox.focusInput()
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }

                                            countrySuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите страну"
                                                searchQuery = locationCountry?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getCountryCores(0, 10, query)
                                                }
                                                suggestionFieldFunction = {
                                                    it.run {
                                                        publishedName ?: draftName ?: "Без названия"
                                                    }
                                                }
                                                onSuggestionSelect = { entry ->
                                                    async {
                                                        if (coreId == null || locationArea == null) {
                                                            val countryId = entry.publishedVersionId
                                                                    ?: entry.draftVersionId
                                                            locationCountryArea = countryId?.let {
                                                                Countries.getById(it).await().area
                                                            }
                                                            locationArea = locationCountryArea
                                                            locationArea?.let {
                                                                googleMap.mapFitBounds(
                                                                        it.southWest.latitude,
                                                                        it.southWest.longitude,
                                                                        it.northEast.latitude,
                                                                        it.northEast.longitude
                                                                )
                                                            }
                                                        }
                                                        locationCountry = entry
                                                        locationParent = null
                                                        locationParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.countryAddDialog {
                                                        countryName = query
                                                        onSaveFunction = {
                                                            locationCountry = Core(
                                                                    id = it.coreId,
                                                                    code = it.code,
                                                                    objectType = it.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = it.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = it.name
                                                            )
                                                            locationCountryArea = null
                                                            locationParent = null
                                                            locationParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.countryAddDialog {
                                                        countryName = countrySuggestionBox.searchQuery
                                                        onSaveFunction = { newCountry ->
                                                            locationCountry = Core(
                                                                    id = newCountry.coreId,
                                                                    code = newCountry.code,
                                                                    objectType = newCountry.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newCountry.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newCountry.name
                                                            )
                                                            locationCountryArea = null
                                                            locationParent = null
                                                            locationParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Расположение") {
                                    hidden = locationCountry == null || locationTypeParents.isEmpty()

                                    parentRenderBlocks = renderBlocks {
                                        val parentText = locationParent?.let {
                                            val nameText = it.publishedName ?: it.draftName ?: "Удалено"
                                            if (it.objectSubtype != null) {
                                                val subtypeText = LocationType.valueOf(it.objectSubtype.toUpperCase()).displayName
                                                "$nameText ($subtypeText)"
                                            } else nameText
                                        } ?: "Не выбрано"
                                        renderBlock {
                                            span {
                                                appendText(parentText)
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    parentSuggestionBox.focusInput()
                                                }
                                            }
                                            if (locationParent != null) {
                                                clrButton {
                                                    style = ButtonStyle.Flat
                                                    isIcon = true
                                                    iconShape = IconShape.Trash
                                                    tooltipTitle = "Очистить"
                                                    onClickFunction = {
                                                        locationParent = null
                                                        locationParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }
                                            parentSuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите расположение"
                                                searchQuery = locationParent?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getParentCores(0, 10, query, locationCountry?.id
                                                            ?: 0, locationTypeParents)
                                                }
                                                suggestionFieldFunction = { selected ->
                                                    val version = selected.published ?: selected.draft
                                                    version?.let {
                                                        val typeName = LocationType.valueOf(version.type).displayName
                                                        "${it.name} ($typeName)"
                                                    } ?: "Удалено"
                                                }
                                                onSuggestionSelect = { entry ->
                                                    locationParent = Core(
                                                            id = entry.id,
                                                            code = entry.code,
                                                            objectType = entry.objectType,
                                                            objectSubtype = entry.draft?.type?.toLowerCase(),
                                                            publishedVersionId = entry.published?.id,
                                                            draftVersionId = entry.draft?.id,
                                                            publishedName = entry.published?.name,
                                                            draftName = entry.draft?.name
                                                    )
                                                    if (coreId == null || locationArea == null) {
                                                        val parentVersion = entry.published ?: entry.draft
                                                        parentVersion?.mapData?.let {
                                                            locationParentArea = it.area
                                                        } ?: run {
                                                            locationParentArea = null
                                                        }
                                                        locationArea = locationParentArea
                                                        locationArea?.let {
                                                            googleMap.mapFitBounds(
                                                                    it.southWest.latitude,
                                                                    it.southWest.longitude,
                                                                    it.northEast.latitude,
                                                                    it.northEast.longitude
                                                            )
                                                        }
                                                    }
                                                    fillMapGeocodeInput()
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.locationParentAddDialog {
                                                        locationName = query
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = locationCountry
                                                        onSaveFunction = {
                                                            locationParent = Core(
                                                                    id = it.coreId,
                                                                    code = it.code,
                                                                    objectType = it.objectType,
                                                                    objectSubtype = it.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = it.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = it.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.locationParentAddDialog {
                                                        locationName = parentSuggestionBox.searchQuery
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = locationCountry
                                                        onSaveFunction = { newParent ->
                                                            locationParent = Core(
                                                                    id = newParent.coreId,
                                                                    code = newParent.code,
                                                                    objectType = newParent.objectType,
                                                                    objectSubtype = newParent.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newParent.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newParent.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (locationTags.size > 0) {
                                        locationTags.sortBy { it.name.toLowerCase() }
                                        locationTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    locationTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (locationTags.none { it.id == entry.id }) {
                                                locationTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                locationTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = locationSynonyms
                                        onChangeFunction = {
                                            locationSynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.Locations.location(draft.id, draft.coreId)
                                else Navigation.Locations.locations()
                            }
                        }
                    }
                    div {
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                        googleMap = googleMap("100%", "500px") {
                            markerMode = GoogleMapMarkerMode.Marker
                            markerCanMove = true
                            geocodeEnabled = true

                            locationArea?.let {
                                mapBounds(it.southWest.latitude, it.southWest.longitude, it.northEast.latitude, it.northEast.longitude)
                            } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                            locationMarker?.let {
                                mapMarker(it.latitude, it.longitude)
                            }

                            onChangeFunction = {
                                locationArea = bounds?.let {
                                    Area(Point(it.southWest.latitude, it.southWest.longitude), Point(it.northEast.latitude, it.northEast.longitude))
                                }
                                locationMarker = marker?.let { Point(it.latitude, it.longitude) }
                                enableSaveButton()
                            }
                            onPostRender = {
                                mapFitControllerBounds()
                                fillMapGeocodeInput()
                            }
                        }
                    }
                }
            }
        }
    }
}
