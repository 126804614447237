package ru.playa.sce.views.hotels

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.countries.countryAddDialog
import ru.playa.sce.views.dictionaries.dictionaryEntryAddDialog
import ru.playa.sce.views.locations.locationParentAddDialog
import kotlin.dom.appendText

class HotelEditorView(private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "hotels/editor"
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/

    private lateinit var draft: Hotel
    private lateinit var locationTypeParents: List<LocationType>
    private lateinit var dictionaryHotelCategories: Array<DictionaryEntry>
    private lateinit var dictionaryHotelTypes: Array<DictionaryEntry>

    private var hotelName = ""
    private var hotelCountry: Core? = null
    private var hotelParent: Core? = null
    private var hotelCategory: DictionaryEntry? = null
    private var hotelAllocationType: DictionaryEntry? = null
    private var hotelArea: Area? = null
    private var hotelMarker: Point? = null
    private val hotelTags = mutableListOf<Option>()
    private var hotelSynonyms = ""
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()

    private var hotelCountryArea: Area? = null
    private var hotelParentArea: Area? = null

    private lateinit var countryRenderBlocks: RenderBlocks
    private lateinit var countrySuggestionBox: SuggestionBox<Core>
    private lateinit var parentRenderBlocks: RenderBlocks
    private lateinit var parentSuggestionBox: SuggestionBox<CoreSearch>
    private lateinit var googleMap: GoogleMap

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun fillMapGeocodeInput() = googleMap.fillGeocodeInput(
            hotelName,
            hotelCountry?.publishedName ?: hotelCountry?.draftName,
            hotelParent?.publishedName ?: hotelParent?.draftName,
            hotelCategory?.name
    )

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)
    private fun validateMapData(point: Point?, area: Area?) = if (point != null && area != null) area.contains(point) else point == null && area == null
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex(".{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(hotelName)) validationErrors.add("Указано некорректное наименование отеля")
        if (hotelCountry == null) validationErrors.add("Не указано обязательное поле \"Страна\"")
        if (hotelCategory == null) validationErrors.add("Не указано обязательное поле \"Категория отеля\"")
        if (hotelAllocationType == null) validationErrors.add("Не указано обязательное поле \"Тип размещения\"")
        if (!validateMapData(hotelMarker, hotelArea)) validationErrors.add("Указано некорректное взаимное расположение маркера и области на карте")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (!validateSynonyms(hotelSynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
        if (countryRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Страны (выбрать, добавить, отменить)")
            countrySuggestionBox.invalidateInput()
        }
        if (parentRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Расположения (выбрать, добавить, отменить)")
            parentSuggestionBox.invalidateInput()
        }
    }

    override fun saveEditorData() = async {
        val hotel = Hotel(
                id = if (coreId != null) draft.id else 0,
                code = if (coreId != null) draft.code else "",
                name = hotelName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = hotelTags.toTypedArray(),
                synonyms = hotelSynonyms,
                country = hotelCountry ?: throw IllegalStateException(),
                mapData = hotelMarker?.let { m -> hotelArea?.let { a -> MapData(m, a) } },
                parent = hotelParent,
                category = hotelCategory,
                hotelType = hotelAllocationType
        )
        val result = if (coreId == null) Hotels.create(hotel).await() else Hotels.update(hotel).await()
//        confirmOnLeave = false
        Navigation.Hotels.hotel(result.id, result.coreId)
    }

    private fun initEditorData() = async {
        hotelTags.clear()
        if (coreId != null) {
            draft = Hotels.getDraft(coreId).await()
            hotelName = draft.name
            hotelCountry = draft.country
            hotelParent = draft.parent
            hotelCategory = draft.category
            hotelAllocationType = draft.hotelType
            hotelArea = draft.mapData?.let { Area(it.area.southWest, it.area.northEast) }
            hotelMarker = draft.mapData?.center
            hotelTags.addAll(draft.tags)
            hotelSynonyms = draft.synonyms ?: ""
        } else {
            hotelName = ""
            hotelCountry = null
            hotelParent = null
            hotelCategory = null
            hotelAllocationType = null
            hotelArea = null
            hotelMarker = null
            hotelSynonyms = ""
            hotelCountryArea = null
            hotelParentArea = null
        }
        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType("hotel").await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        dictionaryHotelCategories = Dictionaries.getDictionaryEntries("category", 0, 500, "").await().data.apply { sortBy { it.rating } }
        dictionaryHotelTypes = Dictionaries.getDictionaryEntries("hoteltype", 0, 500, "").await().data
        locationTypeParents = Locations.getParentTypes(LocationType.HOTEL).await().data.map { LocationType.valueOf(it) }.toList()
        validationAlert = null
//        confirmOnLeave = false
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (coreId == null) "Новый отель" else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Отели") {
                        Navigation.Hotels.hotels()
                    }
                }
                div {
                    style.apply { display = "flex"; flexDirection = "row" }
                    div {
                        style.width = "600px"
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        width = "100%"
                                        value = hotelName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            hotelName = value
                                            fillMapGeocodeInput()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Страна", true) {
                                    countryRenderBlocks = renderBlocks {
                                        blockIndex = if (coreId == null && hotelCountry == null) 1 else 0

                                        renderBlock {
                                            span {
                                                appendText(hotelCountry?.run { publishedName ?: draftName ?: "" }
                                                        ?: "Не выбрана")
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    countrySuggestionBox.focusInput()
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }

                                            countrySuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите страну"
                                                searchQuery = hotelCountry?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getCountryCores(0, 10, query)
                                                }
                                                suggestionFieldFunction = {
                                                    it.run {
                                                        publishedName ?: draftName ?: "Без названия"
                                                    }
                                                }
                                                onSuggestionSelect = { entry ->
                                                    async {
                                                        if (coreId == null || hotelArea == null) {
                                                            val countryId = entry.publishedVersionId
                                                                    ?: entry.draftVersionId
                                                            hotelCountryArea = countryId?.let {
                                                                Countries.getById(it).await().area
                                                            }
                                                            hotelArea = hotelCountryArea
                                                            hotelArea?.let {
                                                                googleMap.mapFitBounds(
                                                                        it.southWest.latitude,
                                                                        it.southWest.longitude,
                                                                        it.northEast.latitude,
                                                                        it.northEast.longitude
                                                                )
                                                            }
                                                        }
                                                        hotelCountry = entry
                                                        hotelParent = null
                                                        hotelParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.countryAddDialog {
                                                        countryName = query
                                                        onSaveFunction = { newCountry ->
                                                            hotelCountry = Core(
                                                                    id = newCountry.coreId,
                                                                    code = newCountry.code,
                                                                    objectType = newCountry.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newCountry.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newCountry.name
                                                            )
                                                            hotelCountryArea = null
                                                            hotelParent = null
                                                            hotelParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.countryAddDialog {
                                                        countryName = countrySuggestionBox.searchQuery
                                                        onSaveFunction = { newCountry ->
                                                            hotelCountry = Core(
                                                                    id = newCountry.coreId,
                                                                    code = newCountry.code,
                                                                    objectType = newCountry.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newCountry.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newCountry.name
                                                            )
                                                            hotelCountryArea = null
                                                            hotelParent = null
                                                            hotelParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Расположение") {
                                    hidden = hotelCountry == null || locationTypeParents.isEmpty()

                                    parentRenderBlocks = renderBlocks {
                                        val parentText = hotelParent?.let {
                                            val nameText = it.publishedName ?: it.draftName ?: "Удалено"
                                            if (it.objectSubtype != null) {
                                                val subtypeText = LocationType.valueOf(it.objectSubtype.toUpperCase()).displayName
                                                "$nameText ($subtypeText)"
                                            } else nameText
                                        } ?: "Не выбрано"

                                        renderBlock {
                                            span {
                                                appendText(parentText)
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    parentSuggestionBox.focusInput()
                                                }
                                            }
                                            if (hotelParent != null) {
                                                clrButton {
                                                    style = ButtonStyle.Flat
                                                    isIcon = true
                                                    iconShape = IconShape.Trash
                                                    tooltipTitle = "Очистить"
                                                    onClickFunction = {
                                                        hotelParent = null
                                                        hotelParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }
                                            parentSuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите расположение"
                                                searchQuery = hotelParent?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getParentCores(0, 10, query, hotelCountry?.id
                                                            ?: 0, locationTypeParents)
                                                }
                                                suggestionFieldFunction = { selected ->
                                                    val version = selected.published ?: selected.draft
                                                    version?.let {
                                                        val typeName = LocationType.valueOf(version.type).displayName
                                                        "${it.name} ($typeName)"
                                                    } ?: "Удалено"
                                                }
                                                onSuggestionSelect = { entry ->
                                                    hotelParent = Core(
                                                            id = entry.id,
                                                            code = entry.code,
                                                            objectType = entry.objectType,
                                                            objectSubtype = entry.draft?.type?.toLowerCase(),
                                                            publishedVersionId = entry.published?.id,
                                                            draftVersionId = entry.draft?.id,
                                                            publishedName = entry.published?.name,
                                                            draftName = entry.draft?.name
                                                    )
                                                    if (coreId == null || hotelArea == null) {
                                                        val parentVersion = entry.published ?: entry.draft
                                                        parentVersion?.mapData?.let {
                                                            hotelParentArea = it.area
                                                        } ?: run {
                                                            hotelParentArea = null
                                                        }
                                                        hotelArea = hotelParentArea
                                                        hotelArea?.let {
                                                            googleMap.mapFitBounds(
                                                                    it.southWest.latitude,
                                                                    it.southWest.longitude,
                                                                    it.northEast.latitude,
                                                                    it.northEast.longitude
                                                            )
                                                        }
                                                    }
                                                    fillMapGeocodeInput()
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.locationParentAddDialog {
                                                        locationName = query
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = hotelCountry
                                                        onSaveFunction = {
                                                            hotelParent = Core(
                                                                    id = it.coreId,
                                                                    code = it.code,
                                                                    objectType = it.objectType,
                                                                    objectSubtype = it.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = it.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = it.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.locationParentAddDialog {
                                                        locationName = parentSuggestionBox.searchQuery
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = hotelCountry
                                                        onSaveFunction = { newParent ->
                                                            hotelParent = Core(
                                                                    id = newParent.coreId,
                                                                    code = newParent.code,
                                                                    objectType = newParent.objectType,
                                                                    objectSubtype = newParent.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newParent.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newParent.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Категория отеля", true) {
                                    clrSelect<DictionaryEntry?> {
                                        clrOption("Не выбрана", null)
                                        dictionaryHotelCategories.forEach {
                                            clrOption(it.name, it, hotelCategory?.id == it.id)
                                        }
                                        onChangeFunction = {
                                            hotelCategory = selectedOptions.first().value
                                            fillMapGeocodeInput()
                                            enableSaveButton()
                                        }
                                    }

                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = {
                                            dom.dictionaryEntryAddDialog("category") {
                                                headerTitle = "Новая категория отелей"
                                                onSaveFunction = { newCategory ->
                                                    async {
                                                        hotelCategory = newCategory
                                                        dictionaryHotelCategories = Dictionaries.getDictionaryEntries("category", 0, 500, "").await().data
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Тип размещения", true) {
                                    clrSelect<DictionaryEntry?> {
                                        clrOption("Не выбран", null)
                                        dictionaryHotelTypes.forEach {
                                            clrOption(it.name, it, hotelAllocationType?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            hotelAllocationType = selectedOptions.first().value
                                            enableSaveButton()
                                        }
                                    }

                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = {
                                            dom.dictionaryEntryAddDialog("hoteltype") {
                                                headerTitle = "Новый тип размещения"
                                                onSaveFunction = { newHotelType ->
                                                    async {
                                                        hotelAllocationType = newHotelType
                                                        dictionaryHotelTypes = Dictionaries.getDictionaryEntries("hoteltype", 0, 500, "").await().data
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (hotelTags.size > 0) {
                                        hotelTags.sortBy { it.name.toLowerCase() }
                                        hotelTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    hotelTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (hotelTags.none { it.id == entry.id }) {
                                                hotelTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                hotelTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = hotelSynonyms
                                        onChangeFunction = {
                                            hotelSynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.Hotels.hotel(draft.id, draft.coreId)
                                else Navigation.Hotels.hotels()
                            }
                        }
                    }
                    div {
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                        googleMap = googleMap("100%", "500px") {
                            markerMode = GoogleMapMarkerMode.Marker
                            markerCanMove = true
                            geocodeEnabled = true
                            coordinatesEnabled = true

                            hotelArea?.let {
                                mapBounds(it.southWest.latitude, it.southWest.longitude, it.northEast.latitude, it.northEast.longitude)
                            } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                            hotelMarker?.let {
                                mapMarker(it.latitude, it.longitude)
                            }

                            onChangeFunction = {
                                hotelArea = bounds?.let {
                                    Area(Point(it.southWest.latitude, it.southWest.longitude), Point(it.northEast.latitude, it.northEast.longitude))
                                }
                                hotelMarker = marker?.let { Point(it.latitude, it.longitude) }
                                enableSaveButton()
                            }
                            onPostRender = {
                                mapFitControllerBounds()
                                fillMapGeocodeInput()
                            }
                        }
                    }
                }
            }
        }
    }
}