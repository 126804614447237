package ru.playa.sce.core

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import redux.Action
import redux.Redux.createStore
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.img
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.keycloak.js.Auth
import ru.playa.kotlinx.route.js.Router
import ru.playa.sce.api.Accounts
import ru.playa.sce.components.CKEditorExtension
import ru.playa.sce.dto.Account
import ru.playa.sce.dto.SCEUser
import ru.playa.sce.views.app.PageNotFoundView
import ru.playa.sce.views.countries.CountriesView
import ru.playa.sce.views.countries.CountryEditorView
import ru.playa.sce.views.countries.CountryView
import ru.playa.sce.views.descriptionEditor.DescriptionEditorView
import ru.playa.sce.views.descriptionTypes.DescriptionTypeCreatorView
import ru.playa.sce.views.descriptionTypes.DescriptionTypesView
import ru.playa.sce.views.dictionaries.DictionariesView
import ru.playa.sce.views.dictionaries.DictionaryEntriesView
import ru.playa.sce.views.dictionaries.DictionaryEntryCreatorView
import ru.playa.sce.views.excursion.ExcursionEditorView
import ru.playa.sce.views.excursion.ExcursionView
import ru.playa.sce.views.excursion.ExcursionsView
import ru.playa.sce.views.externalSystems.ExternalSystemCreatorView
import ru.playa.sce.views.externalSystems.ExternalSystemsView
import ru.playa.sce.views.hotels.HotelEditorView
import ru.playa.sce.views.hotels.HotelView
import ru.playa.sce.views.hotels.HotelsView
import ru.playa.sce.views.imageSettings.ImageSettingsView
import ru.playa.sce.views.locations.LocationEditorView
import ru.playa.sce.views.locations.LocationView
import ru.playa.sce.views.locations.LocationsView
import ru.playa.sce.views.start.StartView
import ru.playa.sce.views.textBlocks.TextBlockEditorView
import ru.playa.sce.views.textBlocks.TextBlockView
import ru.playa.sce.views.textBlocks.TextBlocksView
import ru.playa.sce.views.tourPackets.TourPacketEditorView
import ru.playa.sce.views.tourPackets.TourPacketView
import ru.playa.sce.views.tourPackets.TourPacketsView
import ru.playa.sce.views.tourismObjects.TourismObjectEditorView
import ru.playa.sce.views.tourismObjects.TourismObjectView
import ru.playa.sce.views.tourismObjects.TourismObjectsView
import ru.playa.sce.views.tours.TourEditorView
import ru.playa.sce.views.tours.TourPointEditorView
import ru.playa.sce.views.tours.TourView
import ru.playa.sce.views.tours.ToursView
import ru.playa.sce.views.usersRoles.RoleEditorView
import ru.playa.sce.views.usersRoles.UserRolesEditorView
import ru.playa.sce.views.usersRoles.UsersRolesView
import ru.playa.sce.views.visibilityAreas.VisibilityAreaCreatorView
import ru.playa.sce.views.visibilityAreas.VisibilityAreasView
import kotlin.browser.document
import kotlin.browser.window
import kotlin.dom.appendText

object Application {

    lateinit var user: SCEUser
    lateinit var account: Account
    var userIsOwner = false

    lateinit var loadingIndicator: Spinner
    lateinit var applicationLayout: ApplicationLayout
    lateinit var root: HTMLElement


    //private val reduxMapping: HashMap<String,ArrayList<(AppState, Action<Any>)->Int>> = hashMapOf()

    /*fun subscribe(actionName: String,actionHandler: (AppState, Action<Any>)->Int){
        if(reduxMapping.keys.contains(actionName))
            reduxMapping[actionName]?.add(actionHandler)
        else {
            val handlers = arrayListOf<(AppState, Action<Any>)->Int>()
            handlers.add(actionHandler)
            reduxMapping[actionName] = handlers
        }
    }*/

    /*fun unsubscribe(actionName: String,actionHandler: (AppState, Action<Any>)->Int){
        reduxMapping[actionName]?.remove(actionHandler)
    }*/

    /*fun unsubscribeAllHandlers(actionName: String){
        reduxMapping[actionName]?.clear()
    }*/

    class AppState {
        val data: HashMap<String, Any> = hashMapOf()
    }

    val reduxStore = createStore<AppState, Action<Any>>({ state, action ->
        //        if(reduxMapping.keys.contains(action.type)){
//            reduxMapping[action.type]?.forEach {
//                it.invoke(state,action)
//            }
//            state
//        }
        state.apply { this.data[action.type.toString()] = action.value }
        state
    }, AppState())

    fun run() {
        initLoadingIndicator()
        window.location.href.let { if (!it.contains("#state=") && it.contains("#")) Router.storeHash() }
        Auth.timeout = 30
        Auth.onLoginFunction = {
            async {
                user = Accounts.getSCEUser().await()
                account = Accounts.getCurrentAccount().await()
                userIsOwner = user.account.id == account.id

                initApplicationLayout()
                if (document.cookie.contains("isFirst=true")) {
                    applicationLayout.topLevelAlert.showLimitedTime {
                        clrAlertItem("Вы успешно вошли в систему.")
                        style = Alert.Style.SUCCESS
                    }
                }

                initRouter()
                if (window.location.hash.isEmpty()) {
                    Router.restoreHash()
                } else {
                    Router.setView()
                }
            }
        }
        Auth.login()
    }

    private fun initLoadingIndicator() {
        val body = document.body ?: throw IllegalStateException("Can't find \"body\" element")
        loadingIndicator = body.clrSpinner().apply {
            onPostRender = {
                this.getHTMLElement().apply {
                    style.position = "absolute"
                    style.top = "50%"
                    style.left = "50%"
                    style.zIndex = "9999999"
                }
                this.hide()
            }
        }
        loadingIndicator.hide()
    }

    private fun initApplicationLayout() {
        root = document.getElementById("root") as? HTMLElement
                ?: throw IllegalStateException("Can't find \"root\" element")
        applicationLayout = root.clrApplicationLayout()

        //Init CKEPlugins
        CKEditorExtension.init()

        applicationLayout.subNav.itemsColor = "#000000"
        applicationLayout.subNav.render()

        applicationLayout.header.apply {
            Accounts.getAll().then { accounts ->
                style = Header.Style.HeaderFour
                clrContent {
                    async {
                        clrHeaderBranding {
                            div {
                                img {
                                    src = "logo.svg"
                                    style.height = "100%"
                                    style.alignSelf = "center"
                                }
                                style.paddingBottom = "10px"
                                style.paddingTop = "10px"
                            }
                            style.cursor = "pointer"
                            this.onclick = {
                                applicationLayout.subNav.apply {
                                    items.clear()
                                    this.getHTMLElement().style.opacity = "0"
                                    render()
                                }
                                Navigation.start()
                            }
                        }
                        clrHeaderActions {
                            style.justifyContent = "center"
                            style.alignItems = "center"
                            style.fontSize = "14px"
                            when {
                                accounts.size > 1 -> {
                                    clrSelect<Int> {
                                        selectStyle = "font-family: OpenSans; font-size: 100%; color: #fafafa; opacity: 0.65;"
                                        accounts.forEach { acc ->
                                            clrOption(acc.name, acc.id, account.id == acc.id)
                                        }

                                        onChangeFunction = {
                                            async {
                                                if (Accounts.changeActiveAccount(selectedOptions.first().value).await().id == selectedOptions.first().value) {
                                                    Navigation.start()
                                                    window.location.reload()
                                                }
                                            }
                                        }
                                    }
                                }
                                accounts.size == 1 && user.account.id == accounts[0].id -> {
                                    div {
                                        style.color = "#fafafa"
                                        style.opacity = "0.65"
                                        style.fontFamily = "OpenSans"
                                        style.fontSize = "100%"
                                        appendText(accounts[0].name)
                                    }
                                }
                            }
                        }
                        clrHeaderNavigation {
                            style.fontSize = "14px"
                            clrHeaderNavLinkText("Справочники") {
                                applicationLayout.subNav.apply {
                                    items.clear()
                                    render()
                                }
                                Navigation.Dictionaries.dictionaries()
                            }
                            clrHeaderNavLinkText("География") {
                                applicationLayout.subNav.apply {
                                    items.clear()
                                    this.getHTMLElement().style.opacity = "0"
                                    clrSubNavItem("Страны") {
                                        Navigation.Countries.countries()
                                    }
                                    clrSubNavItem("Географические объекты") {
                                        Navigation.Locations.locations()
                                    }
                                    render()
                                }
                            }
                            clrHeaderNavLinkText("Туризм") {
                                applicationLayout.subNav.apply {
                                    items.clear()
                                    this.getHTMLElement().style.opacity = "0"
                                    clrSubNavItem("Отели") {
                                        Navigation.Hotels.hotels()
                                    }
                                    clrSubNavItem("Экскурсии") {
                                        Navigation.Excursions.excursions()
                                    }
                                    clrSubNavItem("Туры") {
                                        Navigation.Tours.tours()
                                    }
                                    clrSubNavItem("Турпакеты") {
                                        Navigation.TourPackets.tourPackets()
                                    }
                                    clrSubNavItem("Достопримечательности") {
                                        Navigation.TourismObjects.tourismObjects("LANDMARK")
                                    }
                                    clrSubNavItem("Аэропорты") {
                                        Navigation.TourismObjects.tourismObjects("AIRPORT")
                                    }
                                    clrSubNavItem("Порты") {
                                        Navigation.TourismObjects.tourismObjects("PORT")
                                    }
                                    render()
                                }
                            }
                            clrHeaderNavLinkText("Контент") {
                                applicationLayout.subNav.apply {
                                    items.clear()
                                    render()
                                }
                                Navigation.TextBlocks.textBlocks()
                            }
                            if (userIsOwner) {
                                clrHeaderNavLinkText("Настройки") {
                                    applicationLayout.subNav.apply {
                                        items.clear()
                                        clrSubNavItem("Области видимости") {
                                            Navigation.VisibilityAreas.visibilityAreas()
                                        }
                                        clrSubNavItem("Типы описаний") {
                                            Navigation.DescriptionTypes.descriptionTypes()
                                        }
                                        clrSubNavItem("Внешние справочники") {
                                            Navigation.ExternalSystems.externalSystems()
                                        }
                                        clrSubNavItem("Параметры изображений") {
                                            Navigation.ImageSettings.imageSettings()
                                        }
                                        clrSubNavItem("Пользователи и роли") {
                                            Navigation.UsersRoles.usersRoles()
                                        }
                                        render()
                                    }
                                }
                            }
                        }
                        clrHeaderActions {
                            clrHeaderDivider()
                            clrHeaderNavLinkIcon(IconShape.User) {
                                applicationLayout.subNav.apply {
                                    navStyle = "justify-content: flex-end;"
                                    items.clear()
                                    clrSubNavItem("Выход") {
                                        Auth.logout()
                                    }
                                    render()
                                    navStyle = ""
                                }
                            }
                            clrHeaderNavLinkIcon(IconShape.Bell) {
                                TODO()
                            }
                        }
                    }
                }
                render()
            }
        }
    }

    private fun initRouter() {
        Router.contentRoot = applicationLayout.contentArea
        Router.defaultHash = "#start"
        Router.createViewFunction = { path, params ->
            when (path) {
                StartView.PATH -> StartView()
                CountriesView.PATH -> CountriesView()
                CountryView.PATH -> {
                    try {
                        CountryView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                ExcursionView.PATH -> {
                    try {
                        ExcursionView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                ExcursionsView.PATH -> ExcursionsView()
                ExcursionEditorView.PATH -> ExcursionEditorView(params["coreId"]?.toInt())
                CountryEditorView.PATH -> CountryEditorView(params["coreId"]?.toInt())
                DescriptionTypesView.PATH -> DescriptionTypesView()
                DescriptionTypeCreatorView.PATH -> DescriptionTypeCreatorView()
                ExternalSystemsView.PATH -> ExternalSystemsView()
                ExternalSystemCreatorView.PATH -> ExternalSystemCreatorView()
                VisibilityAreasView.PATH -> VisibilityAreasView()
                VisibilityAreaCreatorView.PATH -> VisibilityAreaCreatorView()
                DictionaryEntriesView.PATH -> {
                    try {
                        DictionaryEntriesView(params["code"] ?: throw Exception())
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                DictionaryEntryCreatorView.PATH -> {
                    try {
                        DictionaryEntryCreatorView(params["code"] ?: throw Exception())
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                DictionariesView.PATH -> DictionariesView()
                LocationView.PATH -> {
                    try {
                        LocationView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                LocationsView.PATH -> LocationsView()
                LocationEditorView.PATH -> LocationEditorView(params["coreId"]?.toInt())
                TourismObjectsView.PATH -> {
                    try {
                        TourismObjectsView(params["locationType"] ?: throw Exception())
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                TourismObjectView.PATH -> {
                    try {
                        TourismObjectView(
                                params["locationType"] ?: throw Exception(),
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                TourismObjectEditorView.PATH -> {
                    try {
                        TourismObjectEditorView(
                                params["locationType"] ?: throw Exception(),
                                params["coreId"]?.toInt()
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                UsersRolesView.PATH -> UsersRolesView()
                UserRolesEditorView.PATH -> {
                    try {
                        UserRolesEditorView(params["email"] ?: throw Exception())
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                RoleEditorView.PATH -> RoleEditorView(params["id"]?.toInt())
                HotelView.PATH -> {
                    try {
                        HotelView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                HotelsView.PATH -> HotelsView()
                HotelEditorView.PATH -> HotelEditorView(params["coreId"]?.toInt())
                TourView.PATH -> {
                    try {
                        TourView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                ToursView.PATH -> ToursView()
                TourEditorView.PATH -> TourEditorView(params["coreId"]?.toInt())
                TourPointEditorView.PATH -> {
                    try {
                        TourPointEditorView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["tourPointId"]?.toInt()
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                TourPacketView.PATH -> {
                    try {
                        TourPacketView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                TourPacketsView.PATH -> TourPacketsView()
                TourPacketEditorView.PATH -> TourPacketEditorView(params["coreId"]?.toInt())
                TextBlockView.PATH -> {
                    try {
                        TextBlockView(
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["initialTab"] ?: "main"
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                TextBlocksView.PATH -> TextBlocksView()
                TextBlockEditorView.PATH -> TextBlockEditorView(
                        params["coreId"]?.toInt(),
                        params["objectType"],
                        params["objectId"]?.toInt(),
                        params["objectCoreId"]?.toInt(),
                        params["textType"],
                        params["objectName"]
                )
                DescriptionEditorView.PATH -> {
                    try {
                        DescriptionEditorView(
                                params["objectType"] ?: throw Exception(),
                                params["id"]?.toInt() ?: throw Exception(),
                                params["coreId"]?.toInt() ?: throw Exception(),
                                params["descriptionId"]?.toInt()
                        )
                    } catch (e: Exception) {
                        PageNotFoundView()
                    }
                }
                ImageSettingsView.PATH -> ImageSettingsView()
                else -> PageNotFoundView()
            }
        }
    }
}
