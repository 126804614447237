package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.ObjectStatus
import ru.playa.sce.dto.Text
import ru.playa.sce.dto.TextType

object TextBlocks {

    fun get(
            start: Int = 0,
            pageSize: Int = 100,
            query: String = "",
            textType: TextType? = null,
            startDate: String = "",
            finishDate: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            coreId: Int? = null,
            blockCode: String? = null,
            includeParents: Boolean = false,
            includeCountry: Boolean = false
    ) = Network.request<ListResult<Text>>(Network.Method.GET,
            "/sce-web/api/public/objects/text/search?s=$start&p=$pageSize&includeParents=$includeParents&ic=$includeCountry&q=$query${if (textType != null) "&t=$textType" else ""}${if (startDate.isNotBlank()) "&sd=$startDate" else ""}${if (finishDate.isNotBlank()) "&fd=$finishDate" else ""}${objectStatus.joinToString("") { "&os=$it" }}${if (coreId != null) "&c=$coreId" else ""}${if (blockCode != null) "&bc=$blockCode" else ""}")

    fun getById(id: Int) = Network.request<Text>(Network.Method.GET, "/sce-web/api/user/objects/text/$id")

    fun getDraft(coreId: Int) = Network.request<Text>(Network.Method.GET, "/sce-web/api/user/objects/text/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<Text>(Network.Method.GET,
            "/sce-web/api/user/objects/text/draft/$coreId?version=$version&archive=$archive")

    fun create(text: Text) = Network.send(Network.Method.POST, text, "/sce-web/api/user/objects/text")

    fun update(text: Text) = Network.send(Network.Method.PUT, text, "/sce-web/api/user/objects/text")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/text/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/text/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/text/publish/$id")
}