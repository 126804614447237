package ru.playa.sce.views.tourismObjects

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.countries.countryAddDialog
import ru.playa.sce.views.locations.locationParentAddDialog
import kotlin.dom.appendText

class TourismObjectEditorView(private val locationTypeName: String, private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "tourismObjects/editor"
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/


    private lateinit var draft: Location
    private lateinit var locationTypeParents: List<LocationType>

    private val locationType = LocationType.valueOf(locationTypeName)
    private var tourismObjectCode = ""
    private var tourismObjectName = ""
    private var tourismObjectCountry: Core? = null
    private var tourismObjectParent: Core? = null
    private var tourismObjectArea: Area? = null
    private var tourismObjectMarker: Point? = null
    private val tourismObjectTags = mutableListOf<Option>()
    private var tourismObjectSynonyms = ""
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()

    private var tourismObjectCountryArea: Area? = null
    private var tourismObjectParentArea: Area? = null

    private lateinit var countryRenderBlocks: RenderBlocks
    private lateinit var countrySuggestionBox: SuggestionBox<Core>
    private lateinit var parentRenderBlocks: RenderBlocks
    private lateinit var parentSuggestionBox: SuggestionBox<CoreSearch>
    private lateinit var googleMap: GoogleMap

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun fillMapGeocodeInput() = googleMap.fillGeocodeInput(
            tourismObjectName,
            tourismObjectCountry?.publishedName ?: tourismObjectCountry?.draftName,
            tourismObjectParent?.publishedName ?: tourismObjectParent?.draftName
    )

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)
    private fun validateMapData(point: Point?, area: Area?) = if (point != null && area != null) area.contains(point) else point == null && area == null
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex(".{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(tourismObjectName)) validationErrors.add("Указано некорректное наименование")
        if (tourismObjectCountry == null) validationErrors.add("Не указано обязательное поле \"Страна\"")
        if (!validateMapData(tourismObjectMarker, tourismObjectArea)) validationErrors.add("Указано некорректное взаимное расположение маркера и области на карте")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (!validateSynonyms(tourismObjectSynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
        if (countryRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Страны (выбрать, добавить, отменить)")
            countrySuggestionBox.invalidateInput()
        }
        if (parentRenderBlocks.blockIndex == 1) {
            validationErrors.add("Необходимо завершить редактирование Расположения (выбрать, добавить, отменить)")
            parentSuggestionBox.invalidateInput()
        }
    }

    override fun saveEditorData() = async {
        val location = Location(
                id = if (coreId != null) draft.id else 0,
                code = if (locationType == LocationType.AIRPORT) tourismObjectCode else if (coreId != null) draft.code else "",
                name = tourismObjectName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = tourismObjectTags.toTypedArray(),
                synonyms = tourismObjectSynonyms,
                country = tourismObjectCountry ?: throw IllegalStateException(),
                type = locationTypeName,
                mapData = tourismObjectMarker?.let { m -> tourismObjectArea?.let { a -> MapData(m, a) } },
                parent = tourismObjectParent
        )
        val result = if (coreId == null) Locations.create(location).await() else Locations.update(location).await()
//        confirmOnLeave = false
        Navigation.TourismObjects.tourismObject(locationTypeName, result.id, result.coreId)
    }

    private fun initEditorData() = async {
        tourismObjectTags.clear()
        if (coreId != null) {
            draft = Locations.getDraft(coreId).await()
            tourismObjectCode = draft.code
            tourismObjectName = draft.name
            tourismObjectCountry = draft.country
            tourismObjectArea = draft.mapData?.let { Area(it.area.southWest, it.area.northEast) }
            tourismObjectMarker = draft.mapData?.center
            tourismObjectParent = draft.parent
            tourismObjectSynonyms = draft.synonyms ?: ""
            tourismObjectTags.addAll(draft.tags)
        } else {
            tourismObjectCode = ""
            tourismObjectName = ""
            tourismObjectCountry = null
            tourismObjectArea = null
            tourismObjectMarker = null
            tourismObjectParent = null
            tourismObjectSynonyms = ""

            tourismObjectCountryArea = null
            tourismObjectParentArea = null
        }
        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType(locationType.name.toLowerCase()).await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        validationAlert = null
        locationTypeParents = Locations.getParentTypes(locationType).await().data.map { LocationType.valueOf(it) }.toList()
        //        confirmOnLeave = false
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                val displayName = locationType.displayName.toLowerCase()
                clrPageHeader(if (coreId == null) {
                    "${if (displayName == "достопримечательность") "Новая" else "Новый"} $displayName"
                } else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink(locationType.pluralName) {
                        Navigation.TourismObjects.tourismObjects(locationTypeName)
                    }
                }
                div {
                    style.apply { display = "flex"; flexDirection = "row" }
                    div {
                        style.width = "600px"
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                clrGroup("Код", true) {
                                    hidden = locationType != LocationType.AIRPORT

                                    clrInput {
                                        value = tourismObjectCode
                                        onChangeFunction = {
                                            value = value.toUpperCase()
                                            value = translitToLat(value)
                                            tourismObjectCode = value
                                            this@clrInput.render()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Наименование", true) {
                                    clrInput {
                                        width = "100%"
                                        value = tourismObjectName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            tourismObjectName = value
                                            fillMapGeocodeInput()
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Страна", true) {
                                    countryRenderBlocks = renderBlocks {
                                        blockIndex = if (coreId == null && tourismObjectCountry == null) 1 else 0

                                        renderBlock {
                                            span {
                                                appendText(tourismObjectCountry?.run {
                                                    publishedName ?: draftName ?: ""
                                                }
                                                        ?: "Не выбрана")
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    countrySuggestionBox.focusInput()
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }

                                            countrySuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите страну"
                                                searchQuery = tourismObjectCountry?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getCountryCores(0, 10, query)
                                                }
                                                suggestionFieldFunction = {
                                                    it.run {
                                                        publishedName ?: draftName ?: "Без названия"
                                                    }
                                                }
                                                onSuggestionSelect = { entry ->
                                                    async {
                                                        if (coreId == null || tourismObjectArea == null) {
                                                            val countryId = entry.publishedVersionId
                                                                    ?: entry.draftVersionId
                                                            tourismObjectCountryArea = countryId?.let {
                                                                Countries.getById(it).await().area
                                                            }
                                                            tourismObjectArea = tourismObjectCountryArea
                                                            tourismObjectArea?.let {
                                                                googleMap.mapFitBounds(
                                                                        it.southWest.latitude,
                                                                        it.southWest.longitude,
                                                                        it.northEast.latitude,
                                                                        it.northEast.longitude
                                                                )
                                                            }
                                                        }
                                                        tourismObjectCountry = entry
                                                        tourismObjectParent = null
                                                        tourismObjectParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.countryAddDialog {
                                                        countryName = query
                                                        onSaveFunction = {
                                                            tourismObjectCountry = Core(
                                                                    id = it.coreId,
                                                                    code = it.code,
                                                                    objectType = it.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = it.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = it.name
                                                            )
                                                            tourismObjectCountryArea = null
                                                            tourismObjectParent = null
                                                            tourismObjectParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.countryAddDialog {
                                                        countryName = countrySuggestionBox.searchQuery
                                                        onSaveFunction = { newCountry ->
                                                            tourismObjectCountry = Core(
                                                                    id = newCountry.coreId,
                                                                    code = newCountry.code,
                                                                    objectType = newCountry.objectType,
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newCountry.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newCountry.name
                                                            )
                                                            tourismObjectCountryArea = null
                                                            tourismObjectParent = null
                                                            tourismObjectParentArea = null
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Расположение") {
                                    hidden = tourismObjectCountry == null || locationTypeParents.isEmpty()

                                    parentRenderBlocks = renderBlocks {
                                        val parentText = tourismObjectParent?.let {
                                            val nameText = it.publishedName ?: it.draftName ?: "Удалено"
                                            if (it.objectSubtype != null) {
                                                val subtypeText = LocationType.valueOf(it.objectSubtype.toUpperCase()).displayName
                                                "$nameText ($subtypeText)"
                                            } else nameText
                                        } ?: "Не выбрано"

                                        renderBlock {
                                            span {
                                                appendText(parentText)
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Pencil
                                                tooltipTitle = "Выбрать"
                                                onClickFunction = {
                                                    this@renderBlocks.nextBlock()
                                                    parentSuggestionBox.focusInput()
                                                }
                                            }
                                            if (tourismObjectParent != null) {
                                                clrButton {
                                                    style = ButtonStyle.Flat
                                                    isIcon = true
                                                    iconShape = IconShape.Trash
                                                    tooltipTitle = "Очистить"
                                                    onClickFunction = {
                                                        tourismObjectParent = null
                                                        tourismObjectParentArea = null
                                                        fillMapGeocodeInput()
                                                        this@clrForm.render()
                                                        enableSaveButton()
                                                    }
                                                }
                                            }
                                        }
                                        renderBlock {
                                            style.apply { display = "flex"; justifyContent = "flex-start" }
                                            parentSuggestionBox = suggestionBox {
                                                width = 200
                                                inputPlaceholder = "Выберите расположение"
                                                searchQuery = tourismObjectParent?.run {
                                                    publishedName ?: draftName ?: "Без названия"
                                                } ?: ""
                                                getDataFunction = { query ->
                                                    Locations.getParentCores(0, 10, query, tourismObjectCountry?.id
                                                            ?: 0, locationTypeParents)
                                                }
                                                suggestionFieldFunction = { selected ->
                                                    val version = selected.published ?: selected.draft
                                                    version?.let {
                                                        val typeName = LocationType.valueOf(version.type).displayName
                                                        "${it.name} ($typeName)"
                                                    } ?: "Удалено"
                                                }
                                                onSuggestionSelect = { entry ->
                                                    tourismObjectParent = Core(
                                                            id = entry.id,
                                                            code = entry.code,
                                                            objectType = entry.objectType,
                                                            objectSubtype = entry.draft?.type?.toLowerCase(),
                                                            publishedVersionId = entry.published?.id,
                                                            draftVersionId = entry.draft?.id,
                                                            publishedName = entry.published?.name,
                                                            draftName = entry.draft?.name
                                                    )
                                                    if (coreId == null || tourismObjectArea == null) {
                                                        val parentVersion = entry.published ?: entry.draft
                                                        parentVersion?.mapData?.let {
                                                            tourismObjectParentArea = it.area
                                                        } ?: run {
                                                            tourismObjectParentArea = null
                                                        }
                                                        tourismObjectArea = tourismObjectParentArea
                                                        tourismObjectArea?.let {
                                                            googleMap.mapFitBounds(
                                                                    it.southWest.latitude,
                                                                    it.southWest.longitude,
                                                                    it.northEast.latitude,
                                                                    it.northEast.longitude
                                                            )
                                                        }
                                                    }
                                                    fillMapGeocodeInput()
                                                    this@clrForm.render()
                                                    enableSaveButton()
                                                }
                                                onNotFoundAdd = { query ->
                                                    dom.locationParentAddDialog {
                                                        locationName = query
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = tourismObjectCountry
                                                        onSaveFunction = {
                                                            tourismObjectParent = Core(
                                                                    id = it.coreId,
                                                                    code = it.code,
                                                                    objectType = it.objectType,
                                                                    objectSubtype = it.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = it.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = it.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = {
                                                    dom.locationParentAddDialog {
                                                        locationName = parentSuggestionBox.searchQuery
                                                        childParentTypes = locationTypeParents
                                                        parentCountry = tourismObjectCountry
                                                        onSaveFunction = { newParent ->
                                                            tourismObjectParent = Core(
                                                                    id = newParent.coreId,
                                                                    code = newParent.code,
                                                                    objectType = newParent.objectType,
                                                                    objectSubtype = newParent.type.toLowerCase(),
                                                                    publishedVersionId = null,
                                                                    draftVersionId = newParent.draftVersionId,
                                                                    publishedName = null,
                                                                    draftName = newParent.name
                                                            )
                                                            fillMapGeocodeInput()
                                                            this@clrForm.render()
                                                            enableSaveButton()
                                                        }
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Times
                                                tooltipTitle = "Отменить"
                                                onClickFunction = {
                                                    this@renderBlocks.previousBlock()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (tourismObjectTags.size > 0) {
                                        tourismObjectTags.sortBy { it.name.toLowerCase() }
                                        tourismObjectTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    tourismObjectTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (tourismObjectTags.none { it.id == entry.id }) {
                                                tourismObjectTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                tourismObjectTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = tourismObjectSynonyms
                                        onChangeFunction = {
                                            tourismObjectSynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.TourismObjects.tourismObject(locationTypeName, draft.id, draft.coreId)
                                else Navigation.TourismObjects.tourismObjects(locationTypeName)
                            }
                        }
                    }
                    div {
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                        googleMap = googleMap("100%", "500px") {
                            markerMode = GoogleMapMarkerMode.Marker
                            markerCanMove = true
                            geocodeEnabled = true
                            coordinatesEnabled = true

                            tourismObjectArea?.let {
                                mapBounds(it.southWest.latitude, it.southWest.longitude, it.northEast.latitude, it.northEast.longitude)
                            } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                            tourismObjectMarker?.let {
                                mapMarker(it.latitude, it.longitude)
                            }

                            onChangeFunction = {
                                tourismObjectArea = bounds?.let {
                                    Area(Point(it.southWest.latitude, it.southWest.longitude), Point(it.northEast.latitude, it.northEast.longitude))
                                }
                                tourismObjectMarker = marker?.let { Point(it.latitude, it.longitude) }
                                enableSaveButton()
                            }
                            onPostRender = {
                                mapFitControllerBounds()
                                fillMapGeocodeInput()
                            }
                        }
                    }
                }
            }
        }
    }

    private fun translitToLat(value: String): String {
        var result = ""
        value.forEach {
            result += charToLat(it)
        }
        return result
    }

    private fun charToLat(ch: Char) = when (ch) {
        'A' -> "A"
        'Б' -> "B"
        'В' -> "V"
        'Г' -> "G"
        'Д' -> "D"
        'Е' -> "E"
        'Ё' -> "YO"
        'Ж' -> "ZH"
        'З' -> "Z"
        'И' -> "I"
        'Й' -> "Y"
        'К' -> "K"
        'Л' -> "L"
        'М' -> "M"
        'Н' -> "N"
        'О' -> "O"
        'П' -> "P"
        'Р' -> "R"
        'С' -> "S"
        'Т' -> "T"
        'У' -> "U"
        'Ф' -> "F"
        'Х' -> "KH"
        'Ц' -> "TS"
        'Ч' -> "CH"
        'Ш' -> "SH"
        'Щ' -> "SCH"
        'Ъ' -> ""
        'Ы' -> "IH"
        'Ь' -> ""
        'Э' -> "E"
        'Ю' -> "UI"
        'Я' -> "IA"
        ' ' -> "-"
        else -> ch.toString()
    }
}