package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.SolrSearchResult


object Search {
    fun search(request: String, visibilityCodes: List<Int>, objectTypeCodes: List<String>, objectStatuses: List<String>, strict: Boolean, start: Int, pageSize: Int) =
            Network.request<ListResult<SolrSearchResult>>(Network.Method.GET, buildUrl(request, visibilityCodes, objectTypeCodes, objectStatuses, strict, start, pageSize))

    fun <T> searchObjectType(
            request: String = "",
            objectStatuses: List<String> = emptyList(),
            start: Int = 0,
            pageSize: Int = 50,
            objectTypeCodes: List<String> = emptyList(),
            visibilityCodes: List<Int> = emptyList()
    ) = Network.request<ListResult<T>>(Network.Method.GET, buildUrl(request, visibilityCodes, objectTypeCodes, objectStatuses, false, start, pageSize))

    private fun buildUrl(request: String, visibilityCodes: List<Int>, objectTypeCodes: List<String>, objectStatuses: List<String>, strict: Boolean, start: Int, pageSize: Int): String {
        var str = "/sce-web/api/search?start=$start&pageSize=$pageSize&request=$request&strict=$strict"
        visibilityCodes.forEach {
            str += "&visibilityCodes=$it"
        }
        objectTypeCodes.forEach {
            str += "&objectTypes=$it"
        }
        objectStatuses.forEach {
            str += "&objectStatuses=$it"
        }
        return str
    }
}