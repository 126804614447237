package ru.playa.sce.views.tourPackets

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.components.suggestionBox
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.dictionaries.dictionaryEntryAddDialog

class TourPacketEditorView(private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "tourPackets/editor"
    }

    private lateinit var draft: TourPacket
    private lateinit var dictionaryTourPacketTypes: Array<DictionaryEntry>
    private lateinit var allCountryCores: Array<Core>

    private var tourPacketName = ""
    private var tourPacketCountry: Option? = null
    private var tourPacketType: Option? = null
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()
    private val tourPacketTags = mutableListOf<Option>()
    private var tourPacketSynonyms = ""

    private lateinit var mainTab: Tab

    private fun enableSaveButton() {
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)
    private fun validateDays(value: String) = value.toIntOrNull()?.let { it > 0 } ?: false
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex(".{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(tourPacketName)) validationErrors.add("Указано некорректное название турпакета")
        if (tourPacketCountry == null) validationErrors.add("Не указано обязательное поле \"Основная страна\"")
        if (tourPacketType == null) validationErrors.add("Не указано обязательное поле \"Тип турпакета\"")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (!validateSynonyms(tourPacketSynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
    }

    override fun saveEditorData() = async {
        val tourPacket = TourPacket(
                id = if (coreId != null) draft.id else 0,
                code = if (coreId != null) draft.code else "",
                name = tourPacketName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = tourPacketTags.toTypedArray(),
                synonyms = tourPacketSynonyms,
                country = tourPacketCountry,
                tourPacketType = tourPacketType
        )
        val result = if (coreId == null) TourPackets.create(tourPacket).await() else TourPackets.update(tourPacket).await()
        Navigation.TourPackets.tourPacket(result.id, result.coreId)
    }

    private fun initEditorData() = async {
        if (coreId != null) {
            draft = TourPackets.getDraft(coreId).await()
            tourPacketName = draft.name
            tourPacketCountry = draft.country
            tourPacketType = draft.tourPacketType
            tourPacketTags.clear()
            tourPacketTags.addAll(draft.tags)
            tourPacketSynonyms = draft.synonyms ?: ""
        } else {
            tourPacketName = ""
            tourPacketCountry = null
            tourPacketType = null
            tourPacketTags.clear()
            tourPacketSynonyms = ""
        }

        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType("tourpacket").await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        dictionaryTourPacketTypes = Dictionaries.getDictionaryEntries("tourpackettype", 0, 500, "").await().data.apply { sortBy { it.id } }
        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
        validationAlert = null
    }


    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (coreId == null) "Новый турпакет" else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Турпакеты") {
                        Navigation.TourPackets.tourPackets()
                    }
                }
                clrTabs {
                    mainTab = clrTab("Основная информация") {
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                clrGroup("Название турпакета", true) {
                                    clrInput {
                                        value = tourPacketName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            tourPacketName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Основная страна", true) {
                                    clrSelect<Option?> {
                                        clrOption("Не выбрана", null)
                                        allCountryCores.forEach {
                                            clrOption(it.publishedName ?: it.draftName ?: "—", Option(it.id, it.publishedName ?: it.draftName ?: "—", false), tourPacketCountry?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            tourPacketCountry = selectedOptions.first().value
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Тип турпакета", true) {
                                    clrSelect<Option?> {
                                        clrOption("Не выбран", null)
                                        dictionaryTourPacketTypes.forEach {
                                            clrOption(it.name, Option(it.id, it.name, false), tourPacketType?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            tourPacketType = selectedOptions.first().value
                                            enableSaveButton()
                                        }
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = { _ ->
                                            dom.dictionaryEntryAddDialog("tourpackettype") {
                                                headerTitle = "Новый тип турпакета"
                                                onSaveFunction = { event ->
                                                    async {
                                                        tourPacketType = Option(event.id, event.name, false)
                                                        dictionaryTourPacketTypes = Dictionaries.getDictionaryEntries("tourpackettype", 0, 500, "").await().data.apply { sortBy { it.id } }
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (tourPacketTags.size > 0) {
                                        tourPacketTags.sortBy { it.name.toLowerCase() }
                                        tourPacketTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    tourPacketTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (tourPacketTags.none { it.id == entry.id }) {
                                                tourPacketTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                tourPacketTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = tourPacketSynonyms
                                        onChangeFunction = {
                                            tourPacketSynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.TourPackets.tourPacket(draft.id, draft.coreId)
                                else Navigation.TourPackets.tourPackets()
                            }
                        }
                    }
                }
            }
        }
    }
}
