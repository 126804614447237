package ru.playa.sce.dto

open class VersionedObject(
        id: Int,
        code: String,
        name: String,
        objectType: String,
        val coreId: Int,
        val status: String
) : BaseObject(id, code, name, objectType)
