package ru.playa.sce.dto


class VisibilityArea(
        val id: Int,
        val code: String,
        val name: String,
        val enableByDefault: Boolean
) {
    companion object {
        fun toOption(area: VisibilityArea, disabled: Boolean): Option = Option(area.id, area.name, disabled)
    }
}