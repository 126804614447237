package ru.playa.sce.views.usersRoles

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.Button
import ru.playa.kotlinx.clarity.js.components.ButtonStyle
import ru.playa.kotlinx.clarity.js.components.clrButton
import ru.playa.kotlinx.clarity.js.components.clrCheckbox
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.UsersRoles
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.Role
import ru.playa.sce.dto.User
import kotlin.dom.appendText

class UserRolesEditorView(private val email: String) : View() {

    companion object {
        const val PATH = "userRoles/editor"
    }

    private val accountId = Application.user.account.id
    private lateinit var selectedUser: User
    private lateinit var accountRoles: Array<Role>

    private val selectedRoles = mutableListOf<Role>()

    var saveButton: Button? = null

    private fun saveEditorData() = async {
        val user = User(
                email = selectedUser.email,
                name = selectedUser.name,
                roles = selectedRoles.toTypedArray()
        )
        UsersRoles.updateUser(user).await()
        Navigation.UsersRoles.usersRoles()
    }

    private fun initEditorData() = async {
        selectedRoles.clear()
        selectedUser = UsersRoles.getUserByEmail(email).await()
        accountRoles = UsersRoles.getRoles(accountId).await().data
    }

    override fun render() {
        async {
            if (Application.userIsOwner) {
                initEditorData().await()

                dom.apply {
                    clrPageHeader("Роли пользователя")

                    div {
                        style.display = "flex"
                        style.flexDirection = "row"

                        div {
                            style.marginRight = "50px"
                            div {
                                style.paddingBottom = "10px"
                                appendText(selectedUser.email)
                            }
                            div {
                                appendText(selectedUser.name)
                            }
                        }
                        div {
                            accountRoles.forEach { role ->
                                val roleName = if (role.name.isNotBlank()) role.name else "—"
                                clrCheckbox(roleName) {
                                    if (selectedUser.roles.any { it.id == role.id }) {
                                        isChecked = true
                                        selectedRoles.add(role)
                                    }

                                    onChangeFunction = {
                                        if (isChecked) selectedRoles.add(role) else selectedRoles.remove(role)
                                    }
                                }
                            }
                        }
                    }

                    div {
                        saveButton = clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            style = ButtonStyle.Primary
                            onClickFunction = {
                                saveEditorData()
                            }
                        }
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                Navigation.UsersRoles.usersRoles()
                            }
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }
}