package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.*

object Tours {

    fun get(
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            external: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            visibility: List<String> = emptyList(),
            tags: List<String> = emptyList(),
            countryId: Int? = null,
            startTownId: Int? = null,
            tourTypeId: Int? = null,
            locationId: Int? = null,
            deleteStatus: DeleteStatus = DeleteStatus.ALL
    ) = Network.request<ListResult<Tour>>(Network.Method.GET,
            "/sce-web/api/public/objects/tour/search?start=$start&pageSize=$pageSize&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict&external=$external${objectStatus.joinToString("") { "&objectStatus=$it" }}${visibility.joinToString("") { "&visibility=$it" }}${tags.joinToString("") { "&tags=$it" }}&deleteStatus=$deleteStatus${if (countryId != null) "&countryId=$countryId" else ""}${if (startTownId != null) "&startTownId=$startTownId" else ""}${if (tourTypeId != null) "&tourTypeId=$tourTypeId" else ""}${if (locationId != null) "&locationId=$locationId" else ""}")

    fun getTourCores(start: Int, pageSize: Int, name: String) = Network.request<ListResult<Core>>(Network.Method.GET,
            "/sce-web/api/user/objects/tour/search/tour?start=$start&pageSize=$pageSize&name=$name")

    fun getById(id: Int) = Network.request<Tour>(Network.Method.GET, "/sce-web/api/user/objects/tour/$id")

    fun getDraft(coreId: Int) = Network.request<Tour>(Network.Method.GET, "/sce-web/api/user/objects/tour/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<Tour>(Network.Method.GET,
            "/sce-web/api/user/objects/tour/draft/$coreId?version=$version&archive=$archive")

    fun create(tour: Tour) = Network.send(Network.Method.POST, tour, "/sce-web/api/user/objects/tour")

    fun update(tour: Tour) = Network.send(Network.Method.PUT, tour, "/sce-web/api/user/objects/tour")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/tour/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/tour/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/tour/publish/$id")

    fun getTourPoints(id: Int) = Network.request<ListResult<TourPoint>>(Network.Method.GET,
            "/sce-web/api/public/objects/tour/tourpoint/$id")

    fun createTourPoint(point: TourPoint) = Network.send(Network.Method.POST, point, "/sce-web/api/user/objects/tour/tourpoint")

    fun updateTourPoint(point: TourPoint) = Network.send(Network.Method.PUT, point, "/sce-web/api/user/objects/tour/tourpoint")

    fun deleteTourPoint(objectId: Int, descriptionId: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/tour/tourpoint/$objectId/$descriptionId")

}