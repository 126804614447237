package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.html.button
import ru.playa.kotlinx.clarity.js.html.li
import ru.playa.kotlinx.clarity.js.html.newUL
import ru.playa.kotlinx.clarity.js.util.async

class SimpleTabs(parent: HTMLElement) : Component(parent) {
    class Tab(val name: String, val title: String, var active: Boolean = false)

    val tabs = linkedSetOf<Tab>()
    var onClickFunction: (String) -> Unit = {}
    override fun build() = async {
        newUL("nav") {
            if (tabs.none { it.active })
                tabs.firstOrNull()?.active = true
            tabs.forEach { item ->
                li("nav-item") {
                    button("btn btn-link nav-link ${if (item.active) "active" else ""}") {
                        this.innerText = item.title
                        type = "button"
                        onclick = {
                            tabs.forEach {
                                it.active = false
                            }
                            item.active = true
                            render()
                            onClickFunction(item.name)
                        }
                    }
                }
            }
        }
    }
}

fun HTMLElement.clrSimpleTabs(block: SimpleTabs.() -> Unit = {}) = SimpleTabs(this).apply(block).render()