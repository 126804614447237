package ru.playa.sce.views.dictionaries

import ru.playa.kotlinx.clarity.js.components.clrColumn
import ru.playa.kotlinx.clarity.js.components.clrDatagrid
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.Dictionary
import kotlin.dom.appendText

class DictionariesView : View() {

    companion object {
        const val PATH = "dictionaries"
    }

    override fun render() {
        dom.apply {
            clrPageHeader("Справочники")
            clrDatagrid<Dictionary> {
                clrColumn("Код", 1, "code") {
                    innerText = it.code
                }
                clrColumn("Наименование", 3, "name") { entry ->
                    a {
                        appendText(entry.name)
                        href = "javascript://"
                        onclick = {
                            Navigation.Dictionaries.dictionary(entry.code)
                        }
                    }

                }
                clrColumn("Родительский справочник", 3) { entry ->
                    if (entry.parent != null) {
                        a {
                            appendText(entry.parent.name)
                            href = "javascript://"
                            onclick = {
                                Navigation.Dictionaries.dictionary(entry.parent.code)
                            }
                        }
                    }
                }
                pageSize = 25
                pageSizeOptions.add(5)
                pageSizeOptions.add(25)
                pageSizeOptions.add(50)
                orderField = "name"
                getDataFunction = {
                    Dictionaries.getDictionaries(start, pageSize, orderField, reverseOrder)
                }
            }
        }
    }
}
