package ru.playa.sce.views.imageSettings

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.html.h4
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.dto.Account
import ru.playa.sce.dto.DictionaryEntry
import ru.playa.sce.dto.Option
import kotlin.dom.appendText

class ImageSettingsView : View() {

    companion object {
        const val PATH = "imageSettings"
    }

    enum class Settings(val code: String, val displayName: String) {
        MAIN_WIDTH("width", "Ширина основной фотографии (px)"),
        THUMBNAIL_WIDTH("thumbnail.width", "Ширина миниатюры (px)"),
        ICON_WIDTH("icon.width", "Ширина иконки (px)"),
        RATIO("ratio", "Соотношение сторон (Ш:В)")
    }

    enum class ObjectTypes(val displayName: String) {
        COUNTRY("Страны"),
        LOCATION("Географические объекты"),
        HOTEL("Отели"),
        TOUR("Туры"),
        LANDMARK("Достопримечательности"),
        AIRPORT("Аэропорты"),
        PORT("Порты"),
        //        MARINA("Яхтенные порты"),
        BANNER("Текстовые блоки")
    }

    lateinit var account: Account
    private lateinit var imageflags: Array<DictionaryEntry>
    private val settingsValues = Settings.values()
    private val imageProperties = mutableMapOf<String, String>()

    private fun saveAccountProperties() = async {
        val newProps = account.properties.filter { !it.code.startsWith("ru.playa.sce.image") }.toMutableList()
        imageProperties.entries.forEach { entry ->
            if (entry.value.isNotBlank()) newProps.add(Option(0, entry.value, false, entry.key))
        }
        account.properties = newProps.toTypedArray()
        Accounts.updateAccount(account).await()
        Application.account = Accounts.getCurrentAccount().await()
    }

    private fun initEditorData() = async {
        account = Application.account
        imageflags = Dictionaries.getDictionaryEntries("imageflag", 0, Int.MAX_VALUE).await().data

        settingsValues.forEach { setting ->
            if (setting != Settings.RATIO) {
                val propCode = "ru.playa.sce.image.${setting.code}"
                val prop = account.properties.find { it.code == propCode }
                imageProperties[propCode] = prop?.name ?: ""
            }

            ObjectTypes.values().forEach { objectType ->
                val propCode = "ru.playa.sce.image.${setting.code}.${objectType.name.toLowerCase()}"
                val prop = account.properties.find { it.code == propCode }
                imageProperties[propCode] = prop?.name ?: ""
            }

            imageflags.forEach { imageflag ->
                if (setting != Settings.ICON_WIDTH) {
                    val propCode = "ru.playa.sce.image.${setting.code}.${imageflag.code}"
                    val prop = account.properties.find { it.code == propCode }
                    imageProperties[propCode] = prop?.name ?: ""
                }
            }
        }
    }

    override fun render() {
        async {
            if (Application.userIsOwner) {
                initEditorData().await()

                dom.apply {
                    style.marginBottom = "30px"
                    clrPageHeader("Параметры изображений")

                    clrTable {
                        isCompact = true
                        clrHeader {
                            clrRow {
                                clrHeadCell {
                                    style.width = "20%"
                                }
                                settingsValues.forEach {
                                    if (it != Settings.RATIO) {
                                        clrHeadCell {
                                            style.width = "20%"
                                            if (it == Settings.MAIN_WIDTH)
                                                style.width = "24%"
                                            style.verticalAlign = "top"

                                            appendText(it.displayName)
                                            span {
                                                style.color = "red"
                                                appendText("*")
                                            }
                                        }
                                    }
                                }
                                clrCell {
                                    style.apply { verticalAlign = "top"; textAlign = "start"; width = "20%" }
                                }
                            }
                        }
                        clrBody {
                            clrRow {
                                clrCell {
                                    style.apply { verticalAlign = "middle"; textAlign = "start"; paddingLeft = "10px"; width = "20%" }
                                    appendText("Общие")
                                }
                                settingsValues.forEach { setting ->
                                    if (setting != Settings.RATIO) {
                                        clrCell {
                                            style.apply { color = "#007cbb"; cursor = "pointer"; width = "20%" }

                                            val propCode = "ru.playa.sce.image.${setting.code}"
                                            val propValue = imageProperties[propCode] ?: ""
                                            appendText(if (propValue.isNotBlank()) propValue else "—")

                                            onclick = {
                                                buildWidthInputModal("Общие", setting.displayName, propValue, true) { input ->
                                                    imageProperties[propCode] = input
                                                    this@clrTable.render()
                                                    saveAccountProperties()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrCell {
                                    style.apply { verticalAlign = "middle"; textAlign = "start"; paddingLeft = "10px"; width = "20%" }
                                }
                            }
                        }
                    }
                    h4 {
                        style.apply { marginLeft = "5px"; color = "#565656"; }
                        appendText("Фотографии. Объекты")
                    }
                    clrTable {
                        isCompact = true
                        clrHeader {
                            clrRow {
                                clrHeadCell {
                                    style.width = "20%"
                                    style.verticalAlign = "top"
                                }
                                settingsValues.forEach {
                                    clrHeadCell {
                                        style.width = "20%"
                                        if (it == Settings.MAIN_WIDTH)
                                            style.width = "24%"
                                        style.verticalAlign = "top"

                                        appendText(it.displayName)
                                        if (it == Settings.RATIO) {
                                            span {
                                                style.color = "red"
                                                appendText("*")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        clrBody {
                            ObjectTypes.values().forEach { objectType ->
                                clrRow {
                                    clrCell {
                                        style.apply { verticalAlign = "middle"; textAlign = "start"; paddingLeft = "10px"; width = "20%" }
                                        appendText(objectType.displayName)
                                    }
                                    settingsValues.forEach { setting ->
                                        clrCell {
                                            style.apply { color = "#007cbb"; cursor = "pointer"; width = "20%" }

                                            val propCode = "ru.playa.sce.image.${setting.code}.${objectType.name.toLowerCase()}"
                                            val propValue = imageProperties[propCode] ?: ""
                                            appendText(if (propValue.isNotBlank()) propValue else "—")

                                            onclick = {
                                                if (setting == Settings.RATIO) {
                                                    buildRatioInputModal(objectType.displayName, propValue, true) { input ->
                                                        imageProperties[propCode] = input
                                                        this@clrTable.render()
                                                        saveAccountProperties()
                                                    }
                                                } else {
                                                    buildWidthInputModal(objectType.displayName, setting.displayName, propValue, false) { input ->
                                                        imageProperties[propCode] = input
                                                        this@clrTable.render()
                                                        saveAccountProperties()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    h4 {
                        style.apply { marginLeft = "5px"; color = "#565656"; }
                        appendText("Типы иллюстраций")
                    }
                    clrTable {
                        isCompact = true
                        clrHeader {
                            clrRow {
                                clrHeadCell {
                                    style.width = "20%"
                                    style.verticalAlign = "top"
                                }
                                settingsValues.forEach {
                                    if (it != Settings.ICON_WIDTH) {
                                        clrHeadCell {
                                            style.width = "20%"
                                            style.verticalAlign = "top"
                                            appendText(it.displayName)
                                            if (it == Settings.MAIN_WIDTH) {
                                                style.width = "24%"
                                                span {
                                                    style.color = "red"
                                                    appendText("*")
                                                }
                                            }
                                        }
                                    }
                                }
                                clrCell {
                                    style.apply { verticalAlign = "top"; textAlign = "start"; paddingLeft = "10px"; width = "20%" }
                                }
                            }
                        }
                        clrBody {
                            imageflags.forEach { imageflag ->
                                clrRow {
                                    clrCell {
                                        style.apply { width = "20%"; verticalAlign = "middle"; textAlign = "start"; paddingLeft = "10px" }
                                        appendText(imageflag.name)
                                    }
                                    settingsValues.forEach { setting ->
                                        if (setting != Settings.ICON_WIDTH) {
                                            clrCell {
                                                style.apply { color = "#007cbb"; cursor = "pointer"; width = "20%" }

                                                val propCode = "ru.playa.sce.image.${setting.code}.${imageflag.code}"
                                                val propValue = imageProperties[propCode] ?: ""
                                                appendText(if (propValue.isNotBlank()) propValue else "—")

                                                onclick = {
                                                    if (setting == Settings.RATIO) {
                                                        buildRatioInputModal(imageflag.name, propValue, false) { input ->
                                                            imageProperties[propCode] = input
                                                            this@clrTable.render()
                                                            saveAccountProperties()
                                                        }
                                                    } else {
                                                        buildWidthInputModal(imageflag.name, setting.displayName, propValue, setting == Settings.MAIN_WIDTH) { input ->
                                                            imageProperties[propCode] = input
                                                            this@clrTable.render()
                                                            saveAccountProperties()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    clrCell {
                                        style.apply { verticalAlign = "middle"; textAlign = "start"; paddingLeft = "10px"; width = "20%" }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                dom.apply {
                    h1 {
                        appendText("Отказано в доступе")
                    }
                }
            }
        }
    }

    private fun buildWidthInputModal(headerName: String, paramName: String, paramValue: String, required: Boolean, onSave: (String) -> Unit) {
        lateinit var container: HTMLDivElement
        lateinit var alertContainer: HTMLDivElement
        lateinit var widthInput: Input
        var inputValue = paramValue

        container = dom.div {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerName) {
                        dom.removeChild(container)
                    }

                    clrModalDialogBody {
                        div {
                            style.apply { display = "flex"; justifyContent = "space-between" }
                            div {
                                style.fontSize = "14px"
                                appendText(paramName)
                            }
                            div {
                                style.display = "flex"
                                widthInput = clrInput {
                                    size = 10
                                    value = inputValue
                                    validationPredicate = {
                                        value.toIntOrNull()?.let { it > 0 } ?: false
                                    }
                                    onChangeFunction = {
                                        inputValue = value
                                    }
                                    onPressEnterFunction = {
                                        inputValue = value
                                        if (inputValue.isBlank() || value.toIntOrNull()?.let { it > 0 } == true) {
                                            onSave(inputValue)
                                            dom.removeChild(container)
                                        } else {
                                            alertContainer.style.display = "block"
                                        }
                                    }
                                }
                            }
                        }
                    }
                    alertContainer = div {
                        style.display = "none"

                        clrAlert {
                            style = Alert.Style.ERROR
                            clrAlertItem("Положительное целое число")
                        }
                    }
                    clrModalDialogFooter {
                        clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            onClickFunction = {
                                if (inputValue.isBlank() || widthInput.isInvalid) {
                                    alertContainer.style.display = "block"
                                } else {
                                    onSave(inputValue)
                                    dom.removeChild(container)
                                }
                            }
                        }
                        if (!required) {
                            clrButton("Удалить") {
                                iconShape = IconShape.Trash
                                style = ButtonStyle.Secondary
                                onClickFunction = {
                                    onSave("")
                                    dom.removeChild(container)
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                dom.removeChild(container)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun buildRatioInputModal(headerName: String, paramValue: String, required: Boolean, onSave: (String) -> Unit) {
        lateinit var container: HTMLDivElement
        lateinit var alertContainer: HTMLDivElement
        lateinit var ratioWidthInput: Input
        lateinit var ratioHeightInput: Input
        var widthValue = ""
        var heightValue = ""
        if (paramValue.isNotBlank()) {
            paramValue.split(':').let {
                widthValue = it[0]
                heightValue = it[1]
            }
        }

        container = dom.div {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader(headerName) {
                        dom.removeChild(container)
                    }

                    clrModalDialogBody {
                        div {
                            style.apply { display = "flex"; justifyContent = "space-between" }

                            div {
                                style.fontSize = "14px"
                                appendText("Соотношение сторон (Ш:В)")
                            }
                            div {
                                style.display = "flex"

                                ratioWidthInput = clrInput {
                                    size = 4
                                    value = widthValue
                                    validationPredicate = {
                                        value.toIntOrNull()?.let { it > 0 } ?: false
                                    }
                                    onChangeFunction = {
                                        widthValue = value
                                    }
                                }
                                span { innerText = ":" }
                                ratioHeightInput = clrInput {
                                    size = 4
                                    value = heightValue
                                    validationPredicate = {
                                        value.toIntOrNull()?.let { it > 0 } ?: false
                                    }
                                    onChangeFunction = {
                                        heightValue = value
                                    }
                                }
                            }
                        }
                    }
                    alertContainer = div {
                        style.display = "none"

                        clrAlert {
                            style = Alert.Style.ERROR
                            clrAlertItem("Положительные целые числа")
                        }
                    }
                    clrModalDialogFooter {
                        clrButton("Сохранить") {
                            iconShape = IconShape.Check
                            onClickFunction = {
                                if (widthValue.isBlank() || heightValue.isBlank() || ratioWidthInput.isInvalid || ratioHeightInput.isInvalid) {
                                    alertContainer.style.display = "block"
                                } else {
                                    onSave("$widthValue:$heightValue")
                                    dom.removeChild(container)
                                }
                            }
                        }
                        if (!required) {
                            clrButton("Удалить") {
                                iconShape = IconShape.Trash
                                style = ButtonStyle.Secondary
                                onClickFunction = {
                                    onSave("")
                                    dom.removeChild(container)
                                }
                            }
                        }
                        clrButton("Отмена") {
                            iconShape = IconShape.Times
                            style = ButtonStyle.Secondary
                            onClickFunction = {
                                dom.removeChild(container)
                            }
                        }
                    }
                }
            }
        }
    }
}
