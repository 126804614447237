package ru.playa.sce.components

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.components.Input
import ru.playa.kotlinx.clarity.js.components.clrInput
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.icons.clrIcon
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.browser.window
import kotlin.dom.appendText
import kotlin.js.Promise

class SuggestionBox<T>(parent: HTMLElement) : Component(parent) {
    var getDataFunction: ((String) -> Promise<ListResult<T>>)? = null
    var suggestionFieldFunction: ((T) -> String)? = null
    var onSuggestionSelect: ((T) -> Unit)? = null
    private var fetchedSuggestions = emptyArray<T>()

    var searchQuery = ""
    var inputPlaceholder = ""
    var width = 200
    var onAddClick: ((String) -> Unit)? = null
    var onNotFoundAdd: ((String) -> Unit)? = null

    private var inputFocused = false
    private var fetchedOnce = false

    private lateinit var inputComponent: Input

    fun focusInput() = window.setTimeout({ inputComponent.getHTMLElement().focus() }, 10)

    fun invalidateInput() {
        inputComponent.isInvalid = true
        inputComponent.render()
    }

    override fun build() = async {
        val controller = this@SuggestionBox
        val dataFunction = getDataFunction
        val fieldFunction = suggestionFieldFunction
        val selectFunction = onSuggestionSelect
        val addFunction = onAddClick
        val notFoundAddFunction = onNotFoundAdd
        val getSuggestionDebounced = debounceFetch(500) { query: String ->
            async {
                if (dataFunction != null) {
                    fetchedSuggestions = if (query.isNotBlank()) dataFunction(query).await().data else emptyArray()
                    inputFocused = true
                    fetchedOnce = true
                    render()
                }
            }
        }

        return@async newDiv {
            style.apply { position = "relative"; width = "${controller.width + 24}px" }
            inputComponent = clrInput {
                placeholder = controller.inputPlaceholder
                value = controller.searchQuery
                width = "${controller.width}px"
                onInputFunction = {
                    controller.searchQuery = value
                    getSuggestionDebounced(value)
                }
                onPressEnterFunction = {
                    if (addFunction != null) addFunction(controller.searchQuery)
                }
                onPostRender = {
                    if (inputFocused) window.setTimeout({ this.getHTMLElement().focus() }, 10)
                }
            }
            if (addFunction != null) {
                clrIcon(IconShape.PlusCircleSolid) {
                    style.apply { color = "#0077b7"; cursor = "pointer"; height = "24px"; width = "24px" }
                    onclick = {
                        addFunction(controller.searchQuery)
                    }
                }
            }
            if (fetchedSuggestions.isNotEmpty() && fieldFunction != null) {
                div {
                    style.apply { position = "absolute"; backgroundColor = "#fff"; width = "${controller.width}px"; zIndex = "99"; border = "1px solid #9a9a9a"; boxShadow = "0px 3px 15px #888888" }
                    ul {
                        style.listStyle = "none"
                        li {
                            style.apply { fontSize = "9px"; paddingLeft = "8px"; color = "#8f8f8f" }
                            appendText("Выберите вариант или продолжите ввод")
                        }
                        fetchedSuggestions.forEach { suggestion: T ->
                            li {
                                style.padding = "0 5px"
                                a {
                                    style.cursor = "pointer"
                                    appendText(fieldFunction(suggestion))
                                    onclick = {
                                        if (selectFunction != null) selectFunction(suggestion)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (fetchedSuggestions.isEmpty() && searchQuery.isNotBlank() && fetchedOnce) {
                div {
                    style.apply {
                        position = "absolute"; backgroundColor = "#fff"; width = "${controller.width}px"; zIndex = "99"
                        border = "1px solid #9a9a9a"; boxShadow = "0px 3px 15px #888888"; paddingLeft = "8px"
                    }
                    div {
                        style.apply { fontSize = "10px"; color = "8f8f8f" }
                        appendText("""Вариантов для "$searchQuery" не найдено.""")
                    }
                    if (notFoundAddFunction != null) {
                        div {
                            a {
                                style.apply { cursor = "pointer"; fontSize = "12px"; color = "#007cbb" }
                                appendText("Добавить")
                                onclick = {
                                    notFoundAddFunction(controller.searchQuery)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun debounceFetch(timeout: Int, execute: (String) -> Unit): (String) -> Unit {
    var debounceTimer: Int? = null

    return { query: String ->
        val onComplete = {
            execute(query)
            debounceTimer = null
        }

        if (debounceTimer != null) {
            window.clearTimeout(debounceTimer ?: 0)
        }
        debounceTimer = window.setTimeout(onComplete, timeout)
    }
}

fun <T> HTMLElement.suggestionBox(block: SuggestionBox<T>.() -> Unit = {}) =
        SuggestionBox<T>(this).apply(block).apply { render() }