package ru.playa.sce.dto


class ExternalSystem(val id: Int, val code: String, val name: String, val applicableTypes: Array<Option>) {

    //Памятка: после парсинга из json в javascript объект не будут доступны методы, поэтому целесообразнее их делать статическими
    companion object {
        fun toExternalMapping(reference: ExternalSystem, external: String): ExternalMapping =
                ExternalMapping(reference.id, external, Option(reference.id, "", false))

    }

}