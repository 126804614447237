package ru.playa.sce.views.dictionaries

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.External
import ru.playa.sce.api.VisibilityAreas
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.DictionaryEntry
import ru.playa.sce.dto.ExternalMapping
import ru.playa.sce.dto.ExternalSystem
import ru.playa.sce.dto.VisibilityArea


class DictionaryEntryCreatorView(private val dictionaryCode: String) : View() {

    companion object {
        const val PATH = "dictionaries/entries/add"
    }

    private lateinit var parentEntries: Array<DictionaryEntry>

    private var entryCode = ""
    private var entryName = ""
    private var entryRating = ""
    private var entrySynonyms = ""
    private var entryParent: DictionaryEntry? = null
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappingInputs: HashMap<Input, ExternalSystem> = hashMapOf()

    private lateinit var saveButton: Button

    private fun HTMLElement.createEditContent() {
        fun validate() {
            if (entryName.isBlank() || entryCode.isBlank() || entryRating.isBlank() || entrySynonyms.length > 1000) {
                saveButton.isDisabled = true
                saveButton.render()
            } else if (entryName.isNotBlank() && entryCode.isNotBlank() && entryRating.isNotBlank() || entrySynonyms.length <= 1000) {
                saveButton.isDisabled = false
                saveButton.render()
            }
        }

        async {
            val dictionary = Dictionaries.getDictionary(dictionaryCode).await().data.firstOrNull()
                    ?: throw IllegalStateException()
            if (dictionary.parent != null)
                parentEntries = Dictionaries.getDictionaryEntries(dictionary.parent.code).await().data
            val visibilityAreas = VisibilityAreas.get().await()
            visibilityAreas.data.forEach { area ->
                selectedAreas[area] = area.enableByDefault
            }
            val externals = External.getByObjectType(dictionary.code).await().data

            div().apply {
                clrPageHeader("Новый элемент") {
                    clrLink("Справочники") {
                        Navigation.Dictionaries.dictionaries()
                    }
                    clrLink(dictionary.name) {
                        Navigation.Dictionaries.dictionary(dictionaryCode)
                    }
                }
                style.width = "100%"
                clrForm {
                    isCompact = true
                    clrBlock("Основная информация") {
                        if (dictionaryCode != "tag") {
                            clrGroup("Код") {
                                clrInput {
                                    size = 30
                                    this.value = ""
                                    entryCode = this.value
                                    onInputFunction = {
                                        entryCode = value
                                        validate()
                                    }
                                }
                            }
                        }
                        clrGroup("Наименование") {
                            clrInput {
                                size = 30
                                this.value = ""
                                entryName = this.value
                                if (dictionaryCode == "tag") entryCode = this.value
                                onInputFunction = {
                                    entryName = value
                                    if (dictionaryCode == "tag") entryCode = value
                                    validate()
                                }
                            }
                        }
                        clrGroup("Рейтинг") {
                            clrInput {
                                size = 30
                                this.value = ""
                                entryRating = this.value
                                onInputFunction = {
                                    entryRating = value
                                    validate()
                                }
                            }
                            clrToolTip("Только числовое значение")
                        }
                        clrGroup("Область видимости") {
                            selectedAreas.forEach { it ->
                                val area = it.key
                                clrCheckbox(area.name) {
                                    isInline = true
                                    isChecked = it.value
                                    onChangeFunction = {
                                        selectedAreas[area] = isChecked
                                        validate()
                                    }
                                }
                            }
                        }
                        if (dictionary.parent != null) {
                            clrGroup(dictionary.parent.name) {
                                clrSelect<DictionaryEntry?> {
                                    clrOption("", null, entryParent == null)
                                    for (parentEntry in parentEntries) {
                                        clrOption(parentEntry.name, parentEntry, parentEntry.id == entryParent?.id)
                                    }
                                    onChangeFunction = {
                                        entryParent = selectedOptions.first().value
                                        validate()
                                    }
                                }
                            }
                        }
                        clrGroup("Варианты написания") {
                            clrTextArea {
                                rows = 2
                                cols = 80
                                this.value = ""
                                entrySynonyms = this.value
                                onChangeFunction = {
                                    entrySynonyms = value
                                }
                                onInputFunction = {
                                    entrySynonyms = value
                                    validate()
                                }
                            }
                        }
                    }
                    clrBlock("Связи с внешними справочниками") {
                        externals.forEach { external ->
                            clrGroup(external.name) {
                                val ti = clrInput {
                                    value = ""
                                    onInputFunction = {
                                        validate()
                                    }
                                }
                                externalMappingInputs[ti] = external
                            }
                        }
                    }
                }
                div {
                    style.width = "100%"
                    saveButton = clrButton("Сохранить") {
                        iconShape = IconShape.Check
                        style = ButtonStyle.Primary
                        isDisabled = true
                        onClickFunction = { _ ->
                            val ext: ArrayList<ExternalMapping> = arrayListOf()
                            externalMappingInputs.forEach {
                                ext.add(ExternalSystem.toExternalMapping(it.value, it.key.value))
                            }
                            val options = selectedAreas
                                    .filter { it.value }
                                    .map { VisibilityArea.toOption(it.key, true) }
                                    .toTypedArray()
                            val newEntry = DictionaryEntry(
                                    id = 0,
                                    code = entryCode,
                                    name = entryName,
                                    parent = entryParent,
                                    externalMappings = ext.toTypedArray(),
                                    visibility = options,
                                    rating = entryRating.toIntOrNull() ?: 0,
                                    synonyms = entrySynonyms)
                            Dictionaries.createDictionaryEntry(dictionaryCode, newEntry).then {
                                Navigation.Dictionaries.dictionary(dictionaryCode)
                            }
                        }
                    }
                    clrButton("Отмена") {
                        style = ButtonStyle.Secondary
                        iconShape = IconShape.Times
                        onClickFunction = {
                            Navigation.Dictionaries.dictionary(dictionaryCode)
                        }
                    }
                }
            }
        }
    }

    override fun render() {
        externalMappingInputs.clear()
        selectedAreas.clear()
        entryParent = null
        dom.createEditContent()
    }
}
