package ru.playa.sce.views.tourismObjects

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.label
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.Meta
import ru.playa.kotlinx.clarity.js.util.Paging
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.*
import ru.playa.sce.components.clrButtonGroup
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.components.suggestionBox
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import kotlin.dom.appendText
import kotlin.js.Promise

class TourismObjectsView(private val locationTypeName: String) : View() {

    companion object {
        const val PATH = "tourismObjects"
    }

    private lateinit var privileges: Array<String>

    private val locationType = LocationType.valueOf(locationTypeName)

    private var searchFulltext = ""
    private var searchFullStatuses = mutableListOf(ObjectStatus.PUBLISHED, ObjectStatus.DRAFT, ObjectStatus.APPROVED)
    private var fulltextIsSearched = false

    private var searchName = ""
    private var searchStrict = false
    private var searchExternalSystem = ""
    private var searchExternalName = ""
    private var searchNoEquating = false
    private var searchCountry: Core? = null
    private var searchObjectStatuses = mutableListOf<ObjectStatus>()
    private var searchVisibilities = mutableListOf<String>()
    private var searchTags = mutableListOf<String>()
    private var databaseIsSearched = false

    private lateinit var datagridFulltext: DataGrid<Location>
    private lateinit var datagridDatabase: DataGrid<Location>

    override fun render() {
        async {
            privileges = Accounts.getObjectTypePrivileges(locationTypeName.toLowerCase()).await()
            val externals = External.getAll().await().data
            searchExternalSystem = externals.firstOrNull()?.code ?: ""
            val visibilityAreas = VisibilityAreas.get().await().data.apply { sortBy { it.id } }
            val allCountryCores = Locations.getCountryCores(0, 500, "").await().data

            dom.apply {
                clrPageHeader(locationType.pluralName) {
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrButtonGroup {
                        clrButton("Добавить ${locationType.accusativeName}") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Plus
                            isDisabled = !privileges.contains("CREATE")
                            onClickFunction = {
                                Navigation.TourismObjects.add(locationTypeName)
                            }
                        }
                    }
                }
                clrTabs {
                    clrTab("Поиск по базе") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Название объекта или код:") {
                                    clrInput {
                                        value = searchName
                                        size = 30
                                        onChangeFunction = {
                                            searchName = value
                                        }
                                        onPressEnterFunction = {
                                            searchName = value
                                            databaseIsSearched = true
                                            datagridDatabase.getDataEnabled = databaseIsSearched
                                            datagridDatabase.start = 0
                                            datagridDatabase.render()
                                        }
                                    }
                                    clrCheckbox("точное совпадение") {
                                        isInline = true
                                        isChecked = searchStrict
                                        onChangeFunction = {
                                            searchStrict = isChecked
                                        }
                                    }
                                }
                                clrGroup("Страна:") {
                                    clrSelect<Core?> {
                                        clrOption("Не выбрана", null)
                                        allCountryCores.forEach {
                                            clrOption(it.publishedName ?: it.draftName
                                            ?: "—", it, searchCountry?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            searchCountry = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("Теги:") {
                                    if (searchTags.size > 0) {
                                        searchTags.forEach { tag ->
                                            clrLabel(tag) {
                                                style = Label.Style.Blue
                                                isDismissable = true
                                                onDismissFunction = {
                                                    searchTags.remove(tag)
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        width = 250
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (searchTags.none { it == entry.name }) {
                                                searchTags.add(entry.name)
                                                this@clrForm.render()
                                            }
                                        }
                                        inputPlaceholder = "Выберите тег"
                                    }
                                }
                                if (externals.isNotEmpty()) {
                                    clrGroup("Внешний справочник:") {
                                        clrSelect<String> {
                                            for (external in externals) {
                                                clrOption(external.name, external.code).apply {
                                                    if (searchExternalSystem.isNotEmpty())
                                                        selectedOptions.add(this)
                                                }
                                            }

                                            onChangeFunction = {
                                                searchExternalSystem = selectedOptions.firstOrNull()?.value ?: ""
                                            }
                                        }
                                        val externalNameInput = clrInput {
                                            size = 30
                                            value = searchExternalName
                                            onChangeFunction = {
                                                searchExternalName = value
                                            }
                                        }
                                        clrCheckbox("не имеющие приравнения") {
                                            isInline = true
                                            isChecked = searchNoEquating
                                            externalNameInput.isDisabled = isChecked
                                            externalNameInput.render()
                                            onChangeFunction = {
                                                searchNoEquating = isChecked
                                                externalNameInput.isDisabled = isChecked
                                                externalNameInput.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    label { appendText("Показывать:") }
                                    div {
                                        style.marginRight = "40px"
                                        val enumStatus = ObjectStatus.values()
                                        val defaultStatusCheckboxes = mutableListOf<Checkbox>()

                                        for (status in enumStatus) {
                                            clrCheckbox(status.displayName) {
                                                isChecked = searchObjectStatuses.contains(status)
                                                onChangeFunction = {
                                                    if (isChecked) {
                                                        searchObjectStatuses.add(status)
                                                    } else {
                                                        searchObjectStatuses.remove(status)
                                                    }
                                                }
                                            }.let { defaultStatusCheckboxes.add(it) }
                                        }
                                    }
                                    label { appendText("Видимость:") }
                                    div {
                                        val defaultVisibilityCheckboxes = mutableListOf<Checkbox>()

                                        for (area in visibilityAreas) {
                                            clrCheckbox(area.name) {
                                                isChecked = searchVisibilities.contains(area.code)
                                                onChangeFunction = {
                                                    if (isChecked) {
                                                        searchVisibilities.add(area.code)
                                                    } else {
                                                        searchVisibilities.remove(area.code)
                                                    }
                                                }
                                            }.let { defaultVisibilityCheckboxes.add(it) }
                                        }
                                    }
                                }
                                clrButton("Найти") {
                                    iconShape = IconShape.Search
                                    onClickFunction = {
                                        databaseIsSearched = true
                                        datagridDatabase.getDataEnabled = databaseIsSearched
                                        datagridDatabase.start = 0
                                        datagridDatabase.render()
                                    }
                                }
                            }
                        }
                        datagridDatabase = clrDatagrid {
                            clrColumn("Страна", 1) {
                                appendText(it.country.publishedName ?: it.country.draftName ?: "—")
                            }
                            clrColumn("Город", 1) {
                                val currentObjectParent = it.parent
                                if (currentObjectParent != null)
                                    appendText(currentObjectParent.publishedName ?: currentObjectParent.draftName
                                    ?: "—")
                                else appendText("—")
                            }
                            if (locationType == LocationType.AIRPORT) {
                                clrColumn("Код", 1, "code") {
                                    appendText(it.code)
                                }
                            }
                            clrColumn("Наименование", 2, "name") { entry ->
                                a {
                                    appendText(entry.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.TourismObjects.tourismObject(locationTypeName, entry.id, entry.coreId)
                                    }
                                }
                            }
                            clrColumn("Видимость", 1) {
                                var visibilityText = ""
                                val visibilitiesLastIndex = it.visibility.lastIndex
                                it.visibility.forEachIndexed { index, vis ->
                                    visibilityText += vis.name
                                    if (index != visibilitiesLastIndex) visibilityText += ", "
                                }
                                if (visibilitiesLastIndex == -1) visibilityText += "—"
                                appendText(visibilityText)
                            }
                            clrColumn("Статус", 1) {
                                appendText(ObjectStatus.valueOf(it.status).displayName)
                            }
                            clrColumn("Теги", 2) { tourismObject ->
                                val tourismObjectTags = tourismObject.tags.apply { sortBy { it.name.toLowerCase() } }
                                var tagsText = ""
                                val tagsLastIndex = tourismObject.tags.lastIndex
                                tourismObjectTags.forEachIndexed { index, tag ->
                                    tagsText += tag.name
                                    if (index != tagsLastIndex) tagsText += ", "
                                }
                                if (tagsLastIndex == -1) {
                                    tagsText += "—"
                                    appendText(tagsText)
                                } else {
                                    clrDropdown(tagsText) {
                                        isButton = false
                                        isDatagrid = true
                                        tourismObjectTags.forEach { clrItem(it.name) }
                                    }
                                }
                            }
                            overflowYVisible = true
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            orderField = "name"
                            getDataEnabled = false
                            getDataFunction = {
                                getDataDatabase(start, pageSize, orderField, reverseOrder)
                            }
                        }
                    }
                    clrTab("Полнотекстовый поиск") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup {
                                    clrInput {
                                        size = 50
                                        value = searchFulltext
                                        onChangeFunction = {
                                            searchFulltext = value
                                        }
                                        onPressEnterFunction = {
                                            searchFulltext = value
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                    clrButton("Найти") {
                                        isSmall = true
                                        margin = "0.15rem 0"
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                }
                                clrGroup("Статус:") {
                                    val enumStatus = ObjectStatus.values()
                                    val fulltextStatusCheckboxes = mutableListOf<Checkbox>()

                                    for (status in enumStatus) {
                                        clrCheckbox(status.displayName) {
                                            isInline = true
                                            isChecked = searchFullStatuses.contains(status)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    if (status == ObjectStatus.ARCHIVED)
                                                        searchFullStatuses.clear()
                                                    else searchFullStatuses.remove(ObjectStatus.ARCHIVED)
                                                    searchFullStatuses.add(status)
                                                    this@clrForm.render()
                                                } else {
                                                    searchFullStatuses.remove(status)
                                                }
                                            }
                                        }.let { fulltextStatusCheckboxes.add(it) }
                                    }
                                }
                            }
                        }
                        datagridFulltext = clrDatagrid {
                            if (locationType == LocationType.AIRPORT) {
                                clrColumn("Код", 1) {
                                    appendText(it.code)
                                }
                            }
                            clrColumn("Наименование", 3) { entry ->
                                a {
                                    appendText(entry.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.TourismObjects.tourismObject(locationTypeName, entry.id, entry.coreId)
                                    }
                                }
                            }
                            clrColumn("Статус", 3) { entry ->
                                appendText(ObjectStatus.valueOf(entry.status).displayName)
                            }
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            getDataEnabled = fulltextIsSearched
                            getDataFunction = {
                                getDataFulltext(start, pageSize)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getDataFulltext(start: Int, pageSize: Int) = if (searchFulltext.isNotBlank()) {
        val objectStatuses = searchFullStatuses.asSequence().map { it.name }.toList()
        Search.searchObjectType(searchFulltext, objectStatuses, start, pageSize, listOf(locationType.name.toLowerCase()))
    } else {
        async { ListResult<Location>(Meta(0, Paging(0, 10)), emptyArray()) }
    }

    private fun getDataDatabase(start: Int, pageSize: Int, orderField: String, reverseOrder: Boolean): Promise<ListResult<Location>> {
        val external = if (searchExternalSystem.isNotBlank()) {
            when {
                searchNoEquating -> "$searchExternalSystem:null"
                searchExternalName.isNotBlank() -> "$searchExternalSystem:$searchExternalName"
                else -> ""
            }
        } else ""

        return Locations.get(
                start, pageSize, orderField, reverseOrder, searchName, searchStrict,
                external, searchObjectStatuses, searchVisibilities, searchTags,
                searchCountry?.id, listOf(locationType)
        )
    }
}
