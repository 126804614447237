package ru.playa.sce.views.excursion

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Assets
import ru.playa.sce.api.Locations
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.BaseVOView
import kotlin.dom.appendText
import kotlin.dom.clear
import kotlin.js.Promise

class ExcursionView(id: Int, coreId: Int, initialTab: String = "main") : BaseVOView<Location>(id, coreId, initialTab) {

    companion object {
        const val PATH = "excursions/excursion"
    }

    override var objectType: String = "location"

    override fun getVO() = Locations.getById(id)

    override fun getDraft(): Promise<Location> {
        return Locations.getDraft(coreId)
    }

    override fun approve() = Locations.approve(id)

    override fun publish() = Locations.publish(id)

    override fun delete(id: Int) = Locations.delete(id)

    override fun restoreArchived(): Promise<Location> {
        return Locations.restoreArchived(coreId, id)
    }

    override fun navigateTo(id: Int, coreId: Int) {
        Navigation.Excursions.excursion(id, coreId)
    }

    override fun navigateToParent() {
        Navigation.Excursions.excursions()
    }

    override fun toDraftTab(tab: String) {
        Navigation.Excursions.excursionTab(draft.id, draft.coreId, tab)
    }

    override fun isViewButtonDisabled(urlProperty: Option?): Boolean {
        return urlProperty == null || LocationType.valueOf(vo.type) != LocationType.LANDMARK
    }

    override fun getPhotoRatio(): Promise<Ratio> {
        return Assets.getRatio(LocationType.EXCURSION.name.toLowerCase())
    }

    override fun getPrivileges(): Promise<Array<String>> {
        return Accounts.getObjectTypePrivileges(LocationType.EXCURSION.name.toLowerCase())
    }


    override fun PageHeader.buildPageHeaderPathPart() {
        clrLink("Объекты") {
            Navigation.start()
        }
        clrLink("Экскурсии") {
            Navigation.Excursions.excursions()
        }
    }

    private fun HTMLElement.renderBasicInfo() {
        div {
            style.apply { display = "flex"; flexDirection = "row" }
            div {
                style.width = "600px"
                clrForm {
                    isCompact = true
                    clrBlock {
                        if (vo.type == "REGION") {
                            clrGroup("Код") {
                                if (vo.code.isNotBlank()) span { appendText(vo.code) } else span { appendText("—") }
                            }
                        }
                        clrGroup("Наименование") {
                            if (vo.name.isNotBlank()) span { appendText(vo.name) } else span { appendText("—") }
                        }
                        clrGroup("Тип") {
                            if (vo.type.isNotBlank()) span { appendText(LocationType.valueOf(vo.type).displayName) } else span { appendText("—") }
                        }
                        clrGroup("Страна") {
                            span {
                                appendText(vo.country.run {
                                    publishedName ?: draftName ?: ""
                                })
                            }
                        }
                        clrGroup("Расположение") {
                            val parent = vo.parent
                            if (parent != null) {
                                val nameText = parent.publishedName ?: parent.draftName ?: "Удалено"
                                val subtypeText = if (parent.objectSubtype != null)
                                    LocationType.valueOf(parent.objectSubtype.toUpperCase()).displayName
                                else ""
                                val parentText = if (subtypeText.isNotBlank()) "$nameText ($subtypeText)" else nameText

                                if (parent.deletedDate == null) {
                                    span {
                                        appendText(parentText)
                                    }
                                } else {
                                    s {
                                        appendText(parentText)
                                    }
                                }
                            } else span { appendText("—") }
                        }
                        clrGroup("Теги") {
                            if (vo.tags.isNotEmpty()) {
                                val locationTags = vo.tags.apply { sortBy { it.name.toLowerCase() } }
                                locationTags.forEach {
                                    clrLabel(it.name) {
                                        style = Label.Style.Blue
                                    }
                                }
                            } else {
                                span { appendText("—") }
                            }
                        }
                        clrGroup("Области видимости") {
                            if (vo.visibility.isNotEmpty()) {
                                for (area in vo.visibility) {
                                    span {
                                        appendText(area.name)
                                        vo.visibility.run { if (indexOf(area) < lastIndex) appendText(", ") }
                                    }
                                }
                            } else {
                                span { appendText("—") }
                            }
                        }
                        clrGroup("Варианты написания") {
                            val synonyms = vo.synonyms ?: ""
                            if (synonyms.isNotBlank()) span { appendText(synonyms) } else span { appendText("—") }
                        }
                        clrGroup("Коды во внешних справочниках") {
                            if (vo.externalMappings.isNotEmpty()) {
                                for (mapping in vo.externalMappings) {
                                    span {
                                        appendText("${mapping.external.name} (${mapping.code})")
                                        vo.externalMappings.run { if (indexOf(mapping) < lastIndex) appendText(", ") }
                                    }
                                }
                            } else {
                                span { appendText("—") }
                            }
                        }
                    }
                }
                clrButton("Редактировать") {
                    style = ButtonStyle.Secondary
                    iconShape = IconShape.Pencil
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = {
                        if (objectStatus == ObjectStatus.ARCHIVED) {
                            dom.clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    async {
                                        Locations.restoreArchived(vo.coreId, vo.id).await()
                                        Navigation.Excursions.edit(vo.coreId)
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                            }
                        } else {
                            if (objectStatus == ObjectStatus.PUBLISHED) {
                                Application.applicationLayout.topLevelAlert.show {
                                    val alertScope = this
                                    clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                        button("btn alert-action") {
                                            innerText = "OK"
                                            onclick = { _ ->
                                                alertScope.items.clear()
                                                alertScope.hide()
                                            }
                                        }
                                    }
                                    style = Alert.Style.WARNING
                                }
                            }
                            Navigation.Excursions.edit(vo.coreId)
                        }
                    }
                }
            }
            div {
                style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                googleMap("100%", "500px") {
                    markerMode = GoogleMapMarkerMode.Marker
                    vo.mapData?.area?.let {
                        mapBounds(
                                it.southWest.latitude,
                                it.southWest.longitude,
                                it.northEast.latitude,
                                it.northEast.longitude
                        )
                    } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                    vo.mapData?.center?.let {
                        mapMarker(it.latitude, it.longitude)
                    }

                    onPostRender = {
                        mapFitControllerBounds()
                    }
                }
            }
        }
    }

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                createPageHeader(objectType)
                val tabContent: HTMLElement = newDiv { }
                clrSimpleTabs {
                    tabs.add(SimpleTabs.Tab("main", "Основная информация", initialTab == "main"))
                    if (initialTab == "main")
                        tabContent.renderBasicInfo()
                    tabs.add(SimpleTabs.Tab("photo", "Фотографии", initialTab == "photo"))
                    if (initialTab == "photo")
                        tabContent.createPhotoTab()
                    tabs.add(SimpleTabs.Tab("illustrations", "Иллюстрации", initialTab == "illustrations"))
                    if (initialTab == "illustrations")
                        tabContent.createIllustrationTab()
                    tabs.add(SimpleTabs.Tab("files", "Файлы", initialTab == "files"))
                    if (initialTab == "files")
                        tabContent.createFilesTab()
                    tabs.add(SimpleTabs.Tab("desc", "Описания", initialTab == "desc"))
                    if (initialTab == "desc")
                        tabContent.createDescriptionsTab("excursion")
                    tabs.add(SimpleTabs.Tab("news", "Новости", initialTab == "news"))
                    if (initialTab == "news")
                        tabContent.createNewsTab()
                    tabs.add(SimpleTabs.Tab("banners", "Баннеры", initialTab == "banners"))
                    if (initialTab == "banners")
                        tabContent.createBannersTab()
                    tabs.add(SimpleTabs.Tab("services", "Доп. услуги", initialTab == "services"))
                    if (initialTab == "services") {
                        tabContent.createServicesTab(includeParents = true, includeCountry = false)
                    }
                    onClickFunction = {
                        tabContent.clear()
                        when (it) {
                            "photo" -> {
                                tabContent.createPhotoTab()
                            }
                            "illustrations" -> {
                                tabContent.createIllustrationTab()
                            }
                            "files" -> {
                                tabContent.createFilesTab()
                            }
                            "main" -> {
                                tabContent.renderBasicInfo()
                            }
                            "desc" -> {
                                tabContent.createDescriptionsTab("excursion")
                            }
                            "news" -> {
                                tabContent.createNewsTab()
                            }
                            "banners" -> {
                                tabContent.createBannersTab()
                            }
                            "services" -> {
                                tabContent.createServicesTab(includeParents = false, includeCountry = true)
                            }
                        }

                    }
                }

                appendChild(tabContent)

            }
        }

    }
}
