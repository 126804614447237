package ru.playa.sce.views.externalSystems

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.h1
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Common
import ru.playa.sce.api.External
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.ApplicableType
import ru.playa.sce.dto.ApplicableTypeGroups
import ru.playa.sce.dto.ExternalSystem
import ru.playa.sce.dto.Option
import kotlin.dom.appendText


class ExternalSystemCreatorView : View() {

    companion object {
        const val PATH = "external-systems/add"
    }

    override fun render() {
        if (Application.userIsOwner) {
            dom.apply {
                clrPageHeader("Новый внешний справочник") {
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Внешние справочники") {
                        Navigation.ExternalSystems.externalSystems()
                    }
                }
                createEditContent(null) { it ->
                    External.createExternalSystem(it).then {
                        Navigation.ExternalSystems.externalSystems()
                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                            clrAlertItem("Добавление прошло успешно")
                            style = Alert.Style.SUCCESS
                        }
                    }.catch {
                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                            clrAlertItem("Ошибка добавления")
                            style = Alert.Style.ERROR
                        }
                    }
                }
            }
        } else {
            dom.apply {
                h1 {
                    appendText("Отказано в доступе")
                }
            }
        }
    }

    private fun HTMLDivElement.createEditContent(ext: ExternalSystem?, onSaveClick: (ExternalSystem) -> Unit) {
        var code: Input? = null
        var name: Input? = null
        var saveButton: Button? = null

        fun validate() {
            if (name?.value?.isBlank() != false || code?.value?.isBlank() != false) {
                saveButton?.isDisabled = true
                saveButton?.render()
            } else if (name?.value?.isNotBlank() == true && code?.value?.isNotBlank() == true) {
                saveButton?.isDisabled = false
                saveButton?.render()
            }
        }
        async {
            val applicableTypes = Common.getApplicableTypes().await()
            val selectedApplicableTypes = arrayListOf<ApplicableType>()
            div().apply {
                clrForm {
                    clrBlock {
                        clrGroup("Код справочника:") {
                            code = clrInput {
                                type = InputType.Text
                                this.value = ext?.code ?: ""
                                onInputFunction = {
                                    validate()
                                }
                            }
                        }
                        clrGroup("Наименование справочника:") {
                            name = clrInput {
                                type = InputType.Text
                                this.value = ext?.name ?: ""
                                onInputFunction = {
                                    validate()
                                }
                            }
                        }
                    }
                    clrBlock("Для каких объектов применим справочник:") {
                    }
                }
                clrGridRow {
                    ApplicableTypeGroups.values().forEach { applicableTypeGroup ->
                        clrGridColumn(6, 6, 3, 3, 3) {
                            style.width = "${100 / ApplicableTypeGroups.values().size}%"
                            classList.remove("col-sm-6")
                            span {
                                style.marginBottom = "8px"
                                style.fontWeight = "bold"
                                appendText(applicableTypeGroup.groupName)
                            }
                            applicableTypeGroup.groupTypes.forEach { groupType ->
                                val applicableType = applicableTypes.data.find { it.code == groupType }
                                if (applicableType != null) {
                                    clrCheckbox(applicableType.name) {
                                        this.isChecked = ext?.applicableTypes?.any {
                                            if (it.id == applicableType.id) {
                                                selectedApplicableTypes.add(applicableType)
                                                true
                                            } else
                                                false
                                        } ?: false

                                        this.onChangeFunction = {
                                            validate()
                                            if (isChecked) {
                                                selectedApplicableTypes.add(applicableType)
                                            } else
                                                selectedApplicableTypes.remove(applicableType)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                div {
                    saveButton = clrButton("Сохранить") {
                        iconShape = IconShape.Check
                        style = ButtonStyle.Primary
                        isDisabled = true
                        onClickFunction = { _ ->
                            val result = ExternalSystem(ext?.id ?: 0, code?.value ?: throw IllegalStateException(),
                                    name?.value ?: throw IllegalStateException(),
                                    selectedApplicableTypes.map { Option(it.id, it.name, false) }.toTypedArray())
                            onSaveClick(result)
                        }
                    }
                    clrButton("Отмена") {
                        style = ButtonStyle.Secondary
                        iconShape = IconShape.Times
                        onClickFunction = {
                            Navigation.ExternalSystems.externalSystems()
                        }
                    }
                }
            }
        }
    }

}