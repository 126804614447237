package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.Blob
import org.w3c.files.get
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Assets
import ru.playa.sce.core.Application
import ru.playa.sce.dto.AssetType
import kotlin.dom.clear


class CKEditorFilePluginDialog(val parent: HTMLElement, private val objectId: Int, private val coreId: Int, private val editor: CKEDITOR.editor) : Component(parent) {
    @Suppress("IMPLICIT_CAST_TO_ANY")
    override fun build() = async {
        newDiv {
            clrModalDialog {
                clrModalDialogContent {
                    clrModalDialogHeader("Выберите файл") {
                        parent.clear()
                    }
                    lateinit var plane: HTMLElement
                    clrModalDialogBody {
                        plane = div {

                        }
                        if (objectId != -1) {
                            Assets.get(objectId).then { assetsItem ->
                                val files = assetsItem.data.filter { it.assetType == AssetType.FILE.name }
                                plane.apply {
                                    if (files.isNotEmpty()) {
                                        clrTable {
                                            this.clrBody {
                                                files.forEach { asset ->
                                                    clrRow {
                                                        clrCell {
                                                            a {
                                                                innerText = asset.name
                                                                href = "javascript:void(0);"
                                                                onclick = { _ ->
                                                                    lateinit var submitButton: Button
                                                                    lateinit var placeholder: Input
                                                                    plane.apply {
                                                                        clear()
                                                                        clrForm {
                                                                            clrBlock("") {
                                                                                clrGroup("Отображаемый текст") {
                                                                                    placeholder = clrInput {
                                                                                        type = InputType.Text
                                                                                        value = asset.name
                                                                                        onInputFunction = {
                                                                                            if (this.isInvalid) {
                                                                                                submitButton.hide()
                                                                                                submitButton.render()
                                                                                            } else {
                                                                                                submitButton.show()
                                                                                                submitButton.render()
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            clrButtonsBlock {
                                                                                submitButton = clrButton("Ок") {
                                                                                    type = ButtonType.Button
                                                                                    style = ButtonStyle.Success
                                                                                    iconShape = IconShape.Check
                                                                                    onClickFunction = {
                                                                                        editor.insertHtml("<a target=\"_blank\" href=\"/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}\">${placeholder.value}</a>")
                                                                                        parent.clear()
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.style.verticalAlign = "middle"
                                                            this.align = "left"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        span {
                                            innerText = "Файлов нет"
                                        }
                                    }
                                }
                            }
                        }
                    }
                    clrModalDialogFooter {

                    }
                    var form: Form? = null
                    lateinit var fileInput: Input
                    form = clrForm {
                        this.clrBlock {
                            fileInput = clrInput {
                                type = InputType.File
                                accept = ".txt,.pdf,.zip,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChangeFunction = { event ->


                                    val file = (event.target as HTMLInputElement).files?.get(0)
                                    if (file != null) {
                                        if (file.size > 10485760) {
                                            Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                style = Alert.Style.WARNING
                                                clrAlertItem("Файл превышает допустимый размер", style)
                                            }
                                        } else {
                                            lateinit var submitButton: Button
                                            lateinit var placeholder: Input
                                            plane.apply {
                                                clear()
                                                clrForm {
                                                    clrBlock("") {
                                                        clrGroup("Отображаемый текст") {
                                                            placeholder = clrInput {
                                                                type = InputType.Text
                                                                value = file.name
                                                                onInputFunction = {
                                                                    if (this.isInvalid) {
                                                                        submitButton.hide()
                                                                        submitButton.render()
                                                                    } else {
                                                                        submitButton.show()
                                                                        submitButton.render()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    clrButtonsBlock {
                                                        submitButton = clrButton("Ок") {
                                                            style = ButtonStyle.Success
                                                            iconShape = IconShape.Check
                                                            type = ButtonType.Button
                                                            onClickFunction = {
                                                                Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                                    style = Alert.Style.INFO
                                                                    clrAlertItem("Файл загружается", style)
                                                                }
                                                                Application.loadingIndicator.show()
                                                                this@clrButton.isDisabled = true
                                                                this@clrButton.render()
                                                                Assets.sendFile(form?.getHTMLElement() as HTMLFormElement, objectId, coreId, file.name
                                                                        , file as Blob, file.name, ((objectId == -1) && (coreId == -1))).then { asset ->

                                                                    Application.loadingIndicator.hide()
                                                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                                        style = Alert.Style.SUCCESS
                                                                        clrAlertItem("Файл загружен", style)
                                                                    }
                                                                    editor.insertHtml("<a target=\"_blank\" href=\"/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}\">${placeholder.value}</a>")
                                                                    parent.clear()

                                                                }.catch {
                                                                    Application.loadingIndicator.hide()
                                                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                                        style = Alert.Style.ERROR
                                                                        clrAlertItem("Ошибка загрузки файла", style)
                                                                    }
                                                                    this@clrButton.isDisabled = false
                                                                    this@clrButton.render()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                                onPostRender = {
                                    hide()
                                }
                            }
                        }
                    }
                    this.clrButton("Загрузить с компьютера") {
                        style = ButtonStyle.Flat
                        onClickFunction = {
                            fileInput.getHTMLElement().click()
                        }
                    }
                }
            }
        }
    }
}