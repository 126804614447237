package ru.playa.sce.views.tourismObjects

import kotlinx.coroutines.async
import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.button
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.s
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Assets
import ru.playa.sce.api.Locations
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.BaseVOView
import kotlin.dom.appendText
import kotlin.js.Promise

class TourismObjectView(
        private val locationTypeName: String,
        id: Int,
        coreId: Int,
        initialTab: String = "main"
) : BaseVOView<Location>(id, coreId, initialTab) {

    companion object {
        const val PATH = "tourismObjects/tourismObject"
    }

    override var objectType: String = "location"

    override fun getVO(): Promise<Location> {
        return Locations.getById(id)
    }

    override fun getDraft(): Promise<Location> {
        return Locations.getDraft(coreId)
    }

    override fun approve() = Locations.approve(vo.id)

    override fun publish() = Locations.publish(vo.id)

    override fun delete(id: Int) = Locations.delete(vo.id)

    override fun restoreArchived(): Promise<Location> {
        return Locations.restoreArchived(coreId, id)
    }

    override fun navigateTo(id: Int, coreId: Int) {
        Navigation.TourismObjects.tourismObject(locationTypeName, id, coreId)
    }

    override fun navigateToParent() {
        Navigation.TourismObjects.tourismObjects(locationTypeName)
    }

    override fun toDraftTab(tab: String) {
        Navigation.TourismObjects.tourismObjectTab(locationTypeName, draft.id, draft.coreId, tab)
    }

    override fun isViewButtonDisabled(urlProperty: Option?): Boolean {
        return urlProperty == null || LocationType.valueOf(vo.type) != LocationType.LANDMARK
    }

    override fun getPhotoRatio(): Promise<Ratio> {
        return Assets.getRatio(locationTypeName.toLowerCase())
    }

    override fun getPrivileges(): Promise<Array<String>> {
        return Accounts.getObjectTypePrivileges(locationTypeName.toLowerCase())
    }

    private val locationType = LocationType.valueOf(locationTypeName)

    override fun PageHeader.buildPageHeaderPathPart() {
        clrLink("Объекты") {
            Navigation.start()
        }
        clrLink(locationType.pluralName) {
            Navigation.TourismObjects.tourismObjects(locationTypeName)
        }
    }

    lateinit var googleMap: GoogleMap

    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                createPageHeader(locationTypeName)
                clrTabs {
                    clrTab("Основная информация") {
                        div {
                            style.apply { display = "flex"; flexDirection = "row" }
                            div {
                                style.width = "600px"
                                clrForm {
                                    isCompact = true
                                    clrBlock {
                                        if (locationType == LocationType.AIRPORT) {
                                            clrGroup("Код") {
                                                if (vo.code.isNotBlank()) span { appendText(vo.code) } else span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Наименование") {
                                            if (vo.name.isNotBlank()) span { appendText(vo.name) } else span { appendText("—") }
                                        }
                                        clrGroup("Страна") {
                                            span {
                                                appendText(vo.country.run {
                                                    publishedName ?: draftName ?: ""
                                                })
                                            }
                                        }
                                        clrGroup("Расположение") {
                                            val parent = vo.parent
                                            if (parent != null) {
                                                val nameText = parent.publishedName ?: parent.draftName ?: "Удалено"
                                                val subtypeText = if (parent.objectSubtype != null)
                                                    LocationType.valueOf(parent.objectSubtype.toUpperCase()).displayName
                                                else ""
                                                val parentText = if (subtypeText.isNotBlank()) "$nameText ($subtypeText)" else nameText

                                                if (parent.deletedDate == null) {
                                                    span {
                                                        appendText(parentText)
                                                    }
                                                } else {
                                                    s {
                                                        appendText(parentText)
                                                    }
                                                }
                                            } else span { appendText("—") }
                                        }
                                        clrGroup("Теги") {
                                            if (vo.tags.isNotEmpty()) {
                                                val targetTags = vo.tags.apply { sortBy { it.name.toLowerCase() } }
                                                targetTags.forEach {
                                                    clrLabel(it.name) {
                                                        style = Label.Style.Blue
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Области видимости") {
                                            if (vo.visibility.isNotEmpty()) {
                                                for (area in vo.visibility) {
                                                    span {
                                                        appendText(area.name)
                                                        vo.visibility.run { if (indexOf(area) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Варианты написания") {
                                            val synonyms = vo.synonyms ?: ""
                                            if (synonyms.isNotBlank()) span { appendText(synonyms) } else span { appendText("—") }
                                        }
                                        clrGroup("Коды во внешних справочниках") {
                                            if (vo.externalMappings.isNotEmpty()) {
                                                for (mapping in vo.externalMappings) {
                                                    span {
                                                        appendText("${mapping.external.name} (${mapping.code})")
                                                        vo.externalMappings.run { if (indexOf(mapping) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                    }
                                }
                                clrButton("Редактировать") {
                                    style = ButtonStyle.Secondary
                                    iconShape = IconShape.Pencil
                                    isDisabled = !privileges.contains("EDIT")
                                    onClickFunction = {
                                        if (objectStatus == ObjectStatus.ARCHIVED) {
                                            clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                    @Suppress("DeferredResultUnused")
                                                    async {
                                                        Locations.restoreArchived(vo.coreId, vo.id).await()
                                                        Navigation.TourismObjects.edit(locationTypeName, vo.coreId)
                                                    }
                                                }
                                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                            }
                                        } else {
                                            if (objectStatus == ObjectStatus.PUBLISHED) {
                                                Application.applicationLayout.topLevelAlert.show {
                                                    val alertScope = this
                                                    clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                                        button("btn alert-action") {
                                                            innerText = "OK"
                                                            onclick = { _ ->
                                                                alertScope.items.clear()
                                                                alertScope.hide()
                                                            }
                                                        }
                                                    }
                                                    style = Alert.Style.WARNING
                                                }
                                            }
                                            Navigation.TourismObjects.edit(locationTypeName, vo.coreId)
                                        }
                                    }
                                }
                            }
                            div {
                                style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                                googleMap = googleMap("100%", "500px") {
                                    markerMode = GoogleMapMarkerMode.Marker
                                    vo.mapData?.area?.let {
                                        mapBounds(
                                                it.southWest.latitude,
                                                it.southWest.longitude,
                                                it.northEast.latitude,
                                                it.northEast.longitude
                                        )
                                    } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                                    vo.mapData?.center?.let {
                                        mapMarker(it.latitude, it.longitude)
                                    }

                                    onPostRender = {
                                        mapFitControllerBounds()
                                    }
                                }
                            }
                        }
                    }
                    createPhotoTab()
                    createIllustrationTab()
                    createFilesTab()
                    createDescriptionsTab(locationTypeName)
                    createNewsTab(locationTypeName)
                    createBannersTab(locationTypeName)
                }
            }
        }
    }
}
