package ru.playa.sce.views.textBlocks

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.Blob
import org.w3c.files.get
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.img
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Assets
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.TextBlocks
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import kotlin.dom.appendText
import kotlin.dom.clear
import kotlin.js.Date

class TextBlockEditorView(
        private val coreId: Int? = null,
        private val objectType: String? = null,
        private val objectId: Int? = null,
        private val objectCoreId: Int? = null,
        private val textType: String? = null,
        private val objectName: String? = null
) : EditorView() {

    companion object {
        const val PATH = "textBlocks/editor"
    }


    private var draft: Text? = null
    private var dictionaryContentBlocks: ArrayList<DictionaryEntry> = arrayListOf()

    private var textBlockName = ""
    private var textBlockType = TextType.TEXT_BANNER
    private var textBlockStartDate: Date? = null
    private var textBlockStartDateString = ""
    private var textBlockEndDate: Date? = null
    private var textBlockEndDateString = ""
    private var textBlockLinkType = LinkType.SELF
    private var textBlockLinkURL = ""
    private var textBlockLinkTarget: Core? = null
    private val textBlockBlocks = mutableListOf<Option>()
    private val textBlockObjects = mutableListOf<Core>()
    private var textBlockAnnounce = ""
    private var textBlockDescription = ""
    private var ckeditor: CKEDITOR.editor? = null
    lateinit var descriptionContainer: HTMLDivElement
    @Suppress("JoinDeclarationAndAssignment")
    val backgroundImageContainer: HTMLDivElement = newDiv { }

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(textBlockName)) validationErrors.add("Указано некорректый заголовок текстового блока")
        if (textBlockLinkType == LinkType.URL && textBlockLinkURL.isBlank()) validationErrors.add("Необходимо указать URL для ссылки")
        if (textBlockLinkType == LinkType.OBJECT && textBlockLinkTarget == null) validationErrors.add("Необходимо указать объект для ссылки")
        val startdate = textBlockStartDate
        val enddate = textBlockEndDate
        if (startdate != null && enddate != null && startdate.getTime() > enddate.getTime())
            validationErrors.add("Дата начала отображения не может быть позже даты окончания")
    }

    override fun saveEditorData() = async {
        val startDate = textBlockStartDate?.let {
            "${it.getFullYear()}-${(it.getMonth() + 1).toString().padStart(2, '0')}-${it.getDate().toString().padStart(2, '0')}"
        }
        val endDate = textBlockEndDate?.let {
            "${it.getFullYear()}-${(it.getMonth() + 1).toString().padStart(2, '0')}-${it.getDate().toString().padStart(2, '0')}"
        }

        if (ckeditor != null && textBlockType != TextType.GRAPHICAL_BANNER)
            textBlockDescription = ckeditor?.getData(false) ?: ""

        val textBlock = Text(
                id = if (coreId != null) draft?.id ?: 0 else 0,
                code = if (coreId != null) draft?.code ?: "" else "",
                name = textBlockName,
                coreId = if (coreId != null) draft?.coreId ?: 0 else 0,
                textType = textBlockType.name,
                anons = textBlockAnnounce,
                description = textBlockDescription,
                start = startDate,
                end = endDate,
                linkType = textBlockLinkType.name,
                linkURL = if (textBlockLinkType == LinkType.URL) textBlockLinkURL else "",
                target = textBlockLinkTarget,
                blocks = textBlockBlocks.toTypedArray(),
                objects = textBlockObjects.toTypedArray()
        )
        val result = if (coreId == null) TextBlocks.create(textBlock).await() else TextBlocks.update(textBlock).await()
//        confirmOnLeave = false
        if (objectCoreId == null) Navigation.TextBlocks.textBlock(result.id, result.coreId)
        else Navigation.navigateToObject(objectType ?: "", objectId ?: 0, objectCoreId, textType ?: "")
    }

    private fun initEditorData() = async {
        textBlockStartDate = null
        textBlockStartDateString = ""
        textBlockEndDate = null
        textBlockEndDateString = ""
        textBlockBlocks.clear()
        textBlockObjects.clear()
        if (coreId != null) {
            draft = TextBlocks.getDraft(coreId).await()
            textBlockName = draft?.name ?: ""
            textBlockType = TextType.valueOf(draft?.textType ?: "")
            val startdate = draft?.start
            if (startdate != null) {
                textBlockStartDate = Date(startdate)
                textBlockStartDateString = "${startdate.slice(8..9)}.${startdate.slice(5..6)}.${startdate.slice(0..3)}"
            }
            val enddate = draft?.end
            if (enddate != null) {
                textBlockEndDate = Date(enddate)
                textBlockEndDateString = "${enddate.slice(8..9)}.${enddate.slice(5..6)}.${enddate.slice(0..3)}"
            }
            draft?.let {
                textBlockLinkType = LinkType.valueOf(draft?.linkType ?: "")
                textBlockLinkURL = draft?.linkURL ?: ""
                textBlockLinkTarget = draft?.target
                textBlockBlocks.addAll(draft?.blocks ?: emptyArray())
                textBlockObjects.addAll(draft?.objects ?: emptyArray())
                textBlockAnnounce = draft?.anons ?: ""
                textBlockDescription = draft?.description ?: ""
            }
        } else {
            textBlockName = ""
            textBlockType = if (textType == "news") TextType.NEWS_ITEM else if (textType == "services") TextType.SERVICES else TextType.TEXT_BANNER
            textBlockLinkType = LinkType.SELF
            textBlockLinkURL = ""
            textBlockLinkTarget = null
            // Блок создается из определенного объекта
            // "fromObject" помечает объект связанным по умолчанию
            if (objectCoreId != null) {
                textBlockObjects.add(Core(id = objectCoreId, code = "fromObject", objectType = "", publishedName = objectName))
            }
            textBlockAnnounce = ""
            textBlockDescription = ""
        }
        dictionaryContentBlocks.clear()
        dictionaryContentBlocks.addAll(Dictionaries.getDictionaryEntries("contentblock", 0, 500, "").await().data.apply { this.sortBy { it.rating } })
//        confirmOnLeave = false
    }

    private fun rebuildDescription() {
        backgroundImageContainer.clear()
        CKEDITOR.config.filebrowserBrowseUrl = ""
        CKEDITOR.config.filebrowserUploadUrl = ""
        CKEDITOR.config.height = 400
        CKEDITOR.config.colorButton_colors = "757374,9B59B6,6B2365,6c2267,9b7fa0,1ABC9C,2ECC71,3498DB,9B59B6,4E5F70,F1C40F,16A085,27AE60,2980B9,8E44AD,2C3E50,F39C12,E67E22,E74C3C,ECF0F1,95A5A6,DDD,FFF,D35400,C0392B,BDC3C7,7F8C8D,999,000"
        CKEditorExtension.init(if (coreId != 0) draft?.id ?: -1 else -1, coreId ?: -1)
        CKEDITOR.config.also { config ->
            config.toolbarGroups = js("toolbarGroups: [" +
                    "{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] }," +
                    "{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] }," +
                    "{ name: 'forms', groups: [ 'forms' ] }," +
                    "{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] }," +
                    "{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },\n" +
                    "{ name: 'insert', groups: [ 'insert' ] }" +
                    "]")
            config.removeButtons = "scevalign_top,scevalign_center,scevalign_bottom,Image,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,About,ShowBlocks,BGColor,PasteFromWord,Checkbox"
            config.customConfig = ""
        }

        descriptionContainer.appendChild(newDiv {
            if (textBlockType != TextType.GRAPHICAL_BANNER) {
                ckEditor {
                    on("change") {
                        if (checkDirty()) {
                            textBlockDescription = getData(false)
                            enableSaveButton()
                        }
                    }
                    ckeditor = this
                    this.setData(textBlockDescription)
                }
            } else {
                @Suppress("JoinDeclarationAndAssignment")
                lateinit var imagePreview: HTMLImageElement
                div {
                    lateinit var fileUpload: Input
                    div {
                        lateinit var uploadForm: Form
                        uploadForm = clrForm {
                            clrBlock {
                                fileUpload = clrInput {
                                    type = InputType.File
                                    accept = ".jpg,.png,.bmp"
                                    onChangeFunction = { event ->
                                        if ((event.target as HTMLInputElement).files?.length == 0) {
                                            Unit// return. Files not selected
                                        }
                                        val file = (event.target as HTMLInputElement).files?.get(0)
                                        if (file != null) {
                                            if (file.size > 10485760) {
                                                Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                    style = Alert.Style.WARNING
                                                    clrAlertItem("Файл превышает допустимый размер", style)
                                                }
                                            } else {
                                                Application.loadingIndicator.show()
                                                Assets.sendFile(uploadForm.getHTMLElement() as HTMLFormElement, objectId
                                                        ?: -1, coreId ?: -1, file.name
                                                        , file as Blob, file.name, true).then { asset ->
                                                    val assetUrl = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}"
                                                    imagePreview.src = assetUrl
                                                    textBlockDescription = assetUrl
                                                    imagePreview.hidden = false
                                                    enableSaveButton()
                                                    Application.loadingIndicator.hide()
                                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                        style = Alert.Style.SUCCESS
                                                        clrAlertItem("Файл загружен", style)
                                                    }
                                                }.catch {
                                                    Application.loadingIndicator.hide()
                                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                        style = Alert.Style.ERROR
                                                        clrAlertItem("Ошибка загрузки файла", style)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    onPostRender = {
                                        hide()
                                    }
                                }

                            }
                        }
                    }
                    clrButton("Загрузить с компьютера") {
                        style = ButtonStyle.Secondary
                        onClickFunction = {
                            fileUpload.getHTMLElement().click()
                        }
                    }
                    clrButton {
                        style = ButtonStyle.WarningOutline
                        iconShape = IconShape.Trash
                        onClickFunction = {
                            textBlockDescription = ""
                            descriptionContainer.clear()
                            enableSaveButton()
                            rebuildDescription()
                        }
                    }
                }
                div {
                    imagePreview = img {
                        style.overflowX = "auto"
                        src = textBlockDescription
                        hidden = textBlockDescription.isBlank()
                    }
                }
            }
        })
    }

    override fun render() {
        async {
            @Suppress("JoinDeclarationAndAssignment")
            val backgroundImageContainer: HTMLDivElement = newDiv { }
            lateinit var anonsTextField: TextArea
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (coreId == null) "Новый текстовый блок" else draft?.name ?: "") {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Текстовые блоки") {
                        Navigation.TextBlocks.textBlocks()
                    }
                }
                clrForm {
                    isCompact = true
                    clrBlock {
                        clrGroup("Заголовок", true) {
                            clrInput {
                                value = textBlockName
                                tooltipContent = "От 2 до 200 символов"
                                validationPredicate = {
                                    validateName(value)
                                }
                                onChangeFunction = {
                                    textBlockName = value
                                }
                                onInputFunction = {
                                    enableSaveButton()
                                }
                            }
                        }
                        if (coreId == null && (objectCoreId == null || textType == "banners")) {
                            clrGroup("Тип") {
                                val textTypeOptions: Array<TextType> = if (textType == "banners")
                                    arrayOf(TextType.TEXT_BANNER, TextType.GRAPHICAL_BANNER)
                                else
                                    TextType.values().filter { it != TextType.PAGE_TEXT }.toTypedArray()//Временно блокируем выбор Текст на странице

                                clrSelect<TextType> {
                                    textTypeOptions.forEach {
                                        clrOption(it.displayName, it, textBlockType == it)
                                    }

                                    onChangeFunction = {
                                        textBlockType = selectedOptions.first().value
                                        enableSaveButton()
                                        backgroundImageContainer.clear()
                                        //anonsTextField.isDisabled = textBlockType == TextType.GRAPHICAL_BANNER
                                        anonsTextField.render()
                                        descriptionContainer.clear()
                                        rebuildDescription()
                                    }
                                }
                            }
                        }
                        clrGroup("Дата начала отображения") {
                            clrDatePicker {
                                value = textBlockStartDateString
                                selectedDate = textBlockStartDate
                                textBlockStartDate?.let { displayDate = it }
                                onInputFunction = {
                                    textBlockStartDateString = value
                                    textBlockStartDate = selectedDate
                                    enableSaveButton()
                                }
                                onPickFunction = {
                                    textBlockStartDateString = value
                                    textBlockStartDate = selectedDate
                                    enableSaveButton()
                                }
                            }
                        }
                        clrGroup("Дата окончания отображения") {
                            clrDatePicker {
                                value = textBlockEndDateString
                                selectedDate = textBlockEndDate
                                textBlockEndDate?.let { displayDate = it }
                                onInputFunction = {
                                    textBlockEndDateString = value
                                    textBlockEndDate = selectedDate
                                    enableSaveButton()
                                }
                                onPickFunction = {
                                    textBlockEndDateString = value
                                    textBlockEndDate = selectedDate
                                    enableSaveButton()
                                }
                            }
                        }
                        clrGroup("Тип ссылки") {
                            clrSelect<LinkType> {
                                LinkType.values().forEach {
                                    clrOption(it.displayName, it, textBlockLinkType == it)
                                }

                                onChangeFunction = {
                                    textBlockLinkType = selectedOptions.first().value
                                    enableSaveButton()
                                    this@clrForm.render()
                                }
                            }
                        }
                        clrGroup("URL для ссылки", true) {
                            hidden = textBlockLinkType != LinkType.URL
                            clrInput {
                                value = textBlockLinkURL
                                onChangeFunction = {
                                    textBlockLinkURL = value
                                }
                                onInputFunction = {
                                    enableSaveButton()
                                }
                            }
                        }
                        clrGroup("Объект", true) {
                            hidden = textBlockLinkType != LinkType.OBJECT
                            span {
                                appendText(textBlockLinkTarget?.let {
                                    it.publishedName ?: it.draftName ?: "Удаленный"
                                } ?: "Не выбран")
                            }
                            clrButton {
                                style = ButtonStyle.Flat
                                isIcon = true
                                iconShape = IconShape.Pencil
                                tooltipTitle = "Выбрать"
                                onClickFunction = {
                                    dom.coreSelectionDialog {
                                        selectedObject = textBlockLinkTarget
                                        onSaveFunction = {
                                            textBlockLinkTarget = selectedObject
                                            this@clrForm.render()
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            if (textBlockLinkTarget != null) {
                                clrButton {
                                    style = ButtonStyle.Flat
                                    isIcon = true
                                    iconShape = IconShape.Times
                                    tooltipTitle = "Очистить"
                                    onClickFunction = {
                                        textBlockLinkTarget = null
                                        this@clrForm.render()
                                    }
                                }
                            }
                        }
                        clrGroup("Блоки контента") {
                            dictionaryContentBlocks.forEach { cb ->
                                val cbOption = Option(cb.id, cb.name, false)
                                clrCheckbox(cb.name) {
                                    isInline = true
                                    isChecked = textBlockBlocks.any { it.id == cb.id }
                                    onChangeFunction = { _ ->
                                        if (isChecked) {
                                            textBlockBlocks.add(cbOption)
                                        } else {
                                            textBlockBlocks.find { it.id == cb.id }?.let {
                                                textBlockBlocks.remove(it)
                                            }
                                        }
                                        enableSaveButton()
                                    }
                                }
                            }
                        }
                        clrGroup("Связанные объекты") {
                            clrButton {
                                style = ButtonStyle.Flat
                                isIcon = true
                                iconShape = IconShape.Pencil
                                tooltipTitle = "Выбрать"
                                onClickFunction = {
                                    dom.coreSelectionDialog {
                                        selectionMode = CoreSelectionMode.MULTIPLE
                                        selectedObjects.clear()
                                        selectedObjects.addAll(textBlockObjects)
                                        onSaveFunction = {
                                            textBlockObjects.clear()
                                            textBlockObjects.addAll(selectedObjects)
                                            enableSaveButton()
                                            this@clrForm.render()
                                        }
                                    }
                                }
                            }
                            if (textBlockObjects.size > 0) {
                                textBlockObjects.forEach { obj ->
                                    //if (obj.code != "fromObject") {
                                    clrLabel(obj.publishedName ?: obj.draftName ?: "Безымянный") {
                                        style = Label.Style.None
                                        isDismissable = true
                                        onDismissFunction = {
                                            textBlockObjects.remove(obj)
                                            enableSaveButton()
                                        }
                                    }
                                    //}
                                }
                            } else {
                                span { innerText = "—" }
                            }
                        }
                        clrGroup("Анонс") {
                            anonsTextField = clrTextArea {
                                rows = 3
                                cols = 80
                                value = textBlockAnnounce
                                onChangeFunction = {
                                    textBlockAnnounce = value
                                }
                                onInputFunction = {
                                    enableSaveButton()
                                }
                                //isDisabled = textBlockType == TextType.GRAPHICAL_BANNER
                            }
                        }

                    }
                    clrBlock("Описание") {

                    }
                }
                this.appendChild(backgroundImageContainer)

                descriptionContainer = div {

                }

                rebuildDescription()

                validationAlertContainer = div { style.marginBottom = "5px" }
                createSaveButton()
                clrButton("Отмена") {
                    style = ButtonStyle.Secondary
                    iconShape = IconShape.Times
                    onClickFunction = {
                        when {
                            objectCoreId != null -> Navigation.navigateToObject(objectType ?: "", objectId
                                    ?: 0, objectCoreId, textType ?: "")
                            coreId != null -> Navigation.TextBlocks.textBlock(draft?.id ?: -1, draft?.coreId ?: -1)
                            else -> Navigation.TextBlocks.textBlocks()
                        }
                    }
                }
            }
        }
    }
}