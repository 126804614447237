package ru.playa.sce.components

import kotlinx.coroutines.await
import org.w3c.dom.HTMLElement
import ru.playa.kotlinx.clarity.js.components.Component
import ru.playa.kotlinx.clarity.js.html.*
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.async
import kotlin.dom.appendText
import kotlin.js.Promise
import kotlin.math.floor
import kotlin.math.min


class SearchResult<T>(parent: HTMLElement) : Component(parent) {

    var start = 0
    var pageSize = 10
    val pageSizeOptions = mutableListOf<Int>()
    private var dataList: List<T>? = null
    var getDataFunction: (() -> Promise<ListResult<T>>)? = null
    private var total = 0
    private var data: Array<T> = emptyArray()
    private val pagination = PaginationState()
    private var isInversedZebra = false

    private inner class PaginationState {

        var totalCount = 0
        var currentPage = 1
        var lastPage = 0
        var remainingPages = -1
        var outOfRange = true
        var firstCount = 0
        var lastCount = 0
        var finalPage = false

        fun update() {
            val controller = this@SearchResult
            val dataSize = controller.data.size
            totalCount = controller.total
            currentPage = controller.start / controller.pageSize + 1
            if (totalCount != -1) {
                lastPage = -floor(-totalCount.toDouble() / controller.pageSize).toInt()
                remainingPages = lastPage - currentPage
                outOfRange = remainingPages < 0
                firstCount = if (outOfRange) 0 else controller.start + 1
                lastCount = if (outOfRange) 0 else controller.start + dataSize
            } else {
                firstCount = controller.start + 1
                lastCount = controller.start + dataSize
                finalPage = controller.pageSize <= dataSize
            }
        }
    }

    var itemRender: ((T) -> ItemRender)? = null

    override fun build() = async {
        val function = getDataFunction
        if (function != null) function().await().let {
            total = it.meta.total
            data = it.data
        }
        else dataList?.let {
            total = it.size
            data = it.subList(start, min(start + pageSize, it.size)).toTypedArray()
        }
        pageSizeOptions.apply { if (!contains(pageSize)) add(pageSize) }.sort()
        pagination.update()
        return@async newDiv("search_results") {
            if (data.isEmpty()) {
                if (getDataFunction != null)
                    span {
                        textContent = "Нет результатов"
                    }
            }
            div("search_results_title") {
                data.forEachIndexed { index, t ->
                    div(if ((index % 2 != 0) == isInversedZebra) "search_result_groups" else "search_result_groups_even") {
                        div("search_result_group") {
                            itemRender?.invoke(t)?.let { item ->
                                if (item.titleURL.isEmpty()) {
                                    div("search_result_position") {
                                        textContent = item.title
                                    }
                                } else {
                                    a("search_result_position") {
                                        textContent = item.title
                                        href = item.titleURL
                                    }
                                }
                                div("search_result_position_path") {
                                    (item.addressFunction)()
                                }
                                div("search_result_position_text") {
                                    (item.contentFunction)()
                                }
                            }
                        }
                    }
                }
            }
            val controller = this@SearchResult
            if (data.isNotEmpty())
                div("datagrid-foot") {
                    if (pagination.totalCount >= 0) {
                        if (pagination.outOfRange) {
                            div("datagrid-foot-description") {
                                appendText("0 из ${pagination.totalCount} результатов")
                            }
                        } else {
                            div("datagrid-foot-description") {
                                appendText("Показаны результаты ${pagination.firstCount} - ${pagination.lastCount} из ${pagination.totalCount}")
                                if (pageSizeOptions.isNotEmpty()) {
                                    appendText(" по ")
                                    select {
                                        for (option in pageSizeOptions) {
                                            option {
                                                option.toString().let {
                                                    appendText(it)
                                                    value = it
                                                }
                                                selected = option == controller.pageSize
                                            }
                                        }
                                        onchange = {
                                            controller.pageSize = value.toIntOrNull() ?: controller.pageSize
                                            controller.start = 0
                                            controller.render()
                                        }
                                        disabled = pageSizeOptions.size < 2
                                    }
                                }
                            }
                            div("pagination") {
                                ul("pagination-list") {
                                    if (pagination.currentPage > 1) {
                                        li {
                                            button("pagination-previous") {
                                                type = "button"
                                                onclick = {
                                                    controller.start = (pagination.currentPage - 2) * controller.pageSize
                                                    controller.render()
                                                }
                                            }
                                        }
                                        li {
                                            button {
                                                type = "button"
                                                appendText("1")
                                                onclick = {
                                                    controller.start = 0
                                                    controller.render()
                                                }
                                            }
                                        }
                                    }
                                    if (pagination.currentPage > 3) {
                                        li {
                                            appendText("...")
                                        }
                                    }
                                    if (pagination.currentPage > 2) {
                                        li {
                                            button {
                                                type = "button"
                                                appendText("${pagination.currentPage - 1}")
                                                onclick = {
                                                    controller.start = (pagination.currentPage - 2) * controller.pageSize
                                                    controller.render()
                                                }
                                            }
                                        }
                                    }
                                    li("pagination-current") {
                                        appendText("${pagination.currentPage}")
                                    }
                                    if (pagination.remainingPages > 1) {
                                        li {
                                            button {
                                                type = "button"
                                                appendText("${pagination.currentPage + 1}")
                                                onclick = {
                                                    controller.start = pagination.currentPage * controller.pageSize
                                                    controller.render()
                                                }
                                            }
                                        }
                                    }
                                    if (pagination.remainingPages > 2) {
                                        li {
                                            appendText("...")
                                        }
                                    }
                                    if (pagination.remainingPages > 0) {
                                        li {
                                            button {
                                                type = "button"
                                                appendText("${pagination.lastPage}")
                                                onclick = {
                                                    controller.start = (pagination.lastPage - 1) * controller.pageSize
                                                    controller.render()
                                                }
                                            }
                                        }
                                        li {
                                            button("pagination-next") {
                                                type = "button"
                                                onclick = {
                                                    controller.start = pagination.currentPage * controller.pageSize
                                                    controller.render()
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else if (pagination.totalCount == -1) {
                        div("datagrid-foot-description") {
                            if (controller.data.isNotEmpty()) {
                                appendText("Показаны результаты ${pagination.firstCount} - ${pagination.lastCount} по ")
                            }
                            if (pageSizeOptions.isNotEmpty()) {
                                select {
                                    for (option in pageSizeOptions) {
                                        option {
                                            option.toString().let {
                                                appendText(it)
                                                value = it
                                            }
                                            selected = option == controller.pageSize
                                        }
                                    }
                                    onchange = {
                                        controller.pageSize = value.toIntOrNull() ?: controller.pageSize
                                        controller.start = 0
                                        controller.render()
                                    }
                                    disabled = pageSizeOptions.size < 2
                                }
                            }
                        }
                        div("pagination") {
                            ul("pagination-list") {
                                if (pagination.currentPage != 1) {
                                    li {
                                        button("pagination-previous") {
                                            type = "button"
                                            onclick = {
                                                controller.start -= controller.pageSize
                                                controller.render()
                                            }
                                        }
                                    }
                                }
                                li("pagination-current") {
                                    appendText("${pagination.currentPage}")
                                }
                                if (pagination.finalPage) {
                                    li {
                                        button("pagination-next") {
                                            type = "button"
                                            onclick = {
                                                controller.start += controller.pageSize
                                                controller.render()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
}

class ItemRender(var title: String = "", var titleURL: String = "", var addressFunction: HTMLElement.() -> Unit = {}, var contentFunction: HTMLElement.() -> Unit = {})

fun <T> SearchResult<T>.clrItemRender(block: ItemRender.(T) -> Unit) {
    itemRender = { item ->
        ItemRender().apply {
            block(item)
        }
    }
}

fun <T> HTMLElement.clrSearchResult(block: SearchResult<T>.() -> Unit) = SearchResult<T>(this).apply(block).apply { render() }
