package ru.playa.sce.views.tours

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.*
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.components.suggestionBox
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.countries.countryAddDialog
import ru.playa.sce.views.dictionaries.dictionaryEntryAddDialog
import ru.playa.sce.views.locations.locationAddDialog

class TourEditorView(private val coreId: Int? = null) : EditorView() {

    companion object {
        const val PATH = "tours/editor"
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/

    private lateinit var draft: Tour
    private lateinit var dictionaryTourTypes: Array<DictionaryEntry>
    private lateinit var allCountryCores: Array<Core>

    private var tourName = ""
    private val tourCountries = mutableListOf<Option>()
    private var tourType: Option? = null
    private var tourDays: String = ""
    private var tourStart: Option? = null
    private var tourFinish: Option? = null
    private val selectedAreas = mutableMapOf<VisibilityArea, Boolean>()
    private val externalMappings = mutableMapOf<ExternalSystem, String>()
    private val tourTags = mutableListOf<Option>()
    private var tourSynonyms = ""

    private var tourStartCountryId: Int? = null
    private var tourFinishCountryId: Int? = null
    private var tourStartCountryTowns = arrayOf<CoreSearch>()
    private var tourFinishCountryTowns = arrayOf<CoreSearch>()

    private lateinit var mainTab: Tab

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = Regex(".{2,200}").matches(value)
    private fun validateDays(value: String) = value.toIntOrNull()?.let { it > 0 } ?: false
    private fun validateExternalCode(value: String) = if (value.isNotBlank()) Regex(".{1,200}").matches(value) else true
    private fun validateSynonyms(value: String) = value.length <= 1000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(tourName)) validationErrors.add("Указано некорректное название тура")
        if (tourCountries.size == 0) validationErrors.add("Необходимо указать минимум одну страну привязки")
        if (tourType == null) validationErrors.add("Не указано обязательное поле \"Тип тура\"")
        if (!validateDays(tourDays)) validationErrors.add("Указан некорректная продолжительность тура")
        if (externalMappings.any { it.value.isNotBlank() && !validateExternalCode(it.value) })
            validationErrors.add("Указаны неверные коды для внешних справочников")
        if (!validateSynonyms(tourSynonyms)) validationErrors.add("Максимальная длина синонимов - 1000 знаков")
    }

    override fun saveEditorData() = async {
        val tour = Tour(
                id = if (coreId != null) draft.id else 0,
                code = if (coreId != null) draft.code else "",
                name = tourName,
                coreId = if (coreId != null) draft.coreId else 0,
                externalMappings = externalMappings
                        .filter { it.value.isNotBlank() }
                        .map { ExternalSystem.toExternalMapping(it.key, it.value) }
                        .toTypedArray(),
                visibility = selectedAreas
                        .filter { it.value }
                        .map { VisibilityArea.toOption(it.key, true) }
                        .toTypedArray(),
                tags = tourTags.toTypedArray(),
                synonyms = tourSynonyms,
                countries = tourCountries.toTypedArray(),
                type = tourType,
                days = tourDays.toInt(),
                start = tourStart,
                finish = tourFinish
        )
        val result = if (coreId == null) Tours.create(tour).await() else Tours.update(tour).await()
//        confirmOnLeave = false
        Navigation.Tours.tour(result.id, result.coreId)
    }

    private fun initEditorData() = async {
        if (coreId != null) {
            draft = Tours.getDraft(coreId).await()
            tourName = draft.name
            tourCountries.clear()
            tourCountries.addAll(draft.countries)
            tourType = draft.type
            tourDays = draft.days.toString()
            tourStart = draft.start
            if (tourStart != null) {
                tourStartCountryId = Locations.getDraft(tourStart?.id ?: 0).await().country.id
                tourStartCountryTowns = Locations.getParentCores(
                        0, 500, "", tourStartCountryId ?: 0, listOf(LocationType.TOWN)
                ).await().data
            }
            tourFinish = draft.finish
            if (tourFinish != null) {
                tourFinishCountryId = Locations.getDraft(tourFinish?.id ?: 0).await().country.id
                tourFinishCountryTowns = Locations.getParentCores(
                        0, 500, "", tourFinishCountryId ?: 0, listOf(LocationType.TOWN)
                ).await().data
            }
            tourTags.clear()
            tourTags.addAll(draft.tags)
            tourSynonyms = draft.synonyms ?: ""
        } else {
            tourName = ""
            tourCountries.clear()
            tourType = null
            tourDays = ""
            tourStart = null
            tourFinish = null
            tourStartCountryId = null
            tourFinishCountryId = null
            tourStartCountryTowns = arrayOf()
            tourFinishCountryTowns = arrayOf()
            tourTags.clear()
            tourSynonyms = ""
        }

        selectedAreas.clear()
        VisibilityAreas.get().await().data.apply { sortBy { it.id } }.forEach { area ->
            selectedAreas[area] = if (coreId != null) draft.visibility.any { it.id == area.id } else area.enableByDefault
        }
        externalMappings.clear()
        External.getByObjectType("tour").await().data.apply { sortBy { it.id } }.forEach { external ->
            externalMappings[external] = if (coreId != null) draft.externalMappings.firstOrNull { it.external.id == external.id }?.code
                    ?: "" else ""
        }

        dictionaryTourTypes = Dictionaries.getDictionaryEntries("tourtype", 0, 500, "").await().data.apply { sortBy { it.id } }
        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
        validationAlert = null
//        confirmOnLeave = false
    }


    override fun render() {
        async {
            initEditorData().await()

            dom.apply {
                clrPageHeader(if (coreId == null) "Новый тур" else draft.name) {
                    if (coreId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Туры") {
                        Navigation.Tours.tours()
                    }
                }
                clrTabs {
                    mainTab = clrTab("Основная информация") {
                        clrForm {
                            isCompact = true
                            clrBlock("Общая информация") {
                                clrGroup("Название тура", true) {
                                    clrInput {
                                        value = tourName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            tourName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Страны", true) {
                                    if (tourCountries.size > 0) {
                                        tourCountries.sortBy { it.name.toLowerCase() }
                                        tourCountries.forEach { country ->
                                            clrLabel(country.name) {
                                                isDismissable = true
                                                onDismissFunction = {
                                                    tourCountries.remove(country)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<Core> {
                                        width = 300
                                        inputPlaceholder = "Добавьте страну привязки для тура"
                                        getDataFunction = { query ->
                                            Locations.getCountryCores(0, 10, query)
                                        }
                                        suggestionFieldFunction = { it.run { publishedName ?: draftName ?: "" } }
                                        onSuggestionSelect = { entry ->
                                            val entryName = entry.run { publishedName ?: draftName ?: "" }
                                            if (tourCountries.none { it.id == entry.id }) {
                                                tourCountries.add(Option(entry.id, entryName, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        onNotFoundAdd = { query ->
                                            dom.countryAddDialog {
                                                countryName = query
                                                onSaveFunction = {
                                                    async {
                                                        tourCountries.add(Option(it.coreId, it.name, false))
                                                        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = { _ ->
                                            dom.countryAddDialog {
                                                onSaveFunction = {
                                                    async {
                                                        tourCountries.add(Option(it.coreId, it.name, false))
                                                        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Тип тура", true) {
                                    clrSelect<Option?> {
                                        clrOption("Не выбран", null)
                                        dictionaryTourTypes.forEach {
                                            clrOption(it.name, Option(it.id, it.name, false), tourType?.id == it.id)
                                        }

                                        onChangeFunction = {
                                            tourType = selectedOptions.first().value
                                            enableSaveButton()
                                        }
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = { _ ->
                                            dom.dictionaryEntryAddDialog("tourtype") {
                                                headerTitle = "Новый тип тура"
                                                onSaveFunction = { event ->
                                                    async {
                                                        tourType = Option(event.id, event.name, false)
                                                        dictionaryTourTypes = Dictionaries.getDictionaryEntries("tourtype", 0, 500, "").await().data.apply { sortBy { it.id } }
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Продолжительность (в днях)", true) {
                                    clrInput {
                                        value = tourDays
                                        tooltipContent = "Положительное целое число"
                                        validationPredicate = {
                                            validateDays(value)
                                        }
                                        onChangeFunction = {
                                            tourDays = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Город начала тура") {
                                    clrSelect<Int?> {
                                        clrOption("Не выбрана", null)
                                        allCountryCores.forEach {
                                            clrOption(
                                                    it.publishedName ?: it.draftName ?: "—",
                                                    it.id, tourStartCountryId == it.id
                                            )
                                        }

                                        onChangeFunction = {
                                            async {
                                                tourStartCountryId = selectedOptions.first().value
                                                tourStart = null
                                                tourStartCountryTowns = if (tourStartCountryId != null) {
                                                    Locations.getParentCores(
                                                            0, 500, "", tourStartCountryId
                                                            ?: 0, listOf(LocationType.TOWN)
                                                    ).await().data
                                                } else {
                                                    arrayOf()
                                                }
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                    if (tourStartCountryTowns.isNotEmpty()) {
                                        clrSelect<Option?> {
                                            clrOption("Не выбран", null)
                                            tourStartCountryTowns.forEach {
                                                val version = it.published ?: it.draft
                                                clrOption(
                                                        version?.name ?: "—",
                                                        Option(it.id, version?.name ?: "", false),
                                                        tourStart?.id == it.id
                                                )
                                            }

                                            onChangeFunction = {
                                                tourStart = selectedOptions.first().value
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = { _ ->
                                            dom.locationAddDialog("TOWN") {
                                                headerTitle = "Новый город"
                                                onSaveFunction = {
                                                    async {
                                                        tourStartCountryId = it.country.id
                                                        tourStartCountryTowns = Locations.getParentCores(
                                                                0, 500, "", tourStartCountryId
                                                                ?: 0, listOf(LocationType.TOWN)
                                                        ).await().data
                                                        tourStart = Option(it.coreId, it.name, false)
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Город окончания тура") {
                                    clrSelect<Int?> {
                                        clrOption("Не выбрана", null)
                                        allCountryCores.forEach {
                                            clrOption(
                                                    it.publishedName ?: it.draftName ?: "—",
                                                    it.id, tourFinishCountryId == it.id
                                            )
                                        }

                                        onChangeFunction = {
                                            async {
                                                tourFinishCountryId = selectedOptions.first().value
                                                tourFinish = null
                                                tourFinishCountryTowns = if (tourFinishCountryId != null) {
                                                    Locations.getParentCores(
                                                            0, 500, "", tourFinishCountryId
                                                            ?: 0, listOf(LocationType.TOWN)
                                                    ).await().data
                                                } else {
                                                    arrayOf()
                                                }
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                    if (tourFinishCountryTowns.isNotEmpty()) {
                                        clrSelect<Option?> {
                                            clrOption("Не выбран", null)
                                            tourFinishCountryTowns.forEach {
                                                val version = it.published ?: it.draft
                                                clrOption(
                                                        version?.name ?: "—",
                                                        Option(it.id, version?.name ?: "", false),
                                                        tourFinish?.id == it.id
                                                )
                                            }

                                            onChangeFunction = {
                                                tourFinish = selectedOptions.first().value
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Plus
                                        tooltipTitle = "Создать"
                                        onClickFunction = { _ ->
                                            dom.locationAddDialog("TOWN") {
                                                headerTitle = "Новый город"
                                                onSaveFunction = {
                                                    async {
                                                        tourFinishCountryId = it.country.id
                                                        tourFinishCountryTowns = Locations.getParentCores(
                                                                0, 500, "", tourFinishCountryId
                                                                ?: 0, listOf(LocationType.TOWN)
                                                        ).await().data
                                                        tourFinish = Option(it.coreId, it.name, false)
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Теги") {
                                    if (tourTags.size > 0) {
                                        tourTags.sortBy { it.name.toLowerCase() }
                                        tourTags.forEach { tag ->
                                            clrLabel(tag.name) {
                                                style = if (tag.id != 0) Label.Style.Blue else Label.Style.None
                                                isDismissable = true
                                                onDismissFunction = {
                                                    tourTags.remove(tag)
                                                    enableSaveButton()
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup {
                                    suggestionBox<DictionaryEntry> {
                                        getDataFunction = { query ->
                                            Dictionaries.getDictionaryEntries("tag", 0, 10, name = query)
                                        }
                                        suggestionFieldFunction = { it.name }
                                        onSuggestionSelect = { entry ->
                                            if (tourTags.none { it.id == entry.id }) {
                                                tourTags.add(Option(entry.id, entry.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            } else this@clrForm.render()
                                        }
                                        inputPlaceholder = "Добавьте тег"
                                        onAddClick = { query ->
                                            if (query.isNotBlank()) {
                                                tourTags.add(Option(0, query, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Области видимости") {
                                    for ((area, checked) in selectedAreas) {
                                        clrCheckbox(area.name) {
                                            isInline = true
                                            isChecked = checked
                                            onChangeFunction = {
                                                selectedAreas[area] = isChecked
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                                clrGroup("Варианты написания") {
                                    clrTextArea {
                                        rows = 3
                                        cols = 80
                                        value = tourSynonyms
                                        onChangeFunction = {
                                            tourSynonyms = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                            }
                            clrBlock("Коды во внешних справочниках") {
                                for ((external, code) in externalMappings) {
                                    clrGroup(external.name) {
                                        clrInput {
                                            value = code
                                            tooltipContent = "От 1 до 200 букв и цифр"
                                            validationPredicate = {
                                                validateExternalCode(value)
                                            }
                                            onChangeFunction = {
                                                externalMappings[external] = value
                                            }
                                            onInputFunction = {
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                if (coreId != null) Navigation.Tours.tour(draft.id, draft.coreId)
                                else Navigation.Tours.tours()
                            }
                        }
                    }
                }
            }
        }
    }
}
