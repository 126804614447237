package ru.playa.sce.dto

class Tour(
        id: Int = 0,
        code: String = "",
        name: String = "",
        objectType: String = "Tour",
        coreId: Int = 0,
        status: String = ObjectStatus.DRAFT.name,
        publishedVersionId: Int? = null,
        draftVersionId: Int? = null,
        externalMappings: Array<ExternalMapping> = emptyArray(),
        visibility: Array<Option> = emptyArray(),
        tags: Array<Option> = emptyArray(),
        synonyms: String? = "",
        val countries: Array<Option>,
        val type: Option? = null,
        val days: Int,
        val start: Option? = null,
        val finish: Option? = null
) : WebObject(id, code, name, objectType, coreId, status, publishedVersionId, draftVersionId, externalMappings, visibility, tags, synonyms)