package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.DeleteStatus
import ru.playa.sce.dto.Hotel
import ru.playa.sce.dto.ObjectStatus

object Hotels {

    fun get(
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            external: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            visibility: List<String> = emptyList(),
            tags: List<String> = emptyList(),
            countryId: Int? = null,
            parentId: Int? = null,
            categoryRating: Int? = null,
            strictCategoryRating: Boolean = false,
            hotelType: Int? = null,
            deleteStatus: DeleteStatus = DeleteStatus.ALL
    ) = Network.request<ListResult<Hotel>>(Network.Method.GET,
            "/sce-web/api/public/objects/hotel/search?start=$start&pageSize=$pageSize&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict&external=$external${objectStatus.joinToString("") { "&objectStatus=$it" }}${visibility.joinToString("") { "&visibility=$it" }}${tags.joinToString("") { "&tags=$it" }}&deleteStatus=$deleteStatus${if (countryId != null) "&countryId=$countryId" else ""}${if (parentId != null) "&parentId=$parentId" else ""}${if (categoryRating != null) "&categoryRating=$categoryRating&strictCategoryRating=$strictCategoryRating" else ""}${if (hotelType != null) "&hotelType=$hotelType" else ""}")

    fun getById(id: Int) = Network.request<Hotel>(Network.Method.GET, "/sce-web/api/user/objects/hotel/$id")

    fun getDraft(coreId: Int) = Network.request<Hotel>(Network.Method.GET, "/sce-web/api/user/objects/hotel/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<Hotel>(Network.Method.GET,
            "/sce-web/api/user/objects/hotel/draft/$coreId?version=$version&archive=$archive")

    fun create(hotel: Hotel) = Network.send(Network.Method.POST, hotel, "/sce-web/api/user/objects/hotel")

    fun update(hotel: Hotel) = Network.send(Network.Method.PUT, hotel, "/sce-web/api/user/objects/hotel")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/hotel/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/hotel/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/hotel/publish/$id")
}