package ru.playa.sce.views.textBlocks

import kotlinx.coroutines.await
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.a
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.kotlinx.clarity.js.util.Meta
import ru.playa.kotlinx.clarity.js.util.Paging
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.kotlinx.route.js.View
import ru.playa.sce.api.Accounts
import ru.playa.sce.api.Dictionaries
import ru.playa.sce.api.Search
import ru.playa.sce.api.TextBlocks
import ru.playa.sce.components.clrButtonGroup
import ru.playa.sce.components.clrLink
import ru.playa.sce.components.clrPageHeader
import ru.playa.sce.components.coreSelectionDialog
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import kotlin.dom.appendText

class TextBlocksView : View() {

    companion object {
        const val PATH = "textBlocks"
    }

    private lateinit var privileges: Array<String>

    private var searchFulltext = ""
    private var searchFullStatuses = mutableListOf(ObjectStatus.PUBLISHED, ObjectStatus.DRAFT, ObjectStatus.APPROVED)
    private var fulltextIsSearched = false

    private var searchQuery = ""
    private var searchTextType: TextType? = null
    private var searchStartDateString = ""
    private var searchEndDateString = ""
    private var searchBlockCode: String? = null
    private var searchCore: Core? = null
    private val searchObjectStatuses = mutableListOf<ObjectStatus>()
    private var databaseIsSearched = false

    private lateinit var datagridFulltext: DataGrid<SolrSearchResult>
    private lateinit var datagridDatabase: DataGrid<Text>

    override fun render() {
        async {
            privileges = Accounts.getObjectTypePrivileges("banner").await()
            val dictionaryContentBlocks = Dictionaries.getDictionaryEntries("contentblock", 0, 500, "").await().data.apply { sortBy { it.id } }

            dom.apply {
                clrPageHeader("Текстовые блоки") {
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrButtonGroup {
                        clrButton("Добавить текстовый блок") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Plus
                            isDisabled = !privileges.contains("CREATE")
                            onClickFunction = {
                                Navigation.TextBlocks.add()
                            }
                        }
                    }
                }
                clrTabs {
                    clrTab("Поиск по базе") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup("Заголовок:") {
                                    clrInput {
                                        size = 30
                                        value = searchQuery
                                        onChangeFunction = {
                                            searchQuery = value
                                        }
                                        onPressEnterFunction = {
                                            searchQuery = value
                                            databaseIsSearched = true
                                            datagridDatabase.getDataEnabled = databaseIsSearched
                                            datagridDatabase.start = 0
                                            datagridDatabase.render()
                                        }
                                    }
                                }
                                clrGroup("Тип:") {
                                    clrSelect<TextType?> {
                                        clrOption("Не выбран", null, searchTextType == null)
                                        TextType.values().forEach {
                                            if (it !== TextType.PAGE_TEXT) clrOption(it.displayName, it, searchTextType == it)
                                        }

                                        onChangeFunction = {
                                            searchTextType = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("В интервале:") {
                                    clrDatePicker {
                                        value = searchStartDateString
                                        onInputFunction = {
                                            searchStartDateString = value
                                        }
                                        onPickFunction = {
                                            searchStartDateString = value
                                        }
                                    }
                                    span { appendText("—") }
                                    clrDatePicker {
                                        value = searchEndDateString
                                        onInputFunction = {
                                            searchEndDateString = value
                                        }
                                        onPickFunction = {
                                            searchEndDateString = value
                                        }
                                    }
                                }
                                clrGroup("Блок контента:") {
                                    clrSelect<String?> {
                                        clrOption("Не выбран", null, searchBlockCode == null)
                                        dictionaryContentBlocks.forEach {
                                            clrOption(it.name, it.code, searchBlockCode == it.code)
                                        }

                                        onChangeFunction = {
                                            searchBlockCode = selectedOptions.first().value
                                        }
                                    }
                                }
                                clrGroup("Объект:") {
                                    span {
                                        appendText(searchCore?.let {
                                            it.publishedName ?: it.draftName ?: "Удаленный"
                                        } ?: "Не выбран")
                                    }
                                    clrButton {
                                        style = ButtonStyle.Flat
                                        isIcon = true
                                        iconShape = IconShape.Pencil
                                        tooltipTitle = "Выбрать"
                                        onClickFunction = {
                                            dom.coreSelectionDialog {
                                                selectedObject = searchCore
                                                onSaveFunction = {
                                                    searchCore = selectedObject
                                                    this@clrForm.render()
                                                }
                                            }
                                        }
                                    }
                                    if (searchCore != null) {
                                        clrButton {
                                            style = ButtonStyle.Flat
                                            isIcon = true
                                            iconShape = IconShape.Times
                                            tooltipTitle = "Очистить"
                                            onClickFunction = {
                                                searchCore = null
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }

                                clrGroup("Показывать:") {
                                    val enumStatus = ObjectStatus.values()
                                    val defaultStatusCheckboxes = mutableListOf<Checkbox>()

                                    for (status in enumStatus) {
                                        clrCheckbox(status.displayName) {
                                            isInline = true
                                            isChecked = searchObjectStatuses.contains(status)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    searchObjectStatuses.add(status)
                                                } else {
                                                    searchObjectStatuses.remove(status)
                                                }
                                            }
                                        }.let { defaultStatusCheckboxes.add(it) }
                                    }
                                }
                                clrButton("Найти") {
                                    iconShape = IconShape.Search
                                    onClickFunction = {
                                        databaseIsSearched = true
                                        datagridDatabase.getDataEnabled = databaseIsSearched
                                        datagridDatabase.start = 0
                                        datagridDatabase.render()
                                    }
                                }
                            }
                        }
                        datagridDatabase = clrDatagrid {
                            clrColumn("Заголовок", 1) { entry ->
                                a {
                                    appendText(entry.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.TextBlocks.textBlock(entry.id, entry.coreId)
                                    }
                                }
                            }
                            clrColumn("Тип", 1) {
                                appendText(TextType.valueOf(it.textType).displayName)
                            }
                            clrColumn("Период отображения", 1) {
                                if (it.start == null && it.end == null) {
                                    appendText("—")
                                } else {
                                    var datesText = ""
                                    datesText += if (it.start != null) {
                                        "${it.start.slice(8..9)}.${it.start.slice(5..6)}.${it.start.slice(0..3)} — "
                                    } else "... - "
                                    datesText += if (it.end != null) {
                                        "${it.end.slice(8..9)}.${it.end.slice(5..6)}.${it.end.slice(0..3)}"
                                    } else "..."
                                    appendText(datesText)
                                }
                            }
                            clrColumn("Блок контента", 1) {
                                var blocksText = ""
                                val blocksLastIndex = it.blocks.lastIndex
                                it.blocks.forEachIndexed { index, block ->
                                    blocksText += block.name
                                    if (index != blocksLastIndex) blocksText += ", "
                                }
                                if (blocksLastIndex == -1) blocksText += "—"
                                appendText(blocksText)
                            }
                            clrColumn("Статус", 1) {
                                appendText(ObjectStatus.valueOf(it.status).displayName)
                            }
                            clrColumn("Объекты", 1) { entry ->
                                var objectsText = ""
                                val objectsLastIndex = entry.objects.lastIndex
                                entry.objects.forEachIndexed { index, obj ->
                                    objectsText += obj.publishedName ?: obj.draftName ?: "Удаленный"
                                    if (index != objectsLastIndex) objectsText += ", "
                                }
                                if (objectsLastIndex == -1) {
                                    objectsText += "—"
                                    appendText(objectsText)
                                } else {
                                    clrDropdown(objectsText) {
                                        isButton = false
                                        isDatagrid = true
                                        entry.objects.forEach {
                                            clrItem(it.publishedName ?: it.draftName ?: "Удаленный")
                                        }
                                    }
                                }
                            }
                            overflowYVisible = true
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            getDataEnabled = databaseIsSearched
                            getDataFunction = {
                                getDataDatabase(start, pageSize)
                            }
                        }
                    }
                    clrTab("Полнотекстовый поиск") {
                        clrForm {
                            isCompact = true
                            clrBlock {
                                clrGroup {
                                    clrInput {
                                        size = 50
                                        value = searchFulltext
                                        onChangeFunction = {
                                            searchFulltext = value
                                        }
                                        onPressEnterFunction = {
                                            searchFulltext = value
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                    clrButton("Найти") {
                                        isSmall = true
                                        margin = "0.15rem 0"
                                        iconShape = IconShape.Search
                                        onClickFunction = {
                                            fulltextIsSearched = true
                                            datagridFulltext.getDataEnabled = fulltextIsSearched
                                            datagridFulltext.start = 0
                                            datagridFulltext.render()
                                        }
                                    }
                                }
                                clrGroup("Статус:") {
                                    val enumStatus = ObjectStatus.values()
                                    val fullChecksStatus = mutableListOf<Checkbox>()

                                    for (status in enumStatus) {
                                        clrCheckbox(status.displayName) {
                                            isInline = true
                                            isChecked = searchFullStatuses.contains(status)
                                            onChangeFunction = {
                                                if (isChecked) {
                                                    if (status == ObjectStatus.ARCHIVED)
                                                        searchFullStatuses.clear()
                                                    else searchFullStatuses.remove(ObjectStatus.ARCHIVED)
                                                    searchFullStatuses.add(status)
                                                    this@clrForm.render()
                                                } else {
                                                    searchFullStatuses.remove(status)
                                                }
                                            }
                                        }.let { fullChecksStatus.add(it) }
                                    }
                                }
                            }
                        }
                        datagridFulltext = clrDatagrid {
                            clrColumn("Заголовок", 1) { entry ->
                                a {
                                    appendText(entry.name)
                                    href = "javascript://"
                                    onclick = {
                                        Navigation.TextBlocks.textBlock(entry.id, entry.coreId.toInt())
                                    }
                                }
                            }
                            clrColumn("Тип", 1) { entry ->
                                entry.textType?.let {
                                    appendText(TextType.valueOf(it).displayName)
                                }
                            }
                            clrColumn("Период отображения", 1) { entry ->
                                if (entry.startDate == null && entry.endDate == null) {
                                    appendText("—")
                                } else {
                                    var datesText = ""
                                    datesText += entry.startDate?.let { "$it - " } ?: "... - "
                                    datesText += entry.endDate ?: "..."
                                    appendText(datesText)
                                }
                            }
                            clrColumn("Блок контента", 1) {
                                if (it.blocks != null) {
                                    var blocksText = ""
                                    val blocksLastIndex = it.blocks.lastIndex
                                    it.blocks.forEachIndexed { index, block ->
                                        blocksText += block
                                        if (index != blocksLastIndex) blocksText += ", "
                                    }
                                    if (blocksLastIndex == -1) blocksText += "—"
                                    appendText(blocksText)
                                }
                            }
                            clrColumn("Статус", 1) {
                                if (it.status != null) {
                                    appendText(ObjectStatus.valueOf(it.status).displayName)
                                }
                            }
                            clrColumn("Объекты", 1) { entry ->
                                if (entry.objects != null) {
                                    var objectsText = ""
                                    val objectsLastIndex = entry.objects.lastIndex
                                    entry.objects.forEachIndexed { index, obj ->
                                        objectsText += obj
                                        if (index != objectsLastIndex) objectsText += ", "
                                    }
                                    if (objectsLastIndex == -1) {
                                        objectsText += "—"
                                        appendText(objectsText)
                                    } else {
                                        clrDropdown(objectsText) {
                                            isButton = false
                                            isDatagrid = true
                                            entry.objects.forEach {
                                                clrItem(it)
                                            }
                                        }
                                    }
                                }
                            }
                            overflowYVisible = true
                            pageSize = 25
                            pageSizeOptions.addAll(arrayOf(10, 25, 50, 100))
                            getDataEnabled = fulltextIsSearched
                            getDataFunction = {
                                getDataFulltext(start, pageSize)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getDataFulltext(start: Int, pageSize: Int) = if (searchFulltext.isNotBlank()) {
        val objectStatuses = searchFullStatuses.asSequence().map { it.name }.toList()
        Search.searchObjectType(searchFulltext, objectStatuses, start, pageSize, listOf("text"))
    } else {
        async { ListResult(Meta(0, Paging(0, 10)), emptyArray<SolrSearchResult>()) }
    }

    private fun getDataDatabase(start: Int, pageSize: Int) =
            TextBlocks.get(
                    start, pageSize, searchQuery, searchTextType, searchStartDateString, searchEndDateString,
                    searchObjectStatuses, searchCore?.id, searchBlockCode
            )
}
