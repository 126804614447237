@file:Suppress("unused")

package ru.playa.sce.core

import ru.playa.kotlinx.route.js.Router
import ru.playa.sce.views.countries.CountriesView
import ru.playa.sce.views.countries.CountryEditorView
import ru.playa.sce.views.countries.CountryView
import ru.playa.sce.views.descriptionEditor.DescriptionEditorView
import ru.playa.sce.views.descriptionTypes.DescriptionTypeCreatorView
import ru.playa.sce.views.descriptionTypes.DescriptionTypesView
import ru.playa.sce.views.dictionaries.DictionariesView
import ru.playa.sce.views.dictionaries.DictionaryEntriesView
import ru.playa.sce.views.dictionaries.DictionaryEntryCreatorView
import ru.playa.sce.views.excursion.ExcursionEditorView
import ru.playa.sce.views.excursion.ExcursionView
import ru.playa.sce.views.excursion.ExcursionsView
import ru.playa.sce.views.externalSystems.ExternalSystemCreatorView
import ru.playa.sce.views.externalSystems.ExternalSystemsView
import ru.playa.sce.views.hotels.HotelEditorView
import ru.playa.sce.views.hotels.HotelView
import ru.playa.sce.views.hotels.HotelsView
import ru.playa.sce.views.imageSettings.ImageSettingsView
import ru.playa.sce.views.locations.LocationEditorView
import ru.playa.sce.views.locations.LocationView
import ru.playa.sce.views.locations.LocationsView
import ru.playa.sce.views.start.StartView
import ru.playa.sce.views.textBlocks.TextBlockEditorView
import ru.playa.sce.views.textBlocks.TextBlockView
import ru.playa.sce.views.textBlocks.TextBlocksView
import ru.playa.sce.views.tourPackets.TourPacketEditorView
import ru.playa.sce.views.tourPackets.TourPacketView
import ru.playa.sce.views.tourPackets.TourPacketsView
import ru.playa.sce.views.tourismObjects.TourismObjectEditorView
import ru.playa.sce.views.tourismObjects.TourismObjectView
import ru.playa.sce.views.tourismObjects.TourismObjectsView
import ru.playa.sce.views.tours.TourEditorView
import ru.playa.sce.views.tours.TourPointEditorView
import ru.playa.sce.views.tours.TourView
import ru.playa.sce.views.tours.ToursView
import ru.playa.sce.views.usersRoles.RoleEditorView
import ru.playa.sce.views.usersRoles.UserRolesEditorView
import ru.playa.sce.views.usersRoles.UsersRolesView
import ru.playa.sce.views.visibilityAreas.VisibilityAreaCreatorView
import ru.playa.sce.views.visibilityAreas.VisibilityAreasView


object Navigation {

    fun start() {
        Router.goTo(StartView.PATH, hashMapOf())
    }

    fun navigateToObject(type: String, id: Int, coreId: Int, tab: String = "main") {
        when (type) {
            "COUNTRY" -> Countries.countryTab(id, coreId, tab)
            "LOCATION" -> Locations.locationTab(id, coreId, tab)
            "HOTEL" -> Hotels.hotelTab(id, coreId, tab)
            "TOUR" -> Tours.tourTab(id, coreId, tab)
            else -> TourismObjects.tourismObjectTab(type, id, coreId, tab)
        }
    }

    object Countries {

        fun countries(newWindow: Boolean = false) {
            Router.goTo(CountriesView.PATH, hashMapOf(), newWindow)
        }

        fun getCountriesUrl(): String {
            return Router.getURL(CountriesView.PATH, hashMapOf())
        }

        fun country(id: Int, coreId: Int, newWindow: Boolean = false) {
            Router.goTo(CountryView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()), newWindow)
        }

        fun getCountryURL(id: Int, coreId: Int): String {
            return Router.getURL(CountryView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()))
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(CountryEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(coreId: Int) {
            Router.goTo(CountryEditorView.PATH, hashMapOf("coreId" to coreId.toString()))
        }

        fun countryTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(CountryView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString(), "initialTab" to tab))
        }
    }

    object Excursions {
        fun excursions(newWindow: Boolean = false) {
            Router.goTo(ExcursionsView.PATH, hashMapOf(), newWindow)
        }

        fun getExcursionsURL(): String {
            return Router.getURL(ExcursionsView.PATH, hashMapOf())
        }

        fun excursion(id: Int, coreId: Int, newWindow: Boolean = false) {
            Router.goTo(ExcursionView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()), newWindow)
        }

        fun getExcursionURL(id: Int, coreId: Int): String {
            return Router.getURL(ExcursionView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()))
        }


        fun add(newWindow: Boolean = false) {
            Router.goTo(ExcursionEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(coreId: Int) {
            Router.goTo(ExcursionEditorView.PATH, hashMapOf("coreId" to coreId.toString()))
        }

        fun excursionTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(ExcursionView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString(), "initialTab" to tab))
        }


    }

    object Locations {

        fun locations(newWindow: Boolean = false) {
            Router.goTo(LocationsView.PATH, hashMapOf(), newWindow)
        }

        fun getLocationsURL(): String {
            return Router.getURL(LocationsView.PATH, hashMapOf())
        }

        fun location(id: Int, coreId: Int, newWindow: Boolean = false) {
            Router.goTo(LocationView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()), newWindow)
        }

        fun getLocationURL(id: Int, coreId: Int): String {
            return Router.getURL(LocationView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString()))
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(LocationEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(coreId: Int) {
            Router.goTo(LocationEditorView.PATH, hashMapOf("coreId" to coreId.toString()))
        }

        fun locationTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(LocationView.PATH, hashMapOf("id" to id.toString(), "coreId" to coreId.toString(), "initialTab" to tab))
        }
    }

    object Hotels {

        fun hotels() {
            Router.goTo(HotelsView.PATH, hashMapOf())
        }

        fun getHotelsURL(): String {
            return Router.getURL(HotelsView.PATH, hashMapOf())
        }

        fun hotel(id: Int, coreId: Int) {
            Router.goTo(HotelView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun getHotelURL(id: Int, coreId: Int): String {
            return Router.getURL(HotelView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(HotelEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(coreId: Int) {
            Router.goTo(HotelEditorView.PATH, hashMapOf(Pair("coreId", coreId.toString())))
        }

        fun hotelTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(HotelView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("initialTab", tab)
            ))
        }
    }

    object Tours {

        fun tours() {
            Router.goTo(ToursView.PATH, hashMapOf())
        }

        fun getToursURL(): String {
            return Router.getURL(ToursView.PATH, hashMapOf())
        }

        fun tour(id: Int, coreId: Int) {
            Router.goTo(TourView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun getTourURL(id: Int, coreId: Int): String {
            return Router.getURL(TourView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(TourEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(coreId: Int) {
            Router.goTo(TourEditorView.PATH, hashMapOf(Pair("coreId", coreId.toString())))
        }

        fun tourTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(TourView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("initialTab", tab)
            ))
        }

        fun addTourPoint(id: Int, coreId: Int) {
            Router.goTo(TourPointEditorView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString())
            ))
        }

        fun editTourPoint(id: Int, coreId: Int, tourPointId: Int) {
            Router.goTo(TourPointEditorView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("tourPointId", tourPointId.toString())
            ))
        }
    }

    object TourPackets {

        fun tourPackets() {
            Router.goTo(TourPacketsView.PATH, hashMapOf())
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(TourPacketEditorView.PATH, hashMapOf(), newWindow)
        }

        fun tourPacket(id: Int, coreId: Int) {
            Router.goTo(TourPacketView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun tourPacketTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(TourPacketView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("initialTab", tab)
            ))
        }

        fun edit(coreId: Int) {
            Router.goTo(TourPacketEditorView.PATH, hashMapOf(Pair("coreId", coreId.toString())))
        }
    }

    object TourismObjects {

        fun tourismObjects(locationType: String) {
            Router.goTo(TourismObjectsView.PATH, hashMapOf("locationType" to locationType))
        }

        fun getTourismObjectsURL(locationType: String): String {
            return Router.getURL(TourismObjectsView.PATH, hashMapOf("locationType" to locationType))
        }

        fun tourismObject(locationType: String, id: Int, coreId: Int) {
            Router.goTo(TourismObjectView.PATH, hashMapOf(
                    Pair("locationType", locationType),
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString())
            ))
        }

        fun getTourismObjectURL(locationType: String, id: Int, coreId: Int): String {
            return Router.getURL(TourismObjectView.PATH, hashMapOf(
                    Pair("locationType", locationType),
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString())
            ))
        }

        fun add(locationType: String, newWindow: Boolean = false) {
            Router.goTo(TourismObjectEditorView.PATH, hashMapOf("locationType" to locationType), newWindow)
        }

        fun edit(locationType: String, coreId: Int) {
            Router.goTo(TourismObjectEditorView.PATH, hashMapOf(
                    Pair("locationType", locationType),
                    Pair("coreId", coreId.toString())
            ))
        }

        fun tourismObjectTab(locationType: String, id: Int, coreId: Int, tab: String) {
            Router.goTo(TourismObjectView.PATH, hashMapOf(
                    Pair("locationType", locationType),
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("initialTab", tab)
            ))
        }
    }

    object TextBlocks {
        fun textBlocks() {
            Router.goTo(TextBlocksView.PATH, hashMapOf())
        }

        fun getTextBlocksURL(): String {
            return Router.getURL(TextBlocksView.PATH, hashMapOf())
        }

        fun textBlock(id: Int, coreId: Int) {
            Router.goTo(TextBlockView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun getTextBlockURL(id: Int, coreId: Int): String {
            return Router.getURL(TextBlockView.PATH, hashMapOf(Pair("id", id.toString()), Pair("coreId", coreId.toString())))
        }

        fun add(newWindow: Boolean = false) {
            Router.goTo(TextBlockEditorView.PATH, hashMapOf(), newWindow)
        }

        fun edit(id: Int, coreId: Int) {
            Router.goTo(TextBlockEditorView.PATH, hashMapOf(Pair("coreId", coreId.toString()), Pair("objectId", id.toString())))
        }

        fun addByObject(objectType: String, objectId: Int, objectCoreId: Int, textType: String, objectName: String) {
            Router.goTo(TextBlockEditorView.PATH, hashMapOf(
                    Pair("objectType", objectType),
                    Pair("objectId", objectId.toString()),
                    Pair("objectCoreId", objectCoreId.toString()),
                    Pair("textType", textType),
                    Pair("objectName", objectName)
            ))
        }

        fun editByObject(coreId: Int, objectType: String, objectId: Int, objectCoreId: Int, textType: String) {
            Router.goTo(TextBlockEditorView.PATH, hashMapOf(
                    Pair("coreId", coreId.toString()),
                    Pair("objectType", objectType),
                    Pair("objectId", objectId.toString()),
                    Pair("objectCoreId", objectCoreId.toString()),
                    Pair("textType", textType)
            ))
        }

        fun textBlockTab(id: Int, coreId: Int, tab: String) {
            Router.goTo(TextBlockView.PATH, hashMapOf(
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("initialTab", tab)
            ))
        }
    }

    object DescriptionEditor {

        fun addDescription(objectType: String, id: Int, coreId: Int) {
            Router.goTo(DescriptionEditorView.PATH, hashMapOf(
                    Pair("objectType", objectType),
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString())
            ))
        }

        fun editDescription(objectType: String, id: Int, coreId: Int, descriptionId: Int) {
            Router.goTo(DescriptionEditorView.PATH, hashMapOf(
                    Pair("objectType", objectType),
                    Pair("id", id.toString()),
                    Pair("coreId", coreId.toString()),
                    Pair("descriptionId", descriptionId.toString())
            ))
        }
    }

    object DescriptionTypes {

        fun descriptionTypes() {
            Router.goTo(DescriptionTypesView.PATH, hashMapOf())
        }

        fun add() {
            Router.goTo(DescriptionTypeCreatorView.PATH, hashMapOf())
        }
    }

    object ExternalSystems {

        fun externalSystems() {
            Router.goTo(ExternalSystemsView.PATH, hashMapOf())
        }

        fun add() {
            Router.goTo(ExternalSystemCreatorView.PATH, hashMapOf())
        }
    }

    object Dictionaries {

        fun dictionaries(newWindow: Boolean = false) {
            Router.goTo(DictionariesView.PATH, hashMapOf(), newWindow)
        }

        fun getDictionariesURL(): String {
            return Router.getURL(DictionariesView.PATH, hashMapOf())
        }

        fun dictionary(code: String, search: String = "", newWindow: Boolean = false) {
            Router.goTo(DictionaryEntriesView.PATH, hashMapOf("code" to code, "search" to search), newWindow)
        }

        fun getDictionaryURL(code: String, search: String = ""): String {
            return Router.getURL(DictionaryEntriesView.PATH, hashMapOf("code" to code, "search" to search))
        }

        fun addEntry(code: String, newWindow: Boolean = false) {
            Router.goTo(DictionaryEntryCreatorView.PATH, hashMapOf("code" to code), newWindow)
        }
    }

    object VisibilityAreas {

        fun visibilityAreas() {
            Router.goTo(VisibilityAreasView.PATH, hashMapOf())
        }

        fun add() {
            Router.goTo(VisibilityAreaCreatorView.PATH, hashMapOf())
        }
    }

    object ImageSettings {
        fun imageSettings() {
            Router.goTo(ImageSettingsView.PATH, hashMapOf())
        }
    }

    object UsersRoles {

        fun usersRoles() {
            Router.goTo(UsersRolesView.PATH, hashMapOf())
        }

        fun editUserRoles(email: String) {
            Router.goTo(UserRolesEditorView.PATH, hashMapOf("email" to email))
        }

        fun addRole() {
            Router.goTo(RoleEditorView.PATH, hashMapOf())
        }

        fun editRole(id: Int) {
            Router.goTo(RoleEditorView.PATH, hashMapOf("id" to id.toString()))
        }
    }
}
