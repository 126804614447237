package ru.playa.sce.views.tours

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.label
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Countries
import ru.playa.sce.api.Locations
import ru.playa.sce.api.Tours
import ru.playa.sce.components.*
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.*
import ru.playa.sce.dto.Option
import ru.playa.sce.views.EditorView
import ru.playa.sce.views.countries.countryAddDialog
import ru.playa.sce.views.locations.locationAddDialog
import ru.playa.sce.views.locations.locationParentAddDialog
import kotlin.dom.appendText

class TourPointEditorView(private val objectId: Int, private val coreId: Int, private val tourPointId: Int?) : EditorView() {

    companion object {
        const val PATH = "tourpoint/editor"
    }


    enum class TourPointType {
        POINT,
        ROUTE
    }

//    private var confirmOnLeave = false

    /*override fun beforeViewLeave() = Promise<Boolean> { resolve, _ ->
        if (confirmOnLeave) {
            dom.clrChoiceDialog("Все несохраненные изменения будут утеряны. Вы уверены что хотите перейти?") {
                modalDialogConfiguration = { size = ModalDialog.Size.Small }
                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                    resolve(true)
                }
                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times) {
                    resolve(false)
                }
            }
        } else resolve(true)
    }*/


    private lateinit var objectName: String
    private lateinit var tourPoints: Array<TourPoint>
    private lateinit var tourPoint: TourPoint
    private lateinit var googleMapPoint: GoogleMap
    private lateinit var googleMapRoute: GoogleMap
    private lateinit var googleMapPointDiv: HTMLDivElement
    private lateinit var googleMapRouteDiv: HTMLDivElement
    private lateinit var allCountryCores: Array<Core>

    private var tourPointType = TourPointType.POINT
    private var tourPointName = ""
    private var tourPointStartDay = "1"
    private var tourPointStartDaySaved = 0
    private var tourPointEndDay = "1"
    private var tourPointBriefDescription = ""
    private var tourPointFullDescription = ""
    private var tourPointSights = mutableListOf<Option>()

    private var tourPointStart: WayPoint? = null
    private var tourPointStartMarker: Point? = null
    private var tourPointStartCountry: Option? = null
    private var tourPointStartTown: Option? = null
    private var tourPointStartCountryTowns = arrayOf<CoreSearch>()

    private var tourPointFinish: WayPoint? = null
    private var tourPointFinishMarker: Point? = null
    private var tourPointFinishCountry: Option? = null
    private var tourPointFinishTown: Option? = null
    private var tourPointFinishCountryTowns = arrayOf<CoreSearch>()

    private fun enableSaveButton() {
//        confirmOnLeave = true
        saveButton.isDisabled = false
        saveButton.render()
    }

    private fun validateName(value: String) = value.length in 2..200
    private fun validateDay(value: String) = value.toIntOrNull()?.let { it > 0 } ?: false
    private fun validateDays(startDay: Int?, endDay: Int?) = startDay != null && endDay != null && endDay >= startDay
    private fun validateBriefDescription(value: String) = value.length <= 2000

    override fun validateEditorData() {
        validationErrors.clear()
        if (!validateName(tourPointName)) validationErrors.add("Указано некорректное название тура")
        if (!validateBriefDescription(tourPointBriefDescription)) validationErrors.add("Максимальная длина краткого описания - 2000 знаков")
        if (!validateDay(tourPointStartDay)) validationErrors.add("Указан некорректный день начала в туре")
        if (!validateDay(tourPointEndDay)) validationErrors.add("Указан некорректный день окончания в туре")
        if (!validateDays(tourPointStartDay.toIntOrNull(), tourPointEndDay.toIntOrNull())) validationErrors.add("День окончания не может быть позже дня старта")
        if (tourPointStartMarker == null) validationErrors.add("Точка начала не указана на карте")
        if (tourPointType == TourPointType.ROUTE && tourPointFinishMarker == null) validationErrors.add("Точка окончания не указана на карте")
        if (tourPointStartCountry == null) validationErrors.add("Не указана страна для точки начала")
        if (tourPointType == TourPointType.ROUTE && tourPointFinishCountry == null) validationErrors.add("Не указана страна для точки окончания")
    }

    override fun saveEditorData() = async {
        val startWayPoint = WayPoint(
                tourPointStart?.id ?: 0, tourPointStartMarker ?: Point(0.toDouble(), 0.toDouble()),
                tourPointStartCountry, tourPointStartTown
        )
        val finishWayPoint = if (tourPointType == TourPointType.ROUTE) {
            WayPoint(
                    tourPointFinish?.id ?: 0, tourPointFinishMarker ?: Point(0.toDouble(), 0.toDouble()),
                    tourPointFinishCountry, tourPointFinishTown
            )
        } else null
        val order = when {
            tourPointId != null && tourPointStartDaySaved == tourPointStartDay.toInt() -> tourPoint.order
            else -> (tourPoints.filter { it.startDay == tourPointStartDay.toInt() }.maxBy { it.order }?.order ?: -1) + 1
        }
        val tp = TourPoint(
                id = if (tourPointId != null) tourPoint.id else 0,
                parentCoreId = coreId,
                parentVersionId = objectId,
                name = tourPointName,
                briefDescription = tourPointBriefDescription,
                fullDescription = tourPointFullDescription,
                startDay = tourPointStartDay.toInt(),
                endDay = tourPointEndDay.toInt(),
                order = order,
                start = startWayPoint,
                finish = finishWayPoint,
                sights = tourPointSights.toTypedArray()
        )
        if (tourPointId == null) Tours.createTourPoint(tp).await() else Tours.updateTourPoint(tp).await()
//        confirmOnLeave = false
        Navigation.Tours.tourTab(objectId, coreId, "route")
    }

    private fun initEditorData() = async {
        objectName = Tours.getById(objectId).await().name
        allCountryCores = Locations.getCountryCores(0, 500, "").await().data
        tourPoints = Tours.getTourPoints(objectId).await().data

        if (tourPointId != null) {
            tourPoint = tourPoints.firstOrNull { it.id == tourPointId }
                    ?: throw IllegalStateException("Invalid pair of object and description IDs")
            tourPointType = if (tourPoint.finish != null) TourPointType.ROUTE else TourPointType.POINT
            tourPointName = tourPoint.name
            tourPointBriefDescription = tourPoint.briefDescription
            tourPointFullDescription = tourPoint.fullDescription
            tourPointStartDaySaved = tourPoint.startDay
            tourPointStartDay = tourPoint.startDay.toString()
            tourPointEndDay = tourPoint.endDay.toString()
            tourPointSights.clear()
            tourPointSights.addAll(tourPoint.sights)

            tourPointStart = tourPoint.start
            tourPointStartMarker = tourPointStart?.point
            tourPointStartCountry = tourPointStart?.country
            tourPointStartTown = tourPointStart?.town
            if (tourPointStartCountry != null) {
                tourPointStartCountryTowns = Locations.getParentCores(
                        0, 500, "", tourPointStartCountry?.id ?: 0, listOf(LocationType.TOWN)
                ).await().data
            }

            tourPointFinish = tourPoint.finish
            tourPointFinishMarker = tourPointFinish?.point
            tourPointFinishCountry = tourPointFinish?.country
            tourPointFinishTown = tourPointFinish?.town
            if (tourPointFinishCountry != null) {
                tourPointFinishCountryTowns = Locations.getParentCores(
                        0, 500, "", tourPointFinishCountry?.id ?: 0, listOf(LocationType.TOWN)
                ).await().data
            }
        } else {
            tourPointType = TourPointType.POINT
            tourPointName = ""
            tourPointBriefDescription = ""
            tourPointFullDescription = ""
            tourPointStartDay = "1"
            tourPointEndDay = "1"
            tourPointSights.clear()

            tourPointStart = null
            tourPointStartMarker = null
            tourPointStartCountry = null
            tourPointStartTown = null
            tourPointStartCountryTowns = arrayOf()

            tourPointFinish = null
            tourPointFinishMarker = null
            tourPointFinishCountry = null
            tourPointFinishTown = null
            tourPointFinishCountryTowns = arrayOf()
        }

        validationAlert = null
//        confirmOnLeave = false
    }

    override fun render() {
        async {
            initEditorData().await()
            dom.apply {
                clrPageHeader(if (tourPointId == null) "Новая маршрутная точка тура" else tourPoint.name) {
                    if (tourPointId != null) label = "Редактирование"
                    clrLink("Объекты") {
                        Navigation.start()
                    }
                    clrLink("Туры") {
                        Navigation.Tours.tours()
                    }
                    clrLink(objectName) {
                        Navigation.Tours.tour(objectId, coreId)
                    }
                }
                div {
                    style.apply { display = "flex"; flexDirection = "row" }
                    div {
                        style.width = "600px"
                        clrForm {
                            isCompact = true
                            clrBlock("Основная информация") {
                                clrGroup {
                                    val groupName = "tourPointType"
                                    clrRadio("Точка", groupName, TourPointType.POINT) {
                                        isChecked = tourPointType == TourPointType.POINT
                                        isInline = true
                                        onChangeFunction = {
                                            tourPointType = TourPointType.POINT
                                            googleMapPointDiv.hidden = false
                                            googleMapRouteDiv.hidden = true
                                            this@clrForm.render()
                                        }
                                    }
                                    clrRadio("Переезд", groupName, TourPointType.ROUTE) {
                                        isChecked = tourPointType == TourPointType.ROUTE
                                        isInline = true
                                        onChangeFunction = {
                                            tourPointType = TourPointType.ROUTE
                                            googleMapRouteDiv.hidden = false
                                            googleMapPointDiv.hidden = true
                                            this@clrForm.render()
                                        }
                                    }
                                }
                                clrGroup("Название", true) {
                                    clrInput {
                                        size = 30
                                        value = tourPointName
                                        tooltipContent = "От 2 до 200 символов"
                                        validationPredicate = {
                                            validateName(value)
                                        }
                                        onChangeFunction = {
                                            tourPointName = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("День начала в туре", true) {
                                    clrInput {
                                        value = tourPointStartDay
                                        tooltipContent = "Положительное число"
                                        validationPredicate = {
                                            validateDay(value)
                                        }
                                        onChangeFunction = {
                                            tourPointStartDay = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("День окончания в туре", true) {
                                    clrInput {
                                        value = tourPointEndDay
                                        tooltipContent = "Положительное число"
                                        validationPredicate = {
                                            validateDay(value)
                                        }
                                        onChangeFunction = {
                                            tourPointEndDay = value
                                        }
                                        onInputFunction = {
                                            enableSaveButton()
                                        }
                                    }
                                }
                                clrGroup("Точка начала", true) {
                                    div {
                                        style.apply { display = "flex"; flexDirection = "column" }
                                        div {
                                            style.display = "flex"
                                            label {
                                                style.paddingRight = "8px"
                                                appendText("Страна:")
                                            }
                                            clrSelect<Option?> {
                                                clrOption("Не выбрана", null)
                                                allCountryCores.forEach {
                                                    val name = it.publishedName ?: it.draftName
                                                    clrOption(
                                                            name ?: "-",
                                                            Option(it.id, name ?: "", false),
                                                            tourPointStartCountry?.id == it.id
                                                    )
                                                }

                                                onChangeFunction = { _ ->
                                                    async {
                                                        tourPointStartCountry = selectedOptions.first().value
                                                        tourPointStartTown = null
                                                        if (tourPointStartCountry != null) {
                                                            tourPointStartCountryTowns = Locations.getParentCores(
                                                                    0, 500, "", tourPointStartCountry?.id
                                                                    ?: 0, listOf(LocationType.TOWN)
                                                            ).await().data
                                                            val countryId = allCountryCores.find { tourPointStartCountry?.id == it.id }?.run {
                                                                publishedVersionId ?: draftVersionId
                                                            } ?: 0
                                                            Countries.getById(countryId).await().area?.let {
                                                                val activeMap = if (tourPointType == TourPointType.POINT) googleMapPoint else googleMapRoute
                                                                activeMap.mapFitBounds(
                                                                        it.southWest.latitude,
                                                                        it.southWest.longitude,
                                                                        it.northEast.latitude,
                                                                        it.northEast.longitude
                                                                )
                                                            }
                                                        } else {
                                                            tourPointStartCountryTowns = arrayOf()
                                                        }
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = { _ ->
                                                    dom.countryAddDialog {
                                                        onSaveFunction = {
                                                            async {
                                                                tourPointStartCountry = Option(it.coreId, it.name, false)
                                                                allCountryCores = Locations.getCountryCores(0, 500, "").await().data
                                                                tourPointStartTown = null
                                                                tourPointStartCountryTowns = arrayOf()
                                                                enableSaveButton()
                                                                this@clrForm.render()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        if (tourPointStartCountry != null) {
                                            div {
                                                style.display = "flex"
                                                label {
                                                    style.paddingRight = "8px"
                                                    appendText("Город:")
                                                }
                                                if (tourPointStartCountryTowns.isNotEmpty()) {
                                                    clrSelect<Option?> {
                                                        clrOption("Не выбран", null)
                                                        tourPointStartCountryTowns.forEach {
                                                            val version = it.published ?: it.draft
                                                            clrOption(
                                                                    version?.name ?: "—",
                                                                    Option(it.id, version?.name ?: "", false),
                                                                    tourPointStartTown?.id == it.id
                                                            )
                                                        }

                                                        onChangeFunction = { _ ->
                                                            tourPointStartTown = selectedOptions.first().value
                                                            if (tourPointStartTown != null) {
                                                                val townVersion = tourPointStartCountryTowns.find { tourPointStartTown?.id == it.id }?.run {
                                                                    published ?: draft
                                                                }
                                                                if (townVersion != null) {
                                                                    townVersion.mapData?.area?.let {
                                                                        val activeMap = if (tourPointType == TourPointType.POINT) googleMapPoint else googleMapRoute
                                                                        activeMap.mapFitBounds(
                                                                                it.southWest.latitude,
                                                                                it.southWest.longitude,
                                                                                it.northEast.latitude,
                                                                                it.northEast.longitude
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            enableSaveButton()
                                                        }
                                                    }
                                                } else {
                                                    label {
                                                        appendText("У страны нет добавленных городов")
                                                    }
                                                }
                                                clrButton {
                                                    style = ButtonStyle.Flat
                                                    isIcon = true
                                                    iconShape = IconShape.Plus
                                                    tooltipTitle = "Создать"
                                                    onClickFunction = { _ ->
                                                        dom.locationParentAddDialog {
                                                            headerTitle = "Новый город"
                                                            parentCountry = allCountryCores.find { tourPointStartCountry?.id == it.id }
                                                            childParentTypes = listOf(LocationType.TOWN)
                                                            onSaveFunction = {
                                                                async {
                                                                    tourPointStartTown = Option(it.coreId, it.name, false)
                                                                    tourPointStartCountryTowns = Locations.getParentCores(
                                                                            0, 500, "", tourPointStartCountry?.id
                                                                            ?: 0, listOf(LocationType.TOWN)
                                                                    ).await().data
                                                                    enableSaveButton()
                                                                    this@clrForm.render()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                clrGroup("Точка окончания", true) {
                                    hidden = tourPointType == TourPointType.POINT
                                    div {
                                        style.apply { display = "flex"; flexDirection = "column" }
                                        div {
                                            style.display = "flex"
                                            label {
                                                style.paddingRight = "8px"
                                                appendText("Страна:")
                                            }
                                            clrSelect<Option?> {
                                                clrOption("Не выбрана", null)
                                                allCountryCores.forEach {
                                                    val name = it.publishedName ?: it.draftName
                                                    clrOption(
                                                            name ?: "-",
                                                            Option(it.id, name ?: "", false),
                                                            tourPointFinishCountry?.id == it.id
                                                    )
                                                }

                                                onChangeFunction = { _ ->
                                                    async {
                                                        tourPointFinishCountry = selectedOptions.first().value
                                                        tourPointFinishTown = null
                                                        if (tourPointFinishCountry != null) {
                                                            tourPointFinishCountryTowns = Locations.getParentCores(
                                                                    0, 500, "", tourPointFinishCountry?.id
                                                                    ?: 0, listOf(LocationType.TOWN)
                                                            ).await().data
                                                            val countryId = allCountryCores.find { tourPointFinishCountry?.id == it.id }?.run {
                                                                publishedVersionId ?: draftVersionId
                                                            } ?: 0
                                                            Countries.getById(countryId).await().area?.let {
                                                                val activeMap = if (tourPointType == TourPointType.POINT) googleMapPoint else googleMapRoute
                                                                activeMap.mapFitBounds(
                                                                        it.southWest.latitude,
                                                                        it.southWest.longitude,
                                                                        it.northEast.latitude,
                                                                        it.northEast.longitude
                                                                )
                                                            }
                                                        } else {
                                                            tourPointFinishCountryTowns = arrayOf()
                                                        }
                                                        enableSaveButton()
                                                        this@clrForm.render()
                                                    }
                                                }
                                            }
                                            clrButton {
                                                style = ButtonStyle.Flat
                                                isIcon = true
                                                iconShape = IconShape.Plus
                                                tooltipTitle = "Создать"
                                                onClickFunction = { _ ->
                                                    dom.countryAddDialog {
                                                        onSaveFunction = {
                                                            async {
                                                                tourPointFinishCountry = Option(it.coreId, it.name, false)
                                                                allCountryCores = Locations.getCountryCores(0, 500, "").await().data
                                                                tourPointFinishTown = null
                                                                tourPointFinishCountryTowns = arrayOf()
                                                                enableSaveButton()
                                                                this@clrForm.render()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        if (tourPointFinishCountry != null) {
                                            div {
                                                style.display = "flex"
                                                label {
                                                    style.paddingRight = "8px"
                                                    appendText("Город:")
                                                }
                                                if (tourPointFinishCountryTowns.isNotEmpty()) {
                                                    clrSelect<Option?> {
                                                        clrOption("Не выбран", null)
                                                        tourPointFinishCountryTowns.forEach {
                                                            val version = it.published ?: it.draft
                                                            clrOption(
                                                                    version?.name ?: "—",
                                                                    Option(it.id, version?.name ?: "", false),
                                                                    tourPointFinishTown?.id == it.id
                                                            )
                                                        }

                                                        onChangeFunction = { _ ->
                                                            tourPointFinishTown = selectedOptions.first().value
                                                            if (tourPointFinishTown != null) {
                                                                val townVersion = tourPointFinishCountryTowns.find { tourPointFinishTown?.id == it.id }?.run {
                                                                    published ?: draft
                                                                }
                                                                if (townVersion != null) {
                                                                    townVersion.mapData?.area?.let {
                                                                        val activeMap = if (tourPointType == TourPointType.POINT) googleMapPoint else googleMapRoute
                                                                        activeMap.mapFitBounds(
                                                                                it.southWest.latitude,
                                                                                it.southWest.longitude,
                                                                                it.northEast.latitude,
                                                                                it.northEast.longitude
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            enableSaveButton()
                                                        }
                                                    }
                                                } else {
                                                    label {
                                                        appendText("У страны нет добавленных городов")
                                                    }
                                                }
                                                clrButton {
                                                    style = ButtonStyle.Flat
                                                    isIcon = true
                                                    iconShape = IconShape.Plus
                                                    tooltipTitle = "Создать"
                                                    onClickFunction = { _ ->
                                                        dom.locationParentAddDialog {
                                                            headerTitle = "Новый город"
                                                            parentCountry = allCountryCores.find { tourPointFinishCountry?.id == it.id }
                                                            childParentTypes = listOf(LocationType.TOWN)
                                                            onSaveFunction = {
                                                                async {
                                                                    tourPointFinishTown = Option(it.coreId, it.name, false)
                                                                    tourPointFinishCountryTowns = Locations.getParentCores(
                                                                            0, 500, "", tourPointFinishCountry?.id
                                                                            ?: 0, listOf(LocationType.TOWN)
                                                                    ).await().data
                                                                    enableSaveButton()
                                                                    this@clrForm.render()
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    googleMapPointDiv = div {
                        hidden = tourPointType != TourPointType.POINT
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }

                        googleMapPoint = googleMap("100%", "500px") {
                            markerMode = GoogleMapMarkerMode.Marker
                            markerCanMove = true

                            tourPointStartMarker?.let {
                                mapMarker(it.latitude, it.longitude)
                            }

                            onChangeFunction = {
                                tourPointStartMarker = marker?.let { Point(it.latitude, it.longitude) }
                                enableSaveButton()
                            }
                        }.apply {
                            tourPointStartMarker?.let {
                                mapSetCenter(it.latitude, it.longitude)
                                mapSetZoom(12)
                            } ?: mapBounds(-10.0, -90.0, 10.0, 90.0)
                        }
                    }
                    googleMapRouteDiv = div {
                        hidden = tourPointType != TourPointType.ROUTE
                        style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }

                        googleMapRoute = googleMap("100%", "500px") {
                            markerMode = GoogleMapMarkerMode.Route
                            markerCanMove = true

                            tourPointStartMarker?.let {
                                mapRoutePoint(it.latitude, it.longitude)
                            }
                            tourPointFinishMarker?.let {
                                mapRoutePoint(it.latitude, it.longitude)
                            }

                            onChangeFunction = {
                                if (routePoints.size >= 1) {
                                    tourPointStartMarker = routePoints[0].let { Point(it.latitude, it.longitude) }
                                    enableSaveButton()
                                    if (routePoints.size >= 2)
                                        tourPointFinishMarker = routePoints[1].let { Point(it.latitude, it.longitude) }
                                }
                            }
                        }
                    }
                }
                div {
                    clrForm {
                        isCompact = true
                        clrBlock {
                            clrGroup("Места") {
                                if (tourPointSights.size > 0) {
                                    tourPointSights.sortBy { it.name.toLowerCase() }
                                    tourPointSights.forEach { sight ->
                                        clrLabel(sight.name) {
                                            isDismissable = true
                                            onDismissFunction = {
                                                tourPointSights.remove(sight)
                                                enableSaveButton()
                                            }
                                        }
                                    }
                                }
                            }
                            clrGroup {
                                suggestionBox<Location> {
                                    width = 300
                                    inputPlaceholder = "Добавьте достопримечательности"
                                    getDataFunction = { query ->
                                        Locations.get(
                                                0, 10, name = query, locationType = listOf(LocationType.LANDMARK)
                                        )
                                    }
                                    suggestionFieldFunction = { it.name }
                                    onSuggestionSelect = { entry ->
                                        if (tourPointSights.none { it.id == entry.coreId }) {
                                            tourPointSights.add(Option(entry.coreId, entry.name, false))
                                            enableSaveButton()
                                            this@clrForm.render()
                                        } else this@clrForm.render()
                                    }
                                    onNotFoundAdd = { query ->
                                        dom.locationAddDialog("LANDMARK") {
                                            headerTitle = "Новая достопримечательность"
                                            locationName = query
                                            onSaveFunction = {
                                                tourPointSights.add(Option(it.coreId, it.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                                clrButton {
                                    style = ButtonStyle.Flat
                                    isIcon = true
                                    iconShape = IconShape.Plus
                                    tooltipTitle = "Создать"
                                    onClickFunction = { _ ->
                                        dom.locationAddDialog("LANDMARK") {
                                            headerTitle = "Новая достопримечательность"
                                            onSaveFunction = {
                                                tourPointSights.add(Option(it.coreId, it.name, false))
                                                enableSaveButton()
                                                this@clrForm.render()
                                            }
                                        }
                                    }
                                }
                            }
                            clrGroup("Краткое описание") {
                                clrTextArea {
                                    value = tourPointBriefDescription
                                    onChangeFunction = {
                                        tourPointBriefDescription = value
                                    }
                                    onInputFunction = {
                                        enableSaveButton()
                                    }
                                }
                            }
                        }
                        clrBlock("Полное описание") {}
                    }
                    CKEDITOR.config.filebrowserBrowseUrl = ""
                    CKEDITOR.config.filebrowserUploadUrl = ""
                    CKEDITOR.config.height = 400
                    CKEDITOR.config.removeButtons = ""
                    CKEDITOR.config.colorButton_colors = "757374,9B59B6,6B2365,6c2267,9b7fa0,1ABC9C,2ECC71,3498DB,9B59B6,4E5F70,F1C40F,16A085,27AE60,2980B9,8E44AD,2C3E50,F39C12,E67E22,E74C3C,ECF0F1,95A5A6,DDD,FFF,D35400,C0392B,BDC3C7,7F8C8D,999,000"
                    CKEDITOR.config.also { config ->
                        config.toolbarGroups = js("toolbarGroups: [" +
                                "{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] }," +
                                "{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] }," +
                                "{ name: 'forms', groups: [ 'forms' ] }," +
                                "{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] }," +
                                "{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },\n" +
                                "{ name: 'insert', groups: [ 'insert' ] }," +
                                "{ name: 'styles', groups: [ 'styles' ] }," +
                                "{ name: 'colors', groups: [ 'colors' ] }" +
                                "]")
                        config.removeButtons = "scevalign_top,scevalign_center,scevalign_bottom,Image,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,About,ShowBlocks,BGColor,PasteFromWord,Checkbox"
                        config.removePlugins = "scevalign,Image"
                        config.customConfig = ""
                    }
                    ckEditor {
                        setData(tourPointFullDescription)
                        on("change") {
                            if (checkDirty()) {
                                tourPointFullDescription = getData(false)
                                enableSaveButton()
                            }
                        }
                    }
                    div {
                        style.marginTop = "24px"
                        validationAlertContainer = div { style.marginBottom = "5px" }
                        createSaveButton()
                        clrButton("Отмена") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Times
                            onClickFunction = {
                                Navigation.Tours.tourTab(objectId, coreId, "route")
                            }
                        }
                    }
                }
            }
        }
    }
}
