package ru.playa.sce.components

import kotlinx.coroutines.await
import org.w3c.dom.*
import org.w3c.files.Blob
import org.w3c.files.get
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.img
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Assets
import ru.playa.sce.core.Application
import ru.playa.sce.dto.Asset
import ru.playa.sce.dto.AssetType
import kotlin.dom.clear
import kotlin.math.floor

class CKEditorImagePluginDialog(val parent: HTMLElement, private val objectId: Int, private val coreId: Int, private val editor: CKEDITOR.editor) : Component(parent) {
    private fun HTMLElement.buildEditScreen(asset: Asset) {
        lateinit var widthInput: Input
        lateinit var heightInput: Input
        lateinit var marginHInput: Input
        lateinit var marginVInput: Input
        lateinit var submitButton: Button
        lateinit var preveiw: HTMLImageElement
        var align = "auto"
        val assetUrl = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}"
        apply {
            clear()
            div {
                preveiw = img {
                    style.width = "505px"

                }.apply {
                    onloadend = {
                        if (widthInput.value == "0") {
                            widthInput.value = naturalWidth.toString()
                            widthInput.render()
                        }
                        if (heightInput.value == "0") {
                            heightInput.value = naturalHeight.toString()
                            heightInput.render()
                        }
                        Unit
                    }
                    onload = {
                        if (widthInput.value == "0") {
                            widthInput.value = naturalWidth.toString()
                            widthInput.render()
                        }
                        if (heightInput.value == "0") {
                            heightInput.value = naturalHeight.toString()
                            heightInput.render()
                        }
                        Unit
                    }
                    src = assetUrl
                }
            }
            clrForm {
                clrBlock("") {
                    clrGroup("Ширина") {
                        widthInput = clrInput {
                            type = InputType.Text
                            validationPredicate = { this.value.matches("^\\d{1,6}\$") }
                            value = asset.width.toString()
                            if (value == "0") value = preveiw.naturalWidth.toString()
                            onChangeFunction = {
                                if (this.isInvalid) {
                                    submitButton.hide()
                                    submitButton.render()
                                } else {
                                    submitButton.show()
                                    submitButton.render()
                                }
                            }
                        }
                    }

                    clrGroup("Высота") {
                        heightInput = clrInput {
                            type = InputType.Text
                            validationPredicate = { this.value.matches("^\\d{1,6}\$") }
                            value = asset.height.toString()
                            if (value == "0") value = preveiw.naturalHeight.toString()
                            onChangeFunction = {
                                if (this.isInvalid) {
                                    submitButton.isDisabled = true
                                    submitButton.render()
                                } else {
                                    submitButton.isDisabled = false
                                    submitButton.render()
                                }
                            }
                        }
                    }
                    clrGroup("Отступ текста вертикальный") {
                        marginVInput = clrInput {
                            value = "10"
                            validationPredicate = { this.value.matches("^\\d{1,6}\$") }
                            onChangeFunction = {
                                if (this.isInvalid) {
                                    submitButton.isDisabled = true
                                    submitButton.render()
                                } else {
                                    submitButton.isDisabled = false
                                    submitButton.render()
                                }
                            }
                        }
                    }
                    clrGroup("Отступ текста горизонтальный") {
                        marginHInput = clrInput {
                            value = "10"
                            validationPredicate = { this.value.matches("^\\d{1,6}\$") }
                            onChangeFunction = {
                                if (this.isInvalid) {
                                    submitButton.isDisabled = true
                                    submitButton.render()
                                } else {
                                    submitButton.isDisabled = false
                                    submitButton.render()
                                }
                            }
                        }
                    }
                    clrGroup("Расположение") {
                        clrDropdown("Слева") {
                            this.buttonStyle = ButtonStyle.Flat
                            arrayListOf("Слева", "Справа").forEach { direction ->
                                clrItem(direction) {
                                    this.onClickFunction = {
                                        this@clrDropdown.text = this.text
                                        align = when (this.text) {
                                            "Центр" -> "center"
                                            "Верх" -> "top"
                                            "Слева" -> "left"
                                            "Справа" -> "right"
                                            "Низ" -> "bottom"
                                            else -> {
                                                "bottom"
                                            }
                                        }
                                        this@clrDropdown.render()
                                    }
                                }
                            }
                        }
                    }
                }
                clrButtonsBlock {
                    submitButton = clrButton("Ок") {
                        style = ButtonStyle.Success
                        iconShape = IconShape.Check
                        onClickFunction = {
                            if (!widthInput.isInvalid && !heightInput.isInvalid) {
                                editor.insertHtml("<img align=\"$align\" width=\"${widthInput.value}\" height=\"${heightInput.value}\" class=\"sce-content-image\" style=\"vertical-align: $align !important;\" alt=\"${asset.name}\" src=\"$assetUrl\" vspace=\"${marginVInput.value}\" hspace=\"${marginHInput.value}\"></img>")
                                parent.clear()
                            }
                        }
                    }
                }
            }
        }
    }

    override fun build() = async {
        newDiv {
            clrModalDialog {
                this.windowWidth = "40rem"
                clrModalDialogContent {
                    clrModalDialogHeader("Вставка изображения") {
                        parent.clear()
                    }
                    lateinit var plane: HTMLDivElement
                    async {
                        val ratio = Assets.getRatio("description").await()
                        val assets: Array<Asset> = if (objectId != -1) Assets.get(objectId).await().data else emptyArray()
                        clrModalDialogBody {
                            val images = assets.filter { it.assetType == AssetType.IMAGE.name && it.imageType == null }

                            plane = div {
                                style.width = "100%"
                                for (rowIndex in 0 until -floor(-images.size.toDouble() / 4).toInt()) {
                                    clrGridRow {
                                        (0..3).forEach { colIndex ->
                                            val index = 4 * rowIndex + colIndex
                                            if (index < images.size) {
                                                val asset = images[index]
                                                clrGridColumn(12, 12, 6, 6, 3) {
                                                    div {
                                                        style.display = "flex"
                                                        style.flexDirection = "column"
                                                        style.alignItems = "center"
                                                        style.width = "${ratio.thumbnailWidth + 2}px"
                                                        clrCard {
                                                            isClickable = true
                                                            clrCardImg {
                                                                lowsrc = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.variants.firstOrNull { it.code == "thumbnail" }
                                                                        ?.filename ?: asset.filename}"
                                                                src = "/sce/assets/${Application.account.uuid}/${asset.coreId}/${asset.filename}"
                                                                this.onclick = {
                                                                }
                                                            }

                                                            onClickFunction = { _ ->
                                                                plane.buildEditScreen(asset)
                                                            }
                                                            clrCardFooter {
                                                                clrCardText(asset.name)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        lateinit var fileUpload: Input
                        div {
                            clrForm {
                                clrBlock {
                                    fileUpload = clrInput {
                                        type = InputType.File
                                        accept = ".jpg,.png,.bmp"
                                        onChangeFunction = { event ->
                                            this@clrModalDialogContent.removeChild(this@div)
                                            val file = (event.target as HTMLInputElement).files?.get(0)
                                            if (file != null) {
                                                if (file.size > 10485760) {
                                                    Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                        style = Alert.Style.WARNING
                                                        clrAlertItem("Файл превышает допустимый размер", style)
                                                    }
                                                } else {
                                                    lateinit var spiner: Spinner
                                                    plane.clear()
                                                    plane.apply {
                                                        spiner = clrSpinner {
                                                        }
                                                        span {
                                                            innerText = "Подождите, идет загрузка файла"
                                                        }
                                                        spiner.show()
                                                    }
                                                    Assets.sendFile(this@clrForm.getHTMLElement() as HTMLFormElement, objectId, coreId, file.name
                                                            , file as Blob, file.name, true).then {
                                                        spiner.hide()
                                                        plane.buildEditScreen(it)
                                                    }.catch {
                                                        Application.loadingIndicator.hide()
                                                        Application.applicationLayout.topLevelAlert.showLimitedTime {
                                                            style = Alert.Style.ERROR
                                                            clrAlertItem("Ошибка загрузки файла", style)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        onPostRender = { hide() }
                                    }
                                }
                            }
                            clrButton("Загрузить с компьютера") {
                                style = ButtonStyle.Flat
                                onClickFunction = {
                                    fileUpload.getHTMLElement().click()
                                }
                            }
                        }
                    }
                    clrModalDialogFooter {

                    }
                }
            }
        }
    }
}