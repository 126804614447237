package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.DeleteStatus
import ru.playa.sce.dto.ObjectStatus
import ru.playa.sce.dto.TourPacket

object TourPackets {

    fun get(
            start: Int = 0,
            pageSize: Int = 50,
            orderField: String = "name",
            reverseOrder: Boolean = false,
            name: String = "",
            strict: Boolean = false,
            external: String = "",
            objectStatus: List<ObjectStatus> = emptyList(),
            visibility: List<String> = emptyList(),
            tags: List<String> = emptyList(),
            countryId: Int? = null,
            tourPacketTypeId: Int? = null,
            deleteStatus: DeleteStatus = DeleteStatus.ALL
    ) = Network.request<ListResult<TourPacket>>(Network.Method.GET,
            "/sce-web/api/public/objects/tourpacket/search?start=$start&pageSize=$pageSize&orderField=$orderField&reverseOrder=$reverseOrder&name=$name&strict=$strict&external=$external${objectStatus.joinToString("") { "&objectStatus=$it" }}${visibility.joinToString("") { "&visibility=$it" }}${tags.joinToString("") { "&tags=$it" }}&deleteStatus=$deleteStatus${if (countryId != null) "&countryId=$countryId" else ""}${if (tourPacketTypeId != null) "&tourPacketTypeId=$tourPacketTypeId" else ""}")

    fun getById(id: Int) = Network.request<TourPacket>(Network.Method.GET, "/sce-web/api/user/objects/tourpacket/$id")

    fun getDraft(coreId: Int) = Network.request<TourPacket>(Network.Method.GET, "/sce-web/api/user/objects/tourpacket/draft/$coreId")

    fun restoreArchived(coreId: Int, version: Int, archive: Boolean = true) = Network.request<TourPacket>(Network.Method.GET,
            "/sce-web/api/user/objects/tourpacket/draft/$coreId?version=$version&archive=$archive")

    fun create(tourPacket: TourPacket) = Network.send(Network.Method.POST, tourPacket, "/sce-web/api/user/objects/tourpacket")

    fun update(tourPacket: TourPacket) = Network.send(Network.Method.PUT, tourPacket, "/sce-web/api/user/objects/tourpacket")

    fun delete(id: Int) = Network.requestNoResult(Network.Method.DELETE, "/sce-web/api/user/objects/tourpacket/$id")

    fun approve(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/tourpacket/approve/$id")

    fun publish(id: Int) = Network.requestNoResult(Network.Method.GET, "/sce-web/api/user/objects/tourpacket/publish/$id")
}