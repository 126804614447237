package ru.playa.sce.api

import ru.playa.kotlinx.clarity.js.util.ListResult
import ru.playa.sce.core.Network
import ru.playa.sce.dto.ApplicableType


object Common {
    fun getApplicableTypes() =
            Network.request<ListResult<ApplicableType>>(Network.Method.GET,
                    "/sce-web/api/user/objecttype")
}