package ru.playa.sce.views.tours

import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import ru.playa.kotlinx.clarity.js.components.*
import ru.playa.kotlinx.clarity.js.html.button
import ru.playa.kotlinx.clarity.js.html.div
import ru.playa.kotlinx.clarity.js.html.label
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.icons.Direction
import ru.playa.kotlinx.clarity.js.icons.IconShape
import ru.playa.kotlinx.clarity.js.icons.clrIcon
import ru.playa.kotlinx.clarity.js.util.async
import ru.playa.sce.api.Tours
import ru.playa.sce.components.*
import ru.playa.sce.core.Application
import ru.playa.sce.core.Navigation
import ru.playa.sce.dto.ObjectStatus
import ru.playa.sce.dto.Tour
import ru.playa.sce.dto.TourPoint
import ru.playa.sce.views.BaseVOView
import kotlin.dom.appendText
import kotlin.js.Promise

class TourView(id: Int, coreId: Int, initialTab: String = "main") : BaseVOView<Tour>(id, coreId, initialTab) {

    companion object {
        const val PATH = "tours/tour"
    }

    override var objectType: String = "tour"

    override fun getVO(): Promise<Tour> {
        return Tours.getById(id)
    }

    override fun getDraft(): Promise<Tour> {
        return Tours.getDraft(vo.coreId)
    }

    override fun approve(): Promise<Unit> {
        return Tours.approve(vo.id)
    }

    override fun publish(): Promise<Unit> {
        return Tours.publish(vo.id)
    }

    override fun delete(id: Int): Promise<Unit> {
        return Tours.delete(vo.id)
    }

    override fun restoreArchived(): Promise<Tour> {
        return Tours.restoreArchived(vo.coreId, vo.id)
    }

    override fun navigateTo(id: Int, coreId: Int) {
        Navigation.Tours.tour(id, coreId)
    }

    override fun navigateToParent() {
        Navigation.Tours.tours()
    }

    override fun toDraftTab(tab: String) {
        Navigation.Tours.tourTab(draft.id, draft.coreId, tab)
    }

    override fun PageHeader.buildPageHeaderPathPart() {
        clrLink("Объекты") {
            Navigation.start()
        }
        clrLink("Туры") {
            Navigation.Tours.tours()
        }
    }

    private lateinit var tourPoints: Array<TourPoint>
    private lateinit var mainTab: Tab
    private lateinit var routeTab: Tab
    private lateinit var routeDatagrid: DataGrid<TourPoint>

    override fun render() {
        async {
            initEditorData().await()
            tourPoints = Tours.getTourPoints(vo.id).await().data

            dom.apply {
                createPageHeader(objectType)
                clrTabs {
                    mainTab = clrTab("Основная информация", initialTab == "main", rebuildOnActive = true) {
                        div {
                            style.apply { display = "flex"; flexDirection = "row" }
                            div {
                                style.width = "600px"
                                clrForm {
                                    isCompact = true
                                    clrBlock {
                                        clrGroup("Название тура") {
                                            if (vo.name.isNotBlank()) span { appendText(vo.name) } else span { appendText("—") }
                                        }
                                        clrGroup("Страны") {
                                            if (vo.countries.isNotEmpty()) {
                                                for (country in vo.countries) {
                                                    span {
                                                        appendText(country.name)
                                                        vo.countries.run { if (indexOf(country) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Тип тура") {
                                            span { appendText(vo.type?.name ?: "—") }
                                        }
                                        clrGroup("Продолжительность (в днях)") {
                                            span { appendText(vo.days.toString()) }
                                        }
                                        clrGroup("Город начала тура") {
                                            span { appendText(vo.start?.name ?: "—") }
                                        }
                                        clrGroup("Город окончания тура") {
                                            span { appendText(vo.finish?.name ?: "—") }
                                        }
                                        clrGroup("Теги") {
                                            if (vo.tags.isNotEmpty()) {
                                                val tourTags = vo.tags.apply { sortBy { it.name.toLowerCase() } }
                                                tourTags.forEach {
                                                    clrLabel(it.name) {
                                                        style = Label.Style.Blue
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Области видимости") {
                                            if (vo.visibility.isNotEmpty()) {
                                                for (area in vo.visibility) {
                                                    span {
                                                        appendText(area.name)
                                                        vo.visibility.run { if (indexOf(area) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                        clrGroup("Варианты написания") {
                                            val synonyms = vo.synonyms ?: ""
                                            if (synonyms.isNotBlank()) span { appendText(synonyms) } else span { appendText("—") }
                                        }
                                        clrGroup("Коды во внешних справочниках") {
                                            if (vo.externalMappings.isNotEmpty()) {
                                                for (mapping in vo.externalMappings) {
                                                    span {
                                                        appendText("${mapping.external.name} (${mapping.code})")
                                                        vo.externalMappings.run { if (indexOf(mapping) < lastIndex) appendText(", ") }
                                                    }
                                                }
                                            } else {
                                                span { appendText("—") }
                                            }
                                        }
                                    }
                                }
                                clrButton("Редактировать") {
                                    style = ButtonStyle.Secondary
                                    iconShape = IconShape.Pencil
                                    isDisabled = !privileges.contains("EDIT")
                                    onClickFunction = {
                                        if (objectStatus == ObjectStatus.ARCHIVED) {
                                            clrChoiceDialog("Редактирование архивированной версии восстановит ее и заменит существующий черновик. Продолжить?") {
                                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                                    async {
                                                        Tours.restoreArchived(vo.coreId, vo.id).await()
                                                        Navigation.Tours.edit(vo.coreId)
                                                    }
                                                }
                                                clrChoice("Нет", ButtonStyle.Secondary, IconShape.Times)
                                            }
                                        } else {
                                            if (objectStatus == ObjectStatus.PUBLISHED) {
                                                Application.applicationLayout.topLevelAlert.show {
                                                    val alertScope = this
                                                    clrAlertItem("Вы были перенаправлены на страницу редактирования Черновика") {
                                                        button("btn alert-action") {
                                                            innerText = "OK"
                                                            onclick = { _ ->
                                                                alertScope.items.clear()
                                                                alertScope.hide()
                                                            }
                                                        }
                                                    }
                                                    style = Alert.Style.WARNING
                                                }
                                            }
                                            Navigation.Tours.edit(vo.coreId)
                                        }
                                    }
                                }
                            }
                            div {
                                style.apply { marginTop = "20px"; marginLeft = "20px"; flexGrow = "1" }
                                googleMap("100%", "500px") {
                                    markerMode = GoogleMapMarkerMode.Route

                                    tourPoints.forEach { tp ->
                                        tp.start.point.let {
                                            val infoText = if (tp.finish != null) "Переезд: ${tp.name}" else "Точка - ${tp.name}"
                                            mapRoutePoint(it.latitude, it.longitude) {
                                                div { appendText(infoText) }
                                                div { appendText("Продолжительность: ${tp.startDay} - ${tp.endDay}") }
                                            }
                                        }
                                        if (tp.finish != null) {
                                            tp.finish.point.let {
                                                mapRoutePoint(it.latitude, it.longitude) {
                                                    div { appendText("Переезд: ${tp.name}") }
                                                    div { appendText("Продолжительность: ${tp.startDay} - ${tp.endDay}") }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    routeTab = clrTab("Маршрут", initialTab == "route") {
                        clrButton("Добавить") {
                            style = ButtonStyle.Secondary
                            iconShape = IconShape.Plus
                            isDisabled = !privileges.contains("EDIT")
                            onClickFunction = {
                                checkVersionObjectStatus("route") {
                                    Navigation.Tours.addTourPoint(draft.id, draft.coreId)
                                }
                            }
                        }
                        routeDatagrid = clrDatagrid {
                            clrColumn("Название", 2) {
                                appendText(it.name)
                            }
                            clrColumn("Продолжительность", 1) {
                                appendText("${it.startDay} - ${it.endDay}")
                            }
                            clrColumn("Краткое описание", 2) {
                                appendText(it.briefDescription)
                            }
                            clrColumn("", 1) { point ->
                                if (privileges.contains("EDIT")) {
                                    clrIcon(IconShape.Pencil) {
                                        onclick = {
                                            checkVersionObjectStatus("route") {
                                                Navigation.Tours.editTourPoint(draft.id, draft.coreId, point.id)
                                            }
                                        }
                                    }
                                    this.style.color = "#0079b8"
                                    this.style.cssFloat = "right"
                                    this.style.cursor = "pointer"
                                    this.style.marginRight = "24px"
                                }
                            }
                            clrOverallDetails {
                                async {
                                    buildRouteDetails(it)
                                }
                            }
                            pageSize = 50
                            dataList = tourPoints.toList()
                        }
                    }
                    createPhotoTab()
                    createIllustrationTab()
                    createFilesTab()
                    createDescriptionsTab()
                    createNewsTab()
                    createBannersTab()
                }
            }
        }
    }

    private fun HTMLDivElement.buildRouteDetails(point: TourPoint) {
        val sameDayPoints = tourPoints.filter { it.startDay == point.startDay }
        div {
            style.apply { width = "100%"; display = "flex"; flexDirection = "column" }
            div {
                style.apply { display = "flex"; justifyContent = "space-between" }
                div {
                    clrForm {
                        clrBlock {
                            clrGroup("Точка начала") {
                                label { appendText("Страна: ${point.start.country?.name ?: "—"}") }
                                label { appendText("Город: ${point.start.town?.name ?: "—"}") }
                            }
                            if (point.finish != null) {
                                clrGroup("Точка окончания") {
                                    label { appendText("Страна: ${point.finish.country?.name ?: "—"}") }
                                    label { appendText("Город: ${point.finish.town?.name ?: "—"}") }
                                }
                            }
                            clrGroup("Места") {
                                var sightsText = ""
                                val sightsLastIndex = point.sights.lastIndex
                                point.sights.forEachIndexed { index, sight ->
                                    sightsText += sight.name
                                    if (index != sightsLastIndex) sightsText += ", "
                                }
                                if (sightsLastIndex == -1) sightsText += "—"
                                label { appendText(sightsText) }
                            }
                        }
                    }
                    div { innerHTML = point.fullDescription }
                }
                googleMap("400px", "400px") {
                    if (point.finish != null) {
                        markerMode = GoogleMapMarkerMode.Route

                        point.start.point.let {
                            mapRoutePoint(it.latitude, it.longitude) {
                                div { appendText("Переезд - ${point.name}") }
                                div { appendText("Продолжительность: ${point.startDay} - ${point.endDay}") }
                            }
                        }
                        point.finish.point.let {
                            mapRoutePoint(it.latitude, it.longitude) {
                                div { appendText("Переезд - ${point.name}") }
                                div { appendText("Продолжительность: ${point.startDay} - ${point.endDay}") }
                            }
                        }
                    } else {
                        markerMode = GoogleMapMarkerMode.Marker
                        point.start.point.let {
                            mapMarker(it.latitude, it.longitude)
                        }
                    }
                }.apply {
                    if (point.finish == null) {
                        point.start.point.let {
                            mapSetCenter(it.latitude, it.longitude)
                            mapSetZoom(12)
                        }
                    }
                }
            }
            clrForm {
                if (sameDayPoints.size > 1 && sameDayPoints.indexOf(point) > 0) {
                    clrButton("Поднять вверх") {
                        style = ButtonStyle.Secondary
                        iconShape = IconShape.Angle
                        isDisabled = !privileges.contains("EDIT")
                        onClickFunction = {
                            checkVersionObjectStatus("route") {
                                reorderTourPoints(point, sameDayPoints, true)
                            }
                        }
                    }
                }
                if (sameDayPoints.size > 1 && sameDayPoints.indexOf(point) != sameDayPoints.lastIndex) {
                    clrButton("Опустить вниз") {
                        style = ButtonStyle.Secondary
                        iconShape = IconShape.Angle
                        iconDirection = Direction.Down
                        isDisabled = !privileges.contains("EDIT")
                        onClickFunction = {
                            checkVersionObjectStatus("route") {
                                reorderTourPoints(point, sameDayPoints, false)
                            }
                        }
                    }
                }
                clrButton("Редактировать") {
                    style = ButtonStyle.Secondary
                    iconShape = IconShape.Pencil
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = {
                        checkVersionObjectStatus("route") {
                            Navigation.Tours.editTourPoint(draft.id, draft.coreId, point.id)
                        }
                    }
                }
                clrButton("Удалить") {
                    style = ButtonStyle.WarningOutline
                    iconShape = IconShape.Trash
                    isDisabled = !privileges.contains("EDIT")
                    onClickFunction = {
                        checkVersionObjectStatus("route") {
                            clrChoiceDialog("Точка тура будет удалена. Продолжить?") {
                                modalDialogConfiguration = {
                                    this.size = ModalDialog.Size.Small
                                }
                                clrChoice("Да", ButtonStyle.Primary, IconShape.Check) {
                                    async {
                                        Tours.deleteTourPoint(vo.id, point.id).await()
                                        tourPoints = Tours.getTourPoints(vo.id).await().data
                                        routeDatagrid.dataList = tourPoints.toList()
                                        routeDatagrid.render()
                                        mainTab.rebuild()
                                    }
                                }
                                clrChoice("Нет", ButtonStyle.Primary, IconShape.Times)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun reorderTourPoints(point: TourPoint, sameDayPoints: List<TourPoint>, raise: Boolean) {
        async {
            val diff = if (raise) -1 else 1
            val swapPoint = sameDayPoints[sameDayPoints.indexOf(point) + diff]
            val bucket = point.order
            point.order = swapPoint.order
            swapPoint.order = bucket
            Tours.updateTourPoint(point).await()
            Tours.updateTourPoint(swapPoint).await()
            tourPoints = Tours.getTourPoints(vo.id).await().data
            mainTab.rebuild()
            routeTab.rebuild()
        }
    }
}
