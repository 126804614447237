package ru.playa.sce.components

import org.w3c.dom.HTMLElement
import org.w3c.dom.css.CSSStyleDeclaration
import ru.playa.kotlinx.clarity.js.html.newDiv
import ru.playa.kotlinx.clarity.js.html.span
import ru.playa.kotlinx.clarity.js.util.async

external object CKEDITOR {

    fun appendTo(element: HTMLElement): editor

    fun style(style: dynamic): dynamic

    @Suppress("ClassName")
    object config {
        var height: Int
        var width: Int
        var filebrowserBrowseUrl: String
        var filebrowserUploadUrl: String
        var removeButtons: String
        var extraPlugins: String
        var removePlugins: String
        var allowedContent: String
        var extraAllowedContent: String
        var toolbar: String
        var editorConfig: (config) -> Unit
        var toolbarGroups: dynamic
        var customConfig: String = definedExternally
        var colorButton_colors: String
    }

    @Suppress("ClassName")
    object editor {
        fun getData(internal: Boolean): String
        fun setData(data: String)
        fun on(eventName: String, listenerFunction: (eventInfo) -> dynamic)
        fun checkDirty(): Boolean
        fun resetDirty()
        fun addCommand(name: String, cmd: dynamic)
        fun getCommand(name: String): dynamic
        fun insertHtml(html: String)
        fun getSelection(): selection
        val ui: ui
        var document: Document
        fun attachStyleStateChange(style: dynamic, callback: dynamic)
    }

    @Suppress("ClassName")
    object ui {
        fun addButton(name: String, bt: dynamic)
    }

    @Suppress("ClassName")
    object plugins {
        fun add(name: String, plugin: CKEditorPlugin)
        fun get(name: String): Any?
    }

    @Suppress("ClassName")
    object selection {
        fun getSelectedElement(): element
        fun getStartElement(): element
    }

    @Suppress("ClassName")
    object element {
        fun getParent(): element
        fun removeClass(name: String)
        fun addClass(name: String)
        fun setStyle(name: String, value: String)
        fun getHtml(): String
        fun getChildren(): nodeList
        fun getName(): String
    }

    @Suppress("ClassName")
    object nodeList {
        fun toArray(): Array<element>
    }

    @Suppress("ClassName")
    object Document {
        fun findOne(selector: String): element?
    }

    @Suppress("ClassName")
    abstract class eventInfo
}

class CKEditorController(val block: CKEDITOR.editor.() -> Unit, val parent: HTMLElement) {
    private var root: HTMLElement? = null
    lateinit var editor: CKEDITOR.editor
    fun build(): HTMLElement {
        return newDiv {
            js("for(name in CKEDITOR.instances)\n" +
                    "{\n" +
                    "    if(CKEDITOR.instances[name] != null){" +
                    "    CKEDITOR.instances[name].removeAllListeners();" +
                    "    CKEDITOR.instances[name].destroy(true);}\n" +
                    "}")
            editor = CKEDITOR.appendTo(this).apply {
                this.on("instanceReady") {
                    editor.apply(block)
                }
            }
        }
    }

    private var cssFunction: CSSStyleDeclaration.() -> Unit = {}

    /**
     * Задаёт css стиль коренвого элемента
     * @param style Лямбда - конфигурация стиля
     */
    fun css(style: CSSStyleDeclaration.() -> Unit) {
        cssFunction = style
    }

    /**
     * Лямбда-функция, вызывается после отрисовки элемента
     */
    var onPostRender: () -> Unit = {}

    /**
     * Рендер компонента
     */
    fun render() {
        if (root == null) {
            root = parent.span()
        }
        async {
            val new = build()
            root?.let { parent.replaceChild(new, it) }
            root = new
            cssFunction(new.style)
            onPostRender()
        }
    }

    /**
     * Отобразить компонент
     */
    fun show() {
        root?.hidden = false
    }

    /**
     * Скрыть компонент
     */
    fun hide() {
        root?.hidden = true
    }

    /**
     * Возвращает корневой элемент компонента
     * @return Корневой элемент dom модели компонента
     */
    fun getHTMLElement(): HTMLElement {
        return root!!
    }

}

class CKEditorCommand(var exec: (CKEDITOR.editor) -> Unit)
class CKEditorPlugin(var init: (CKEDITOR.editor) -> Unit)
class CKEditorButton(var label: String, var command: String, var toolbar: String, var icon: String)

fun HTMLElement.ckEditor(block: CKEDITOR.editor.() -> Unit) = CKEditorController(block, this).apply { render() }.editor
